import { useState } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export const copyToClipboard = (value, isJson) => {
  const elem = document.createElement("textarea");
  document.querySelector("body").appendChild(elem);
  if (isJson) {
    elem.value = JSON.stringify(value, null, 4);
  } else {
    elem.value = value
  }
  elem.select();
  document.execCommand('copy');
  elem.remove();
}
const ClipboardCopy = ({ copyText }) => {

  const [tooltipText, setTooltipText] = useState('Copy')
  const [, setIsCopied] = useState(false);

  // const copyIcon = (value) => { 
  // }
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {

    setTooltipText('Copied!')
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="d-block justify-content-center">
      <div className='px-3 mt-3'>
        <input type="text" className="form-control" aria-label="Small" placeholder={copyText} aria-describedby="inputGroup-sizing-sm" />
      </div>
      <div className="text-center mt-3 mb-4">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip >{tooltipText}</Tooltip>}
        >
          <Button variant="primary" onClick={handleCopyClick} onMouseEnter={(e) => { e.stopPropagation(); }} onMouseLeave={(e) => { e.stopPropagation(); setTooltipText('Copy') }}>
            Copy Your Link
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
              <path d="M14.75 3.75H6.5C5.675 3.75 5 4.425 5 5.25V15.75C5 16.575 5.675 17.25 6.5 17.25H14.75C15.575 17.25 16.25 16.575 16.25 15.75V5.25C16.25 4.425 15.575 3.75 14.75 3.75ZM14 15.75H7.25C6.8375 15.75 6.5 15.4125 6.5 15V6C6.5 5.5875 6.8375 5.25 7.25 5.25H14C14.4125 5.25 14.75 5.5875 14.75 6V15C14.75 15.4125 14.4125 15.75 14 15.75Z" fill="white" />
              <path opacity="0.4" d="M11.75 0.75H3.5C2.675 0.75 2 1.425 2 2.25V12C2 12.4125 2.3375 12.75 2.75 12.75C3.1625 12.75 3.5 12.4125 3.5 12V3C3.5 2.5875 3.8375 2.25 4.25 2.25H11.75C12.1625 2.25 12.5 1.9125 12.5 1.5C12.5 1.0875 12.1625 0.75 11.75 0.75Z" fill="white" />
            </svg>
          </Button>

        </OverlayTrigger>
      </div>
    </div>
  );
}
export default ClipboardCopy;