import React, { useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { AddNewTasks, GetTaskList } from '../../../apis/Dashboard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Markdown from './markdowan';
import { useSelector } from 'react-redux';

export const TaskCreate = ({ addTask, onHide }) => {
    const other = useSelector((state) => state.isOther);
    const [isPlaceholder, setIsPlaceholder] = useState()
    const { mutate } = AddNewTasks();
    const { data, refetch } = GetTaskList();
    const [taskText, setTaskText] = useState(addTask);
    const [taskStatus, setTaskStatus] = useState(false);
    const groupId = localStorage.getItem("Sub_id")

    useEffect(() => {
        setIsPlaceholder(other?.tasks_placeholder)
    }, [other])
    const handleTextareaChange = (event) => {
        setTaskText(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const trimmedTaskText = taskText ? taskText.trim() : '';
        if (trimmedTaskText === '') {
            toast.error("Task cannot be empty");
            return;
        }

        // Start loader
        setTaskStatus(true);

        mutate({ title: taskText, group_id: parseInt(groupId) }, {
            onSuccess: () => {
                console.log("Task created");
                setTaskStatus(false);  // Stop loader
                toast.success("Your Task Added");
                setTaskText('');  // Reset task text input
                refetch();  // Fetch updated tasks list
                onHide();  // Hide modal or form
            },
            onError: (error) => {
                setTaskStatus(false);  // Stop loader even if error occurs
                console.error("Error submitting task:", error);
                toast.error("Failed to add task");
            }
        });
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    return (
        <div>
            <ToastContainer />
            <Modal.Header closeButton style={{ background: "#F0F0F0" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Task
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="mb-3">
                            <textarea
                                placeholder={isPlaceholder}
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                style={{ resize: 'none' }}
                                value={taskText}
                                onChange={(e) => setTaskText(e.target.value)}  // Handle text input change
                            ></textarea>
                        </div>
                    </div>

                    {/* Conditionally render loader or button text */}
                    <Button
                        className='float-end m-3'
                        variant="primary"
                        type='submit'
                        disabled={taskStatus}
                    >
                        {!taskStatus ? (
                            'Add to task'
                        ) : (

                            <>
                                Add to task...
                                <Spinner as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true">
                                </Spinner>
                            </>
                        )}
                    </Button>
                </form>
            </Modal.Body>
        </div>
    )
}
