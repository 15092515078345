import React, { useEffect, useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

// images
import img1 from '../../src/assets/images/3.png'
import img2 from '../../src/assets/images/avatars/2.png'
import EventSourceObj from '../utilities/EventSource'
import ReactTyped from "react-typed";
import ReactMarkdown from "react-markdown";
import { ChatLoader } from './chat'
import Markdown from './partials/components/markdowan'
import { TaskCreate } from './partials/components/TaskCreate'

export default function SuggestAdvice({ isActive, challengeID }) {
   

    const [advice, setAdvice] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [isStream, setIsStream] = useState(null)
    const [isShow, setIsShow] = useState(false)
    const [addTask, setAddTask] = useState('')
    const [eventTask, setEventTask] = useState(null);
    const [isReloadLoading, setIsReloadLoading] = useState(false)
    
    useEffect(() => {
        setAdvice('')
        setAddTask('')
        if ((challengeID && !isActive) || loaded) {
            return () => {
               
            };
        }
        let event = EventSourceObj('api/v3/get_content?type=advice&challenge_id=' + challengeID)
        setLoaded(true)
        event.addEventListener('message', (eventData) => {

            let { stream, finished, id, type } = JSON.parse(eventData.data)
            if (id) {
                setLoaded(false)
                setIsStream(id)
            }
            if (type === 'goal' && stream) {
                setLoaded(false)
                setAdvice(prev => prev + stream)
            }
            if (type === 'task' && stream) {
                setLoaded(false)
                setAddTask(prev => prev + stream)
            }
            if (finished) {
            }
        },)
        event.addEventListener('error', (eventData) => {
            event.close()
        }, { once: true })
        return () => {
            event?.close()
        }
    }, [isActive])


    const handleCloseFullScreen = () => {
        setIsShow(!isShow)
    }
    const handleTaskGenrate = () => {
        setIsReloadLoading(true)
        setAddTask('');
        let EventResponces = {};
        let event;
        event = EventSourceObj('api/v3/task_new?challenge_id=' + challengeID);
        event && event.addEventListener('message', (eventData) => {
            setEventTask({});
            setIsReloadLoading(false)
            let eventJsonData = JSON.parse(eventData.data);
            EventResponces = { ...EventResponces, ...eventJsonData };
            setEventTask(EventResponces);
            let { stream, finished, type } = eventJsonData;
            if (finished) {
            }
            if (type === "task" && stream) {
                setAddTask(prev => prev + stream);
            }
        });

        event && event.addEventListener('error', (eventData) => {
            console.error('EventSource error:', eventData);
            event.close();
        });
    }
    return (
        <div>
            <Modal show={isShow} onHide={handleCloseFullScreen}>
                <TaskCreate  addTask={addTask} onHide={handleCloseFullScreen}  />
            </Modal>
            <Card.Body>

                <div>
                    <div className='text-end my-1' >
                        <span className="d-inline-block px-2 py-1 bg-soft-secondary">Select task or try refreshing for new task</span>
                    </div>
                    <div className='py-2 px-4 d-flex  justify-content-between my-2 bg-white rounded-3 flex-wrap gap-2 advice-background' >
                        {loaded ? (
                            <div className='d-flex flex-column  justify-content-start h-100 ' style={{width:"90%"}}>
                                <p className='iq-skeleton' style={{ width: "90%" }}>&nbsp;</p>
                            </div>
                        ) : (
                            <Markdown content={addTask} />
                        )}

                        {/* <h5 className='text-align-center mb-0' >{addTask}</h5> */}
                        <div>
                            <button className="btn btn-primary btn-sm mx-1 rounded-3 "
                                style={{ padding: "2px 4px" }}
                                onClick={() => handleTaskGenrate()}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className={`bi bi-arrow-clockwise ${isReloadLoading ? 'spin' : 'bi bi-arrow-clockwise'}`}
                                    viewBox="0 0 16 16"
                                // style={{ color: 'rgb(64 72 79)' }}
                                >
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                </svg>
                            </button>
                            <button className="btn btn-primary btn-sm mx-1 rounded-3 "
                                style={{ padding: "2px 4px" }}
                                onClick={() => setIsShow(!isShow)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                {
                    loaded ? (<>
                        <div className='d-flex pt-3 flex-column justify-content-start h-100'>
                            {/* Show loading indicator */}
                            <ChatLoader />
                        </div>
                    </>) : (
                        <div>
                            {advice.length !== 0 && (<>
                                <p className='my-5'>
                                    {(!loaded || isStream > 0) ? (<>
                                        <Markdown content={advice} />
                                    </>) : (<>
                                        {/* {" "}<ReactTyped strings={[advice]} typeSpeed={2.5} showCursor={false} /> */}
                                        {/* <Markdown content={advice} /> */}
                                    </>)}
                                </p>
                            </>)}
                        </div>
                    )}

            </Card.Body>
        </div>
    )
}
