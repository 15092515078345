import { memo, Fragment, useContext, useState, useEffect, useRef } from 'react'

// react-bootstrap
import { Row, Col, Accordion, useAccordionButton, Button, AccordionContext, InputGroup, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap'

//react router dom
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

// components
import Card from '../../components/bootstrap/card'

//plugin
import FsLightbox from 'fslightbox-react'

// heading paragraph formating
import ReactMarkdown from 'react-markdown';


// images
import img1 from '../../assets/images/3.png'
import img2 from '../../assets/images/avatars/2.png'
import EventSourceObj from '../../utilities/EventSource'
import { useDispatch, useSelector } from 'react-redux'
import SuggestAdvice from '../suggest-advice'
import Vimeo from '@u-wave/react-vimeo';
import Markdown from '../partials/components/markdowan'
import ShowMoreText from 'react-show-more-text';
import { Nav_Id, setRowData } from '../../store/dashboardConfig/dashboardConfigSlice';
import { TaskCreate } from '../partials/components/TaskCreate'
import { GetToolkit, ToolkitUpdate } from '../../apis/Dashboard'


const CustomToggle = memo(({ children, eventKey, onClick, variant }) => {

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) => {
        onClick(!active ? eventKey : '')
    });


    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Button to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} variant={variant} className="nav-link" role="button" onClick={(e) => {
            decoratedOnClick(isCurrentEventKey)
        }}>
            {children}
        </Button>
    );
})


const AdviceWidget = memo(() => {
    const { group } = useParams();
    const { refetch } = GetToolkit();
    const { isLoading: isLoadingToolkit, mutate: mutateToolkit, isSuccess: isSuccessToolkit } = ToolkitUpdate();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const rowData = useSelector((state) => state.rowData);
    const { data } = GetToolkit()
    // const params = new URLSearchParams(location.search);
    const location = useLocation()
    const GroupID = useSelector(state => state.GroupID);
    const [active, setActive] = useState(null)

    const [isLoading, setIsLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [eventResponce, setEventResponce] = useState({})
    const [adviceMessage, setAdviceMessage] = useState('')
    const [adviceTask, setAdviceTask] = useState('')
    const [imageController, setImageController] = useState({
        toggler: false,
        slide: 1
    });
    const [showMore, setShowMore] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [prevGroupId, setPrevGroupId] = useState(null);
    const [eventTask, setEventTask] = useState(null);
    const [isReloadLoading, setIsReloadLoading] = useState(false)
    const [loadingButtons, setLoadingButtons] = useState({});

    useEffect(() => {
        setActive(null);
        let EventResponces = {};
        let event;

        // Check if the initial load has been completed and if both the previous and current locations are defined and the group IDs are different
        if (prevGroupId !== group) {
            event = EventSourceObj('api/v3/get_content?type=advice_tab&group_id=' + group);
            setPrevGroupId(group);
        }

        event && event.addEventListener('message', (eventData) => {
            setIsLoading(false);
            setEventResponce({});
            let eventJsonData = JSON.parse(eventData.data);
            EventResponces = { ...EventResponces, ...eventJsonData };
            dispatch(setRowData(EventResponces));
            setEventResponce(EventResponces);
            let { stream, finished, type } = eventJsonData;
            if (finished) {
                setLoaded(true);
            }
            if (type === "overview" && stream) {
                setAdviceMessage(prev => prev + stream);
            }
            if (type === "task" && stream) {
                setAdviceTask(prev => prev + stream);
            }
        });

        event && event.addEventListener('error', (eventData) => {
            console.error('EventSource error:', eventData);
            setIsLoading(false);
            event.close();
        });

        return () => {
            setAdviceTask('')
            setAdviceMessage('');
            setIsLoading(true);
            if (event) {
                event.close();
            }
        };
    }, [group, setPrevGroupId]);

    useEffect(() => {
        dispatch(Nav_Id("group_id"))
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location]);
    const adviceClass = windowWidth < 992 ? 'd-flex justify-content-end w-100' : 'd-flex button-width';

    const toggleShowMore = () => {
        setShowMore(prevState => !prevState);
    };

    function imageOnSlide(number) {
        setImageController({
            toggler: !imageController.toggler,
            slide: number
        });
    }
    const [isShow, setIsShow] = useState(false)

    const handleCloseFullScreen = () => {
        setIsShow(!isShow)
    }
    const handleTaskGenrate = () => {
        setIsReloadLoading(true);
        setAdviceTask('')
        setActive(null);
        let EventResponces = {};
        let event;
        event = EventSourceObj('api/v3/task_new?group_id=' + group);
        setPrevGroupId(group);
        event && event.addEventListener('message', (eventData) => {
            setIsReloadLoading(false);
            setEventTask({});
            let eventJsonData = JSON.parse(eventData.data);
            EventResponces = { ...EventResponces, ...eventJsonData };
            setEventTask(EventResponces);
            let { stream, finished, type } = eventJsonData;
            if (finished) {
            }
            if (type === "task" && stream) {
                setAdviceTask(prev => prev + stream);
            }
        });

        event && event.addEventListener('error', (eventData) => {
            console.error('EventSource error:', eventData);
            event.close();
        });
        return () => {

            setIsLoading(true);
            if (event) {
                event.close();
            }
        };
    }
    const handleToolitNavigate = (e, toolkitId) => {
        localStorage.setItem('toolkitId', toolkitId);
        const newPath = `/toolkit/${toolkitId}`;
        navigate(newPath);
    }
    const handleAddToolkit = (e, toolkitId) => {
        // Start loader for the specific button being clicked
        setLoadingButtons(prevState => ({
            ...prevState,
            [toolkitId]: true
        }));

        // Mutate the toolkit
        mutateToolkit({ toolkits: { [toolkitId]: true } });
    };
    useEffect(() => {
        if (isSuccessToolkit) {
            refetch()
                .then(response => {
                    // Stop loader for all buttons after refetch completes
                    if (response.isSuccess) {
                        setLoadingButtons({});
                    }
                })
                .catch(error => {
                    console.error("Error fetching toolkit:", error);
                    // Stop loader even in case of an error
                    setLoadingButtons({});
                });
        }
    }, [isSuccessToolkit, refetch]);
    
    return (
        <Fragment>
            <Modal show={isShow} onHide={handleCloseFullScreen}>
                <TaskCreate addTask={adviceTask} onHide={handleCloseFullScreen} />
            </Modal>
            <div>
                <FsLightbox
                    toggler={imageController.toggler}
                    sources={[img1]}
                    slide={imageController.slide}
                />
                {/* backgroundImage: `url(${window.iqonic_build + img1} */}
                <Row>
                    <Col lg="3" md="5" sm="12" className="iq-file-manager ">
                        <div className='position-relative iq-video-hover bordred-image'>
                            <div style={{ maxWidth: "100%", aspectRatio: "1/1", backgroundRepeat: 'no-repeat', backgroundSize: "cover" }}>
                                {!eventResponce.video && !eventResponce?.media?.image && (
                                    <div className="text-center h-100" style={{ display: 'grid', placeItems: 'center', background: '#fff3' }}>
                                        <div className="spinner-grow text-primary" role="status">
                                            {/* <span className="visually-hidden">Loading...</span> */}
                                        </div>
                                    </div>
                                )}
                                {eventResponce.video && (
                                    <>
                                        <Vimeo
                                            video={eventResponce.video}
                                            className='w-100 vimeo-iframe'
                                        />
                                    </>
                                )}
                                {!eventResponce.video && (
                                    <div>
                                        {eventResponce?.media?.image ?
                                            <img className="img-fluid" src={eventResponce?.media?.image} />
                                            :
                                            ""
                                        }
                                    </div>
                                )}
                            </div>

                        </div>

                        {isLoading ? (
                            <div className='d-flex pt-3 flex-column justify-content-start h-100'>
                                <p className='iq-skeleton' style={{ width: "80%" }}>&nbsp;</p>
                                <p className='iq-skeleton' style={{ width: "90%" }}>&nbsp;</p>
                                <p className='iq-skeleton' style={{ width: "83%" }}>&nbsp;</p>
                            </div>
                        ) : (
                            <div className='position-relative iq-video-hover bordred-image mt-3 d-flex align-items-start justify-content-between'>
                                {/* {adviceTask.length !== 0 && (
                                <Markdown content={adviceTask} />
                            )} */}
                                {adviceTask.length != 0 && (<>
                                    {!loaded ? (<>
                                        <Markdown content={adviceTask} />
                                    </>) : (<>
                                        {/* {" "}<ReactTyped strings={[adviceMessage]} typeSpeed={10} showCursor={false} /> */}
                                        <Markdown content={adviceTask} />
                                        {/* {adviceMessage} */}
                                    </>)}
                                </>)}
                                {/* <p>{adviceTask}</p> */}
                                <div className='d-flex justify-content-end'>
                                    <div className='d-flex justify-content-between align-items-start flex-column ' style={{ height: "90px" }}>
                                        <button className="btn btn-secondary btn-sm mx-1 rounded-3 icons-colors "
                                            style={{ padding: "2px 4px" }}
                                            onClick={() => setIsShow(!isShow)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                            </svg>
                                        </button>
                                        <button className="btn btn-sm mx-1 rounded-3 "
                                            style={{ padding: "2px 4px", }}
                                            onClick={() => handleTaskGenrate()}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className={`bi bi-arrow-clockwise ${isReloadLoading ? 'spin' : ''}`}
                                                viewBox="0 0 16 16"
                                                style={{ color: 'rgb(64 72 79)' }}
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                                                />
                                                <path
                                                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </Col>
                    <Col lg="9" md="7" sm="12" >
                        {
                            isLoading ? (<>
                                <div className='d-flex pt-3 flex-column justify-content-start h-100'>
                                    <p className='iq-skeleton' style={{ width: "80%" }}>&nbsp;</p>
                                    <p className='iq-skeleton' style={{ width: "90%" }}>&nbsp;</p>
                                    <p className='iq-skeleton' style={{ width: "83%" }}>&nbsp;</p>
                                </div>

                            </>) : (<>
                                {adviceMessage.length !== 0 && (<>
                                    <p className='my-5'>
                                        {!loaded ? (<>
                                            <Markdown content={adviceMessage} />
                                        </>) : (<>
                                            <Markdown content={adviceMessage} />
                                        </>)}
                                    </p>
                                </>)}
                            </>)
                        }
                        {!isLoading && data?.data?.categories && Object.values(data.data.categories).some(item => item?.selected && Object.keys(item.toolkits).some(toolkitKey => eventResponce.toolkits.includes(toolkitKey))) && (
                            <>
                                <p className="mb-2">Linked Toolkit</p>
                                <div className="d-flex align-items-center flex-wrap gap-3">
                                    {data?.data?.categories &&
                                        Object.values(data.data.categories)
                                            .filter(item => item?.selected)
                                            .flatMap((item) =>
                                                Object.entries(item.toolkits)
                                                    .map(([toolkitKey, toolkit]) => (
                                                        eventResponce.toolkits.includes(toolkitKey) && (
                                                            toolkit.selected ? (
                                                                <Button
                                                                    key={toolkitKey}
                                                                    onClick={(e) => handleToolitNavigate(e, toolkitKey)}
                                                                    style={{ border: "none" }}
                                                                    variant="soft-secondary text-primary icons-colors"
                                                                >
                                                                    {toolkit.title}
                                                                    <span style={{ paddingLeft: "4%" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                                                        </svg>
                                                                    </span>
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    key={toolkitKey}
                                                                    onClick={(e) => handleAddToolkit(e, toolkitKey)}
                                                                    variant="outline-primary"
                                                                >
                                                                    {toolkit.title}
                                                                    <span style={{ paddingLeft: "4%" }}>
                                                                        {loadingButtons[toolkitKey] ? (
                                                                            <div style={{ height: "14px", width: "14px" }} className="spinner-grow text-primary" role="status"></div>
                                                                        ) : (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                                                            </svg>
                                                                        )}


                                                                    </span>
                                                                </Button>
                                                            )
                                                        )
                                                    ))
                                            )
                                    }
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
                <Accordion className='iq-accordian mt-5'>
                    {
                        Object.keys(eventResponce?.challenges ?? {}).length != 0 ?
                            Object.keys(eventResponce?.challenges ?? {}).map((challengeID, index) => {
                                return (
                                    <>
                                        {isLoading ? (
                                            <div className='d-flex pt-3 flex-column justify-content-start h-100'>
                                                <p className='iq-skeleton' style={{ width: "90%", height: "50px" }}>&nbsp;</p>
                                            </div>
                                        ) : (
                                            <Card className="shadow-none mb-3">
                                                <Card.Header className={(active === challengeID + index ? 'active' : '') + ' flex-wrap gap-3'}>
                                                    {windowWidth >= 992 && (
                                                        <div className="d-none d-md-block">
                                                            <p className="d-flex align-items-center mb-0 flex-grow-1">
                                                                {eventResponce?.challenges[challengeID].title}
                                                            </p>
                                                        </div>
                                                    )}

                                                    {windowWidth < 992 && (
                                                        <div className="">
                                                            <ShowMoreText
                                                                lines={1}
                                                                more='Show more'
                                                                less='Hide'
                                                                anchorClass='btn-link text-secondary'
                                                                onClick={toggleShowMore}
                                                                expanded={showMore}
                                                                width={380}
                                                                truncatedEndingComponent={"..."}
                                                            >
                                                                {eventResponce?.challenges[challengeID].title}
                                                            </ShowMoreText>
                                                        </div>
                                                    )}
                                                    <div className={adviceClass}>
                                                        <CustomToggle eventKey={challengeID + index} onClick={(value) => setActive(value)} variant={active === challengeID + index ? 'light' : 'primary'}>
                                                            Suggest advice
                                                            <svg className="right-icon ms-2" width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.75 9H14.25M14.25 9L9 3.75M14.25 9L9 14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </CustomToggle>
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={challengeID + index}>
                                                    <SuggestAdvice isActive={active === challengeID + index} challengeID={challengeID} />
                                                </Accordion.Collapse>
                                            </Card>
                                        )}
                                    </>
                                )
                            })
                            :
                            [...new Array(3)].map((arr, index) => (
                                <>
                                    <Card className="shadow-none mb-3 iq-skeleton" >
                                        <Card.Header className={(active === index ? 'active' : '') + ' flex-wrap'}>
                                            <p className="d-flex align-items-center mb-0 flex-grow-1"> &nbsp; </p>
                                            <div className='d-flex button-width'>
                                                <div className='d-flex align-items-center iq-content me-5'>
                                                    <p className='mb-0'>&nbsp;</p>
                                                </div>
                                            </div>
                                        </Card.Header>
                                    </Card>
                                </>
                            ))
                    }

                    {/* Currently to display to none just for going live purposes */}
                    <div className='border-bottom mb-4 d-none'></div> 
                    <Card className="d-none">
                        <InputGroup className="">
                            <input type="text" className="form-control chat-input z-bg-color gurus" placeholder="Suggest Advice" aria-label="Recipient's username" />
                            <span className="input-group-text mgs-icon voice-icon z-bg-color">
                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-24 gurus" height="32" transform='rotate(45)'>
                                    <path d="M21.4274 2.5783C20.9274 2.0673 20.1874 1.8783 19.4974 2.0783L3.40742 6.7273C2.67942 6.9293 2.16342 7.5063 2.02442 8.2383C1.88242 8.9843 2.37842 9.9323 3.02642 10.3283L8.05742 13.4003C8.57342 13.7163 9.23942 13.6373 9.66642 13.2093L15.4274 7.4483C15.7174 7.1473 16.1974 7.1473 16.4874 7.4483C16.7774 7.7373 16.7774 8.2083 16.4874 8.5083L10.7164 14.2693C10.2884 14.6973 10.2084 15.3613 10.5234 15.8783L13.5974 20.9283C13.9574 21.5273 14.5774 21.8683 15.2574 21.8683C15.3374 21.8683 15.4274 21.8683 15.5074 21.8573C16.2874 21.7583 16.9074 21.2273 17.1374 20.4773L21.9074 4.5083C22.1174 3.8283 21.9274 3.0883 21.4274 2.5783Z" fill="currentColor"></path>
                                    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M3.01049 16.8079C2.81849 16.8079 2.62649 16.7349 2.48049 16.5879C2.18749 16.2949 2.18749 15.8209 2.48049 15.5279L3.84549 14.1619C4.13849 13.8699 4.61349 13.8699 4.90649 14.1619C5.19849 14.4549 5.19849 14.9299 4.90649 15.2229L3.54049 16.5879C3.39449 16.7349 3.20249 16.8079 3.01049 16.8079ZM6.77169 18.0003C6.57969 18.0003 6.38769 17.9273 6.24169 17.7803C5.94869 17.4873 5.94869 17.0133 6.24169 16.7203L7.60669 15.3543C7.89969 15.0623 8.37469 15.0623 8.66769 15.3543C8.95969 15.6473 8.95969 16.1223 8.66769 16.4153L7.30169 17.7803C7.15569 17.9273 6.96369 18.0003 6.77169 18.0003ZM7.02539 21.5683C7.17139 21.7153 7.36339 21.7883 7.55539 21.7883C7.74739 21.7883 7.93939 21.7153 8.08539 21.5683L9.45139 20.2033C9.74339 19.9103 9.74339 19.4353 9.45139 19.1423C9.15839 18.8503 8.68339 18.8503 8.39039 19.1423L7.02539 20.5083C6.73239 20.8013 6.73239 21.2753 7.02539 21.5683Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </InputGroup>
                    </Card> {/* END => Currently to display to none just for going live purposes */}

                </Accordion>

            </div>
        </Fragment>
    )
}
)

AdviceWidget.displayName = "AdviceWidget"
export default AdviceWidget