
import { Modal, Button } from 'react-bootstrap'

// Import selectors & action from setting store
import ClipboardCopy from '../../utilities/CopyToClipBoard';

export default function ShareYourPlan({ show, onHide }) {
    return (
        <Modal show={show} onHide={onHide} size="sm-3" centered>
            <div className='text-end'>
                <Button variant='none' onClick={onHide}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.9945 7.00445L7.00497 16.994M16.9978 17L6.99988 7" stroke="#38393B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </Button>
            </div>
            <Modal.Body>
                <div className='px-3'>
                    <p className='text-center'>This is your link to access and edit your report</p>
                    <ClipboardCopy copyText="https://sustain.chat/plan/sulntUJw/3HcRxIgkGU~8veu0Lm1/" />
                </div>
                <div className='px-3 mt-3'>
                    <p className='text-center'>This link can be shared with others to view, but not edit, your report</p>
                    <div className='d-flex justify-content-center gap-4'>
                        <ClipboardCopy copyText="https://sustain.chat/plan/" />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}