import Modal from 'react-bootstrap/Modal';
import { Row, Col, Button, Spinner, Card } from 'react-bootstrap'
import Vimeo from '@u-wave/react-vimeo';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import axios_inst from '../../utilities/axios';
import { useMutation } from '@tanstack/react-query';
import { setCategoryId, setPersonaTitle, SetActivePlan } from '../../store/dashboardConfig/dashboardConfigSlice';
import guru1 from "../../assets/images/guru1.png";
import { PersonaAvatar } from '../partials/components/PersonaAvatar';
import { useNavigate, useLocation } from 'react-router-dom';
//scrollbar
import Scrollbar from "smooth-scrollbar";

export default function GettingStarted({ show, onHide, data, status, sideBarData, setSideBarData, sideBarToolkitData, setSideBarToolkitData }) {
    const location                   = useLocation()
    const navigate                   = useNavigate()
    const configDispatch             = useDispatch()
    const { register, handleSubmit } = useForm();
    const NavIds                     = useSelector(state => state.nav_id);
    const plandata                   = useSelector(state => state.ActivePlan);

    const [personaDetails, setPersonaDetails]   = useState([])
    const [avatarDetail, setAvatarDetail]       = useState()
    const [openModel, setOpenModel]             = useState(false)
    const [isEnable, setIsEnable]               = useState(false)
    const [selected, setSelected] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategoryVideo, setSelectedCategoryVideo] = useState(null);

    const saveGroup = useMutation({
        mutationFn: async (data) => {
            const response = await axios_inst.post('api/v3/save_groups', data);
            return response?.data;
        }
    });

    const getToolkit = useMutation({
        mutationFn: async (data) => {
            const response = await axios_inst.get('api/v3/toolkits', { params: data });
            return response?.data;
        }
    });

    const getPlan = useMutation({
        mutationFn: async (data) => {
            const response = await axios_inst.get('api/v4/get_plan', { params: data });
            return response?.data?.data;
        }
    });

    const CategoryUpdate = useMutation({
        mutationFn: async (data) => {
            const response = await axios_inst.post('api/v3/save_categories?debug=none', data);
            return response?.data;
        }
    });

    const saveToolkit = useMutation({
        mutationFn: async (data) => {
            const response = await axios_inst.post('api/v3/save_toolkits?debug=none', data);
            return response?.data;
        }
    });

    const handleCardClick = (id) => {
        setSelected((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    const onSubmit = async (formData, e) => {
        if (status === 1) {
            const persona_id     = formData.persona_id;
            let formFilterVal    = Object.values(formData).map(val => val.toString()).filter(val => val !== "false");
            const groupIds       = Object.fromEntries(data.group_ids.map(val => [val, formFilterVal.includes(val.toString())]));
            const isEmptyGroups  = Object.values(groupIds).every(val => val === false);
            let oldGroupIds      = localStorage.getItem('groupIds_' + persona_id);
            oldGroupIds          = oldGroupIds ? JSON.parse(oldGroupIds) : {};
            const newlyAddedIds  = Object.keys(groupIds)
                                    .filter(key => groupIds[key] && !oldGroupIds[key])
                                    .map(id => parseInt(id, 10));

            localStorage.setItem('groupIds_' + persona_id, JSON.stringify(groupIds));

            if ( isEmptyGroups ) {
                await saveGroup.mutateAsync({ groups: groupIds });
                const plans = await getPlan.mutateAsync();
                setSideBarData(plans);
                const selectedGroupAndPersona = getFirstSelectedGroup(plans.pages.Learn);
                onHide(false);
                if (selectedGroupAndPersona) {
                    localStorage.setItem('Sub_id',selectedGroupAndPersona.groupId);
                    configDispatch(setPersonaTitle( getPersonaById( sideBarData?.pages?.Learn, selectedGroupAndPersona.personaId )));
                    configDispatch(setCategoryId({ 'category_id': selectedGroupAndPersona.groupId, 'persona_id': selectedGroupAndPersona.personaId }));
                    navigate(`/dashboard/${selectedGroupAndPersona.groupId}/advice`)
                }
                else {
                    localStorage.setItem('Sub_id', null);
                    configDispatch(setCategoryId({ 'category_id': false, 'persona_id': false }));
                    navigate(`dashboard/`);
                }
            }
            else if( newlyAddedIds.length > 0 ){
                updateSidebarGroupData(groupIds, persona_id);
                await saveGroup.mutateAsync({ groups: groupIds });
                configDispatch(setPersonaTitle( getPersonaById( sideBarData?.pages?.Learn, persona_id)));
                configDispatch(setCategoryId({ 'category_id': newlyAddedIds[0], 'persona_id': persona_id}));
                localStorage.setItem('Sub_id', newlyAddedIds[0]);

                navigate(`/dashboard/${newlyAddedIds[0]}/advice`);
                onHide(false);
            } else {
                onHide(false);
                updateSidebarGroupData(groupIds, persona_id);
                await saveGroup.mutateAsync({ groups: groupIds });

                const firstActiveGroup = Object.entries(groupIds).find(([key, value]) => value === true)?.[0];
                if (firstActiveGroup && newlyAddedIds.length == 0) {
                    localStorage.setItem('Sub_id', firstActiveGroup);
                    configDispatch(setPersonaTitle( getPersonaById( sideBarData?.pages?.Learn, persona_id)));
                    configDispatch(setCategoryId({ 'category_id': firstActiveGroup, 'persona_id': persona_id }));
                    navigate(`/dashboard/${firstActiveGroup}/advice`)
                } 
            }

        } else if (status === 0 && location.pathname.includes( '/dashboard' ) ) {
            const categoryFormData = selected.reduce((acc, id) => {
                acc[id] = true;
                return acc;
            }, {});

            // Add logic to handle removal of selected categories
            Object.keys(sideBarData.pages.Learn.categories).forEach((categoryId) => {
                if (!selected.includes(parseInt(categoryId))) {
                    categoryFormData[categoryId] = false;
                }
            });

            const isEmptyCategory   = Object.values(categoryFormData).every(val => val === false);
            let oldCategoryIds      = localStorage.getItem('categoryIds');
            oldCategoryIds          = oldCategoryIds ? JSON.parse(oldCategoryIds) : {};
            const newlyAddedIds     = Object.keys(categoryFormData).filter(key => categoryFormData[key] && !oldCategoryIds[key]).map(id => parseInt(id, 10));
        
            const newSideBarData = updateSideBarCategory( categoryFormData );
            await CategoryUpdate.mutateAsync({ categories: categoryFormData });
            // Assuming categoryFormData is your object
            localStorage.setItem('categoryIds', JSON.stringify(categoryFormData));
            const getActieGroup = getFirstSelectedGroup( newSideBarData.pages.Learn );

            if( isEmptyCategory || ! getActieGroup ) {
                localStorage.setItem('Sub_id',null);
                configDispatch(setCategoryId({ 'category_id': false, 'persona_id': false }));
                navigate(`dashboard/`);
            } 
            else if( newlyAddedIds.length > 0 ){
                const newcatgroupids = getFirstSelectedGroupFromNewlyAdded( newSideBarData.pages.Learn, newlyAddedIds );
                if( newcatgroupids ) {
                    localStorage.setItem('Sub_id', newcatgroupids.groupId);

                    configDispatch(setPersonaTitle( getPersonaById( newSideBarData.pages.Learn, newcatgroupids.personaId)));
                    configDispatch(setCategoryId({ 'category_id': newcatgroupids.groupId, 'persona_id': newcatgroupids.personaId }));
                    navigate(`/dashboard/${newcatgroupids.groupId}/advice`);
                }
            }
            else {
                const selectedGroupAndPersona = getFirstSelectedGroup( newSideBarData.pages.Learn);
                if (selectedGroupAndPersona) {
                    localStorage.setItem('Sub_id', selectedGroupAndPersona.groupId);
                    
                    configDispatch(setPersonaTitle( getPersonaById( sideBarData.pages.Learn, selectedGroupAndPersona.personaId)));
                    configDispatch(setCategoryId({ 'category_id': selectedGroupAndPersona.groupId, 'persona_id': selectedGroupAndPersona.personaId }));
                    navigate(`/dashboard/${selectedGroupAndPersona.groupId}/advice`)
                }
            }
            onHide(false);

        }
        else if (status === 0 && location.pathname.includes( '/toolkit' ) ) {
            const categoryFormData = selected.reduce((acc, id) => {
                acc[id] = true;
                return acc;
            }, {});

            // Add logic to handle removal of selected categories
            Object.keys(sideBarToolkitData.data.categories).forEach((categoryId) => {
                if (!selected.includes(parseInt(categoryId))) {
                    categoryFormData[categoryId] = false;
                }
            });

            const isEmptyCategory = Object.values(categoryFormData).every(val => val === false || val === null);
            let oldCategoryIds = localStorage.getItem('toolkit_categoryIds');
            oldCategoryIds = oldCategoryIds ? JSON.parse(oldCategoryIds) : {};
            const newlyAddedIds = Object.keys(categoryFormData).filter(key => categoryFormData[key] && !oldCategoryIds[key]).map(id => parseInt(id, 10));
            const newSideBarData = updateSideBarToolkitCategory(categoryFormData);
            await CategoryUpdate.mutateAsync({ categories: categoryFormData });
            // Assuming categoryFormData is your object
            localStorage.setItem('toolkit_categoryIds', JSON.stringify(categoryFormData));
            const getActieToolKit = getFirstSelectedToolKit(newSideBarData.data);

            if (isEmptyCategory || !getActieToolKit) {
                localStorage.setItem('toolkitId', '');
                configDispatch(setCategoryId({ 'category_id': false, 'persona_id': false }));
                navigate(`/toolkit/`);
            } 
            else if (newlyAddedIds.length > 0) {
                const newcatgroupids = getFirstSelectedToolkitFromNewlyAdded(newSideBarData.data, newlyAddedIds);
                if (newcatgroupids) {
                    localStorage.setItem('toolkitId', newcatgroupids.toolkitId);

                    configDispatch(setPersonaTitle(getPersonaById(newSideBarData.data, newcatgroupids.personaId)));
                    configDispatch(setCategoryId({ 'category_id': newcatgroupids.toolkitId, 'persona_id': newcatgroupids.personaId }));
                    navigate(`/toolkit/${newcatgroupids.toolkitId}`);
                }
            }
            else {
                const selectedGroupAndPersona = getFirstSelectedToolKit(newSideBarData.data);
                if (selectedGroupAndPersona) {
                    localStorage.setItem('toolkitId', selectedGroupAndPersona.toolKitid);
                    
                    configDispatch(setPersonaTitle(getPersonaById(newSideBarData.data, selectedGroupAndPersona.personaId)));
                    configDispatch(setCategoryId({ 'category_id': selectedGroupAndPersona.toolKitid, 'persona_id': selectedGroupAndPersona.personaId }));
                    navigate(`/toolkit/${selectedGroupAndPersona.toolKitid}`)
                }
            }
            onHide(false);

        }
        else if (status === 3) {
            const persona_id = formData.persona_id;
            const toolkitsids = formData.toolkits;
            const isEmptyToolkits = Object.values(toolkitsids).every(val => val === false || val === null);
            let oldToolkitIds = localStorage.getItem('toolkitsids_' + persona_id);
            oldToolkitIds = oldToolkitIds ? JSON.parse(oldToolkitIds) : {};
            const newlyAddedIds = Object.keys(toolkitsids)
                .filter(key => toolkitsids[key] && !oldToolkitIds[key])
                .map(id => parseInt(id, 10));

            localStorage.setItem('toolkitsids_' + persona_id, JSON.stringify(toolkitsids));

            if (isEmptyToolkits) {
                await saveToolkit.mutateAsync({ toolkits: toolkitsids });
                const toolkitdata = await getToolkit.mutateAsync();
                setSideBarToolkitData(toolkitdata);
                onHide(false);
                const selectedToolkitAndPersona = getFirstSelectedToolKit(toolkitdata.data);
                if (selectedToolkitAndPersona) {
                    localStorage.setItem('toolkitId', selectedToolkitAndPersona.toolKitid);
                    configDispatch(setPersonaTitle(getPersonaById(toolkitdata.data, selectedToolkitAndPersona.personaId)));
                    configDispatch(setCategoryId({ 'category_id': selectedToolkitAndPersona.toolKitid, 'persona_id': selectedToolkitAndPersona.personaId }));
                    navigate(`/toolkit/${selectedToolkitAndPersona.toolKitid}`);
                } else {
                    configDispatch(setCategoryId({ 'category_id': false, 'persona_id': false }));
                    localStorage.setItem('toolkitId', '');
                    navigate(`/toolkit`);
                }
            } else if (newlyAddedIds.length > 0) {
                updateSidebarToolkitData(toolkitsids, persona_id);
                await saveToolkit.mutateAsync({ toolkits: toolkitsids });
                configDispatch(setPersonaTitle(getPersonaById(sideBarToolkitData.data, persona_id)));
                configDispatch(setCategoryId({ 'category_id': newlyAddedIds[0], 'persona_id': persona_id }));
                localStorage.setItem('toolkitId', newlyAddedIds[0]);
                navigate(`/toolkit/${newlyAddedIds[0]}`);
                onHide(false);
            } else {
                onHide(false);
                updateSidebarToolkitData(toolkitsids, persona_id);
                await saveToolkit.mutateAsync({ toolkits: toolkitsids });
                const firstActiveToolkit = Object.entries(toolkitsids).find(([key, value]) => value === true)?.[0];
                if (firstActiveToolkit && newlyAddedIds.length == 0) {
                    localStorage.setItem('toolkitId', firstActiveToolkit);
                    configDispatch(setPersonaTitle(getPersonaById(sideBarToolkitData.data, persona_id)));
                    configDispatch(setCategoryId({ 'category_id': firstActiveToolkit, 'persona_id': persona_id }));
                    navigate(`/toolkit/${firstActiveToolkit}`);
                } 
            }
        }
    }
    const onError = (errors, e) => console.log(errors, e)

    const getFirstSelectedToolkitFromNewlyAdded = (data, newlyAddedIds) => {
        const newlyAddedIdSet = new Set(newlyAddedIds); // Convert numbers to strings for Set
    
        return Object.values(data.categories)
            .filter(category => category.selected && newlyAddedIdSet.has(category.id)) // Ensure category.id is compared as a string
            .map(category => {
                const toolkitId = Object.entries(category.toolkits).find(([, toolkit]) => toolkit.selected)?.[0];
                return {
                    personaId: category.persona.id,
                    toolkitId: toolkitId
                };
            })
            .find(result => result.toolkitId) || false;
    };

    const getFirstSelectedGroupFromNewlyAdded = (data, newlyAddedIds) => {
        const newlyAddedIdSet = new Set(newlyAddedIds); // Convert numbers to strings for Set
    
        return Object.values(data.categories)
            .filter(category => category.selected && newlyAddedIdSet.has(category.id)) // Ensure category.id is compared as a string
            .map(category => {
                const groupId = Object.entries(category.groups).find(([, group]) => group.selected)?.[0];
                return {
                    personaId: category.persona.id,
                    groupId: groupId
                };
            })
            .find(result => result.groupId) || false;
    };
    

    useEffect(() => {
        if (status === 0) {
            let categories = typeof sideBarData?.pages?.Learn?.categories !== 'undefined' ? sideBarData?.pages?.Learn?.categories : sideBarToolkitData?.data?.categories;
            if (categories) {
                const categoriesArray = Object.values(categories).map(category => category);
                setPersonaDetails(categoriesArray);
                const selectedCategories = categoriesArray.filter(category => category.selected).map(category => category.id);
                setSelected(selectedCategories);
            }
        }
    }, [sideBarData, sideBarToolkitData, status]);

    const updateSideBarToolkitCategory = (categoryIds) => {
        // Create a copy of the current sidebar data
        const newSideBarData = {
            ...sideBarToolkitData,
            data: {
                ...sideBarToolkitData.data,
                categories: {
                    ...sideBarToolkitData.data.categories,
                },
            },
        };
    
        // Update the selected status of categories based on categoryIds
        Object.entries(categoryIds).forEach(([categoryId, isSelected]) => {
            // Check if categoryId exists before updating
            if (newSideBarData.data.categories[categoryId]) {
                // Update the category with the new selected property
                newSideBarData.data.categories[categoryId] = {
                    ...newSideBarData.data.categories[categoryId],
                    selected: isSelected,
                };
            }
        });
        // Update the state with the new data
        setSideBarToolkitData(newSideBarData);
        return newSideBarData;
    };

    const updateSideBarCategory = (categoryIds) => {
        // Create a copy of the current sidebar data
        const newSideBarData = {
            ...sideBarData,
            pages: {
                ...sideBarData.pages,
                Learn: {
                    ...sideBarData.pages.Learn,
                    categories: {
                        ...sideBarData.pages.Learn.categories,
                    },
                },
            },
        };
    
        // Update the selected status of categories based on categoryIds
        Object.entries(categoryIds).forEach(([categoryId, isSelected]) => {
            // Check if categoryId exists before updating
            if (newSideBarData.pages.Learn.categories[categoryId]) {
                // Update the category with the new selected property
                newSideBarData.pages.Learn.categories[categoryId] = {
                    ...newSideBarData.pages.Learn.categories[categoryId],
                    selected: isSelected,
                };
            }
        });
        // Update the state with the new data
        setSideBarData(newSideBarData);
        configDispatch(SetActivePlan(newSideBarData));

        return newSideBarData;
    };
    

    const updateSidebarToolkitData = (toolkitIds, persona_id) => {
        // Create a deep copy of sideBarToolkitData to ensure immutability
        const updatedSideBarToolkitData = JSON.parse(JSON.stringify(sideBarToolkitData));
        
        // Find the category where persona.id matches persona_id
        Object.entries(updatedSideBarToolkitData.data.categories).forEach(([categoryKey, categoryValue]) => {
            // Check if persona.id matches persona_id
            if (categoryValue.persona?.id == persona_id) {
                // Update the selected status of toolkits based on toolkitIds
                Object.entries(toolkitIds).forEach(([toolkitId, isSelected]) => {
                    // Check if toolkitId exists before updating
                    if (categoryValue.toolkits[toolkitId]) {
                        // Update the toolkit with the new selected property
                        categoryValue.toolkits[toolkitId].selected = isSelected;
                    }
                });
            }
        });
            
        // Update the state with the new data
        setSideBarToolkitData(updatedSideBarToolkitData);
        // configDispatch(SetActivePlan(updatedSideBarToolkitData));
    };

    const updateSidebarGroupData = (groupIds, persona_id) => {
        // Create a deep copy of sideBarData to ensure immutability
        const updatedSideBarData = JSON.parse(JSON.stringify(sideBarData));
        
        // Find the category where persona.id matches persona_id
        Object.entries(updatedSideBarData.pages.Learn.categories).forEach(([categoryKey, categoryValue]) => {
            // Check if persona.id matches persona_id
            if (categoryValue.persona?.id == persona_id) {
                // Update the selected status of groups based on groupIds
                Object.entries(groupIds).forEach(([groupId, isSelected]) => {
                    // Check if groupId exists before updating
                    if (categoryValue.groups[groupId]) {
                        // Update the group with the new selected property
                        categoryValue.groups[groupId].selected = isSelected;
                    }
                });
            }
        });
            
        // Update the state with the new data
        setSideBarData(updatedSideBarData);
        configDispatch(SetActivePlan(updatedSideBarData));
    };
    

    const getFirstSelectedToolKit = (data) => {
        return Object.values(data.categories)
            .filter(category => category.selected)
            .map(category => ({
                personaId: category.persona.id,
                toolKitid: Object.entries(category.toolkits).find(([, toolkit]) => toolkit.selected)?.[0]
            }))
            .find(result => result.toolKitid) || false;
    };

    const getFirstSelectedGroup = (data) => {
        return Object.values(data.categories)
            .filter(category => category.selected)
            .map(category => ({
                personaId: category.persona.id,
                groupId: Object.entries(category.groups).find(([, group]) => group.selected)?.[0]
            }))
            .find(result => result.groupId) || false;
    };

    const getPersonaById = (data, persona_id) => {
        return Object.values(data.categories)
            .filter(category => category.persona.id == persona_id)
            .map(category => category.persona)[0] || false;
    };

    const handleOpenPopup = (e, item) => {
        const { skills, text, title } = item.description
        const { avatar } = item.media
        const avatarDetails = {
            skills: skills,
            text: text,
            title: title,
            avatar: avatar
        };
        setAvatarDetail(avatarDetails)
        setOpenModel(true)
    }
    const handleClose = () => {
        setOpenModel(false)
    }

    const infoClick = (categoryId) => {
        const category = personaDetails.find(item => item.id === categoryId);
        if (category) {
            if(category.video){
                setSelectedCategoryVideo(category.persona.media.video || null); 
            }
            setSelectedCategory(category || null);
        }
    }

     useEffect(() => {
            Scrollbar.init(document.querySelector("#my-scrollbar"));
     }); 

    return (
        <Modal show={show} onHide={onHide} size="xl" centered dialogClassName={status === 0 ? 'modal-dialog-scrollable' : ''}>
            {/* <style>{dynamicStyles}</style> */}
                <div className='text-end'>
                    <Button variant='none' onClick={onHide} className='p-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" className='model-close-icon' width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M16.9945 7.00445L7.00497 16.994M16.9978 17L6.99988 7" stroke="#38393B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </Button>
                </div>
            <PersonaAvatar show={openModel} handleClose={handleClose} avatarDetail={avatarDetail} />
            <Modal.Body className='px-5 pb-4 pt-2 d-flex gap-5 flex-lg-nowrap flex-wrap'>
                    <Col lg="3" sm="12" className={`iq-file-manager ${status === 0 ? 'iq-guru-video' : ''}`}>
                        {status === 1 || status === 3 ? (
                            <div className='position-relative iq-video-hover h-100'>
                                <div className='video_container' style={{ height: "330px" }}>
                                    {selectedCategoryVideo ? (
                                        <Vimeo
                                            video={selectedCategoryVideo}
                                            width="275"
                                            className='w-100 h-100'
                                        />
                                    ) : (
                                        <Vimeo
                                            video={data?.persona?.media?.video || data?.video}
                                            width="275"
                                            className='w-100 h-100'
                                        />
                                    )}
                                </div>
                                <div className='d-flex align-items-center justify-content-center gap-2 mt-3'>
                                    <h3 className='mb-0'>{data?.persona?.description.title}</h3>
                                    <button className='btn bg-transparent p-0' onClick={(e) => handleOpenPopup(e, data?.persona)} >
                                        <i className="bi bi-info-circle gurus"></i>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className='position-relative iq-video-hover h-100'>
                                <div className='video_container h-100'>
                                    {selectedCategory ? (
                                        <>
                                            <Vimeo
                                                video={selectedCategoryVideo}
                                                width="275"
                                                className='w-100 h-100'
                                            />
                                            <div className='mt-3' style={{ maxHeight: '22rem', overflowY: 'auto' }}>
                                                <div id="my-scrollbar" class="iq-guru-scroll">
                                                    <div className='d-flex align-items-center gap-2 mb-3'>
                                                        <img src={selectedCategory.persona.media.avatar} className='avatar avatar-50 rounded-pill' />
                                                        <h5 className='mb-0'>{selectedCategory.persona.title}</h5>
                                                    </div>
                                                    <p>{selectedCategory.persona.description.text}</p>
                                                    <div className='d-flex align-items-center gap-2'>
                                                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z" fill="currentColor"></path>
                                                            </svg>
                                                            <small>SKILLS</small>
                                                        </div>
                                                    <p>{selectedCategory.persona.description.skills}</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        (plandata?.other?.category_video || data?.other?.category_video) && (
                                            <Vimeo
                                                video={typeof plandata?.other?.category_video !== 'undefined' ? plandata?.other?.category_video : data?.other?.category_video}
                                                width="275"
                                                className='w-100 h-100'
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                        {
                            data?.whatsapp?.active && (
                                <>
                                    <Button
                                        variant="outline-primary  iq-btn-outline"
                                        className="d-flex gap-2 ms-2"
                                        target='_blank'
                                        href={"https://api.whatsapp.com/send?phone=" + data.whatsapp.number}
                                    >
                                        <span className="btn-inner d-flex">
                                            <svg className='me-1' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                <g clipPath="url(#clip0_582_11056)">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9692 3.00607C13.3904 1.42548 11.2909 0.554638 9.05407 0.553711C4.44497 0.553711 0.693821 4.30475 0.691967 8.9151C0.691349 10.3889 1.07635 11.8275 1.80814 13.0956L0.621826 17.4287L5.05471 16.2659C6.27615 16.9322 7.65126 17.2833 9.05067 17.2837H9.05418C13.6628 17.2837 17.4143 13.5323 17.4161 8.92179C17.417 6.68738 16.548 4.58655 14.9692 3.00607ZM9.05407 15.8715H9.05119C7.8041 15.871 6.58102 15.5358 5.51376 14.9027L5.26008 14.752L2.62954 15.4421L3.33167 12.8774L3.16636 12.6144C2.47062 11.5078 2.10323 10.2288 2.10385 8.91561C2.10529 5.08361 5.22321 1.966 9.05685 1.966C10.9133 1.96662 12.6583 2.69048 13.9705 4.00421C15.2827 5.31793 16.0049 7.06414 16.0043 8.92128C16.0027 12.7536 12.8849 15.8715 9.05407 15.8715ZM12.8664 10.6661C12.6575 10.5615 11.6302 10.0562 11.4387 9.98637C11.2473 9.91664 11.1078 9.88193 10.9687 10.091C10.8293 10.3001 10.429 10.7708 10.307 10.9101C10.1851 11.0496 10.0633 11.0671 9.85436 10.9625C9.64538 10.8579 8.97219 10.6372 8.17407 9.92539C7.553 9.37137 7.1337 8.68716 7.01175 8.47808C6.89001 8.26879 7.01072 8.16662 7.10342 8.05167C7.3296 7.7708 7.55609 7.47633 7.62571 7.33698C7.69544 7.19752 7.66053 7.07547 7.6082 6.97093C7.55609 6.86639 7.13823 5.83796 6.96416 5.41949C6.79443 5.01224 6.62232 5.06724 6.49398 5.06085C6.37224 5.05478 6.23289 5.05354 6.09353 5.05354C5.95428 5.05354 5.72789 5.10576 5.53632 5.31505C5.34485 5.52423 4.80515 6.02964 4.80515 7.05806C4.80515 8.08649 5.55383 9.07999 5.65827 9.21945C5.76271 9.35891 7.13164 11.4693 9.22752 12.3742C9.72602 12.5897 10.1151 12.7182 10.4187 12.8145C10.9192 12.9735 11.3746 12.951 11.7347 12.8973C12.1362 12.8372 12.9707 12.3918 13.145 11.9038C13.3191 11.4157 13.3191 10.9974 13.2667 10.9101C13.2146 10.823 13.0753 10.7708 12.8664 10.6661Z" fill="currentColor" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_582_6668">
                                                        <rect width="18" height="18" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        {data.whatsapp.button}
                                    </Button>
                                </>
                            )
                        }

                    </Col>
                <Row className='mt-5 mt-lg-0'>
                    {status === 1 && (

                        <div className='mt-md-0 mt-3'>
                            <h2 className='mb-3'>{data.title}</h2>
                            <p className='mt-0 mb-2'>Select from below to add advice.:</p>
                            <form onSubmit={handleSubmit(onSubmit, onError)}  >
                                <input
                                    type="hidden"
                                    name="persona_id"
                                    id="persona_id"
                                    value={data.persona.id}
                                    {...register('persona_id')}
                                />

                                <div className="d-flex flex-wrap mb-5 gap-4">
                                    {data !== "" && data?.group_ids?.map((item, index) => {
                                        const itemTitle = data.groups[item]?.title.replace(/ /g, "_");
                                        const isChecked = data.groups[item]?.selected;

                                        return (
                                            <div key={index}>
                                                <input
                                                    type="checkbox"
                                                    className="btn-check"
                                                    id={itemTitle}
                                                    defaultChecked={isChecked}
                                                    value={item}
                                                    {...register(itemTitle)}
                                                    style={{ display: 'none' }}
                                                />
                                                <label
                                                    className="btn btn-border d-block rounded-pill select_guru_btn"
                                                    htmlFor={data.groups[item]?.title.replace(/ /g, "_")}
                                                >{data.groups[item].img && (
                                                    <img className='me-1' style={{ height: "24px", width: "24px", borderRadius: "25px" }} src={data.groups[item].img} />
                                                )}
                                                    {data.groups[item]?.title}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className='mt-4 mb-0'>
                                    <Button variant="primary" type='submit' disabled={isEnable} >
                                        {saveGroup.isLoading || getPlan.isLoading ? 'Saving' : 'Save'}
                                        {
                                            saveGroup.isLoading || getPlan.isLoading ? (
                                                <>
                                                    &nbsp;
                                                    <Spinner as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true">
                                                    </Spinner>
                                                </>
                                            ) : ('')
                                        }
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                    {status === 0 && (
                        <Col sm="12" className='mt-md-0 mt-3'>
                            <p className='mt-0 mb-2'>Select from below to add guru:</p>
                            <form id="guru-form" onSubmit={handleSubmit(onSubmit, onError)}  className='iq-guru-selected'>
                                <Row className='mt-5 mt-lg-0'>
                                    {Object.values(personaDetails).map((item, index) => (
                                        <Col lg={4} md={6} className='pe-0 position-relative'>
                                            <div className='position-absolute iq-info-icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon-20" onClick={() => infoClick(item.id)}>
                                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0 24-10.7 24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                                                </svg>
                                            </div>
                                            <Card key={index} className={`shadow mb-3 ${selected.includes(item.id) ? 'selected' : ''}`} onClick={() => handleCardClick(item.id)} style={{ cursor: 'pointer' }}>
                                            <div className='p-2'>
                                                <div className='text-center mb-2'>
                                                {item?.persona?.media?.image ? (
                                                    <img className='avatar avatar-60 rounded-pill me-1' src={item?.persona?.media?.avatar} />
                                                ) : (
                                                    <img className='avatar avatar-60 rounded-pill me-1' src={guru1} />
                                                )}
                                                </div>
                                                <div>
                                                    <p className='text-center'>{item?.title}</p>
                                                    <pre className='text-wrap text-ellipsis short-2'>{item?.persona?.description?.skills}</pre>
                                                </div>
                                            </div>
                                            <div className={`shadow ${selected.includes(item.id) ? 'selected-checked-icon' : 'd-none'}`}>
                                                <svg className='iq-guru-icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
                                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                                                </svg>
                                            </div>
                                        </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </form>
                        </Col>
                    )}
                    {status === 3 && (
                        <Col className='mt-md-0 mt-3'>
                            <h2 className='mb-3'>Add More Toolkits</h2>
                            <p className='mt-0 mb-2'>Select from below to receive toolkits.:</p>
                            <form onSubmit={handleSubmit(onSubmit, onError,)}  >
                                <input
                                    type="hidden"
                                    name="persona_id"
                                    id="persona_id"
                                    value={data.persona.id}
                                    {...register('persona_id')}
                                />
                                <div className="d-flex flex-wrap mb-5 gap-4">
                                    {Object.entries(data?.toolkits || {})
                                        .map(([toolkitKey, item], index) => {
                                            return (
                                                <div key={toolkitKey}>
                                                    <input
                                                        type="checkbox"
                                                        className="btn-check"
                                                        id={toolkitKey}
                                                        defaultChecked={item.selected}
                                                        {...register(`toolkits.${toolkitKey}`)}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <label
                                                        className="btn btn-border d-block rounded-pill select_guru_btn"
                                                        htmlFor={toolkitKey}
                                                    >
                                                        <span style={{ padding: "5px" }}>{item?.title}</span>
                                                    </label>
                                                </div>
                                            );
                                        })}
                                </div>

                                <div className='mt-4 mb-0'>
                                    <Button variant="primary" type='submit' disabled={isEnable} >
                                        {saveToolkit.isLoading || getToolkit.isLoading ? 'Saving' : 'Save'}
                                        {
                                            saveToolkit.isLoading || getToolkit.isLoading ? (
                                                <>
                                                    &nbsp;
                                                    <Spinner as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true">
                                                    </Spinner>
                                                </>
                                            ) :
                                            ( '' )
                                        }
                                    </Button>
                                </div>
                            </form>
                        </Col>
                    )}
                </Row>
            </Modal.Body>
            {
                status === 0 && (
                    <Modal.Footer className='sticky-footer'>
                        <Button variant="primary" type='submit' form="guru-form">
                            {CategoryUpdate.isLoading ? 'Saving' : 'Save'}
                            {CategoryUpdate.isLoading && (
                                <>
                                    &nbsp;
                                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"></Spinner>
                                </>
                            )}
                        </Button>
                    </Modal.Footer>
                )
            }
        </Modal>
    )
}