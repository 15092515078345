import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export const DocumentDeleteModal = ({ documentId, showDeleteModal, hanldCloseDeleteModel, handleDeleteDoc }) => {
    return (
        <div>
            <Modal show={showDeleteModal} onHide={hanldCloseDeleteModel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this document?</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={hanldCloseDeleteModel}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteDoc(documentId)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
