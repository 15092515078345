import React, { memo, Fragment, useState } from "react";

// react-bootstrap
import { Row, Col, Form, FormCheck, Image } from "react-bootstrap";

// components
import ImpactSubHeader from "../../components/partials/dashboard/headerstyle/impact-sub-header";
import SuggestionSection from "../../components/suggestion-section";
import Card from "../../components/bootstrap/card";
import Counter from "../../components/partials/components/counter";

//Apexcharts
import Chart from "react-apexcharts";
import ReactSlider from "react-slider";

// image
import img1 from "../../assets/images/5.png";
import img2 from "../../assets/images/6.png";
import img3 from "../../assets/images/7.png";
import img4 from "../../assets/images/8.png";
import img5 from "../../assets/images/9.png";
import img6 from "../../assets/images/10.png";
import img7 from "../../assets/images/12.png";

const CarbonFootprint = memo(() => {
  const chart1 = {
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
    series: [
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Free Cash Flow",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
  };
  const chart2 = {
    options: {
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 41, 17, 15],
  };
  const chart3 = {
    series: [60],
    options: {
      chart: {
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      labels: ["Average Results"],
    },
  };
  const [value, setValue] = useState([0, 70]);
  return (
    <Fragment>
      <ImpactSubHeader image={window.iqonic_build + img6}>
        <div>
          <h2>Carbon Footprint</h2>
          <p>Your estimate accuracy and total carbon footprint graph</p>
        </div>
        <Chart
          options={chart3.options}
          series={chart3.series}
          type="radialBar"
        />
      </ImpactSubHeader>
      <div
        className="mt-5"
        style={{
          minHeight: "131px",
          backgroundImage: `url(${window.iqonic_build + img7})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="d-flex justify-content-around flex-wrap pt-4">
          <Row>
            <Col md="3" sm="6">
              <p className="text-white mb-0">Today</p>
              <h2 className="text-secondary mb-0">7.2</h2>
              <p className="text-secondary mb-0">CO2 Tonnes</p>
            </Col>
            <Col md="3" sm="6">
              <p className="text-white mb-0">Future</p>
              <h2 className="text-secondary mb-0">6.4</h2>
              <p className="text-secondary mb-0">CO2 Tonnes</p>
            </Col>
            <Col md="3" sm="6">
              <p className="text-white mb-0">Reduction</p>
              <h2 className="text-secondary mb-0">0.8</h2>
              <p className="text-secondary mb-0">CO2 Tonnes</p>
            </Col>
            <Col md="3" sm="6">
              <p className="text-white mb-0">Saving</p>
              <h2 className="text-secondary mb-0">12,000/year</h2>
            </Col>
          </Row>
          <div style={{ paddingLeft: "8rem" }}>
            <p className="text-secondary mb-0">Its like 72 trips between </p>
            <p className="text-secondary mb-0">
              Amsterdam & Paris with the Thalys
            </p>
          </div>
        </div>
      </div>
      <Card>
        <Card.Body>
          <h5>Today</h5>
          <Row className="row-cols-1 row-cols-md-5 g-4">
            <Col>
              <Card className="bg-soft-secondary z-carbon-card h-100">
                <div className="z-border-bottom p-3">
                  <h5 className="mb-0 text-center">Gas Usage</h5>
                </div>
                <Card.Body>
                  <Counter value="1000 Kwh" />
                  <p className="text-primary text-center mt-5 mb-3">
                    Renewable:
                  </p>
                  <div className="d-flex gap-3 justify-content-center">
                    <Form.Check>
                      <FormCheck.Input
                        type="radio"
                        name="radios"
                        id="yes"
                        defaultChecked
                      />
                      <FormCheck.Label className="text-black" htmlFor="yes">
                        Yes
                      </FormCheck.Label>
                    </Form.Check>
                    <Form.Check>
                      <FormCheck.Input type="radio" name="radios" id="no" />
                      <FormCheck.Label className="text-black" htmlFor="no">
                        No
                      </FormCheck.Label>
                    </Form.Check>
                  </div>
                </Card.Body>
                <div>
                  <Image
                    src={window.iqonic_build + img1}
                    className="img-fuild"
                    loading="lazy"
                  />
                </div>
              </Card>
            </Col>
            <Col>
              <Card className="bg-soft-secondary z-carbon-card h-100">
                <div className="z-border-bottom p-3">
                  <h5 className="mb-0 text-center">Electricity Usage</h5>
                </div>
                <Card.Body>
                  <Counter value="12590 Kwh" />
                  <p className="text-primary text-center mt-5 mb-3">
                    Renewable:
                  </p>
                  <div className="d-flex gap-3 justify-content-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        defaultChecked
                      />
                      <label
                        className="form-check-label text-black"
                        htmlFor="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label
                        className="form-check-label text-black"
                        htmlFor="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </Card.Body>
                <div>
                  <Image
                    src={window.iqonic_build + img2}
                    className="img-fuild"
                    loading="lazy"
                  />
                </div>
              </Card>
            </Col>
            <Col>
              <Card className="bg-soft-secondary z-carbon-card h-100">
                <div className="z-border-bottom p-3">
                  <h5 className="mb-0 text-center">Goods</h5>
                </div>
                <Card.Body>
                  <Counter value="10,000" />
                  <p className="text-black text-center mt-5">
                    Goods & Services
                  </p>
                </Card.Body>
                <div>
                  <Image
                    src={window.iqonic_build + img3}
                    className="img-fuild"
                    loading="lazy"
                  />
                </div>
              </Card>
            </Col>
            <Col>
              <Card className="bg-soft-secondary z-carbon-card h-100">
                <div className="z-border-bottom p-3">
                  <h5 className="mb-0 text-center">People</h5>
                </div>
                <Card.Body>
                  <div className="form-group mt-3 product-range">
                    <ReactSlider
                      className="horizontal-slider pb-2"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      defaultValue={value}
                      ariaLabel={["Lower thumb", "Upper thumb"]}
                      onChange={(currentPriceState) => {
                        setValue([...currentPriceState]);
                      }}
                      step={1}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-5">
                    <p className="text-black">Home</p>
                    <p className="text-black">Office</p>
                  </div>
                </Card.Body>
                <div>
                  <Image
                    src={window.iqonic_build + img4}
                    className="img-fuild"
                    loading="lazy"
                  />
                </div>
              </Card>
            </Col>
            <Col>
              <Card className="bg-soft-secondary z-carbon-card h-100">
                <div className="z-border-bottom p-3">
                  <h5 className="mb-0 text-center">Work Travel</h5>
                </div>
                <Card.Body>
                  <Counter value="10" />
                  <p className="text-black text-center mt-5">Trips Per Years</p>
                </Card.Body>
                <div>
                  <Image
                    src={window.iqonic_build + img5}
                    className="img-fuild"
                    loading="lazy"
                  />
                </div>
              </Card>
            </Col>
          </Row>
          <div className="mt-4">
            <h4>Priorities</h4>
            <p>
              Here are some priorities for your business that you can update
            </p>
            <Row>
              <Col md="4">
                <Card className="bg-soft-secondary z-carbon-card">
                  <Card.Body>
                    <p className="text-black">
                      my priority is reduce my electricity and save my earth.
                    </p>
                    <div className="z-image-end">
                      <div className="avatar-30 bg-secondary z-border-radius">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1929_2565)">
                            <path
                              d="M6 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3535C2.55268 13.2598 2.5 13.1326 2.5 13V10.2069C2.50006 10.0744 2.55266 9.94743 2.64625 9.85374L10.3538 2.14624C10.4475 2.05254 10.5746 1.99991 10.7072 1.99991C10.8397 1.99991 10.9669 2.05254 11.0606 2.14624L13.8538 4.93749C13.9474 5.03125 14.0001 5.15837 14.0001 5.29093C14.0001 5.42348 13.9474 5.55061 13.8538 5.64436L6 13.5Z"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.5 13.5H6"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.5 4L12 7.5"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1929_2565">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4">
                <Card className="bg-soft-secondary z-carbon-card">
                  <Card.Body>
                    <p className="text-black">
                      my priority is reduce my electricity and save my earth.
                    </p>
                    <div className="z-image-end">
                      <div className="avatar-30 bg-secondary z-border-radius">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1929_2565)">
                            <path
                              d="M6 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3535C2.55268 13.2598 2.5 13.1326 2.5 13V10.2069C2.50006 10.0744 2.55266 9.94743 2.64625 9.85374L10.3538 2.14624C10.4475 2.05254 10.5746 1.99991 10.7072 1.99991C10.8397 1.99991 10.9669 2.05254 11.0606 2.14624L13.8538 4.93749C13.9474 5.03125 14.0001 5.15837 14.0001 5.29093C14.0001 5.42348 13.9474 5.55061 13.8538 5.64436L6 13.5Z"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.5 13.5H6"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.5 4L12 7.5"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1929_2565">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4">
                <Card className="bg-soft-secondary z-carbon-card">
                  <Card.Body>
                    <p className="text-black">
                      my priority is reduce my electricity and save my earth.
                    </p>
                    <div className="z-image-end">
                      <div className="avatar-30 bg-secondary z-border-radius">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1929_2565)">
                            <path
                              d="M6 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3535C2.55268 13.2598 2.5 13.1326 2.5 13V10.2069C2.50006 10.0744 2.55266 9.94743 2.64625 9.85374L10.3538 2.14624C10.4475 2.05254 10.5746 1.99991 10.7072 1.99991C10.8397 1.99991 10.9669 2.05254 11.0606 2.14624L13.8538 4.93749C13.9474 5.03125 14.0001 5.15837 14.0001 5.29093C14.0001 5.42348 13.9474 5.55061 13.8538 5.64436L6 13.5Z"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.5 13.5H6"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.5 4L12 7.5"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1929_2565">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div>
            <h4>Future</h4>
            <p>
              Here you can update the key areas of your emissions and see the
              difference you could make.
            </p>
            <h4>We would like to : </h4>
            <div className="d-flex gap-5">
              <div>
                <div className="d-flex">
                  <p>1. Switch to renewables</p>
                  <div className="d-flex gap-3 justify-content-center ms-3">
                    <Form.Check>
                      <FormCheck.Input
                        type="radio"
                        name="default"
                        id="defaultyes1"
                        defaultChecked
                      />
                      <FormCheck.Label
                        className="text-black"
                        htmlFor="defaultyes1"
                      >
                        Yes
                      </FormCheck.Label>
                    </Form.Check>
                    <Form.Check>
                      <FormCheck.Input
                        type="radio"
                        name="default"
                        id="defaultno1"
                      />
                      <FormCheck.Label
                        className="text-black"
                        htmlFor="defaultno1"
                      >
                        No
                      </FormCheck.Label>
                    </Form.Check>
                  </div>
                </div>
                <div>
                  <p>
                    2. Reduce gas use by{" "}
                    <span className="text-decoration-underline">30</span> %
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    3. Reduce electricity use{" "}
                    <span className="text-decoration-underline">18</span> %
                  </p>
                </div>
                <div>
                  <p>
                    4. Reduce goods and service impact{" "}
                    <span className="text-decoration-underline">22</span> %
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            <Col md="7">
              <h5>Carbon Forecast</h5>
              <Chart
                options={chart1.options}
                series={chart1.series}
                type="bar"
              />
            </Col>
            <Col md="5">
              <h5>Future Emissions</h5>
              <Chart
                options={chart2.options}
                series={chart2.series}
                type="donut"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <SuggestionSection />
    </Fragment>
  );
});

CarbonFootprint.displayName = "CarbonFootprint";
export default CarbonFootprint;
