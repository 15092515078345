import React,{memo,Fragment} from 'react'

// react-bootstrap
import {Row,Col} from 'react-bootstrap'

// components
import Card from './bootstrap/card'
import RatingStar from '../components/rating-star';
import ImpactModal from '../components/impact-modal'

// images
import img1 from "../assets/images/2.png";

const SuggestionSection = memo(() => {
  return (
    <Fragment>
        <Row>
          <Col md="5">
            <Card>
              <Card.Body>
                <h5>Suggested Support</h5>
                <div className="mt-4">
                    <Card className="mb-0">
                        <Card.Body className="z-suggetion-card z-support-card">
                            <div className='d-flex align-items-center justify-content-between'> 
                             <h5 className="mb-0">Net Zero</h5>
                            <div className="text-primary d-flex align-items-center">
                                <p className='mb-0'>Get support</p>
                            <svg className="right-icon ms-2" width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 9H14.25M14.25 9L9 3.75M14.25 9L9 14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="my-3">
                        <Card.Body className="z-suggetion-card z-support-card">
                            <div className='d-flex align-items-center justify-content-between'> 
                             <h5 className="mb-0">Renewable Energy</h5>
                            <div className="text-primary d-flex align-items-center">
                                <p className='mb-0'>Get support</p>
                            <svg className="right-icon ms-2" width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 9H14.25M14.25 9L9 3.75M14.25 9L9 14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body className="z-suggetion-card z-support-card">
                            <div className='d-flex align-items-center justify-content-between'> 
                             <h5 className="mb-0">Resource & Waste</h5>
                            <div className="text-primary d-flex align-items-center">
                                <p className='mb-0'>Get support</p>
                            <svg className="right-icon ms-2" width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 9H14.25M14.25 9L9 3.75M14.25 9L9 14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="7">
            <Card>
              <Card.Body>
                <h5>Suggested Social Groups</h5>
                <Row className='row-cols-1 row-cols-md-3 text-center'>
                    <Col>
                    <Card className="z-suggetion-card">
                        <Card.Body className="p-3">
                        <img src={window.iqonic_build+img1} alt="product-details" className="img-fluid trending-img iq-product-img" loading="lazy" />
                        <h5 className="mb-0 mt-3">Pure Renewables</h5>
                        <div className='d-flex gap-1 align-items-center justify-content-center'>
                            <RatingStar count2="1" count="4" /> (6)
                        </div>
                        </Card.Body>
                              </Card>
                    </Col>
                    <Col>
                    <Card className="z-suggetion-card">
                        <Card.Body className="p-3">
                        <img src={window.iqonic_build+img1} alt="product-details" className="img-fluid trending-img iq-product-img" loading="lazy" />
                        <h5 className="mb-0 mt-3">Head-on Renew...</h5>
                        <div className='d-flex gap-1 align-items-center justify-content-center'>
                            <RatingStar count2="1" count="4" /> (6)
                        </div>
                        </Card.Body>
                              </Card>
                    </Col>
                    <Col>
                    <Card className="z-suggetion-card">
                        <Card.Body className="p-3"> 
                        <img src={window.iqonic_build+img1} alt="product-details" className="img-fluid trending-img iq-product-img" loading="lazy" />
                        <h5 className="mb-0 mt-3">Your Brokers</h5>
                        <div className='d-flex gap-1 align-items-center justify-content-center'>
                            <RatingStar count2="1" count="4" /> (6)
                        </div>
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
               
              </Card.Body>
            </Card>
          </Col>
        </Row>
    </Fragment>
  )
})

SuggestionSection.displayName = "SuggestionSection"
export default SuggestionSection