import axios from 'axios';

// const baseURL = window.iqonic_rest_url + "iqonic/"
const apiEndpointSendWindowHref = [
    'api/v4/get_plan',
    'api/v3/platform',
    'api/v3/getting_started'
];
// const baseURL= "https://zeroheroes.ai "
// const baseURL= "https://any.guru/"
// const baseURL= "https://zeroheroes.ai/"
// const baseURL= "https://young.bridgit.care/"

const baseURL = window.location.origin + "/";


const axios_inst = axios.create({
    baseURL: baseURL,
    timeout: 200000,
    withCredentials: true,
    validateStatus: () => true,
    headers: {
        'X-WP-Nonce': window?.nonce
    }
});

// Add a request interceptor to append query parameters
axios_inst.interceptors.request.use(config => {
    if(config?.url && apiEndpointSendWindowHref.includes(config.url)){
        const queryParams = {
            url:window.location.href
        }
        config.params = { 
            ...queryParams,  // Merge existing query params with new ones
            ...config.params // Preserve any params passed in the request
        };
    }
    return config;
}, error => Promise.reject(error));

export default axios_inst;
export { baseURL};
