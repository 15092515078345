import React,{memo,Fragment} from 'react'

const fillstar= <svg xmlns="http://www.w3.org/2000/svg" width="20"  viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z" fill="#F2A127"/>
                </svg>

const unfill = <svg xmlns="http://www.w3.org/2000/svg" width="20"  viewBox="0 0 24 24" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z" stroke="#F2A127" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const halfFill = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 12" fill="none">
  <path d="M6.6284 0.819294C6.71223 0.561359 7.07714 0.561359 7.16098 0.819294L8.1789 3.95115C8.21639 4.06651 8.32389 4.1446 8.44518 4.1446L11.7391 4.1446C12.0103 4.1446 12.1231 4.49174 11.9036 4.65115L9.23895 6.58653C9.14079 6.65783 9.09971 6.78424 9.13721 6.89963L10.1551 10.0312C10.2389 10.2892 9.94368 10.5037 9.72422 10.3443L7.05923 8.40872C6.96111 8.33745 6.82826 8.33745 6.73014 8.40872L4.06516 10.3443C3.8457 10.5037 3.55048 10.2892 3.63432 10.0312L4.65216 6.89963C4.68967 6.78424 4.64859 6.65783 4.55042 6.58653L1.88577 4.65115C1.66629 4.49174 1.77905 4.1446 2.05032 4.1446L5.34419 4.1446C5.46549 4.1446 5.57299 4.06651 5.61048 3.95115L6.6284 0.819294Z" fill="#F0F0F0"/>
  <mask id="mask0_1987_407" style={{ maskType:'alpha' }} maskUnits="userSpaceOnUse" x="1" y="0" width="12" height="11">
    <path d="M6.83902 0.819574C6.92284 0.561607 7.28779 0.561607 7.37161 0.819574L8.38953 3.95242C8.42702 4.06779 8.53453 4.1459 8.65583 4.1459H11.9499C12.2211 4.1459 12.3339 4.49299 12.1145 4.65242L9.44952 6.58863C9.35139 6.65993 9.31032 6.78631 9.34781 6.90168L10.3657 10.0345C10.4495 10.2925 10.1543 10.507 9.93485 10.3476L7.26989 8.41137C7.17176 8.34007 7.03887 8.34007 6.94073 8.41137L4.27577 10.3476C4.05633 10.507 3.76108 10.2925 3.8449 10.0345L4.86282 6.90168C4.90031 6.78631 4.85924 6.65993 4.76111 6.58863L2.09615 4.65242C1.87671 4.49299 1.98948 4.1459 2.26073 4.1459H5.5548C5.6761 4.1459 5.78361 4.06779 5.82109 3.95242L6.83902 0.819574Z" fill="#F2B827"/>
  </mask>
  <g mask="url(#mask0_1987_407)">
    <rect x="1.10531" width="7.71429" height="12" fill="#F2A127"/>
  </g>
</svg>

const StarRating = memo(function StarRating({count, value, count1,count2,
  inactiveColor = '#ddd',
    halfFillColor = '#F2A127',
    size=24,
    activeColor='#f00', onChange1={value}}) {

  // short trick 
  const stars = Array.from({length: count}, () => fillstar)
  const stars1 = Array.from({length: count1}, () => unfill)
  const stars2 = Array.from({length: count2}, () => halfFill)

  // Internal handle change function
  const handleChange = (value) => {
   return onChange1(value + 1);
  }

  return (
    <div>
      {stars.map((s, index) => {
        let style = inactiveColor;
        if (index < value) {
          style=activeColor;
        }
        return (
          <span className={"star"}  
            key={index}
            style={{color: style, width:size, height:size, fontSize: size}}
            onClick={()=>handleChange(index)}>{s}</span>
        )
      })}
      {value}

      {stars1.map((s, index) => {
        let style = inactiveColor;
        if (index < value) {
          style=activeColor;
        }
        return (
          <span className={"star"}  
            key={index}
            style={{color: style, width:size, height:size, fontSize: size}}
            onClick={()=>handleChange(index)}>{s}</span>
        )
      })}
      {value}
      
      {stars2.map((s, index) => {
        let style = halfFillColor;
        if (index < value) {
          style=activeColor;
        }
        return (
          <span className={"star"}  
            key={index}
            style={{color: style, width:size, height:size, fontSize: size}}
            onClick={()=>handleChange(index)}>{s}</span>
        )
      })}
      {value}
    </div>
  )
})

const RatingStar = memo((props) => {
  
    return (
        <Fragment> 
          <StarRating count1={props.count1} count2={props.count2} count={props.count}/>
        </Fragment>
    )
})

RatingStar.displayName="RatingStar"
export default RatingStar; 