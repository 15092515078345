import { memo, Fragment, useState, useEffect, useRef } from 'react'

//React-router
import { Link } from 'react-router-dom'
const Footer = memo(() => {
    // date
    const [date, setDate] = useState();
    const getYear = () => setDate(new Date().getFullYear());
    useEffect(() => {
        getYear();
    }, []);
    const trademark = localStorage.getItem("trademark")
    const privacyurl = localStorage.getItem("privacyurl")
    const termsurl = localStorage.getItem("termsurl")

    const footerRef = useRef(null);

    useEffect(() => {
        const footerheight = () => {
            if (footerRef.current) {
                const footerHeight = footerRef.current.clientHeight;
                document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`);
            }
        };

        footerheight();

        window.addEventListener('resize', footerheight);

        return () => {
            window.removeEventListener('resize', footerheight);
        };
    })

    return (
        <Fragment>
            <footer className="footer" ref={footerRef}>
                <div className="footer-body">
                    <ul className="left-panel list-inline mb-0 p-0">
                        <li className="list-inline-item">
                            <a href={`https://${privacyurl}`} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </li>
                        <li className="list-inline-item">
                            <a href={`https://${termsurl}`} target="_blank" rel="noopener noreferrer">Terms of Use</a>
                        </li>
                    </ul>
                    <div className="right-panel">
                        <div className="d-flex">
                            <div>
                                {trademark}
                                {/* Copyright © {date}{" "}
                                <Link to="#" className="mx-2">
                                    {" "}
                                    Eco Hero{" "}
                                </Link>{" "}
                                is a trademark of Upstream Outcomes Limited */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
})

Footer.displayName = "Footer"
export default Footer
