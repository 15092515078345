import React, { memo, Fragment, useState, useEffect } from 'react'
import { Form, InputGroup, OverlayTrigger } from 'react-bootstrap'
import guru1 from "../assets/images/guru1.png";
import Card from './bootstrap/card'
import img1 from '../assets/images/chat-logo.png'
import EventSourceObj from '../utilities/EventSource'
import Markdown from '../components/partials/components/markdowan'
import { useDispatch, useSelector } from 'react-redux'
import { PersonaAvatar } from './partials/components/PersonaAvatar';
import { ImageUpload } from './ImageUpload';
import { AddNewToolkit, ConnectMessage, ConnectMessageStatus } from '../store/dashboardConfig/dashboardConfigSlice';
import { DocUpload } from '../apis/Dashboard';
import { ImageVeiw } from './partials/components/ImageVeiw';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from 'react-router-dom';
import axios_inst, { baseURL } from '../utilities/axios';
import ButtonWidget from './button-widget'; // Import ButtonWidget

export const ChatHeader = memo(({ chatTitel }) => {
    const [avatarDetail, setAvatarDetail] = useState()
    const [openModel, setOpenModel] = useState(false)
    const parsedPersonaTitle = JSON.parse(localStorage.getItem('personaTitle'));

    const handleOpenPopup = (e, item) => {
        const defaultValue = JSON.parse(localStorage.getItem('personaTitle')) || {
            description: { skills: '', text: '', title: '' },
            media: { avatar: '' }
        };

        const skills = item?.description?.skills || item?.personaTitle?.description?.skills || defaultValue.description.skills;
        const text = item?.description?.text || item?.personaTitle?.description?.text || defaultValue.description.text;
        const title = item?.description?.title || item?.personaTitle?.description?.title || defaultValue.description.title;
        const avatar = item?.media?.avatar || item?.personaTitle?.media?.avatar || defaultValue.media.avatar;

        const avatarDetails = { skills, text, title, avatar };
        setAvatarDetail(avatarDetails);
        setOpenModel(true);
    };

    const handleClose = () => setOpenModel(false);

    return (
        <div className='d-flex flex-grow-1' style={{ height: "70px", alignItems: "center" }}>
            <PersonaAvatar show={openModel} handleClose={handleClose} avatarDetail={avatarDetail} />
            <div className='avatar-info position-relative flex-shrink-0'>
                {!chatTitel && !parsedPersonaTitle?.media?.avatar && (
                    <div className="text-center h-100" style={{ display: 'grid', placeItems: 'center', background: '#fff3' }}>
                        <div className="spinner-grow text-primary" role="status"></div>
                    </div>
                )}
                <>
                    {chatTitel?.personaTitle && (
                        <img alt='logo' className="img-fuild chat-box-userimage" loading="lazy" src={chatTitel?.personaTitle?.media?.avatar || guru1} />
                    )}
                    {chatTitel?.description && (
                        <img alt='logo' className="img-fuild chat-box-userimage" loading="lazy" src={chatTitel?.media?.avatar || guru1} />
                    )}
                    {!chatTitel && (
                        <img alt='logo' className="img-fuild chat-box-userimage" loading="lazy" src={parsedPersonaTitle?.media?.avatar} />
                    )}
                    <button className='btn bg-transparent position-absolute iq-info-icon p-0 btn-avatar-info' onClick={(e) => handleOpenPopup(e, chatTitel)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon-20"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0 24-10.7 24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"></path></svg>
                    </button>
                </>
            </div>
            <div className='flex-grow-1'>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <span className="tooltip-custom">
                            {chatTitel?.personaTitle?.description?.title || chatTitel?.description?.title || parsedPersonaTitle?.description?.title}
                        </span>
                    }
                >
                    <h3 className='chat-box-username' style={{ display: '-webkit-box', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: "15px", marginLeft: ".325rem" }}>
                        {chatTitel?.personaTitle?.description?.title || chatTitel?.description?.title || parsedPersonaTitle?.description?.title}
                    </h3>
                </OverlayTrigger>
            </div>
        </div>
    )
})

export const RightChat = memo(({ chatMessage, loaded, dateTime, chatTitel, documentData }) => {
    const title = chatTitel?.description?.title || chatTitel?.personaTitle?.description?.title;
    const avatar = chatTitel?.media?.avatar || chatTitel?.personaTitle?.media?.avatar
    const timestamp = chatMessage?.time;
    const date = new Date(timestamp * 1000);
    const formattedDate = chatMessage?.time ? date.toLocaleString() : dateTime
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const documentId = chatMessage?.message?.document_id;

    const images = documentId ? `${baseURL}api/v3/document?id=${documentId}&raw=true` : null;
    const fileType = documentData.type;
    const documentsIds = documentData.id;
    const docName = documentData.name;
    const [documents, setDocuments] = useState({});

    const handleDocumentDownload = async (documentId) => {
        const apiUrl = `${baseURL}api/v3/document?id=${documentId}`;
        if (!apiUrl) return;
    
        try {
            const response = await fetch(apiUrl);
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
            const data = await response.json();
            setDocuments(prevDocuments => ({
                ...prevDocuments,
                [documentId]: {
                    type: data?.document?.type,
                    id: data?.document?.id,
                    name: data?.document?.name
                }
            }));
        } catch (err) {
            console.log("error");
        }
    };

    return (
        <div>
            <ImageVeiw file={images} handleClose={handleClose} show={show} />
            {chatMessage.direction && (
                <div className="iq-message-body iq-current-user">
                    <div className="iq-chat-text">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="iq-chating-content-right d-flex align-items-center chat-bg-left">
                                {chatMessage?.message?.text ? (
                                    <p className="text-wrap mr-2 mb-0" style={{ wordBreak: "break-word" }}>
                                        {chatMessage?.message?.text}
                                    </p>
                                ) : (
                                    <>
                                        {fileType && fileType.startsWith('image') ? (
                                            <div className="pitchdeck-container">
                                                <a href={`${baseURL}api/v3/document?id=${documentsIds}&raw=true`} target="_blank" rel="noopener noreferrer" download={docName} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'white' }} onClick={() => handleDocumentDownload(documentsIds)}>
                                                    <div className="image-container" style={{ display: "flex", marginRight: '8px', position: 'relative' }}>
                                                        <LazyLoadImage className="img-fluid" src={images} />
                                                        <svg className="eye-icon" style={{ width: '25px', height: '25px', position: 'absolute', transition: 'opacity 0.3s', top: '0px', right: '0px', left: '0px', bottom: '0px', margin: 'auto', background: "#8e8f9061" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                                                            <path d="M12 4.5c-5.05 0-9.27 3.11-11 7.5 1.73 4.39 5.95 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-5.95-7.5-11-7.5zm0 13c-3.03 0-5.5-2.47-5.5-5.5s2.47-5.5 5.5-5.5 5.5 2.47 5.5 5.5-2.47 5.5-5.5 5.5zm0-9c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5z" />
                                                        </svg>
                                                    </div>
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="pitchdeck-container">
                                                <a href={`${baseURL}api/v3/document?id=${documentsIds}&raw=true`} target="_blank" rel="noopener noreferrer" download={docName} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'white' }} onClick={() => handleDocumentDownload(documentsIds)}>
                                                    <div style={{ display: "flex", height: '36px', position: 'relative', marginRight: '8px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="35" fill="currentColor" className="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                                                        </svg>
                                                        <span className='truncate' style={{ paddingTop: "6px", textAlign: "center" }}>{docName}</span>
                                                    </div>
                                                </a>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <small className="iq-chating p-0">{formattedDate}</small>
                    </div>
                </div>
            )}
            {!chatMessage.direction && (
                <></>
            )}
        </div>
    )
})

var messageText = ''
export const LeftChat = memo(({ chatMessage, dateTime, chatTitel }) => {
    const title = chatTitel?.description?.title || chatTitel?.personaTitle?.description?.title;
    const avatar = chatTitel?.media?.avatar || chatTitel?.personaTitle?.media?.avatar
    const timestamp = chatMessage?.time;
    const date = new Date(timestamp * 1000);
    const formattedDate = chatMessage?.time ? date.toLocaleString() : dateTime
    messageText = chatMessage?.message?.text;
    return (
        <div>
            <div className="iq-message-body iq-other-user">
                <div className="chat-profile">
                    <img src={avatar ? avatar : img1} alt="chat-user" loading="lazy" className="avatar-30 rounded-pill" />
                </div>
                <div className="iq-chat-text">
                    <p className="iq-chating p-0 mb-1" style={{ fontWeight: '600' }}>{title}</p>
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="iq-chating-content d-flex align-items-center bg-soft-secondary">
                            {chatMessage?.message?.text ? (
                                <p className="mr-2 mb-0 text-primary iq-chat-response" style={{ wordBreak: "break-word" }}>
                                    <Markdown content={messageText} />
                                </p>
                            ) : (
                                <img className="img-fluid" src={`${baseURL}api/v3/document?id=${chatMessage?.message?.document_id}`} alt='img' />
                            )}
                            {chatMessage?.message?.content?.map((contentItem, index) => (
                                console.log(contentItem?.action),
                                contentItem.type === 'button' ? (
                                    <ButtonWidget key={index} config={contentItem}
                                    buttonType={contentItem?.action}
                                    buttonText={contentItem?.text}
                                     />
                                ) : null
                            ))}
                        </div>
                    </div>
                    <small className="iq-chating p-0">{formattedDate}</small>
                </div>
            </div>
        </div>
    )
})

export const ChatLoader = memo(({ chatTitel }) => {
    const [title, setTitle] = useState('');
    const [avtar, setAvtar] = useState('');

    useEffect(() => {
        if (chatTitel) {
            setTitle(chatTitel.description?.title || chatTitel?.personaTitle?.description?.title);
            setAvtar(chatTitel.media?.avatar || chatTitel?.personaTitle?.media?.avatar);
        }
    }, [chatTitel]);

    return (
        <div>
            <div className="iq-message-body iq-other-user">
                <div className="chat-profile">
                    <img
                        src={avtar}
                        alt="chat-user"
                        loading="lazy"
                        className="avatar-30 rounded-pill"
                        onError={(e) => e.target.style.display = 'none'}
                    />
                </div>
                <div className="iq-chat-text">
                    <p className="iq-chating p-0 mb-1">{title}</p>
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="iq-chating-content d-flex align-items-center bg-soft-secondary rounded-pill">
                            <div className="snippet px-3" data-title="dot-flashing">
                                <div className="stage">
                                    <div className="dot-flashing"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
const Chat = memo((props) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { mutate } = DocUpload()
    const chatTitel = useSelector(state => state.chatTitel);
    const category_id = useSelector(state => state.category_id);
    const connectmessage = useSelector(state => state.connectmessage);
    const connectmessagestatus = useSelector(state => state.connectmessagestatus);
    const mobileChatModel = useSelector(state => state.mobileChatModel);
    const chatFullScreen = useSelector(state => state.chatFullScreen)
    const NavIds = useSelector(state => state.nav_id);
    const [active, setActive] = useState(null)
    const [message, setMessage] = useState('');
    const [show, setShow] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [eventResponce, setEventResponce] = useState({})
    const [chatMessage, setChatMessage] = useState('')
    const [eventResponse, setEventResponse] = useState('');
    const [conversations, setConversations] = useState([])
    const [dateTime, setDateTime] = useState('');
    const [isStream, setIsStream] = useState(false)
    const [mobileVeiw, setMobileVeiw] = useState(true)
    const [sendMsgStatus, setSendMsgStatus] = useState(false)
    const [file, setFile] = useState(null);
    const [selecetImage, setSelectImage] = useState(false)
    const [documentId, setDocumentId] = useState(null);
    const [documents, setDocuments] = useState({});
    const [visibleConversations, setVisibleConversations] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = 5;
    let isStreaming = 0;
    const [loadingMore, setLoadingMore] = useState(false);
    const [checkDocument , setcheckDocument] = useState(false)

    
    useEffect(() => {
        if (category_id.persona_id && category_id.category_id) {
            setActive(null);
            setConversations([]);
            setMessage('');
            setDocumentId(null);
            setDocuments({});
            setPage(1);
            setVisibleConversations([]);
            setLoadingMore(false);

            let event;
            let eventResponses = {};

            if (location.pathname === "/business") {
                event = EventSourceObj(`api/v3/chat?history&persona=${category_id.persona_id}&association_tab_id=Profile`);
            } else {
                let baseURL = `api/v3/chat?history&persona=${category_id.persona_id}&${NavIds}=${category_id.category_id}`;
                if (documentId) {
                    baseURL += `&document_id=${documentId}`;
                }
                event = EventSourceObj(baseURL);
            }

            event.addEventListener('message', (eventData) => {
                setIsLoading(false);
                let eventJsonData = JSON.parse(eventData.data);
                eventResponses = { ...eventResponses, ...eventJsonData };
                setConversations(prevConversations => [...prevConversations, eventJsonData]);
            });

            event.addEventListener('error', (eventData) => {
                setEventResponse(eventResponses);
                event.close();
            }, { once: true });

            return () => {
                setChatMessage('');
                event.close();
            };
        }
    }, [category_id, NavIds]);

    useEffect(() => {
        const fetchDocument = async (documentId) => {
            // intial load document
            const apiUrl = documentId ? `${baseURL}api/v3/document?id=${documentId}&data=false` : null;
            if (!apiUrl) return;

            try {
                const response = await fetch(apiUrl);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const data = await response.json();
                setDocuments(prevDocuments => ({
                    ...prevDocuments,
                    [documentId]: {
                        type: data?.document?.type,
                        id: data?.document?.id,
                        name: data?.document?.name
                    }
                }));
            } catch (err) {
                console.log("error");
            }
        };

        conversations.forEach(({ message }) => {
            const documentId = message?.document_id;
            if (documentId && !documents[documentId]) {
                fetchDocument(documentId);
            }
        });
    }, [conversations]);

    useEffect(() => {
        const handleScroll = () => {
            const chatBody = document.querySelector('.chat-body');
            // Check if we've scrolled to the top and if there's more data to load
            if (chatBody && chatBody.scrollTop <= 1 && page * itemsPerPage < conversations.length) {
                if (!loadingMore) {
                    setLoadingMore(true);
                    setTimeout(() => {
                        setPage(prevPage => prevPage + 1); // Load next page
                        setLoadingMore(false); // Set loading to false after data load
                    }, 1000); // Adjust the delay as needed
                }
            }
        };
    
        const chatBody = document.querySelector('.chat-body');
        if (chatBody) {
            chatBody.addEventListener('scroll', handleScroll);
        }
    
        return () => {
            if (chatBody) {
                chatBody.removeEventListener('scroll', handleScroll);
            }
        };
    }, [page, conversations, loadingMore, itemsPerPage]);
    
    useEffect(() => {
        const newVisibleConversations = conversations.slice(0, page * itemsPerPage);
        setVisibleConversations(newVisibleConversations);
    }, [page, conversations, itemsPerPage]);
    

    const handleChange = (event) => {
        setMessage(event.target.value);
    };
    useEffect(() => {
        setMessage(connectmessage);
    }, [connectmessage]);
    useEffect(() => {
        setShow(!(!category_id.persona_id))
    }, [category_id]);

    useEffect(() => {
        if (connectmessage && !connectmessagestatus) {
            handleSendMessage();
            dispatch(ConnectMessageStatus(true))
        }
    }, [message, connectmessagestatus]);
    const handleSendMessage = async () => {
        setMessage('');
        if (!message.trim() && !file) {
            return;
        }
        if (isStream) {
            return;
        }
        setIsLoading(true);
        setActive(null);
        let EventResponces = {};

        if (message) {
            setConversations(prevConversations => [
                { direction: "from_user", message: { text: message, document_id: documentId } },
                ...prevConversations
            ]);
        }

        const callEventSource = (docId) => {
            let eventSourceUrl = `api/v3/chat?persona=${category_id.persona_id}&${NavIds}=${category_id.category_id}&message=${message}`;
            if (location.pathname === "/business") {
                eventSourceUrl = `api/v3/chat?persona=${category_id.persona_id}&association_tab_id=Profile&message=${message}`;
            }

            if (docId) {
                eventSourceUrl += `&document_id=${docId}`;
            }

            let event = EventSourceObj(eventSourceUrl);

            const currentDate = new Date().toLocaleString();

            setSelectImage(false);
            setEventResponce({});
            scrollToBottom();

            event.addEventListener('message', (eventData) => {
                let eventJsonData = JSON.parse(eventData.data);
                let { stream, finished, newDocument, content } = eventJsonData;

                if (newDocument) {
                    const startTime = Math.floor(Date.now() / 1000);
                    const finishTime = startTime + 100;
                    dispatch(AddNewToolkit({
                        documents: [newDocument],
                        finish_time: finishTime,
                        start_time: startTime
                    }));
                    setDocumentId(newDocument.id); // Ensure documentId is set
                }
                if (finished) {
                    setLoaded(true);
                    event.close();
                    setIsStream(false);
                }

                if (stream) {
                    setIsLoading(false);
                    setIsStream(true);
                    setConversations(prevConversations => {
                        const newConversation = {
                            direction: "to_user",
                            message: {
                                text: prevConversations?.length > 0 && isStreaming < 1 ? stream : prevConversations[0]?.message?.text + stream,
                                document_id: documentId,
                                content: content // Ensure content array is included
                            }
                        };
                        if (isStreaming < 1) {
                            isStreaming++;
                            return [
                                newConversation,
                                ...prevConversations
                            ];
                        } else {
                            return [
                                newConversation,
                                ...prevConversations.slice(1)
                            ];
                        }
                    });
                    scrollToBottom();
                }
            });

            return () => {
                documentId = '';
                setChatMessage('');
                setIsLoading(true);
                event.close();
            };
        };

        if (file) {
            const formData = new FormData();
            formData.append('data', file);

            mutate(formData, {
                onSuccess: (response) => {
                    const newDocumentId = response?.data?.document?.id;
                    setDocumentId(newDocumentId); // Ensure documentId is set
                    if (newDocumentId) {
                        setConversations(prevConversations => [
                            { direction: "from_user", message: { text: message, document_id: newDocumentId } },
                            ...prevConversations
                        ]);
                    }
                    setFile('');
                    callEventSource(newDocumentId);
                },
                onError: (error) => {
                    console.error('Document upload failed:', error);
                }
            });
        } else {
            callEventSource();
        }
    };


    const scrollToBottom = () => {
        const chatBody = document.querySelector('.chat-body');
        if (chatBody) {
            chatBody.scrollTop = chatBody.scrollHeight;
        }
    };

    const [isMaximized, setIsMaximized] = useState(false);

    useEffect(() => {
        setMessage(connectmessage);
        if (connectmessage && connectmessagestatus) {
            if(chatFullScreen){
                fullscreen()
            }
            setShow(!show)
            setMessage("");
        }
    }, [connectmessage, connectmessagestatus]);
    const fullscreen = () => {
        const card = document.querySelector('.resize');
        if (card) {
            if (!isMaximized) {
                card.classList.add('maximized');
                setIsMaximized(true);
            } else {
                card.classList.remove('maximized');
                setIsMaximized(false);
                dispatch(ConnectMessage(''))
                dispatch(ConnectMessageStatus(false))
            }
        }
    };
    useEffect(() => {
        const handleResize = () => {
            const mobileWidth = window.innerWidth <= 420;

            if (mobileWidth) {
                setMobileVeiw(false);
            }

        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (!selectedFile) {
            setMessage('No file selected');
            return;
        }
        const formData = new FormData();
        formData.append('image', selectedFile);
        try {
            setSelectImage(true)
        } catch (error) {
            setMessage('Error uploading file');
            console.error(error);
        }
        e.target.value = null
    };
    const handleImage = (event) => {
        const { target = {} } = event || {};
        target.value = "";
    }
    const handelClose = () => {
        setSelectImage(false)
    }

    return (
        category_id.persona_id && (<Fragment>
            <Card className="scroll sticky-top mb-0" style={{ zIndex: "unset" }}>

                <Card.Header className="bg-soft-secondary position-relative chat-card-header" >
                    <ChatHeader chatTitel={chatTitel}>

                    </ChatHeader>
                    {show === true && (
                        <div className="sidebar-toggle top-35 rotate-180" onClick={props.onToggle}>
                            <i className="icon"><svg width="20" className="icon-20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.25 12.2744L19.25 12.2744" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></i>
                        </div>
                    )}
                    <div className="btn btn-secondary btn-icon btn-md rounded-pill  d-none d-sm-flex d-md-flex icons-colors-filled flex-shrink-0" style={{ height: "1.9rem", width: "1.9rem", paddingTop: "" }} onClick={() => { fullscreen(); setShow(!show); }}>
                        <span className="btn-inner"  >
                            <svg className={`normal-screen ${show === true ? '' : 'd-none'} icon-24`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.5528 5.99656L13.8595 10.8961" stroke="white" strokeWidth="1.5" strokeLinejoin="round"></path>
                                <path d="M14.8016 5.97618L18.5524 5.99629L18.5176 9.96906" stroke="white" strokeWidth="1.5" strokeLinejoin="round"></path>
                                <path d="M5.8574 18.896L10.5507 13.9964" stroke="white" strokeWidth="1.5" strokeLinejoin="round" ></path>
                                <path d="M9.60852 18.9164L5.85775 18.8963L5.89258 14.9235" stroke="white" strokeWidth="1.5" strokeLinejoin="round"></path>
                            </svg>
                            <svg className={`full-normal-screen ${show === false ? '' : 'd-none'} icon-24`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7542 10.1932L18.1867 5.79319" stroke="white" strokeWidth="1.5" strokeLinejoin="round"></path>
                                <path d="M17.2976 10.212L13.7547 10.1934L13.7871 6.62518" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"></path>
                                <path d="M10.4224 13.5726L5.82149 18.1398" stroke="white" strokeWidth="1.5" strokeLinejoin="round"></path>
                                <path d="M6.74391 13.5535L10.4209 13.5723L10.3867 17.2755" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                    </div>
                </Card.Header>
                <Card.Body className="chat-body flex-column-reverse">
                    {isLoading && (
                        <ChatLoader chatTitel={chatTitel} />
                    )}
                    <>
                        {visibleConversations.map((messages, index) => {
                            const { direction, message } = messages;
                            const documentData = documents[message?.document_id] || {};
                            if (direction === 'to_user') {
                                return <LeftChat chatTitel={chatTitel} key={index} chatMessage={messages} dateTime={dateTime} />;
                            } else {
                                return <RightChat chatTitel={chatTitel} key={index} chatMessage={messages} loaded={loaded} dateTime={dateTime} documentData={documentData} />
                            }
                        })}
                    </>
                    {loadingMore && (
                        <div className="text-center my-2">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                </Card.Body>
                {selecetImage && (
                    <div className="px-3 rounded-0">
                        <ImageUpload file={file} handelClose={handelClose} />
                    </div>
                )}
                <div className="px-3 pb-3 rounded-0">
                    <Form onSubmit={(e) => {
                        e.preventDefault()
                        if (!isLoading) {
                            handleSendMessage();
                        }
                    }}>
                        <InputGroup>
                            <input type="text"
                                className="form-control chat-input"
                                placeholder="Write a message"
                                aria-label="Recipient's username"
                                value={message}
                                onChange={handleChange}
                            />
                            <span className="input-group-text mgs-icon voice-icon" onClick={() => document.getElementById('fileInput').click()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
                                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                                </svg>
                            </span>
                            <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} onClick={handleImage} />
                            <span className="input-group-text mgs-icon voice-icon" onClick={handleSendMessage}>
                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-24 gurus" height="32">
                                    <path d="M21.4274 2.5783C20.9274 2.0673 20.1874 1.8783 19.4974 2.0783L3.40742 6.7273C2.67942 6.9293 2.16342 7.5063 2.02442 8.2383C1.88242 8.9843 2.37842 9.9323 3.02642 10.3283L8.05742 13.4003C8.57342 13.7163 9.23942 13.6373 9.66642 13.2093L15.4274 7.4483C15.7174 7.1473 16.1974 7.1473 16.4874 7.4483C16.7774 7.7373 16.7774 8.2083 16.4874 8.5083L10.7164 14.2693C10.2884 14.6973 10.2084 15.3613 10.5234 15.8783L13.5974 20.9283C13.9574 21.5273 14.5774 21.8683 15.2574 21.8683C15.3374 21.8683 15.4274 21.8683 15.5074 21.8573C16.2874 21.7583 16.9074 21.2273 17.1374 20.4773L21.9074 4.5083C22.1174 3.8283 21.9274 3.0883 21.4274 2.5783Z" fill="currentColor"></path>
                                    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M3.01049 16.8079C2.81849 16.8079 2.62649 16.7349 2.48049 16.5879C2.18749 16.2949 2.18749 15.8209 2.48049 15.5279L3.84549 14.1619C4.13849 13.8699 4.61349 13.8699 4.90649 14.1619C5.19849 14.4549 5.19849 14.9299 4.90649 15.2229L3.54049 16.5879C3.39449 16.7349 3.20249 16.8079 3.01049 16.8079ZM6.77169 18.0003C6.57969 18.0003 6.38769 17.9273 6.24169 17.7803C5.94869 17.4873 5.94869 17.0133 6.24169 16.7203L7.60669 15.3543C7.89969 15.0623 8.37469 15.0623 8.66769 15.3543C8.95969 15.6473 8.95969 16.1223 8.66769 16.4153L7.30169 17.7803C7.15569 17.9273 6.96369 18.0003 6.77169 18.0003ZM7.02539 21.5683C7.17139 21.7153 7.36339 21.7883 7.55539 21.7883C7.74739 21.7883 7.93939 21.7153 8.08539 21.5683L9.45139 20.2033C9.74339 19.9103 9.74339 19.4353 9.45139 19.1423C9.15839 18.8503 8.68339 18.8503 8.39039 19.1423L7.02539 20.5083C6.73239 20.8013 6.73239 21.2753 7.02539 21.5683Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </InputGroup>
                    </Form>
                </div>
            </Card>
        </Fragment>)
    )
})

Chat.displayName = "Chat"
export default Chat