import React, { Fragment, memo, useEffect, useState } from 'react';
import { Form, Col, Row, Dropdown, Badge, Button } from 'react-bootstrap';
import Card from '../../components/bootstrap/card';
import ImpactSubHeader from '../../components/partials/dashboard/headerstyle/impact-sub-header';
import { GetAssociation, GetToolkit, ProfileMutation } from '../../apis/Dashboard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import img1 from "../../assets/images/business.png";
import { useDispatch, useSelector } from 'react-redux';
import { Nav_Id } from '../../store/dashboardConfig/dashboardConfigSlice';
import Markdown from '../../components/partials/components/markdowan';
import { SummeryGenrate } from '../../components/partials/components/SummeryGenrate';
import EventSourceObj from '../../utilities/EventSource';
import { useOutletContext } from 'react-router-dom';

const BusinessProfile = memo((props) => {
    const dispatch = useDispatch();
    const { mutate } = ProfileMutation();
    const { data, refetch } = GetAssociation();
    const { bannerImage } = useOutletContext();

    const [associationData, setAssociationData] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});
    const [formData, setFormData] = useState({});
    const [initialFormData, setInitialFormData] = useState({});
    const [businessSummary, setBusinessSummary] = useState("");
    const [initialBusinessSummary, setInitialBusinessSummary] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [banner, setBanner] = useState()
    const [title, setTitle] = useState()
    const [newSummary, setNewSummary] = useState(false)
    const [isFieldChanged, setIsFieldChanged] = useState(false);
    const SideBar = useSelector(state => state.pages);

    useEffect(() => {
        // Check if `SideBar.associations` contains valid data
        const banner = SideBar.associations?.banner;
        const title = SideBar.associations?.tabs[0];

        if (banner && title) {
            // If valid data exists, set the state and store it in localStorage
            setBanner(banner);
            setTitle(title);
            localStorage.setItem('banner', banner);
            localStorage.setItem('title', JSON.stringify(title));
        } else {
            // If not, fall back to retrieving from localStorage
            const storedBanner = localStorage.getItem('banner');
            const storedTitle = JSON.parse(localStorage.getItem('title'));

            if (storedBanner && storedTitle) {
                setBanner(storedBanner);
                setTitle(storedTitle);
            }
        }
    }, [SideBar]);
    // const Banner = localStorage.getItem('banner');
    useEffect(() => {
        if (data && data.associations) {
            const associations = data?.associations.map(item => item.other);
            setAssociationData(associations);
                if (associations?.[0].summary.value) {
                    setBusinessSummary(associations?.[0]?.summary?.value);
                    // setInitialBusinessSummary(field.summary.value);
                }
                if (!associations?.[0].summary.value) {
                    // const businessSummary = "summary" //local
                    const businessSummary = "association?summary" //live
                    let event = EventSourceObj(`api/v3/${businessSummary}`);
                    let eventResponses = {};

                    event.addEventListener('message', (eventData) => {
                        // setIsLoading(false);
                        let eventJsonData = JSON.parse(eventData.data);
                        eventResponses = { ...eventResponses, ...eventJsonData };
                        // setBusinessSummary(prevConversations => [...prevConversations, eventJsonData]);
                        let { stream, finished, type } = eventJsonData;
                        if (finished) {
                        }
                        if (type === "summary" && stream) {
                            setBusinessSummary(prev => prev + stream);
                        }
                    });

                    event.addEventListener('error', (eventData) => {
                        // setEventResponse(eventResponses);
                        event.close();
                    }, { once: true });

                    return () => {
                        // setChatMessage('');
                        event.close();
                    };

                }
                // if (field.id) {
                //     initialData[field.id] = '';
                //     Object.keys(field).forEach(key => {
                //         if (key !== 'id') {
                //             initialData[field.id] = field[key]?.value || '';
                //         }
                //     });
            //     // }
            // setFormData(initialData);
            // setInitialFormData(initialData);
        }
    }, [data])


    // useEffect(() => {
    //   // setActive(null);
    //   // setConversations([]);
    //   const businessSummary = "association?summary" //live
    //   // const businessSummary = "summary" //local
    //   let event = EventSourceObj(`api/v3/${businessSummary}`);
    //   let eventResponses = {};

    //   event.addEventListener('message', (eventData) => {
    //     // setIsLoading(false);
    //     let eventJsonData = JSON.parse(eventData.data);
    //     eventResponses = { ...eventResponses, ...eventJsonData };
    //     // setBusinessSummary(prevConversations => [...prevConversations, eventJsonData]);
    //     let { stream, finished, type } = eventJsonData;
    //     if (finished) {
    //     }
    //     if (type === "summary" && stream) {
    //       setBusinessSummary(prev => prev + stream);
    //     }
    //   });

    //   event.addEventListener('error', (eventData) => {
    //     // setEventResponse(eventResponses);
    //     event.close();
    //   }, { once: true });

    //   return () => {
    //     // setChatMessage('');
    //     event.close();
    //   };
    // }, []);

    const handleEdit = () => {
        setIsEditable(true);
    };

    const handleInputChange = (e, fieldId) => {
        const { value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [fieldId]: value
        }));

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedData = {};
        Object.keys(formData).forEach(key => {
            if (formData[key] !== initialFormData[key]) {
                updatedData[key] = formData[key];

            }
        });

        if (businessSummary !== initialBusinessSummary) {
            updatedData.summary = businessSummary;
        }

        if (Object.keys(updatedData).length > 0) {
            try {
                await mutate(updatedData, {
                    onSuccess: (response) => {

                        if (response.data.summary) {
                            setIsFieldChanged(true);
                            refetch();
                        }
                    }
                });
                toast.success("Changes have been saved");
                setInitialFormData(formData);
                setInitialBusinessSummary(businessSummary);
            } catch (error) {
                console.error("Error occurred while submitting data:", error);
                toast.error("An error occurred while saving changes");
            }
        } else {
            // toast.info("No changes to save");
        }
        setIsEditable(false);
    };

    const handleItemSelect = (item, fieldId) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldId]: item
        }));
        setSelectedItems(prevState => ({
            ...prevState,
            [fieldId]: item
        }));
    };




    const handleSummeryGenrate = () => {
        setShowModal(true);
        setIsFieldChanged(false);

    };

    const hanldCloseSummary = () => {
        setShowModal(false)
    }
    const handelSaveChange = () => {
        setNewSummary(true)
        setShowModal(false)
        setBusinessSummary('')
        const businessSummary = "association?summary" //live
        // const businessSummary = "summary" //local
        let event = EventSourceObj(`api/v3/${businessSummary}`);
        let eventResponses = {};
        const updatedData = { summary: "" }
        try {
            mutate(updatedData, {
                onSuccess: (response) => {
                    if (response.data.summary) {
                        refetch();
                    }
                }
            });
            setInitialFormData(formData);
            setInitialBusinessSummary(businessSummary);
        } catch (error) {
            console.error("Error occurred while submitting data:", error);
            toast.error("An error occurred while saving changes");
        }


        event.addEventListener('message', (eventData) => {
            // setIsLoading(false);
            let eventJsonData = JSON.parse(eventData.data);
            eventResponses = { ...eventResponses, ...eventJsonData };
            // setBusinessSummary(prevConversations => [...prevConversations, eventJsonData]);
            let { stream, finished, type } = eventJsonData;
            if (finished) {
            }
            if (type === "summary" && stream) {
                setBusinessSummary(prev => prev + stream);
            }
        });

        event.addEventListener('error', (eventData) => {
            // setEventResponse(eventResponses);
            event.close();
        }, { once: true });

        return () => {
            // setChatMessage('');
            event.close();
        };
    }

    return (
        <Fragment>
            <ToastContainer />
            <ImpactSubHeader image={bannerImage}>
                <div>
                    <h2>{title?.title}</h2>
                    <p>{title?.description}</p>
                </div>
            </ImpactSubHeader>
            <Card>
                <Card.Body>
                    {!data && (
                        <div className='d-flex pt-3 flex-column justify-content-start h-100'>
                            <p className='iq-skeleton' style={{ width: "80%", height: "50px" }}>&nbsp;</p>
                            <p className='iq-skeleton' style={{ width: "90%", height: "50px" }}>&nbsp;</p>
                            <p className='iq-skeleton' style={{ width: "80%", height: "50px" }}>&nbsp;</p>
                            <p className='iq-skeleton' style={{ width: "90%", height: "50px" }}>&nbsp;</p>
                            <p className='iq-skeleton' style={{ width: "80%", height: "50px" }}>&nbsp;</p>
                        </div>
                    )}
                    <Form onSubmit={handleSubmit}>
                        {associationData && associationData.map((field, index) => (
                            <Row key={index}>
                                {Object.keys(field).map((key) => {
                                    if (key === 'summary') return null; // Skip rendering summary field
                                    return (
                                        key !== 'id' &&
                                        <Col lg="6" md="6" key={field.id}>
                                            <Form.Group controlId={`validationCustom${field.id}-${key}`} className="z-form p-3" key={key}>
                                                {field[key].visible && (<Form.Label>{field[key]?.name}</Form.Label>)}
                                                {field[key].type === "select" ? (
                                                    field[key].visible && (
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                className='dropdown-custom'
                                                                disabled={!field[key].editable}
                                                                style={
                                                                    !field[key].editable ?
                                                                        { background: "#e1e1e1", color: "#38393B", border: "unset", overflow: "hidden" } :
                                                                        { background: "#F9F9F9", color: "#38393B", border: "unset", overflow: "hidden" }
                                                                }
                                                                id={`dropdown-basic-${index}`}
                                                            >
                                                                {selectedItems[field[key]?.id] ? selectedItems[field[key]?.id] : `${field[key]?.value}`}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {field[key].data.options.map((item, optionIndex) => (
                                                                    <Dropdown.Item key={optionIndex} onClick={() => handleItemSelect(item, field[key]?.id)}>{item}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )
                                                ) :  field[key]?.type === "textarea" ? (
                                                    <Form.Control
                                                        as="textarea"
                                                        name={field.id}
                                                        placeholder={`${field[key]?.value || ''}`}
                                                        defaultValue={field[key]?.value || ''}
                                                        onChange={(e) => handleInputChange(e, field[key]?.id)}
                                                        style={{ height: "4rem" }}
                                                    />
                                                ) :(
                                                    <Form.Control
                                                        style={
                                                            !field[key].editable ?
                                                                { background: "#e1e1e1" } :
                                                                { background: "#F9F9F9" }
                                                        }
                                                        type={field[key]?.type || 'text'}
                                                        placeholder={`${field[key]?.value || ''}`}
                                                        defaultValue={field[key]?.value || ''}
                                                        onChange={(e) => handleInputChange(e, field[key]?.id)}
                                                        disabled={!field[key].editable}
                                                        min={['employee_count', 'property_size'].includes(field[key]?.id) ? 0 : undefined}
                                                    />
                                                )}
                                            </Form.Group>
                                        </Col>
                                    );
                                })}
                            </Row>
                        ))}
                        <div className='m-4 d-flex justify-content-end'>
                            <button className='btn btn-primary' type="submit">Save</button>
                        </div>
                    </Form>
                    <SummeryGenrate show={showModal} handleClose={hanldCloseSummary} handleSave={handelSaveChange} />
                    <Card className="bg-soft-secondary mt-5 mb-3">
                        <Card.Header className="p-4 align-items-center">
                            <h4 className="mb-0">Your Summary</h4>

                            {!isEditable ? (
                                <div className='d-flex'>
                                    {isFieldChanged && (
                                        <div>
                                            <Badge bg="secondary">You have updated your information, so summary may be outdated please click to regenerate</Badge>
                                        </div>
                                    )}
                                    <button className="btn btn-sm mx-1 rounded-3 "
                                        style={{ padding: "2px 14px", }}
                                        onClick={() => handleSummeryGenrate()}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className={`bi bi-arrow-clockwise`}
                                            viewBox="0 0 16 16"
                                            style={{ color: 'rgb(64 72 79)' }}
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                                            />
                                            <path
                                                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"
                                            />
                                        </svg>
                                    </button>
                                    <div className="avatar-30 bg-secondary z-border-radius" onClick={handleEdit}>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g clipPath="url(#clip0_1929_2565)">
                                                <path d="M6 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3535C2.55268 13.2598 2.5 13.1326 2.5 13V10.2069C2.50006 10.0744 2.55266 9.94743 2.64625 9.85374L10.3538 2.14624C10.4475 2.05254 10.5746 1.99991 10.7072 1.99991C10.8397 1.99991 10.9669 2.05254 11.0606 2.14624L13.8538 4.93749C13.9474 5.03125 14.0001 5.15837 14.0001 5.29093C14.0001 5.42348 13.9474 5.55061 13.8538 5.64436L6 13.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.5 13.5H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.5 4L12 7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1929_2565">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            ) : (
                                <button className='btn btn-primary' onClick={handleSubmit}>Save</button>
                            )}
                        </Card.Header>
                        <Form>
                            {associationData && associationData.map((field, index) => (
                                <Card.Body className="border-top" key={index}>
                                    {isEditable ? (
                                        Object.keys(field).map((key) => (
                                            key === "summary" && (
                                                <textarea
                                                    key={field.id}
                                                    style={{
                                                        width: '100%',
                                                        background: "rgb(244 248 244)",
                                                        borderRadius: "5px",
                                                        height: "71px",
                                                        border: "1px solid #bad0ba"
                                                    }}
                                                    placeholder={`${field[key]?.value || ''}`}
                                                    defaultValue={businessSummary || ''}
                                                    onChange={(e) => setBusinessSummary(e.target.value)}
                                                    disabled={!field[key].editable}
                                                />
                                            )
                                        ))
                                    ) : (
                                        <Markdown key={index} content={businessSummary} />
                                    )}
                                </Card.Body>
                            ))}
                        </Form>
                    </Card>
                </Card.Body>
            </Card>
        </Fragment>
    );
});

BusinessProfile.displayName = "BusinessProfile";
export default BusinessProfile;
