import React from 'react'

import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { DocumentDeleteModal } from '../components/partials/components/toolkitDeleteConformation';
import {
    DecoupledEditor,
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    BalloonToolbar,
    BlockQuote,
    Bold,
    Code,
    Essentials,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    Paragraph,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Underline,
    Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import { Button, Modal } from 'react-bootstrap';

export const CkEditer = ({ documentId, ckEditData, onSave, onContentChange, isEditable, onClose,onSaveClose, setShowDeleteModal, showDeleteModal, hanldCloseDeleteModel, handleDeleteDocument }) => {
    const editorContainerRef = useRef(null);
    const editorMenuBarRef = useRef(null);
    const editorToolbarRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    const [editorContent, setEditorContent] = useState(ckEditData);
    const [enableSave, setEnableSave] = useState(false)


    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);

    const handleSave = () => {
        onSave(editorContent);
    };
    const handleSaveClose = () => {
        onSaveClose(editorContent);
    };

    const handleDelete = () => {
        setShowDeleteModal(true);
    }



    const editorConfig = {
        toolbar: {
            items: [
                'undo',
                'redo',
                '|',
                'showBlocks',
                '|',
                'heading',
                '|',
                'fontSize',
                'fontFamily',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                'underline',
                '|',
                'link',
                'insertImageViaUrl',
                'insertTable',
                'highlight',
                'blockQuote',
                '|',
                'alignment',
                '|',
                'indent',
                'outdent'
            ],
            shouldNotGroupWhenFull: false
        },
        plugins: [
            AccessibilityHelp,
            Alignment,
            Autoformat,
            AutoImage,
            AutoLink,
            Autosave,
            BalloonToolbar,
            BlockQuote,
            Bold,
            Code,
            Essentials,
            FindAndReplace,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            GeneralHtmlSupport,
            Heading,
            Highlight,
            HorizontalLine,
            HtmlEmbed,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageTextAlternative,
            ImageToolbar,
            Indent,
            IndentBlock,
            Italic,
            Link,
            LinkImage,
            Paragraph,
            RemoveFormat,
            SelectAll,
            ShowBlocks,
            SpecialCharacters,
            SpecialCharactersArrows,
            SpecialCharactersCurrency,
            SpecialCharactersEssentials,
            SpecialCharactersLatin,
            SpecialCharactersMathematical,
            SpecialCharactersText,
            Strikethrough,
            Subscript,
            Superscript,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            TextTransformation,
            Underline,
            Undo
        ],
        balloonToolbar: ['bold', 'italic', '|', 'link'],
        fontFamily: {
            supportAllValues: true
        },
        fontSize: {
            options: [10, 12, 14, 'default', 18, 20, 22],
            supportAllValues: true
        },
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph'
                },
                {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1'
                },
                {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2'
                },
                {
                    model: 'heading3',
                    view: 'h3',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3'
                },
                {
                    model: 'heading4',
                    view: 'h4',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4'
                },
                {
                    model: 'heading5',
                    view: 'h5',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5'
                },
                {
                    model: 'heading6',
                    view: 'h6',
                    title: 'Heading 6',
                    class: 'ck-heading_heading6'
                }
            ]
        },
        htmlSupport: {
            allow: [
                {
                    name: /^.*$/,
                    styles: true,
                    attributes: true,
                    classes: true
                }
            ]
        },
        image: {
            toolbar: [
                'toggleImageCaption',
                'imageTextAlternative',
                '|',
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'resizeImage'
            ]
        },
        initialData: '',
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            decorators: {
                toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'file'
                    }
                }
            }
        },
        menuBar: {
            isVisible: true
        },
        placeholder: 'Type or paste your content here!',
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        },
    };

    return (
        <div className="main-container">
            <DocumentDeleteModal documentId={documentId}showDeleteModal={showDeleteModal} hanldCloseDeleteModel={hanldCloseDeleteModel} handleDeleteDoc={handleDeleteDocument} />
            <Modal.Header className='justify-content-end m-0' closeButton>
                {!isEditable ? (
                    <div className='d-flex justify-content-end px-2'>
                         <button className="btn btn-primary mx-2" disabled={!enableSave} onClick={handleSaveClose}>
                            <span className="text" closeButton>Save & Close</span>
                        </button>
                        <button className="btn btn-primary mx-2" disabled={!enableSave} onClick={handleSave}>
                            <span className="text">Save</span>
                        </button>
                    </div>
                ) : (
                    <div className='d-flex justify-content-end px-2'>
                        <button className="btn btn-primary mx-2" onClick={handleDelete}>
                            <span className="text">Delete</span>
                        </button>
                    </div>
                )}
            </Modal.Header>
            <div className="editor-container editor-container_document-editor" ref={editorContainerRef}>
                <div className="editor-container__menu-bar" ref={editorMenuBarRef}></div>
                <div className="editor-container__toolbar" ref={editorToolbarRef}></div>
                <div className="editor-container__editor-wrapper">
                    <div className="editor-container__editor">
                        <div ref={editorRef}>
                            {isLayoutReady && (
                                <CKEditor
                                    onReady={editor => {
                                        editorToolbarRef.current.appendChild(editor.ui.view.toolbar.element);
                                        editorMenuBarRef.current.appendChild(editor.ui.view.menuBarView.element);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setEditorContent(data);
                                        setEnableSave(true)
                                        onContentChange(true);
                                    }}
                                    onAfterDestroy={() => {
                                        Array.from(editorToolbarRef.current.children).forEach(child => child.remove());
                                        Array.from(editorMenuBarRef.current.children).forEach(child => child.remove());
                                    }}
                                    data={editorContent}
                                    editor={DecoupledEditor}
                                    config={editorConfig}
                                    disabled={isEditable}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}