import React,{memo,Fragment,useState, useEffect} from 'react'

// react-router
import { Outlet } from "react-router-dom";

// react-bootstrap
import {Row,Col, Modal} from 'react-bootstrap'

import axios_inst from '../../utilities/axios';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

// header
import Headerpro from "../../components/partials/pro/headerstyle/header-pro";

//sidebar
import Sidebar from "../../components/partials/dashboard/sidebarstyle/impact-sidebar";

//footer
import Footer from "../../components/partials/dashboard/footerstyle/footer";

// navlayout
import Chat from '../../components/chat'


import Loader from "../../components/Loader";
import { SetActivePlan } from '../../store/dashboardConfig/dashboardConfigSlice';

const ImpactLayout = memo(({setNewPages}) => {
const dispatch = useDispatch()
  const [visibleChat, setVisibleChat] = useState(true)
  const [mobileView, setMobileView] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [activeKey, setActiveKey] = useState('');

  const handleClose = () => setShowChatModal(false);
  useEffect(() => {
    const handleResize = () => {
        const maxwidth = window.innerWidth <= 820;
        const minwidth = window.innerWidth >= 420;
        const mobileWidth = window.innerWidth <= 420;

        if (maxwidth && minwidth) {
            setVisibleChat(false);
            setMobileView(true)
        }
        if (mobileWidth) {
            setMobileView(true)
            setVisibleChat(false)
        }

    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);
  
const { data: plan_data, isFetched: plan_fetched, isStale: plan_isstale } = useQuery({
  refetchOnWindowFocus: false,
  queryFn: (data) =>
      axios_inst
          .get('api/v4/get_plan')
          .then(res => res.data)
          .catch(err => {
              console.log(err);
          }),
});
if (plan_fetched) {
  dispatch(SetActivePlan(plan_data?.data));
  // console.log(plan_data?.data)
  setNewPages(plan_data?.data?.new_pages);
}
const partnerButton = plan_data?.data
const partnerTab = plan_data?.data?.new_pages
const bannerImage = plan_data?.data?.pages?.associations?.banner

  return (
    <Fragment>
        <Loader />
        <Sidebar Tab={partnerTab} activeKey={activeKey} setActiveKey={setActiveKey} />
        <main className="main-content">
          <div className="iq-banner default position-relative">
            <Headerpro Button={partnerButton}/>
          </div>
          <div className="container-fuild content-inner pb-0 pt-5">
            <Row>
            <Col md={visibleChat ? 9 : 12} sm="12">
              <Outlet context={{ partnerTab, bannerImage, activeKey, setActiveKey, partnerTab }}></Outlet>
            </Col>
            {visibleChat ?
                    <Col lg="3" md="12" sm="12" className='resize'>
                        <Chat onToggle={() => setVisibleChat(false)} visible={visibleChat}></Chat>
                    </Col> :
                    <button onClick={() => mobileView ? setShowChatModal(true) : setVisibleChat(true)} style={{top:"25%"}} className='btn btn-fixed-end btn-primary btn-icon btn-setting 6'>
                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-20" height="20">
                            <path opacity="0.4" d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M11.9807 13.2901C11.2707 13.2801 10.7007 12.7101 10.7007 12.0001C10.7007 11.3001 11.2807 10.7201 11.9807 10.7301C12.6907 10.7301 13.2607 11.3001 13.2607 12.0101C13.2607 12.7101 12.6907 13.2901 11.9807 13.2901ZM7.37033 13.2901C6.67033 13.2901 6.09033 12.7101 6.09033 12.0101C6.09033 11.3001 6.66033 10.7301 7.37033 10.7301C8.08033 10.7301 8.65033 11.3001 8.65033 12.0101C8.65033 12.7101 8.08033 13.2801 7.37033 13.2901ZM15.3105 12.0101C15.3105 12.7101 15.8805 13.2901 16.5905 13.2901C17.3005 13.2901 17.8705 12.7101 17.8705 12.0101C17.8705 11.3001 17.3005 10.7301 16.5905 10.7301C15.8805 10.7301 15.3105 11.3001 15.3105 12.0101Z" fill="currentColor"></path>
                        </svg>
                    </button>
                }
            </Row>
            {showChatModal && (
                <Modal show={showChatModal} onHide={handleClose} centered>
                    <Modal.Body>
                        <Chat onToggle={handleClose} visible={showChatModal} />
                    </Modal.Body>
                </Modal>
            )}
          </div>
          <Footer />
        </main>
    </Fragment>
  )
})

ImpactLayout.displayName = "ImpactLayout"
export default ImpactLayout