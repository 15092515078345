import { memo,Fragment } from 'react'

const ImpactSubHeader = memo((props) => {
    return (
        <Fragment>
            <div className={`d-flex px-50 align-items-center justify-content-between ${props.className}`} style={{ minHeight: '225px', backgroundImage: `url(${props.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                {props.children}
            </div>
        </Fragment>
    )
})

ImpactSubHeader.displayName = "ImpactSubHeader"
export default ImpactSubHeader
