import React from 'react'
import { Modal } from 'react-bootstrap'

export const ImageVeiw = ({ handleClose, file, show }) => {
    const isFileObject = file && typeof file === 'object' && file.name;

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                {isFileObject ? (
                    <img className='img-fluid' src={URL.createObjectURL(file)} alt=' ' />
                ) : (
                    <img className='img-fluid' src={file} alt=' ' />
                )}
            </Modal.Body>
        </Modal>
    )
}