import { memo } from 'react'

//React-bootstrap
import { Container } from 'react-bootstrap'



const SubHeader = memo(() => {

    return (
        <>
            <div className="iq-navbar-header">
                <Container fluid className="iq-container">
                    <div className="d-flex gap-3 justify-content-center align-items-center p-5">
                        <p>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_625_17512)">
                                    <path d="M14.1387 15.9995H3.51367C2.72105 15.9995 2.07617 15.3546 2.07617 14.562V6.99884C2.07617 6.72272 2.30002 6.49884 2.57617 6.49884C2.85233 6.49884 3.07617 6.72272 3.07617 6.99884V14.562C3.07617 14.8032 3.27242 14.9995 3.51367 14.9995H14.1387C14.3799 14.9995 14.5762 14.8032 14.5762 14.562V6.99884C14.5762 6.72272 14.8 6.49884 15.0762 6.49884C15.3523 6.49884 15.5762 6.72272 15.5762 6.99884V14.562C15.5762 15.3546 14.9313 15.9995 14.1387 15.9995Z" fill="#070D0A" />
                                    <path d="M16.3262 8.74868C16.1982 8.74868 16.0703 8.69986 15.9726 8.60221L9.75423 2.38386C9.24251 1.87211 8.40985 1.87211 7.8981 2.38386L1.67973 8.60224C1.48448 8.79752 1.16788 8.79752 0.972633 8.60224C0.777352 8.40699 0.777352 8.09039 0.972633 7.89514L7.19101 1.67674C8.09263 0.775113 9.55973 0.775113 10.4613 1.67674L16.6797 7.89511C16.875 8.09039 16.875 8.40696 16.6797 8.60221C16.5821 8.69986 16.4541 8.74868 16.3262 8.74868Z" fill="#070D0A" />
                                    <path d="M10.8262 15.9995H6.82617C6.55002 15.9995 6.32617 15.7756 6.32617 15.4995V11.062C6.32617 10.2004 7.02711 9.49945 7.88867 9.49945H9.76367C10.6252 9.49945 11.3262 10.2004 11.3262 11.062V15.4995C11.3262 15.7756 11.1023 15.9995 10.8262 15.9995ZM7.32617 14.9995H10.3262V11.062C10.3262 10.7518 10.0738 10.4995 9.76367 10.4995H7.88867C7.57852 10.4995 7.32617 10.7518 7.32617 11.062V14.9995Z" fill="#070D0A" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_625_17512">
                                        <rect width="16" height="16" fill="white" transform="translate(0.826172 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </p>
                        <p>/</p>
                        <p>
                            To Do List
                        </p>
                        <p>/</p>
                        <p className="text-secondary">Setting Service</p>
                    </div>
                </Container>
                <div className="iq-header-img bg-soft-secondary"></div>
            </div>
        </>
    )
})

SubHeader.displayName = "SubHeader"
export default SubHeader
