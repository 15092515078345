import React, { memo, Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/logo";
import Scrollbar from "smooth-scrollbar";
import { Nav } from 'react-bootstrap';

const ImpactSidebar = memo((props) => {
    const [isMiniSidebar, setIsMiniSidebar] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [sidebarTabs, setSidebarTabs] = useState([]);
    const { activeKey, setActiveKey } = props;

    const minisidebar = () => {
        const sidebarElement = document.querySelector("aside[data-sidebar='responsive']");
        const isMini = sidebarElement.classList.toggle("sidebar-mini");
        setIsMiniSidebar(isMini);
        setIsSidebarVisible(!isMini);
    };

    useEffect(() => {
        Scrollbar.init(document.querySelector("#my-scrollbar"));

        const toggleSidebar = () => {
            const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]');
            const isSmallScreen = window.innerWidth <= 1200;

            sidebarResponsive.classList.toggle("sidebar-mini", isSmallScreen);
            setIsMiniSidebar(isSmallScreen);
            setIsSidebarVisible(!isSmallScreen);
        };

        toggleSidebar();
        window.addEventListener("resize", toggleSidebar);

        return () => {
            window.removeEventListener("resize", toggleSidebar);
        };
    }, []);

    const location = useLocation();
    const navigate = useNavigate();
    const SidebarTab = props?.Tab;

    useEffect(() => {
        // Update sidebarTabs based on the current route
        const route = location.pathname.split('/')[1];
        const newTabs = SidebarTab?.filter(tab => tab.url === route) || [];
        setSidebarTabs(newTabs);
    }, [location, SidebarTab]);

    return (
        <Fragment>
            <aside
                className="left-bordered sidebar sidebar-base navs-rounded-all sidebar-default sidebar-white"
                data-sidebar="responsive"
            >
                <div className="sidebar-header d-flex align-items-center justify-content-start">
                    <Link to="/" className="navbar-brand">
                        <Logo />
                    </Link>
                    <div
                        className="sidebar-toggle"
                        data-toggle="sidebar"
                        data-active="true"
                        onClick={minisidebar}
                        style={{
                            right: isMiniSidebar ? "-40px" : "",
                            top: isMiniSidebar ? "15px" : "",
                            transition: "right 0.3s ease, top 0.3s ease",
                        }}
                    >
                        <i className="icon">
                            <svg
                                className="icon-20"
                                style={{ padding: "5px" }}
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.93851 0.9256L0.26355 5.36934C-0.0878487 5.70369 -0.0878487 6.29532 0.26355 6.63085L4.93851 11.0746C5.30357 11.4208 5.89337 11.4196 6.25594 11.071C6.61852 10.7225 6.61852 10.1605 6.25346 9.81308L3.17779 6.88813L11.0687 6.88813C11.584 6.88813 12 6.48976 12 5.99891C12 5.50806 11.584 5.10969 11.0687 5.10969L3.17779 5.10969L6.25346 2.18592C6.43599 2.01164 6.52663 1.784 6.52663 1.55517C6.52663 1.32871 6.43599 1.10107 6.25594 0.927972C5.89337 0.580582 5.30357 0.579397 4.93851 0.9256Z"
                                    fill="white"
                                />
                            </svg>
                        </i>
                    </div>
                </div>
                <div
                    className="pt-0 pb-3 sidebar-body data-scrollbar"
                    data-scroll="1"
                    id="my-scrollbar"
                >
                    <div className="sidebar-list navbar-collapse" id="sidebar">
                        <ul className="navbar-nav iq-main-menu">
                                <Nav variant="tabs flex-column" activeKey={activeKey}>
                                    {sidebarTabs?.map((data, index) => (
                                        <Fragment key={index}>
                                            {data?.tabsections?.map((item, navIndex) => (
                                                <Fragment key={navIndex}>
                                                    <Nav.Item className="nav-item static-item">
                                                        <span className="nav-link static-item disabled">
                                                            <span className="default-icon text-uppercase text-primary">
                                                                {item.title}
                                                            </span>
                                                            <span className="mini-icon">-</span>
                                                        </span>
                                                    </Nav.Item>
                                                    {item?.tabs?.map((linkItem, linkIndex) => (
                                                        <Nav.Item key={`${index}-${navIndex}-${linkIndex}`}>
                                                            <Nav.Link eventKey={linkItem.url} onClick={() => {
                                                                setActiveKey(linkItem.url);
                                                                navigate(`/${data.url}/${linkItem.url}`);
                                                            }}>
                                                                {linkItem.icon.startsWith("bi") ? (
                                                                    <i className={`icon ${linkItem.icon} me-2`}></i>
                                                                ) : (
                                                                    <i className="icon me-2">{linkItem.icon}</i>
                                                                )}
                                                                <span className="default-icon">
                                                                    {linkItem.title}
                                                                </span>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    ))}
                                </Nav>
                            {/* {location.pathname.startsWith('/portal') ? (
                            ) : (
                                <li className="nav-item static-item">
                                <Link to="#" className="nav-link static-item disabled">
                                    <span className="default-icon text-uppercase text-primary">
                                        main
                                    </span>
                                    <span className="mini-icon">-</span>
                                </Link>
                            </li>
                            )} */}
                        </ul>
                    </div>
                </div>
            </aside>
        </Fragment>
    );
});

ImpactSidebar.displayName = "ImpactSidebar";
export default ImpactSidebar;
