import React, { Fragment, memo, useEffect, useState } from 'react'
import {  Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { ConnectMessage, MobileChatModel, Nav_Id, setCategoryId, setPersonaTitle, SetActivePlan } from '../../store/dashboardConfig/dashboardConfigSlice';
import Markdown from '../../components/partials/components/markdowan';
import { CkEditer } from '../../components/CkEditer';
import axios_inst from '../../utilities/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentUpdate,GetToolkit } from '../../apis/Dashboard';
import { useMutation } from '@tanstack/react-query';
import { toast, ToastContainer } from 'react-toastify';

const Toolkit = memo(({sideBarData, setSideBarData}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const sidebar_id = useParams()
    const { mutate } = DocumentUpdate()

    const toolkitDetails = useSelector(state => state.toolkitDetails);
    const toolkitId = useSelector(state => state.toolkitId);
    const [openCkEditmodel, setOpenCkEditModel] = useState(false)
    const [ckEditData, setCkEditData] = useState()
    const [isContentChanged, setIsContentChanged] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [description, setDescription] = useState(null)
    const [opneModel, setOpenModel] = useState(false)
    const [documentId, setDocumentId] = useState()
    const [oldDocumentId, setOldDocumentId] = useState('')
    const [newDocumentId, setNewDocumentId] = useState('')
    const [documentsData, setDocumentsData] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [loadingButtons, setLoadingButtons] = useState({});
    const [loadingContent, setLoadingContent] = useState(false);
    const category_id = useSelector(state => state.category_id);

    const getDocument = useMutation({
        mutationFn: async (data) => {
            const response = await axios_inst.get('api/v3/document', { params: data });
            return response?.data?.document || false;
        }
    });

    const deleteDocument = useMutation({
        mutationFn: async (data) => {
            const response = await axios_inst.get('api/v3/document', { params: data });
            return response?.data || false;
        }
    });

    
    const hanldCloseDeleteModel = () => {
        setShowDeleteModal(false)
    }

    const removeDocumentById = (id) => {
        setDocumentsData((prevDocuments) => {
            const updatedDocuments = { ...prevDocuments }; // Create a shallow copy of the previous state
            delete updatedDocuments[id]; // Delete the specific document by ID
            return updatedDocuments; // Return the new state
        });
    };

    const handleDeleteDocument = async (documentId) => {
        const deleteDoc = async (documentId) => {
            const docResponse = await deleteDocument.mutateAsync({ id: documentId, delete: true });
            return ! docResponse?.document ? false : docResponse?.document; 
        }
        const response = await deleteDoc(documentId);
        console.log( documentsData );
        if( ! response ) {
            setShowDeleteModal(false);
            setOpenCkEditModel(false);
            removeDocumentById(documentId);
            toast.success( 'File deleted successfully' );
        } else {
            toast.error( 'Unable to delete file' );
        }
    }

    
    const fetchDocument = async (data) => {
        try {
            return await getDocument.mutateAsync(data);
        } catch (error) {
            console.error("Error fetching document:", error);
            return false;
        }
    };
    
    useEffect(() => {
        const fetchDocuments = async () => {
            setDocumentsData([]);
            const docsPromises = toolkitDetails?.completions
                ?.filter(({ documents }) => documents?.length)
                .map(async ({ documents }) => {
                    const documentId = (oldDocumentId === documents.join(', ') && newDocumentId) ? newDocumentId : documents.join(', ');
                    const docResponse = await fetchDocument({ id: documentId, data: false });
                    return { documentId, docResponse };
                }) || [];

            const documentsResponses = await Promise.all(docsPromises); 
            if (documentsResponses) {
                let documentsDataMap = {}; // Initialize an empty object
            
                // Iterate over the resolved promises to map documentId to its response
                documentsResponses.forEach(({ documentId, docResponse }) => {
                    // Structure the object as required
                    if( docResponse ) {
                        documentsDataMap[documentId] = docResponse;
                    }
                });
                setDocumentsData((prev) => ({ ...prev, ...documentsDataMap })); // Merge the new data into the previous state
            }
        };
    
        fetchDocuments();
    }, [toolkitDetails]);

    useEffect(() => {
        const CategoryID = localStorage.getItem('CategoryID');
        const PersonaID = localStorage.getItem('PersonaID');
        const toolkit_Id = localStorage.getItem('toolkitId');
        setPersonaTitle()
        dispatch(Nav_Id("toolkit_id"))
        dispatch(setCategoryId({
            category_id: toolkitId ? toolkitId : toolkit_Id,
            persona_id: PersonaID
        }));
    }, [])

    const handleConnect = (e, startphars) => {
        dispatch(ConnectMessage(startphars))
        dispatch(MobileChatModel(true))
    }
    const openCkEdit = async (e, documentId, editable) => {
        setOldDocumentId(documentId);

        function decodeHexData(data) {
            const hexPairs = data.slice(2).match(/.{1,2}/g).map(byte => parseInt(byte, 16)); // Extract hex pairs

            function chunkArray(array, chunkSize) {
                let result = [];
                for (let i = 0; i < array.length; i += chunkSize) {
                    result.push(array.slice(i, i + chunkSize));
                }
                return result;
            }
            const chunks = chunkArray(hexPairs, 10000); // Adjust chunk size based on system needs
            let documents = '';

            chunks.forEach(chunk => {
                documents += String.fromCharCode(...chunk);
            });
            return documents; 
        }


        try {
            const baseurl = "api/v3/document"; // Live endpoint
            const url = `${baseurl}?id=${documentId}&editable=true`;
            const response = await axios_inst.get(url);
            const documentData = response.data.document;
            const fetchedDocuments = decodeHexData(documentData.data);
            const fetchedBytes = Array.from(fetchedDocuments).map(char => char.charCodeAt(0));
            const decoder = new TextDecoder('utf-8');
            const fetchedDecodedString = decoder.decode(new Uint8Array(fetchedBytes));
            setIsEditable(editable);
            setDocumentId(documentId);
            setCkEditData(fetchedDecodedString);
            setOpenCkEditModel(!openCkEditmodel);

        } catch (error) {
            console.error('Failed to fetch the document:', error);
        }
    };


    const AdviceUpdate = () => {
        const { isLoading, mutate, isSuccess } = useMutation({
            mutationFn: data => {
                return axios_inst.post('api/v3/save_groups',data) ;
            },
            mutationKey: 'save_toolkits',
        });
    
        // Ensure that mutateCategory is returned as a function
        return { isLoading, mutate, isSuccess };
    };

    const { isLoading: isLoadingToolkit, mutate: mutateAdvice, isSuccess: isSuccessToolkit } = AdviceUpdate();
    const { refetch } = GetToolkit();
    

    const handleAdviceAdd = (e, id) => {
        const persona_id = category_id.persona_id;
        setLoadingButtons(prevState => ({
            ...prevState,
            [id]: true
        }));
        const groupsPayload = toolkitDetails.related_groups.reduce((acc, group) => {
            acc[group.id] = group.selected || group.id === id;
            return acc;
        }, {});
        const payload = { groups: groupsPayload };
        localStorage.setItem('groupIds_' + persona_id, JSON.stringify(groupsPayload));

        // Mutate the toolkit with the constructed payload
        mutateAdvice(payload);

        // Update the sidebar with the new group selection
    updateSidebarGroupData(groupsPayload, persona_id);

    };
    
    // Updating the sidebar state and setting the active plan
    const updateSidebarGroupData = (groupIds, persona_id) => {
        const updatedSideBarData = JSON.parse(JSON.stringify(sideBarData));
        // Find and update the category where persona.id matches persona_id
        Object.entries(updatedSideBarData.pages.Learn.categories).forEach(([categoryKey, categoryValue]) => {
            if (categoryValue.persona?.id === persona_id) {
                Object.entries(groupIds).forEach(([groupId, isSelected]) => {
                    if (categoryValue.groups[groupId]) {
                        categoryValue.groups[groupId].selected = isSelected;
                    }
                });
            }
        });

        // Update the state with the new data
        setSideBarData(updatedSideBarData);
        dispatch(SetActivePlan(updatedSideBarData));
    };

    const handleToolitNavigate = async (e, id) => {
        setLoadingContent(true); // Set loading state to true
         // If the API call is successful, proceed with the navigation
         localStorage.setItem('CategoryID', id);  // Store CategoryID in localStorage
         const newPath = `/dashboard/${id}/advice`; // Build the new path
         navigate(newPath);  // Navigate to the new page
        updateSidebarGroupData();
        setLoadingContent(false); // Set loading state to false after navigation
    }

    useEffect(() => {
        const fetchToolkitDetails = async () => {
            setLoadingContent(true);

            const toolkit_Id = localStorage.getItem("toolkitId");
            if (toolkit_Id) {
                try {
                    const response = await refetch({ id: toolkit_Id });
                    if (response.isSuccess) {
                        // dispatch(SetActivePlan(response.data));
                    }
                } catch (error) {
                    console.error("Error fetching toolkit:", error);
                }
            }

            setLoadingContent(false);
        };

        // Call API only once when the component mounts
        fetchToolkitDetails();
    }, [refetch, dispatch]);

    useEffect(() => {
        let isCancelled = false;
        if (isSuccessToolkit) {
            refetch()
                .then(response => {
                    if (isCancelled) return;
                    if (response.isSuccess) {
                        setLoadingButtons({});
                        // Ensure the URL does not change unexpectedly
                        const toolkit_Id = localStorage.getItem("toolkitId");
                        if (toolkit_Id) {
                            navigate(`/toolkit/${toolkit_Id}`, { replace: true });
                        }
                    }
                })
                .catch(error => {
                    if (isCancelled) return;
                    console.error("Error fetching toolkit:", error);
                    setLoadingButtons({});
                });
        }
        return () => {
            isCancelled = true;
        };
    }, [isSuccessToolkit, navigate]);

    const handleSave = (content) => {
        const contentUpdate = {
            "content": content,
            "original_id": documentId
        }
        mutate(contentUpdate, {
            onSuccess: (response) => {
                const status = response.status
                if (status === 200) {
                    setNewDocumentId(response.data.document.id)
                }
            }
        })
        setIsContentChanged(false);
    };
    const handleSaveClose = (content) => {
        const contentUpdate = {
            "content": content,
            "original_id": documentId
        }
        mutate(contentUpdate, {
            onSuccess: (response) => {
                const status = response.status
                if (status === 200) {
                    setNewDocumentId(response.data.document.id)
                    setOpenCkEditModel(false)
                }
            }
        })
        setIsContentChanged(false);
    }
    const handleClose = () => {
        if (isContentChanged) {
            if (window.confirm('You have unsaved changes. Are you sure you want to close without saving?')) {
                setOpenCkEditModel(false);
                setIsContentChanged(false)
            }
        } else {
            setOpenCkEditModel(false);
        }
    };

    const handleToggleChange = (e) => {
        setIsEditable(!isEditable);

    };
    const handleDescriopn = (e, description) => {
        setDescription(description)
        setOpenModel(true)
    }
    const handleCloseModel = () => {
        setOpenModel(false)
    }

    const [expandedDocs, setExpandedDocs] = useState({}); // Stores expanded state for each document

const handleToggleDescription = (documentId) => {
    setExpandedDocs(prev => ({
        ...prev,
        [documentId]: !prev[documentId], // Toggle state for the specific documentId
    }));
};

const formatTimeAgo = (date) => {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 1) {
        return date.toLocaleDateString();
    } else if (days === 1) {
        return `Yesterday at ${date.toLocaleTimeString()}`;
    } else if (hours > 1) {
        return `${hours} hours ago`;
    } else if (hours === 1) {
        return `1 hour ago`;
    } else if (minutes > 1) {
        return `${minutes} minutes ago`;
    } else if (minutes === 1) {
        return `1 minute ago`;
    } else {
        return `just now`;
    }
};

    return (
        <Fragment>
            <ToastContainer />
            <div>
                <Modal
                    show={opneModel}
                    onHide={handleCloseModel}
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton style={{ background: "#F0F0F0" }}>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="mb-3">
                                <p>{description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="container mt-5">
                    <div className="bg-light p-4 rounded">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                {loadingContent ? (
                                    <div className="text-center h-100" style={{ display: 'grid', placeItems: 'center' }}>
                                        <div className="spinner-grow text-primary" role="status">
                                        </div>
                                    </div>
                                ) : (
                                    <img src={toolkitDetails?.media?.image} className="img-fluid w-100" alt="image" />
                                )}

                            </div>
                            <div className="col-md-6 mt-md-0 mt-3">
                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                    <h5 className="m-0">{toolkitDetails?.title}</h5>
                                    <div className="d-flex align-items-center gap-1">
                                        <div className="text-success">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" className="bi bi-circle-fill"
                                                viewBox="0 0 16 16">
                                                <circle cx="8" cy="8" r="8" />
                                            </svg>
                                        </div>
                                        <div className="text-success">
                                            {toolkitDetails?.media?.time}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 mb-0">
                                    {loadingContent ? (
                                        <div className='d-flex pt-3 flex-column justify-content-start h-100'>
                                            <p className='iq-skeleton' style={{ width: "80%" }}>&nbsp;</p>
                                            <p className='iq-skeleton' style={{ width: "90%" }}>&nbsp;</p>
                                            <p className='iq-skeleton' style={{ width: "83%" }}>&nbsp;</p>
                                        </div>
                                    ) : (
                                        <Markdown content={toolkitDetails?.description} />
                                    )}
                                </div>
                                <div className="d-flex align-items-center flex-wrap gap-3 mt-4">
                                    <button className="btn btn-primary" onClick={(e) => handleConnect(e, toolkitDetails.start_phrase)}>
                                        <span className="text ">
                                            Start
                                        </span>
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                <path d="M3.75 9.5H14.25M14.25 9.5L9 4.25M14.25 9.5L9 14.75" stroke="white" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <div className="table-responsive table-custom">
                            <table className="table table-success">
                                <thead>
                                    <tr>
                                        <td width="70%" className='bg-success'>
                                            <h5 className='my-2 text-white lh-1'>Document Information</h5>
                                        </td>
                                        <td width="15%" className='bg-success'>
                                            <h5 className='my-2 text-white lh-1'>Date</h5>
                                        </td>
                                        <td width="15%" className='bg-success text-center'>
                                            <h5 className='my-2 text-white lh-1'>Action</h5>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadingContent ? (
                                        <tr>
                                            <td colSpan="3">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className="spinner-grow text-primary" role="status">
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : (
                                        documentsData && Object.entries(documentsData)
                                            .sort(([, a], [, b]) => b.updated_at.timestamp - a.updated_at.timestamp) // Sort documents by timestamp in descending order
                                            .map(([documentId, docData], i) => {
                                            // Convert timestamp to a formatted date
                                            const timestamp = docData?.updated_at?.timestamp > docData.created_at?.timestamp ? docData?.updated_at?.timestamp : docData.created_at?.timestamp;
                                            const date = new Date(timestamp * 1000);
                                            const formattedDate = formatTimeAgo(date);
                                            const isExpanded = expandedDocs[documentId] || false; // Get expanded state for current documentId

                                            return ( 
                                                <tr className='toolkit-table' key={documentId}>
                                                    <td>
                                                        <p className='table-content-small'>
                                                            <strong>{docData?.name}</strong><br/>
                                                            <div
                                                                className={`description-text ${isExpanded ? 'expanded' : 'collapsed'}`}
                                                                style={{ maxHeight: isExpanded ? 'none' : '1.4em', overflow: 'hidden' }}
                                                            >
                                                                {docData?.description}
                                                            </div>
                                                            {docData?.description?.length > 100 && (
                                                                <button
                                                                    onClick={() => handleToggleDescription(documentId)} // Pass the documentId to toggle state
                                                                    className="btn btn-link p-0"
                                                                >
                                                                    {isExpanded ? 'Read Less' : 'Read More'}
                                                                </button>
                                                            )}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {formattedDate}<br/>
                                                            <strong>{docData?.updated_at?.timestamp === docData.created_at?.timestamp ? '' : ' (edited)'}</strong>
                                                        </p>
                                                    </td>
                                                    <td className='text-center'>
                                                        <span className='d-flex align-items-center justify-content-evenly'>    
                                                            <span 
                                                                className='icon lh-base align-text-bottom'
                                                                onClick={(e) => openCkEdit(e, documentId, true)}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon-20 toolkit-icons " width="20" height="20" viewBox="0 0 15 12" fill="none">
                                                                    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M11.8024 1.53455C13.0829 2.52291 14.1732 3.97091 14.9561 5.78182C15.0146 5.91927 15.0146 6.08 14.9561 6.21091C13.3902 9.83273 10.6024 12 7.5 12H7.49268C4.39756 12 1.60976 9.83273 0.0439024 6.21091C-0.0146341 6.08 -0.0146341 5.91927 0.0439024 5.78182C1.60976 2.15927 4.39756 0 7.49268 0H7.5C9.05122 0 10.522 0.538182 11.8024 1.53455ZM4.57317 6C4.57317 7.6 5.88293 8.90182 7.5 8.90182C9.10976 8.90182 10.4195 7.6 10.4195 6C10.4195 4.39273 9.10976 3.09091 7.5 3.09091C5.88293 3.09091 4.57317 4.39273 4.57317 6Z" fill="currentColor" />
                                                                </svg>
                                                            </span>
                                                            <span 
                                                                className='icon lh-base align-text-bottom'
                                                                onClick={(e) => openCkEdit(e, documentId, false)}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon-20 toolkit-icons" width="20" height="20" viewBox="0 0 15 14" fill="none">
                                                                    <path opacity="0.4" d="M13.4945 12.2148H9.22383C8.80716 12.2148 8.46826 12.5591 8.46826 12.9824C8.46826 13.4065 8.80716 13.7499 9.22383 13.7499H13.4945C13.9112 13.7499 14.2501 13.4065 14.2501 12.9824C14.2501 12.5591 13.9112 12.2148 13.4945 12.2148" fill="currentColor" />
                                                                    <path d="M6.23177 3.17697L10.2787 6.44704C10.3763 6.52525 10.3929 6.66875 10.3168 6.76873L5.51906 13.0202C5.21746 13.4064 4.77301 13.6249 4.29681 13.633L1.67772 13.6652C1.53804 13.6668 1.41581 13.5701 1.38406 13.4314L0.788816 10.8434C0.68564 10.3677 0.788816 9.87594 1.09041 9.49701L5.91192 3.21567C5.9897 3.11489 6.13335 3.09716 6.23177 3.17697" fill="currentColor" />
                                                                    <path opacity="0.4" d="M12.0905 4.49908L11.3103 5.473C11.2317 5.57217 11.0904 5.5883 10.9928 5.50928C10.0444 4.74175 7.61578 2.77213 6.94196 2.22632C6.84355 2.14569 6.83006 2.00219 6.90942 1.90221L7.66182 0.967793C8.34437 0.0890025 9.53486 0.00837955 10.4952 0.774298L11.5984 1.65309C12.0508 2.00783 12.3524 2.47544 12.4555 2.96724C12.5746 3.50822 12.4476 4.03953 12.0905 4.49908" fill="currentColor" />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {openCkEditmodel && (
                            <Modal
                                show={openCkEditmodel}
                                onHide={handleClose}
                                dialogClassName="modal-fullscreen"
                                aria-labelledby="example-custom-modal-styling-title"
                            >

                                <Modal.Body>
                                    <CkEditer
                                        documentId={documentId}
                                        ckEditData={ckEditData}
                                        onSave={handleSave}
                                        onSaveClose={handleSaveClose}
                                        onClose={handleClose}
                                        onContentChange={setIsContentChanged}
                                        isEditable={isEditable}
                                        showDeleteModal={showDeleteModal} 
                                        setShowDeleteModal={setShowDeleteModal}
                                        hanldCloseDeleteModel={hanldCloseDeleteModel}
                                        handleDeleteDocument={handleDeleteDocument}
                                    />
                                </Modal.Body>
                            </Modal>
                        )}

                    </div>
                    <div className="mt-5">
                        <div>
                            <div className="p-5 bg-white shadow rounded iq-suggest-support">
                                <h5 className="mb-4">Suggested Support</h5>
                                <div className="d-flex align-items-center flex-wrap gap-3">
                                    {toolkitDetails?.related_groups?.map((item) => {
                                        const { title, selected, id } = item;

                                        return (
                                            <>
                                                {!selected ? (
                                                    <Button onClick={(e) => handleAdviceAdd(e, id)} style={{ border: "none" }} variant="soft-secondary text-primary icons-colors" className='kishshsh'>{title}
                                                        <span style={{ paddingLeft: "4%" }}>
                                                        {loadingButtons[id] ? (
                                                                            <div style={{ height: "14px", width: "14px" }} className="spinner-grow text-primary" role="status"></div>
                                                                        ) : (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                                                            </svg>
                                                                        )} 
                                                        </span>
                                                    </Button>
                                                ) : (
                                                    <Button onClick={(e) => handleToolitNavigate(e, id)} style={{ border: "none" }} variant="soft-secondary text-primary icons-colors" className='dadadadadadada'>{title}
                                                        <span style={{ paddingLeft: "4%" }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                                            </svg>
                                                        </span>
                                                    </Button>
                                                )}
                                            </>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="p-5 bg-white rounded shadow iq-communities my-5">
                                <h5 className="mb-4">Communities</h5>
                                <div className="row">
                                    {toolkitDetails?.communities?.map((item,) => {
                                        const { name, media, slug, title, description } = item
                                        return (
                                            <div className="col-sm-4 ">
                                                <div className="bg-light py-4 px-3 text-center">
                                                    
                                                    <div className='community-info position-relative '>
                                                        <a href={`/groups/${slug}/`} target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                style={{ height: "70px" }}
                                                                alt='logo'
                                                                className="img-fluid"
                                                                loading="lazy"
                                                                src={media.avatar}
                                                            />
                                                        </a>
                                                        <button className='btn bg-transparent p-0 btn-community-info ' onClick={(e) => handleDescriopn(e, description)}  >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <g clipPath="url(#clip0_2893_2971)">
                                                                    <rect width="18" height="18" rx="9" fill="white" />
                                                                    <path d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9748 18 9C18 4.02578 13.9748 0 9 0ZM9 16.7442C4.72985 16.7442 1.25582 13.2702 1.25582 9C1.25582 4.72982 4.72985 1.25582 9 1.25582C13.2701 1.25582 16.7442 4.72982 16.7442 9C16.7442 13.2702 13.2701 16.7442 9 16.7442Z" fill="#38B349" />
                                                                    <path d="M9.34659 5L9.26136 11.2727H8.27273L8.1875 5H9.34659ZM8.76705 13.7955C8.55682 13.7955 8.37642 13.7202 8.22585 13.5696C8.07528 13.419 8 13.2386 8 13.0284C8 12.8182 8.07528 12.6378 8.22585 12.4872C8.37642 12.3366 8.55682 12.2614 8.76705 12.2614C8.97727 12.2614 9.15767 12.3366 9.30824 12.4872C9.45881 12.6378 9.53409 12.8182 9.53409 13.0284C9.53409 13.1676 9.49858 13.2955 9.42756 13.4119C9.35938 13.5284 9.26705 13.6222 9.15057 13.6932C9.03693 13.7614 8.90909 13.7955 8.76705 13.7955Z" fill="#38B349" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2893_2971">
                                                                        <rect width="18" height="18" rx="9" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div className="mt-3">
                                                        <h6 className="mb-1">{name}</h6>
                                                        <div className="text-center text-warning">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <SuggestionSection /> */}
                </div>

            </div>
                    
               
        </Fragment >
    )
})

Toolkit.displayName = "Toolkit"
export default Toolkit