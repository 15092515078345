import React, { memo, useState, useEffect } from 'react';
import { useNavigate, useParams,useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios_inst from '../utilities/axios';
import { setDashboardConfig, setDashboardSidebar, setStatuDashboardSidebarLoaded, Plan_id, LandingPage, setPersonaData, Other, setUserName, setGroupID, setGroupIDs, IsLoading, setPersonaTitle, SetActivePlan } from '../store/dashboardConfig/dashboardConfigSlice';

// Define Plans component

function isValidJSON(storedData) {
    try {
        JSON.parse(storedData);
        return true;  // Valid JSON
    } catch (error) {
        return false;  // Invalid JSON
    }
}

const GetConfigData = (forceRefresh = false) => {
    return useQuery({
        refetchOnWindowFocus: false,
        queryKey: ['get_config'],
        queryFn: async () =>{
            // Check localStorage first
            const storedData = localStorage.getItem('typography');
            // const isHomePage = location.pathname === '/';

            // Only make API call if localStorage is empty or invalid
            if (!storedData || !isValidJSON(storedData)  || forceRefresh ) {
                const response = await axios_inst.get('api/v3/platform');
                
                if(response?.data?.error === 'NOT_LOGGED_IN' 
                && window.iqonic_login_page_url !== window.location.href //prevent infinite loop in local
                ){
                    window.location.href = window.iqonic_login_page_url;
                    return;
                }
                if (response?.data?.data) {
                    // Store in localStorage
                    localStorage.setItem('typography', JSON.stringify(response.data.data));
                    localStorage.setItem('trademark', response.data.data.platform_footer);
                    localStorage.setItem('termsurl', response.data.data.platform_termsurl);
                    localStorage.setItem('privacyurl', response.data.data.platform_privacyurl);
                    
                    window.dispatchEvent(new Event("storage"));
                    return response.data;
                }
            }

            // Return localStorage data if it exists and is valid
            return { data: JSON.parse(storedData) };
        }
            
    });
};


const Plans = () => {
    const navigate = useNavigate();
    const configDispatch = useDispatch();
    configDispatch(IsLoading(true)); 
    let { group: url_group_id, toolkit_id } = useParams();

    const { data, isFetched, isStale } = useQuery({
        refetchOnWindowFocus: false,
        queryFn: (data) =>
            axios_inst.get('api/v4/get_plan').then(res => {
                    if (res?.data?.data?.pages?.Learn?.categories) {
                        
                        if (!url_group_id) {
                            let groups = findFirstSelectedGroupID( res.data.data.pages.Learn );

                            if( typeof groups === "object" && groups?.groupId ) {
                                localStorage.setItem('Sub_id', groups.groupId);

                                (!toolkit_id) && navigate(`/dashboard/${groups.groupId}/advice`);
                            } else {
                                 navigate(`/dashboard` );
                            }
                        }
                    }
                    return res.data
                })
    });

    if (isFetched && isStale) {
        if (data?.data?.plan_id !== false && data?.message === 'SINGLE_PLAN_CONTENT') {
            configDispatch(setUserName(data.data.user));
            configDispatch(setDashboardConfig(data?.data?.org));
            configDispatch(setDashboardSidebar(data?.data?.pages));
            configDispatch(setPersonaData(data?.data));
            configDispatch(LandingPage(data?.data?.landing));
            configDispatch(Other(data?.data?.other));
            configDispatch(Plan_id(data?.plan_id));
            configDispatch(setStatuDashboardSidebarLoaded(true));
            configDispatch(IsLoading(false)); 
            configDispatch(SetActivePlan(data));
            if (url_group_id) {
                let groups = Object.keys(data?.data?.pages?.Learn?.categories).map((key) => data?.data?.pages?.Learn?.categories[key]).find((item) => item.group_ids.includes(Number(url_group_id)))
                configDispatch(setPersonaTitle(groups.persona));
            }
        }
        return data?.data;
    }
    return {}; // Assuming this is a helper component, not rendering anything
};

const ToolkitUpdate = () => {
    const { isLoading, mutate, isSuccess } = useMutation({
        mutationFn: data => {
            return axios_inst.post('api/v3/save_toolkits?debug=none', data);
        },
        mutationKey: 'save_toolkits',
    });

    // Ensure that mutateCategory is returned as a function
    return { isLoading, mutate, isSuccess };
};
const ProfileMutation = () => {
    return useMutation({
        mutationFn: data => {
            return axios_inst.post('api/v3/association_update?debug=none', data);
        },
        mutationKey: 'profile_update',
    });
};
const DocumentUpdate = () => {
    return useMutation({
        mutationFn: data => {
            return axios_inst.post('api/v3/document', data);
        },
        mutationKey: 'document_update',
    });
};
const DocUpload = () => {
    return useMutation({
        mutationFn: data => {
            return axios_inst.post('api/v3/document', data);
        },
        mutationKey: 'document',
    });
};

// Define GetCategory component
const GetCategory = () => {
    return useQuery({
        queryKey: ['get_category'],
        queryFn: () =>
            axios_inst
                .get('api/v3/get_category')
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    console.log(err);
                }),
    });
};

const GetAssociation = () => {
    return useQuery({
        refetchOnWindowFocus: false,
        queryKey: ['get_association'],
        queryFn: () =>
            axios_inst
                .get('api/v3/association')
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    console.log(err);
                }),
    });
};
const GetToolkit = () => {
    return useQuery({
        refetchOnWindowFocus: false,
        queryKey: ['get_toolkit'],
        queryFn: async () => {
            const toolkitId = localStorage.getItem('toolkitId');
            if (!toolkitId) {
                const response = await axios_inst.get('api/v3/toolkits');
                const firstactivetoolkitid = getFirstSelectedToolKit(response.data.data);
                firstactivetoolkitid?.toolKitid ? localStorage.setItem('toolkitId', firstactivetoolkitid.toolKitid) : localStorage.setItem('toolkitId', '');
                return response.data;
            } else {
                const response = await axios_inst.get('api/v3/toolkits');
                return response.data;
            }
        }
    });
};

const GetDocument = (id) => {
    return useQuery({
        refetchOnWindowFocus: false,
        queryKey: ['get_doc'],
        queryFn: () =>
            axios_inst
                .get(`api/v3/document?id=${id}&&editable=true`)

                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    console.log(err);
                }),
    });
};
const GetTaskList = () => {
    return useQuery({
        refetchOnWindowFocus: false,
        queryKey: ['get_tasks'],
        queryFn: async () => {
            try {
                const res = await axios_inst.get('api/v3/tasks');
                const responseData = res.data;
                const dataStrings = responseData.split("data: ").filter(Boolean);
                const parsedData = dataStrings.map(dataStr => JSON.parse(dataStr));

                // Filter out any objects that contain {"finished": true}
                const filteredData = parsedData.filter(task => !task.finished);

                return filteredData;
            } catch (err) {
                console.log(err);
                throw err;
            }
        },
    });
};

const getFirstSelectedToolKit = (data) => {
    return Object.values(data.categories)
        .filter(category => category.selected)
        .map(category => ({
            personaId: category.persona.id,
            toolKitid: Object.entries(category.toolkits).find(([, toolkit]) => toolkit.selected)?.[0]
        }))
        .find(result => result.toolKitid) || false;
};

function findFirstSelectedGroupID(data, group_id = false ) {
    return Object.values(data.categories)
            .filter(category => category.selected)
            .map(category => ({
                personaId: group_id ? Object.entries(data.categories).find(([, category]) => category.groups[group_id])?.[1].persona.id : category.persona.id ,
                groupId:  group_id ? group_id : Object.entries(category.groups).find(([, group]) => group.selected)?.[0]
            }))
            .find(result => result.groupId) || false;
}

const AddNewTasks = () => {
    const { isLoading, mutate, isSuccess } = useMutation({
        mutationFn: data => {
            return axios_inst.post('api/v3/tasks', data);
        },
        mutationKey: 'save_tasks',
    });
    return { isLoading, mutate, isSuccess };
};
const UpdateTasks = () => {
    const { isLoading, mutate, isSuccess } = useMutation({
        mutationFn: data => {
            return axios_inst.post('api/v3/task_edit', data);
        },
        mutationKey: 'update_tasks',
    });
    return { isLoading, mutate, isSuccess };
};



// Export components
export { Plans, GetCategory, GetAssociation, ProfileMutation, GetToolkit, ToolkitUpdate, GetTaskList, AddNewTasks, UpdateTasks, GetConfigData, DocUpload, GetDocument, DocumentUpdate, findFirstSelectedGroupID };