import { useState, memo, Fragment, useEffect } from 'react'

//Router
import { Link, useLocation } from 'react-router-dom'


//React-bootstrap
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

// components
import SidebarMenu from '../../components/sidebar/sidebar-menu'

// Modals
import { GettingStarted, HowItWorks, LocationSupport, MailSupport, ShareYourPlan } from '../../../modals'
import { useDispatch, useSelector } from 'react-redux'
import { setCategoryId, setGroupID, setPersonaTitle } from '../../../../store/dashboardConfig/dashboardConfigSlice'

// images
import { TaskManager } from '../../components/TaskManager'



const VerticalNav = memo(({sideBarData, setSideBarData}) => {
    // const sidebarOptions = useSelector(SettingSelector.sidebarOptions);
    const [gettingStartedData, setData] = useState('')
    const configDispatch = useDispatch();
    const other = useSelector((state) => state.isOther);

    const [howItWorksModal, setHowItworksModal] = useState(false)
    const [shareYourPlan, setShareYourPlan]     = useState(false)
    const [gettingStarted, setGettingStarted]   = useState(false)
    const [locationSupport, setLocationSupport] = useState(false)
    const [mailSupport, setMailSupport]         = useState(false)
    const [status, setStatus]                   = useState();

    //location
    let location = useLocation();
    const personaData = useSelector(state => state.perasonaData);

    return (
        <Fragment>
            <div>
                {gettingStarted && (<>
                    <GettingStarted 
                        GettingStarted show={gettingStarted} 
                        onHide={() => setGettingStarted(false)} 
                        data={gettingStartedData} status={status}
                        setSideBarData={setSideBarData}
                        sideBarData={sideBarData}
                    >
                    </GettingStarted>
                </>)}
                <HowItWorks show={howItWorksModal} onHide={() => setHowItworksModal(false)} other={other}></HowItWorks>
                <ShareYourPlan show={shareYourPlan} onHide={() => setShareYourPlan(false)}></ShareYourPlan>
                <LocationSupport show={locationSupport} onHide={() => setLocationSupport(false)}></LocationSupport>
                <MailSupport show={mailSupport} onHide={() => setMailSupport(false)}></MailSupport>
            </div>
            <div>
                <ul className="navbar-nav iq-main-menu">
                    {other?.platform_help && (
                        <li className={`${location.pathname === '#' ? 'active' : ''} nav-item `}>
                            <Link to="#" onClick={() => setHowItworksModal(true)} className={` nav-link `} aria-current="page" >
                                <span className="item-name">{other?.platform_help?.title}</span>
                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-20" height="20">
                                    <path opacity="0.4" d="M12 1.99976C17.515 1.99976 22 6.48576 22 11.9998C22 17.5138 17.515 21.9998 12 21.9998C6.486 21.9998 2 17.5138 2 11.9998C2 6.48576 6.486 1.99976 12 1.99976Z" fill="currentColor">
                                    </path><path d="M10.5575 7.77917C10.7485 7.77917 10.9405 7.85217 11.0865 7.99817L14.5735 11.4682C14.7145 11.6092 14.7935 11.8002 14.7935 12.0002C14.7935 12.1992 14.7145 12.3902 14.5735 12.5312L11.0865 16.0032C10.7935 16.2952 10.3195 16.2952 10.0265 16.0012C9.73448 15.7072 9.73548 15.2322 10.0285 14.9402L12.9815 12.0002L10.0285 9.06017C9.73548 8.76817 9.73448 8.29417 10.0265 8.00017C10.1725 7.85217 10.3655 7.77917 10.5575 7.77917Z" fill="currentColor"></path>
                                </svg>
                            </Link>
                        </li>
                    )}
                    <li className="nav-item static-item">
                        <div className="nav-link static-item">
                            <TaskManager />
                        </div>
                    </li>
                    <li><hr className="hr-horizontal" /></li>
                    <div>
                        <li className={` nav-item mb-3 `}>
                            <Link to="#" onClick={() => { setGettingStarted(true); setData(personaData); setStatus(0) }} className={` nav-link icons-colors`} aria-current="page" >
                                <OverlayTrigger placement="right" overlay={<Tooltip>Add Gurus</Tooltip>}>
                                    <i className="icon"></i>
                                </OverlayTrigger>
                                <span className="item-name gurus" >
                                    Add Gurus
                                </span>
                                <i className="right-icon gurus">
                                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-20" height="15"><path opacity="0.4" d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z" fill="currentcolor"></path><path d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z" fill="currentcolor"></path></svg>
                                </i>
                            </Link>
                        </li>
                    </div>
                    <li><hr className="hr-horizontal" /></li>
                    <li className="nav-item static-item">
                        <Link to="#" className="nav-link static-item disabled" >
                            <span className="default-icon text-uppercase">SUPPORT AREAS</span>
                            <span className="mini-icon">-</span>
                        </Link>
                    </li>

                    <li><hr className="hr-horizontal" /></li>
                    <div style={{padding:"0 0 30px 0"}}>
                        {Object.values(sideBarData.pages.Learn.categories)
                            .filter(item => item.selected)
                            .map((item, index) => {
                                const modifiedTitle = item.title.replace(/[&\s]+/g, '-').toLowerCase();

                                return (
                                    <li className={`nav-item  ${modifiedTitle}`} key={index}>
                                        <Link to="#" onClick={() => { setGettingStarted(true); setData(item); setStatus(1) }} className={` nav-link `} aria-current="page" >
                                            <OverlayTrigger placement="right" overlay={<Tooltip>{item.title}</Tooltip>}>
                                                <i className="icon">
                                                </i>
                                            </OverlayTrigger>
                                            <span className="item-name">
                                                {item.title}
                                            </span>
                                            <i className="right-icon">
                                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-20" height="15"><path opacity="0.4" d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z" fill="currentColor"></path><path d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z" fill="currentColor"></path></svg>
                                            </i>
                                        </Link>

                                        {item?.group_ids && item?.group_ids.length > 0 ? (
                                            <div>
                                                <ul className="sub-nav">
                                                    {item?.group_ids
                                                        .filter(subitem => item.groups[subitem]?.selected) // Filter out only selected groups
                                                        .map((subitem, subindex) => {
                                                            const group         = item?.groups[subitem];
                                                            const minititle     = group?.title.substring(0, 2);
                                                            const category_id   = subitem;
                                                            const persona_id    = item.persona.id;
                                                            const personaTitle  = item.persona;

                                                            return (
                                                                <div onClick={() => {
                                                                    configDispatch(setGroupID(subitem));
                                                                    configDispatch(setCategoryId({ category_id, persona_id }));
                                                                    localStorage.setItem('CategoryID', category_id);
                                                                    localStorage.setItem('PersonaID', persona_id);
                                                                    localStorage.setItem('Sub_id', subitem);
                                                                    localStorage.setItem('personaTitle', JSON.stringify(personaTitle));

                                                                    configDispatch(setPersonaTitle({ personaTitle }));
                                                                }} key={subindex}>
                                                                    <SidebarMenu
                                                                        isTag="false"
                                                                        staticIcon="true"
                                                                        pathname={`${subitem}/`}
                                                                        title={group?.title}
                                                                        minititle={minititle}>
                                                                    </SidebarMenu>
                                                                </div>
                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        ) : ""}
                                    </li>
                                )
                            })}
                    </div>

                </ul>
            </div>
        </Fragment>
    )
})

VerticalNav.displayName = "VerticalNav"
export default VerticalNav
