import { memo, Fragment, useEffect, useState } from 'react'

// react-bootstrap
import { Row,Col,Tab,Nav, Form } from 'react-bootstrap'

// react-router
import { Link, useParams } from 'react-router-dom'

// component
import  Card  from '../../components/bootstrap/card';
import ChoicesJs from '../choices'
import EventSourceObj from '../../utilities/EventSource';
import Markdown from '../partials/components/markdowan';

import Playstore from '../../assets/images/playstore.png'
import Appstore from '../../assets/images/appstore.png'

import Vimeo from '@u-wave/react-vimeo';
import ButtonWidget from '../button-widget';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectMessage, ConnectMessageStatus, ChatFullScreen } from '../../store/dashboardConfig/dashboardConfigSlice';


const AppsWidget = memo(() => {
    
    const { group } = useParams();
    const [summary, setSummary] = useState('');
    const [media, setMedia] = useState(null);
    const [loading, setLoading] = useState(true);
    const [prevGroupId, setPrevGroupId] = useState(null);
    const [visibleApps, setVisibleApps] = useState([]);
    const [expandedDocs, setExpandedDocs] = useState({});
    const [selectedType, setSelectedType] = useState('Filter');
    const [selectedPlatform, setSelectedPlatform] = useState('Android');
    const [buttons, setButtons] = useState([]);
    const [videoUrl, setVideoUrl] = useState(null);
    const dispatch = useDispatch();
    const category = useSelector(state => state.category_id);

    const handleChatButtonClick = (persona, message) => {
            dispatch(ChatFullScreen(true));
            dispatch(ConnectMessage(message));
            dispatch(ConnectMessageStatus(false));
            
        };
        
        const updateChatPanel = (persona, message) => {
            dispatch(ChatFullScreen(false));
            dispatch(ConnectMessage(message));
            dispatch(ConnectMessageStatus(false));
        };

    useEffect(() => {
            let appdata;
            if (prevGroupId !== group) {
                // Reset states when group changes
                setVisibleApps([]);
                setSummary('');
                setMedia(null);
                setLoading(true);
    
                appdata = EventSourceObj(`api/v3/get_content?type=apps&group_id=${group}`);
                setPrevGroupId(group);
            }
            if (!appdata) return;
    
            const messageHandler = (appData) => {
                const appsJsonData = JSON.parse(appData.data);
                
                if (appsJsonData.finished) {
                    setLoading(false);
                    appdata.close();
                    return;
                }
    
                if(appsJsonData?.type === 'summary'){
                if(appsJsonData?.content?.length){
                    appsJsonData?.content.forEach(item => {
                        if (item.type === 'video') {
                            setVideoUrl(item.url);
                        } else {
                            setButtons((prevbuttons) => [...prevbuttons, item]);
                        }
                    });
                }else{
                    setSummary((prevSummary) => prevSummary + appsJsonData?.stream);
                }
            } else if (appsJsonData.media) {
                    setMedia(appsJsonData.media);
                  } else {
                    // Check for duplicates using a unique identifier
                    setVisibleApps(prevEvents => {
                        const eventId = appsJsonData.start_time?.timestamp || appsJsonData.id;
                        const isDuplicate = prevEvents.some(event => 
                            (event.start_time?.timestamp || event.id) === eventId
                        );
                        
                        if (isDuplicate) {
                            return prevEvents;
                        }
                        return [...prevEvents, appsJsonData];
                    });
                    setLoading(false);
                  }
            };
            appdata.addEventListener('message', messageHandler);
            appdata.addEventListener('error', (error) => {
                console.error('appdata error:', error);
                appdata.close();
            });
    
            return () => {
                appdata?.close();
            };
        }, [group]);


        const handlePlatformChange = () => {
            setSelectedPlatform(prev => (prev === 'Android' ? 'iOS' : 'Android')); // Toggle between Android and iOS
        };

        // Handle filter change
    const handleFilterChange = (e) => {
        setSelectedType(e.target.value);
    };

    // Prepare options for ChoicesJs component
    const options = [
        { value: 'Filter', label: 'Filter' },
        { value: 'Free', label: 'Free' },
        { value: 'Paid', label: 'Paid' }
    ];
    
    // Filter the appsResponse based on selectedType
    const filteredData = visibleApps.filter((value) => {
        const isPriceZero = value?.price === '0' || value?.price === 'Free' || value?.price === 0;

        // Check platform filter
        if (selectedPlatform === 'Android' && value.source?.platform !== 'Android') {
            return false; // Exclude non-Android apps
        }
        if (selectedPlatform === 'iOS' && value.source?.platform !== 'iOS') {
            return false; // Exclude non-iOS apps
        }

        // Check price filter
        if (selectedType === 'Filter') {
            return true; // Show all apps
        }
        
        if (selectedType === 'Free') {
            return isPriceZero; // Show only free apps
        }
        
        if (selectedType === 'Paid') {
            return !isPriceZero; // Show only paid apps
        }
        
        return false; // Default case
    });
    

// Toggle description expansion
const handleToggleDescription = (id) => {
    setExpandedDocs(prev => ({
        ...prev,
        [id]: !prev[id], // Toggle state for the specific id
    }));
};

const isDescriptionLong = (description) => {
    return description && description.length > 100;
};

const fallbackImage = "https://wallpapers.com/images/featured/blank-white-7sn5o1woonmklx1h.jpg";

const handleImageError = (event) => {
    event.target.src = fallbackImage;
  };

const skeletonItems = new Array(4).fill(null);

    return (
        <Fragment>

            {summary ? (
                <div className="d-flex gap-3 mb-5 flex-wrap flex-md-nowrap">
                    <div>
                        <img src={media?.image} alt="Avatar" className="img-fluid avatar avatar-130 avatar-sm-60" />
                    </div>
                    <div className='iq-accordian w-100 broder-0'>
                        <Card className="mb-0 h-100 p-2">
                            <Markdown content={summary} />
                            {buttons?.length 
                                ? ( 
                                    <div>
                                    {
                                        buttons.map((item, index) => (
                                            <ButtonWidget
                                                key={`event-widget-button-${index}`}
                                                buttonType={item?.action}
                                                buttonText={item?.text}
                                                url={item?.url}
                                                target={item?.target}
                                                videoUrl={item?.url}
                                                persona={category?.persona_id}
                                                category_id={category?.category_id}
                                                message={item?.message}
                                                source={item?.source}
                                                autoplay={item?.autoplay}
                                                id={`event-widget-button-${index}`} // Ensure the id prop is passed here
                                                onChatButtonClick={handleChatButtonClick}
                                                updateChatPanel={updateChatPanel}
                                            />
                                        ))
                                    }
                                    </div>
                                )
                                : ''
                            }
                            {videoUrl && (
                                <Vimeo
                                    video={videoUrl}
                                    autoplay={false}
                                    width="100%"
                                />
                            )}
                        </Card>
                    </div>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '2rem' }}>
                    <div className="iq-img-skeleton iq-skeleton" style={{ width: '130px', height: '130px', borderRadius: '50%' }}></div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="iq-skeleton" style={{ height: '20px', width: '60%', borderRadius: '4px' }}></div>
                        <div className="iq-skeleton" style={{ height: '20px', width: '90%', borderRadius: '4px' }}></div>
                        <div className="iq-skeleton" style={{ height: '20px', width: '75%', borderRadius: '4px' }}></div>
                    </div>
                </div>
            )}

            <Tab.Container defaultActiveKey="grid">
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap gap-3 gap-md-0'>
                    <div className='d-flex align-items-center gap-3 flex-wrap'>
                        <div className="filter-select">
                            <ChoicesJs select="single" value={selectedType} options={options} className="iq-select" onChange={handleFilterChange} />
                        </div>
                        <div className="custom-toggle" onClick={handlePlatformChange}>
                            <div className={`toggle ${selectedPlatform === 'Android' ? 'active' : ''}`}>
                                <span className="toggle-label">Android</span>
                            </div>
                            <div className={`toggle ${selectedPlatform === 'iOS' ? 'active' : ''}`}>
                                <span className="toggle-label">iOS</span>
                            </div>
                        </div>
                    </div>
                    <Nav className='nav-iconly gap-2 flex-nowrap overflow-auto nav nav-tabs' data-toggle="slider-tab" role="tablist">
                        <Nav.Item className='d-none d-md-block'   role="presentation">
                            <Nav.Link className="iq-nav-tab nav-link px-3" eventKey="grid" data-bs-toggle="tab" data-bs-target="#grid" type="button" role="tab" aria-controls="grid" aria-selected="true">
                                <div className='d-flex gap-2 align-items-center'>
                                    Grid View
                                    <span className="btn-inner">
                                            <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z" fill="currentColor"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item  role="presentation" className='d-none d-md-block'>
                            <Nav.Link eventKey="list" className="iq-nav-tab nav-link px-3" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">
                                <div className='d-flex gap-2 align-items-center'>
                                    List View
                                    <span className="btn-inner">
                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z" fill="currentColor"></path>
                                            <path opacity="0.4" d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z" fill="currentColor"></path>
                                            <path d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z" fill="currentColor"></path>
                                            <path d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <p className="blog-line-around my-5"><span className="blog-line-day px-4 py-2">Apps</span></p>
                <Tab.Content className="mt-2">
                    <Tab.Pane  eventKey="grid" variant=" fade show active" id="grid" role="tabpanel" aria-labelledby="pills-grid-tab1">
                        <div className='iq-product-event-view'>
                            <Row>
                                {
                                    loading ? (
                                        skeletonItems.map((_, index) => (
                                            <Col md={6} key={index} className="mb-4">
                                        <Card className="inner-card h-100">
                                            <Card.Body>
                                                <div className='d-flex gap-3 align-items-center mb-3'>
                                                    <div className="iq-img-skeleton iq-skeleton rounded" style={{ width: '80px', height: '80px' }}></div>
                                                    <div style={{ flex: 1 }}>
                                                        <div className="iq-skeleton" style={{ height: '24px', width: '70%' }}></div>
                                                    </div>
                                                    <div className="iq-skeleton" style={{ height: '40px', width: '120px' }}></div>
                                                </div>
                                                <div>
                                                    <div className='mb-3 d-flex justify-content-between'>
                                                        <div className='text-center' style={{ width: '30%' }}>
                                                            <div className="iq-skeleton mb-2" style={{ height: '20px', width: '60%', margin: '0 auto' }}></div>
                                                            <div className="iq-skeleton" style={{ height: '16px', width: '80%', margin: '0 auto' }}></div>
                                                        </div>
                                                        <div className='text-center' style={{ width: '30%' }}>
                                                            <div className="iq-skeleton mb-2" style={{ height: '20px', width: '60%', margin: '0 auto' }}></div>
                                                            <div className="iq-skeleton" style={{ height: '16px', width: '80%', margin: '0 auto' }}></div>
                                                        </div>
                                                        <div className='text-center' style={{ width: '30%' }}>
                                                            <div className="iq-skeleton mb-2" style={{ height: '20px', width: '60%', margin: '0 auto' }}></div>
                                                            <div className="iq-skeleton" style={{ height: '16px', width: '80%', margin: '0 auto' }}></div>
                                                        </div>
                                                        <div className='text-center' style={{ width: '30%' }}>
                                                            <div className="iq-skeleton mb-2" style={{ height: '20px', width: '60%', margin: '0 auto' }}></div>
                                                            <div className="iq-skeleton" style={{ height: '16px', width: '80%', margin: '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <div className="iq-skeleton mb-2" style={{ height: '16px', width: '100%' }}></div>
                                                    <div className="iq-skeleton mb-2" style={{ height: '16px', width: '90%' }}></div>
                                                    <div className="iq-skeleton" style={{ height: '16px', width: '95%' }}></div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                        ))
                                    ) : filteredData.length > 0 ? (
                                        filteredData.map((value, gridIndex) => {
                                            const isDescriptionValid = typeof value.description === 'string' && value.description.length > 0;
                                            return(
                                                <Col md={6} key={gridIndex} className="mb-4">
                                                    <Card className="inner-card h-100">
                                                        <Card.Body>
                                                            <div className='d-flex flex-column flex-md-row justify-content-between mb-3'>
                                                                <div className='d-flex align-items-center gap-3 iq-product-grid-view flex-wrap flex-lg-nowrap'>
                                                                    {
                                                                        value?.logo && (
                                                                            <Link className='flex-shrink-0' to={value.links.Details} target="_blank">
                                                                                <img src={value?.logo} className="img-fluid iq-product-img rounded" alt='app-image' loading='lazy' onError={handleImageError}/>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                    <h5 className='mb-0 text-ellipsis short-2'>{value.title}</h5>
                                                                </div>
                                                                <div className='flex-shrink-0'>
                                                                    {
                                                                        value.source.platform === 'iOS' ? (
                                                                            <Link to={value.links?.Details} target="_blank">
                                                                                <img src={Appstore} alt='appstore-icon' className='img-fluid' loading='lazy' style={{width : '6rem'}}/> {/* Adjusted width for mobile */}
                                                                            </Link>
                                                                        ) : (
                                                                            <Link to={value.links?.Details} target="_blank">
                                                                                <img src={Playstore} alt='playstore-icon' className='img-fluid' loading='lazy' style={{width : '6rem'}}/> {/* Adjusted width for mobile */}
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        <div>
                                                            <div className='mb-3 d-flex flex-column flex-md-row gap-3'>
                                                                <div className='border-end'>
                                                                    <p className='mb-0'>{value?.rating}</p>
                                                                    <p className='text-muted me-3'>rating</p>
                                                                </div>
                                                                {
                                                                    value.source?.platform == 'Android' && (
                                                                        <div className='border-end'>
                                                                        <p className='mb-0'>{value?.downloads}</p>
                                                                            <p className='text-muted me-3'>Downloads</p>
                                                                        </div>
                                                                    ) 
                                                                }
                                                                <div className='border-end pe-3'>
                                                                    {
                                                                        value?.price === 'Free' ? (
                                                                            <p className='mb-0'>{value.price}</p>
                                                                        ) : (
                                                                            <p className='mb-0'>$ {value.price}</p>
                                                                        )
                                                                    }
                                                                    <p className='text-muted'>Price</p>
                                                                </div>
                                                                <div>
                                                                    <p className='mb-0'>{value.genre}</p>
                                                                    <p className='text-muted'>Category</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {isDescriptionValid && (
                                                            <>
                                                                <div className='mb-2'>
                                                                    <p className={`mb-0 ${expandedDocs[value.id] ? "expanded" : "text-ellipsis short-3 "}`}>
                                                                        {expandedDocs[value.id] ? value.description : `${value.description.substring(0, 100)}...`}
                                                                    </p>
                                                                    {isDescriptionLong(value.description) && (
                                                                        <button 
                                                                            onClick={() => handleToggleDescription(value.id)} 
                                                                            className="btn btn-link p-0"
                                                                        >
                                                                            {expandedDocs[value.id] ? 'Read Less' : 'Read More'}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    ) : (
                                        <h4>No apps data found</h4>
                                    )
                                }
                            </Row>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="list" className="fade" id="list" role="tabpanel" aria-labelledby="pills-list-tab1">
                        <div className='iq-product-list-view'>
                            {
                                loading ? (
                                    skeletonItems.map((_, index) => (
                                        <Card className="inner-card mb-3" key={index}>
                                            <Card.Body>
                                                <div className="d-flex flex-column flex-lg-row gap-3">
                                                    <div className='d-flex flex-grow-1 gap-3'>
                                                        <div className="flex-shrink-0">
                                                            <div className="iq-img-skeleton rounded" style={{ width: '130px', height: '130px' }}></div>
                                                        </div>
                                                        <div className='flex-grow-1'>
                                                            <div className="iq-skeleton mb-4" style={{ height: '24px', width: '200px' }}></div>
                                                            <div className='d-flex flex-wrap gap-4 mb-4'>
                                                                <div className='border-end pe-4'>
                                                                    <div className="iq-skeleton mb-2" style={{ height: '16px', width: '60px' }}></div>
                                                                    <div className="iq-skeleton" style={{ height: '14px', width: '90px' }}></div>
                                                                </div>
                                                                <div className='border-end pe-4'>
                                                                    <div className="iq-skeleton mb-2" style={{ height: '16px', width: '70px' }}></div>
                                                                    <div className="iq-skeleton" style={{ height: '14px', width: '80px' }}></div>
                                                                </div>
                                                                <div className='border-end pe-4'>
                                                                    <div className="iq-skeleton mb-2" style={{ height: '16px', width: '120px' }}></div>
                                                                    <div className="iq-skeleton" style={{ height: '14px', width: '60px' }}></div>
                                                                </div>
                                                                <div className='border-end pe-4'>
                                                                    <div className="iq-skeleton mb-2" style={{ height: '16px', width: '80px' }}></div>
                                                                    <div className="iq-skeleton" style={{ height: '14px', width: '70px' }}></div>
                                                                </div>
                                                                <div>
                                                                    <div className="iq-skeleton mb-2" style={{ height: '16px', width: '50px' }}></div>
                                                                    <div className="iq-skeleton" style={{ height: '14px', width: '60px' }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="iq-skeleton mb-2" style={{ height: '16px', width: '100%' }}></div>
                                                            <div className="iq-skeleton mb-2" style={{ height: '16px', width: '90%' }}></div>
                                                            <div className="iq-skeleton" style={{ height: '16px', width: '95%' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-start justify-content-lg-end align-items-center" 
                                                        style={{ minWidth: '120px' }}>
                                                        <div className="iq-skeleton" style={{ height: '40px', width: '120px' }}></div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        ))
                                    ) : filteredData.length > 0 ? (
                                    filteredData.map((value, IndexList) => {
                                        const isDescriptionValid = typeof value.description === 'string' && value.description.length > 0;
                                        return (
                                            <Card className="inner-card" key={IndexList}>
                                                <Card.Body>
                                                    <div className="d-flex flex-column flex-lg-row gap-3">
                                                        <div className='d-flex flex-grow-1 gap-3'>
                                                            <div className="flex-shrink-0" style={{ width: '130px' }}>
                                                                {
                                                                    value?.logo && (
                                                                        <Link to={value.links.Details} target="_blank">
                                                                            <img src={value?.logo} alt="product-details" className="img-fluid iq-product-img rounded" loading="lazy" onError={handleImageError}/>
                                                                        </Link>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='flex-grow-1'>
                                                                <h4 className='mb-3'>{value.title}</h4>
                                                                <div className='d-flex flex-wrap gap-3 mb-3'>
                                                                    <div className='border-end pe-3'>
                                                                        <p className='mb-0'>{value?.rating}</p>
                                                                        <p className='text-muted'>rating</p>
                                                                    </div>
                                                                    {
                                                                        value.source?.platform === 'Android' &&
                                                                        <div className='border-end pe-3'>
                                                                            <p className='mb-0'>{value.downloads}</p>
                                                                            <p className='text-muted'>Downloads</p>
                                                                        </div>
                                                                    }
                                                                    <div className='border-end pe-3'>
                                                                        <p className='text-underline mb-0 text-ellipsis short-2'>{value.source?.name}</p>
                                                                        <p className='text-muted'>Author</p>
                                                                    </div>
                                                                    <div className='border-end pe-3'>
                                                                        <p className='mb-0'>{value?.genre}</p>
                                                                        <p className='text-muted'>Category</p>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            value?.price === 'Free' ? (
                                                                                <p className='mb-0'>{value?.price}</p>
                                                                            ) : (
                                                                                <p className='mb-0'>$ {value?.price}</p>
                                                                            )
                                                                        }
                                                                        <p className='text-muted'>Price</p>
                                                                    </div>
                                                                </div>
                                                                {isDescriptionValid && (
                                                                    <div className='mb-2'>
                                                                        <p className={`mb-0 ${expandedDocs[value.id] ? "expanded" : "text-ellipsis short-3"}`}>
                                                                            {expandedDocs[value.id] ? value.description : `${value.description.substring(0, 100)}...`}
                                                                        </p>
                                                                        {isDescriptionLong(value.description) && (
                                                                            <button 
                                                                                onClick={() => handleToggleDescription(value.id)} 
                                                                                className="btn btn-link p-0"
                                                                            >
                                                                                {expandedDocs[value.id] ? 'Read Less' : 'Read More'}
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0" style={{ minWidth: '150px' }}>
                                                            {value.source?.platform === 'iOS' ? (
                                                                <Link to={value.links.Details} target="_blank">     
                                                                    <img src={Appstore} alt='appstore-icon' className='img-fluid border-0' loading='lazy' />
                                                                </Link>
                                                            ) : (
                                                                <Link to={value.links?.Details} target="_blank">
                                                                    <img src={Playstore} alt='playstore-icon' className='img-fluid border-0' loading='lazy' />
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        )
                                    })
                                ) : (
                                    <h4>No apps data found</h4>
                                )
                            }
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Fragment>
    );
});

AppsWidget.displayName = 'AppsWidget';
export default AppsWidget;
