import React, { memo, Fragment, useEffect, useState } from 'react';
import { useOutletContext, useParams } from "react-router-dom";
import { Form, Tab, Button, Image, Spinner } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import axios_inst from '../../utilities/axios';
import ButtonWidget from '../../components/button-widget';
import Card from '../../components/bootstrap/card';
import ImpactSubHeader from '../../components/partials/dashboard/headerstyle/impact-sub-header';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import { useDispatch } from 'react-redux';
import { ConnectMessage, ConnectMessageStatus } from '../../store/dashboardConfig/dashboardConfigSlice';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import Vimeo from '@u-wave/react-vimeo';

// DataTable component remains unchanged
const DataTable = ({ api, columns, isActive }) => {
  const [data, setData] = useState([]);
  const tableRef = React.useRef(null);

  useEffect(() => {
    if (isActive) {
      const fetchData = async () => {
        try {
          const response = await axios_inst.get(`api/v4/datatable?id=${api}`);
          setData(response.data.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [api, isActive]);

  useEffect(() => {
    if (data.length > 0) {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
      $(tableRef.current).DataTable();
    }
  }, [data]);

  return (
    <div className='table-responsive table-custom'>
      <table ref={tableRef} className="table table-striped table-bordered">
        <thead>
          <tr>
            {columns?.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Improved Form Display component for individual forms
const DirectFormDisplay = ({ formId, isActive }) => {
  const [surveyData, setSurveyData] = useState(null);
  const [answerId, setAnswerId] = useState(null);
  const [loading, setLoading] = useState(true);

  // Load form data when component mounts
  useEffect(() => {
    if (isActive) {
      const fetchFormData = async () => {
        if (!formId) {
          setLoading(false);
          return;
        }

        try {
          const initialResponse = await axios_inst.get(`api/v3/forms?id=${formId}&answers=start`);
          const newAnswerId = initialResponse?.data?.form?.answers[0]?.id;

          if (!newAnswerId) {
            setLoading(false);
            return;
          }

          setAnswerId(newAnswerId);

          const formResponse = await axios_inst.get(`api/v3/forms?id=${formId}&answers=${newAnswerId}`);
          const formData = formResponse?.data?.form?.data;

          if (formData) {
            setSurveyData(formData);
          } else {
            console.error('No form data found for form:', formId, 'with answer ID:', newAnswerId);
          }
        } catch (error) {
          console.error(`Error fetching form data for form ${formId}:`, error);
        } finally {
          setLoading(false);
        }
      };

      fetchFormData();
    }
  }, [formId, isActive]);

  const handleSurveyComplete = (survey) => {
    if (!formId || !answerId) {
      console.error('Missing formId or answerId for form submission');
      return;
    }

    axios_inst.post(`api/v3/forms?id=${formId}&answers=${answerId}`, survey.data)
      .then(response => {
        const updatedResponse = { ...response.data, data: survey.data };
      })
      .catch(error => console.error(`Error saving survey results for form ${formId}:`, error));
  };

  return (
    <div className="direct-form-container mb-4">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
          <Spinner animation="border" />
        </div>
      ) : surveyData ? (
        <>
          <h4>{formId} Form</h4>
          <Survey 
            model={new Model(surveyData)} 
            onComplete={handleSurveyComplete} 
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const PartnerPortal = memo(({ pageUrl }) => {
  const { partnerTab, activeKey, setActiveKey } = useOutletContext();
  const { tabUrl } = useParams();
  const {admin} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (partnerTab?.length > 0 && !activeKey) {
      setActiveKey(partnerTab[0]?.tabsections[0]?.tabs[0]?.url);
    }
  }, [partnerTab, activeKey, setActiveKey]);

  useEffect(() => {
    if (pageUrl && tabUrl) {
      setActiveKey(tabUrl);
    } else if (pageUrl && !tabUrl) {
      setActiveKey(partnerTab[0]?.tabsections[0]?.tabs[0]?.url);
    }
  }, [pageUrl, tabUrl, setActiveKey, partnerTab]);

  useEffect(() => {
    if (tabUrl) {
      const tab = partnerTab?.flatMap(section => section.tabsections)
        .flatMap(tabsection => tabsection.tabs)
        .find(tab => tab.url === tabUrl);
      if (tab) {
        setActiveKey(tab.url);
      }
    }
  }, [tabUrl, partnerTab, setActiveKey]);

  const handleChatButtonClick = (persona, message) => {
    dispatch(ConnectMessage(message));
    dispatch(ConnectMessageStatus(false));
  };

  const updateChatPanel = (data) => {
    console.log('Chat panel updated with data:', data);
  };

  const renderContent = (content, tabTitle) => (
    content?.map((contentItem, contentIndex) => {
      const { type, image, heading, text, fields, save, src, alt, columns, form_id, button, action, target, url, persona, message, source, autoplay, api, css_classes} = contentItem;
      const isActive = activeKey === tabTitle;
      switch (type) {
        case 'banner':
          return (
            <Fragment key={`content-${contentIndex}`}>
              <ImpactSubHeader image={image} className={css_classes}>
                <div>
                  <h2>{heading}</h2>
                  <p>{text}</p>
                </div>
              </ImpactSubHeader>
            </Fragment>
          );
        case 'paragraph':
          return <p key={`content-${contentIndex}`} className={css_classes}>{text}</p>;
        case 'image':
          return <Image key={`content-${contentIndex}`} src={src} alt={alt} className={`img-fluid ${css_classes}`} />;
        case 'datatables':
          return (
            <DataTable key={`content-${contentIndex}`} api={api} columns={columns} isActive={isActive}/>
          );
        case 'association fields':
          return (
            <Fragment key={`content-${contentIndex}`}>
              <Form>
                {fields.map((field, fieldIndex) => (
                  <div className='col-lg-6 col-md-6' key={`field-${fieldIndex}`}>
                    <div className='z-form'>
                      <Form.Group controlId={field.id}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control type={field.type} placeholder={field.placeholder} />
                      </Form.Group>
                    </div>
                  </div>
                ))}
                {save?.enabled === "true" && (
                  <Button variant="primary" type="submit">
                    {save.text}
                  </Button>
                )}
              </Form>
            </Fragment>
          );
        case 'form':
          return <DirectFormDisplay key={`form-${form_id}-${contentIndex}`} formId={form_id} isActive={isActive}/>;
        case 'button':
          return (
            <ButtonWidget
              key={`content-${contentIndex}`}
              buttonType={action}
              buttonText={text}
              url={url}
              target={target}
              videoUrl={url}
              persona={persona}
              message={message}
              source={source}
              autoplay={autoplay}
              id={form_id}
              onChatButtonClick={handleChatButtonClick}
              updateChatPanel={updateChatPanel}
            />
          );
        case 'video':
          return (
            <div key={`content-${contentIndex}`} className={css_classes}>
              <div className="iq-partner-modal">
                        <Vimeo
                          video={url}
                          autoplay={autoplay}
                          width="100%"
                          height="360"
                        />
                      </div>
            </div>
          );
        default:
          return null;
      }
    })
  );

  const renderColumns = (columns, containersIndex, rowIndex, tabTitle) => (
    columns.map((columnItem, columnIndex) => (
      <div className="col" key={`col-${containersIndex}-${rowIndex}-${columnIndex}`}>
        {renderContent(columnItem?.content, tabTitle)}
      </div>
    ))
  );

  const renderRows = (rows, containersIndex, tabTitle) => (
    rows.map((rowItem, rowIndex) => (
      <div className="row" key={`row-${containersIndex}-${rowIndex}`}>
        {renderColumns(rowItem.columns, containersIndex, rowIndex, tabTitle)}
      </div>
    ))
  );

  const renderContainers = (containers, tabTitle) => (
    containers.map((containersitem, containersIndex) => (
      <div key={`container-${containersIndex}`}>
        {renderRows(containersitem?.rows, containersIndex, tabTitle)}
      </div>
    ))
  );

  return (
    <Fragment>
      <Tab.Container activeKey={activeKey} onSelect={setActiveKey}>
        <Tab.Content>
          {partnerTab?.map((data, index) =>
            (data.url === admin || data.url === pageUrl) && data?.tabsections?.map((item, navIndex) =>
              item?.tabs?.map((linkItem, linkIndex) => (
                <Tab.Pane eventKey={linkItem.url} key={`tab-${index}-${navIndex}-${linkIndex}`}>
                  <Card className="mt-5">
                    <Card.Body>
                      {linkItem?.containers ? renderContainers(linkItem.containers, linkItem.url) : renderContent(linkItem?.content, linkItem.url)}
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              ))
            )
          )}
        </Tab.Content>
      </Tab.Container>
    </Fragment>
  );
});

PartnerPortal.displayName = "PartnerPortal";
export default PartnerPortal;