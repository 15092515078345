import { useEffect, useState, memo, Fragment } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import Scrollbar from "smooth-scrollbar";
import ToolkitSidebar from "./toolkit-nav";

const SidebarToolkit = memo(() => {
    const [isMiniSidebar, setIsMiniSidebar] = useState(false); // State to track mini sidebar mode
    const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Ensure content is displayed

    const minisidebar = () => {
        const sidebarElement = document.querySelector("aside[data-sidebar='responsive']");
        const isMini = sidebarElement.classList.toggle("sidebar-mini");
        setIsMiniSidebar(isMini);
        setIsSidebarVisible(!isMini); // Toggle visibility based on mini mode
    };

    useEffect(() => {
        // Initialize the scrollbar
        Scrollbar.init(document.querySelector("#my-scrollbar"));

        const toggleSidebar = () => {
            const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]');

            // Collapse sidebar by default if screen width is less than or equal to 1200px
            if (window.innerWidth <= 1200) {
                if (!sidebarResponsive.classList.contains("sidebar-mini")) {
                    sidebarResponsive.classList.add("sidebar-mini");
                    setIsMiniSidebar(true);
                    setIsSidebarVisible(false); // Hide content in mini mode
                }
            } else {
                sidebarResponsive.classList.remove("sidebar-mini");
                setIsMiniSidebar(false);
                setIsSidebarVisible(true); // Show content for larger screens
            }
        };

        // Run on initial load
        toggleSidebar();

        // Add resize listener to dynamically adjust the sidebar state
        window.addEventListener("resize", toggleSidebar);

        return () => {
            window.removeEventListener("resize", toggleSidebar);
        };
    }, []);

    return (
        <Fragment>
            <aside
                className="left-bordered sidebar sidebar-base navs-rounded-all sidebar-default sidebar-white"
                data-sidebar="responsive"
            >
                <div className="sidebar-header d-flex align-items-center justify-content-start">
                    <Link to="#" className="navbar-brand">
                        <Logo isLogoMini={true} />
                    </Link>
                    <div
                        className="sidebar-toggle"
                        data-toggle="sidebar"
                        data-active="true"
                        onClick={minisidebar}
                        style={{
                            right: isMiniSidebar ? "-40px" : "",
                            top: isMiniSidebar ? "15px" : "",
                            transition: "right 0.3s ease, top 0.3s ease", // Smooth transition for right and top
                        }}
                    >
                        <i className="icon">
                            <svg
                                className="icon-20"
                                style={{ padding: "5px" }}
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.93851 0.9256L0.26355 5.36934C-0.0878487 5.70369 -0.0878487 6.29532 0.26355 6.63085L4.93851 11.0746C5.30357 11.4208 5.89337 11.4196 6.25594 11.071C6.61852 10.7225 6.61852 10.1605 6.25346 9.81308L3.17779 6.88813L11.0687 6.88813C11.584 6.88813 12 6.48976 12 5.99891C12 5.50806 11.584 5.10969 11.0687 5.10969L3.17779 5.10969L6.25346 2.18592C6.43599 2.01164 6.52663 1.784 6.52663 1.55517C6.52663 1.32871 6.43599 1.10107 6.25594 0.927972C5.89337 0.580582 5.30357 0.579397 4.93851 0.9256Z"
                                    fill="white"
                                />
                            </svg>
                        </i>
                    </div>
                </div>
                <div
                    className="pt-0 pb-3 sidebar-body data-scrollbar"
                    data-scroll="1"
                    id="my-scrollbar"
                >
                    {/* sidebar-list class to be added after replace css */}
                    <div className="sidebar-list navbar-collapse" id="sidebar">
                        <ToolkitSidebar />
                    </div>
                </div>
                <div className="sidebar-footer"></div>
            </aside>
        </Fragment>
    );
});

SidebarToolkit.displayName = "SidebarToolkit";
export default SidebarToolkit;
