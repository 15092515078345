//Sweetalert
import Swal from "sweetalert2";

export function Sweetalert() {
    Swal.fire({
        customClass: {
            icon: 'no-border'
        },
        title: 'Something wrong ?',
        text: "We find local services for you online, and they're not always correct",
        iconHtml: '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M73.3346 39.9994C73.3346 58.4127 58.4113 73.3327 40.0013 73.3327C21.5913 73.3327 6.66797 58.4127 6.66797 39.9994C6.66797 21.5927 21.5913 6.66603 40.0013 6.66603C58.4113 6.66603 73.3346 21.5927 73.3346 39.9994Z" fill="#C03221"/><path fillRule="evenodd" clipRule="evenodd" d="M42.9007 42.1024C42.9007 43.709 41.5907 45.019 39.984 45.019C38.3774 45.019 37.0674 43.709 37.0674 42.1024V27.369C37.0674 25.7624 38.3774 24.4524 39.984 24.4524C41.5907 24.4524 42.9007 25.7624 42.9007 27.369V42.1024ZM37.0845 52.6783C37.0845 51.0717 38.3878 49.7617 39.9845 49.7617C41.6278 49.7617 42.9345 51.0717 42.9345 52.6783C42.9345 54.285 41.6278 55.595 40.0178 55.595C38.4011 55.595 37.0845 54.285 37.0845 52.6783Z" fill="#C03221"/></svg>',
        showCancelButton: true,
        confirmButtonColor: '#C03221',
        cancelButtonColor: '#38393B',
        confirmButtonText: 'Report it!',
        footer: 'Reporting this service will hide it from your report, and help to improve how we identify services for others',
        showClass: { popup: "animate__animated animate__zoomIn" },
        hideClass: { popup: "animate__animated animate__zoomOut" }
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                customClass: {
                    icon: 'no-border'
                },
                iconHtml: '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M54.468 6.666H25.568C14.268 6.666 6.66797 14.5993 6.66797 26.3993V53.6327C6.66797 65.3993 14.268 73.3327 25.568 73.3327H54.468C65.768 73.3327 73.3346 65.3993 73.3346 53.6327V26.3993C73.3346 14.5993 65.768 6.666 54.468 6.666Z" fill="#38B349"/><path d="M36.0451 50.8263C35.2985 50.8263 34.5518 50.543 33.9818 49.973L26.0718 42.063C24.9318 40.923 24.9318 39.0763 26.0718 37.9397C27.2118 36.7997 29.0551 36.7963 30.1951 37.9363L36.0451 43.7863L49.8051 30.0263C50.9451 28.8863 52.7885 28.8863 53.9285 30.0263C55.0685 31.1663 55.0685 33.013 53.9285 34.153L38.1085 49.973C37.5385 50.543 36.7918 50.8263 36.0451 50.8263Z" fill="#38B349"/></svg>',
                title: 'Thank you',
                text: 'Your report has been received',
                confirmButtonText: "Start Exploring",
                showClass: { popup: "animate__animated animate__zoomIn" },
                hideClass: { popup: "animate__animated animate__zoomOut" }
            })
        }
    })
}