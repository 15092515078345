import React, { useEffect, memo, Fragment, useContext, useState, useMemo } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import axios_inst from '../../utilities/axios';
import { useMutation } from '@tanstack/react-query'; 
import { setDashboardConfig, setDashboardSidebar, setStatuDashboardSidebarLoaded, Plan_id, LandingPage, setPersonaData, Other, setUserName, setGroupID, setGroupIDs, IsLoading, setPersonaTitle, SetActivePlan, setCategoryId } from "../../store/dashboardConfig/dashboardConfigSlice";
import '../../assets/scss/custom.scss';
import ReactMarkdown from "react-markdown";
import ReactDOMServer from 'react-dom/server';

// Header
import Headerpro from "../../components/partials/pro/headerstyle/header-pro";

// Sidebar
import Sidebar from "../../components/partials/dashboard/sidebarstyle/sidebar";

// Footer
import Footer from "../../components/partials/dashboard/footerstyle/footer";

// Subheader
import SubHeader from '../../components/partials/dashboard/headerstyle/sub-header'

// Loader and API
import Loader from "../../components/Loader";
import { findFirstSelectedGroupID } from "../../apis/Dashboard";
import { useDispatch, useSelector } from 'react-redux'
// import { setCategoryId, setGroupID, setPersonaTitle, setSelecetedToolkit, setToolkitId } from "../../store/dashboardConfig/dashboardConfigSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Default = memo(({sideBarData, setSideBarData, newPages, setNewPages}) => {
    const navigate                      = useNavigate()
    const dispatch                      = useDispatch()
    const location                      = useLocation();
    const { group, toolkit_id }         = useParams();
    const landingPage                   = useSelector(state => state.landingPage);
    const isFetchedAfterMount           = useSelector(state => state.isSideBarSet);
    const SideBar                       = useSelector(state => state.pages);
    const plandata                      = useSelector(state => state.perasonaData);
    const GroupID                       = useSelector(state => state.GroupID);
    const toolkitId                     = useSelector(state => state.toolkitId);
    const ActivePlan                    = useSelector(state => state.ActivePlan);

    const [stepClass, setStepClass]     = useState('');
    const [tutorial, setTutorial]       = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const Tour = ({}) => {
        const tour = useContext(ShepherdTourContext);
        useEffect(() => {
            const hasCompletedTour = sessionStorage.getItem("tourCompleted");
            if (landingPage?.type === 'welcome' && !hasCompletedTour) {
            tour?.start();
            }
        }, [landingPage]);
    
        return null;
    };

    const getPlans = useMutation({
        mutationFn: async (data) => {
            const response = await axios_inst.get('api/v4/get_plan', { params: data });
            return response?.data?.error ? response?.data : response?.data;
        }
    });
    let subHeader = null;
    let commanclass = "";
    const [newSteps, setNewSteps] = useState([]);

    switch (location.pathname) {
        case "/todo":
            subHeader = <SubHeader />;
        default:
            break;
    }
    useEffect(() => {
        const fetchData = async () => {
            const response = await getPlans.mutateAsync();
            if (response?.error === 'NOT_LOGGED_IN' || response?.error === 'DOES_NOT_HAVE_A_PLAN') {
                navigate('/');
                return;
            }

            setSideBarData(response?.data);
            setNewPages(response?.data?.new_pages);
            dispatch(setUserName(response?.data?.user));
            dispatch(setDashboardConfig(response?.data?.org));
            dispatch(setDashboardSidebar(response?.data?.pages));
            dispatch(setPersonaData(response?.data));
            dispatch(LandingPage(response?.data?.landing));
            dispatch(Other(response?.data?.other));
            dispatch(Plan_id(response?.plan_id));
            dispatch(setStatuDashboardSidebarLoaded(true));
            dispatch(IsLoading(false));
            dispatch(SetActivePlan(response?.data));
            localStorage.setItem('banner', response?.data?.pages?.Business?.banner);

            if (response && Object.keys(response).length === 0) {
                navigate('/');
                return;
            }

            const selectedItem = findFirstSelectedGroupID(response?.data.pages.Learn, group);
            if (selectedItem || group) {
                let category_id = group ? group : selectedItem.groupId;
                localStorage.setItem('Sub_id', category_id);

                dispatch(setPersonaTitle(getPersonaById(response?.data?.pages?.Learn, selectedItem.personaId)));
                dispatch(setCategoryId({
                    category_id: category_id,
                    persona_id: selectedItem.personaId
                }));
                (!toolkit_id) && navigate(`dashboard/${category_id}/advice`);
            }
            setIsLoading(false);
        };

        if (isFetchedAfterMount ) {
            setSideBarData( ActivePlan );
            if( ActivePlan?.error === 'NOT_LOGGED_IN' || ActivePlan?.error === 'DOES_NOT_HAVE_A_PLAN'  ) {
                navigate( '/' );
                return;
            }

            const selectedItem = findFirstSelectedGroupID(ActivePlan?.pages?.Learn, group);
            if ( selectedItem || group ) {
                let category_id = group ? group : selectedItem.groupId;
                localStorage.setItem('Sub_id', category_id);

                dispatch(setPersonaTitle( getPersonaById( ActivePlan?.pages?.Learn, selectedItem.personaId)));
                dispatch(setCategoryId({
                    category_id: category_id,
                    persona_id: selectedItem.personaId
                }));
                (!toolkit_id) && navigate( `dashboard/${category_id}/advice` )
            }
            setIsLoading(false);
        } else {
            // Call fetchData only when data needs to be fetched
            fetchData();
        }
    }, [isFetchedAfterMount]);

    const getPersonaById = (data, persona_id) => {
        return Object.values(data.categories)
            .filter(category => category.persona.id == persona_id)
            .map(category => category.persona)[0] || false;
    };

    const closeTour = () => {

        sessionStorage.setItem("tourCompleted", true);
        // Add any additional actions you want to perform when the tour closes
    };

    useEffect(() => {
        if (landingPage?.data?.length > 0 && landingPage.data[0].pages?.advice) {
            setTutorial(landingPage.data[0].pages.advice.tutorial);
        }
    }, [landingPage]);

    const steps = useMemo(() => {
        return tutorial.map((step, index) => {
            let content = "";

            // Only add image if it exists
            if (step.image) {
                content += `<img class="guruImage" src="${step.image}" alt="Guru Image" />`;
            }

            // Only add video if it exists
            if (step.video) {
                content += `<iframe class="w-100 vimeo-iframe" src="${step.video}" height="250px" frameborder="0" allowfullscreen></iframe>`;
            }

            // Only add title if it exists
            if (step.title) {
                content += `<h3 class="shepherd_title title">${step.title}</h3>`;
            }

            // Add step number separately
            content += `<h4 class="shepherd_title">Step ${index + 1}</h4>`;

            // Always add text (stacked at the end)
            content += ReactDOMServer.renderToString(<div class="shepherd_desc"><ReactMarkdown>{step.text}</ReactMarkdown></div>);

            return {
                text: content,
                buttons: [
                    ...(index > 0
                        ? [
                            {
                                type: "back",
                                classes: "shepherd-button-secondary",
                                text: "Back",
                            },
                        ]
                        : []),
                    {
                        type: index < tutorial.length - 1 ? "next" : "cancel",
                        text: index < tutorial.length - 1 ? "Next" : "Done"
                    },
                ],
                when: {
                    destroy: () => closeTour(),
                    show: () => {
                        document
                            .querySelector(".shepherd-modal-overlay-container")
                            .classList.add("shepherd-modal-is-visible");
                    },
                    cancel: () => closeTour(),
                },
            };
        });
    }, [tutorial]);


    useEffect(() => {
        if (steps.length > 0) {
            setNewSteps(steps);
        }
    }, [steps]);

    const tourOptions = useMemo(() => ({
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
        },
        useModalOverlay: true,
        when: {
            cancel: function () { },
        },
    }), []);

    const partnerButton = getPlans.data?.data
    return (
        <Fragment>
                    <ToastContainer />
                    <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
                        <Loader />
                        <Sidebar 
                            sideBarData     = {ActivePlan}
                            setSideBarData  = {setSideBarData}
                        />
                        <Tour stepClass={stepClass} />
                            {isLoading && !isFetchedAfterMount ? (
                <div className="d-flex min-vh-100 align-items-center justify-content-center">
                <div className="loader">
                    <div className="d-flex align-items-center flex-column">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            ) : (
                <>
                <main className="main-content">
                            <div className={`${commanclass} position-relative`}>
                                <Headerpro Button={partnerButton}/>
                                {subHeader}
                            </div>
                            <div className="container-fuild content-inner pb-0">
                                <Outlet></Outlet>
                            </div>
                            <Footer />
                        </main>
                </>
                        )}
                    </ShepherdTour>
        </Fragment>
    );
});

Default.displayName = "Default";
export default Default;