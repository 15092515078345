import React, { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query';
import axios_inst from '../src/utilities/axios';
import { GetConfigData } from './apis/Dashboard';

//scss
import "shepherd.js/dist/css/shepherd.css";
import "flatpickr/dist/flatpickr.css";
import 'choices.js/public/assets/styles/choices.min.css'
import "./assets/scss/hope-ui.scss"
import "./assets/scss/pro.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/rtl.scss"
import "./assets/scss/customizer.scss"
import "./assets/custom/scss/custom.scss"


function App({ children }) {

    const { data: platformData } = GetConfigData();

    function isValidJSON(storedData) {
        try {
            JSON.parse(storedData);
            return true;  // Valid JSON
        } catch (error) {
            return false;  // Invalid JSON
        }
    }

    useEffect(() => {
        if (platformData?.data){
            const { typography, logo_url, favicon_url, platform_name } = platformData.data;

            if (typography) {
                const {
                    primary,
                    secondary,
                    tertiary,
                    bg_color_1,
                    bg_color_2,
                    border,
                    heading_dark,
                    heading_light,
                    body_color,
                    front_family
                } = typography;

                // Set CSS variables dynamically
                const root = document.documentElement;
                root.style.setProperty('--bs-secondary-tint-90', bg_color_2); 
                root.style.setProperty('--bs-primary', bg_color_1);
                root.style.setProperty('--bs-secondary', bg_color_1);
                root.style.setProperty('--bs-btn-bg', bg_color_1);
                root.style.setProperty('--bs-primary-shade-20', bg_color_1);
                root.style.setProperty('--bs-btn-color', bg_color_2);
                root.style.setProperty('--bs-body-font-family', front_family);
                root.style.setProperty('--bs-heading-dark', heading_dark);
            }

            if (favicon_url) {
                const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = favicon_url;
                document.head.appendChild(link);
            }

            if (platform_name) {
                document.title = platform_name;
            }

            const logoElement = document.getElementById('mainsitelogo');
            if (logoElement && logo_url) {
                logoElement.src = logo_url;
            }
        }
    }, [platformData]); // Add platform as a dependency if it's necessary

    return (
        <>
            <div className="App">
                {children}
            </div>
        </>
    );
}

export default App;
