import React, { Fragment, memo } from 'react'
import Style from "../../assets/custom/css/breadcrumb.module.css";
import { Breadcrumb, Nav, Tab, Button } from "react-bootstrap";
import Card from "../../components/bootstrap/card";



const Coaching = memo(() => {
	return (
		<Fragment>
			<div className="container mt-5">
				<div className="bd-heading sticky-xl-top align-self-start">
				</div>
				<Card className="iq-document-card">
					<Tab.Container defaultActiveKey="first">
						<div className="d-flex justify-content-end">

						</div>
						<Tab.Content>
							<Tab.Pane
								eventKey="first"
								className=" bd-heading-1 fade show "
								id="content-Breadcrumb-prv"
								role="tabpanel"
								aria-labelledby="typo-output"
							>
								<div className="bd-example">
									<Breadcrumb>
										<Breadcrumb.Item as="li" href="/">
											Home
										</Breadcrumb.Item>
										<Breadcrumb.Item as="li" href="/">
											Library
										</Breadcrumb.Item>
										<Breadcrumb.Item as="li" active aria-current="page">
											Data
										</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Tab.Pane>
							<Tab.Pane
								eventKey="second"
								className=" bd-heading-1 fade show"
								id="content-Breadcrumb-code"
								role="tabpanel"
								aria-labelledby="typo-output"
							>
								<div className="section-block">
									<pre className="language-markup">
										<code className="language-markup">
											{`<div className="bd-example">
    <Breadcrumb>
        <Breadcrumb.Item as="li" active aria-current="page">Home</Breadcrumb.Item>
    </Breadcrumb>

    <Breadcrumb>
        <Breadcrumb.Item as="li" href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item as="li" active aria-current="page">Library</Breadcrumb.Item>
    </Breadcrumb>

    <Breadcrumb>
        <Breadcrumb.Item as="li" href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item as="li" href="/">Library</Breadcrumb.Item>
        <Breadcrumb.Item as="li" active aria-current="page">Data</Breadcrumb.Item>
    </Breadcrumb>

    <Breadcrumb className={Style.icon}>
        <Breadcrumb.Item as="li" href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item as="li" active aria-current="page">Library</Breadcrumb.Item>
    </Breadcrumb>

    <Breadcrumb className={Style.svgIcon}>
        <Breadcrumb.Item as="li" href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item as="li" active aria-current="page">Library</Breadcrumb.Item>
    </Breadcrumb>
</div>
`}
										</code>
									</pre>
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</Card>
				<div className="bg-white p-4 shadow">
					<div className="row">
						<div className="col-md-3">
							<img src="./images/placeholder.png" className="img-fluid w-100" alt="image" />
						</div>
						<div className="col-md-9 mt-md-0 mt-3">
							<p className="m-0">
								If you want to make things happen then it’s best to write them down. Provide the key things you want to
								commit to
								change
								about your business and we’ll discuss these and progress when you catch up with your guru.
							</p>
						</div>
					</div>
				</div>

				<div className="mt-4">
					<div className="table-responsive">
						<table className="table todo-list">
							<thead>
								<tr>
									<th className="text-white bg-success">To-do List</th>
									<th className="text-white bg-success">Status</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div className="d-flex align-items-center gap-3">
											<div className="flex-shrink-0 number-count">
												1
											</div>
											<div>
												<ul className="list-inline m-0 p-0 todo-list-nav">
													<li>
														To Do List
													</li>
													<li>
														Energy & Renewables
													</li>
													<li>
														<div className="text-success">Net Zero</div>
													</li>
												</ul>
												<h5 className="mt-2 mb-0">Assessing carbon offsetting options for residual emissions</h5>
											</div>
										</div>
									</td>
									<td>
										<div className="form-check form-check-custom">
											<input className="form-check-input" type="checkbox" value="" id="pending" />
											<label className="form-check-label" for="pending">
												Pending
											</label>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div className="d-flex align-items-center gap-3">
											<div className="flex-shrink-0 number-count">
												2
											</div>
											<div>
												<ul className="list-inline m-0 p-0 todo-list-nav">
													<li>
														To Do List
													</li>
													<li>
														Energy & Renewables
													</li>
													<li>
														<div className="text-success">Net Zero</div>
													</li>
												</ul>
												<h5 className="mt-2 mb-0 text-decoration-line-through">Assessing carbon offsetting options for residual emissions</h5>
											</div>
										</div>
									</td>
									<td>
										<div className="form-check form-check-custom">
											<input className="form-check-input" type="checkbox" value="" id="completed" checked />
											<label className="form-check-label" for="completed">
												Completed
											</label>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div className="d-flex align-items-center gap-3">
											<div className="flex-shrink-0 number-count">
												3
											</div>
											<div>
												<ul className="list-inline m-0 p-0 todo-list-nav">
													<li>
														To Do List
													</li>
													<li>
														Energy & Renewables
													</li>
													<li>
														<div className="text-success">Net Zero</div>
													</li>
												</ul>
												<h5 className="mt-2 mb-0">Exploring circular economy business models and product lifecycle approaches</h5>
											</div>
										</div>
									</td>
									<td>
										<div className="form-check form-check-custom form-check-in-progress">
											<input className="form-check-input" type="checkbox" value="" id="inProgress" checked />
											<label className="form-check-label" for="inProgress">
												In Progress
											</label>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div>
						<button className="btn btn-success">
							<span>
								<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path opacity="0.4"
										d="M12.889 0.666016H5.11119C2.27416 0.666016 0.666748 2.27342 0.666748 5.11046V12.8882C0.666748 15.7179 2.26675 17.3327 5.11119 17.3327H12.889C15.726 17.3327 17.3334 15.7179 17.3334 12.8882V5.11046C17.3334 2.27342 15.726 0.666016 12.889 0.666016Z"
										fill="#38B349" />
									<path
										d="M11.7671 9.58963H9.62468V11.7135C9.62468 12.0555 9.34504 12.3327 9.00008 12.3327C8.65512 12.3327 8.37548 12.0555 8.37548 11.7135V9.58963H6.23304C5.91127 9.5566 5.66675 9.2878 5.66675 8.96712C5.66675 8.64643 5.91127 8.37764 6.23304 8.3446H8.36877V6.22739C8.40209 5.90841 8.67323 5.66602 8.99672 5.66602C9.32021 5.66602 9.59136 5.90841 9.62468 6.22739V8.3446H11.7671C12.0889 8.37764 12.3334 8.64643 12.3334 8.96712C12.3334 9.2878 12.0889 9.5566 11.7671 9.58963V9.58963Z"
										fill="#38B349" />
								</svg>
							</span>
							<span className="font-size-14">
								Add
							</span>
						</button>
					</div>
				</div>
			</div>
		</Fragment>
	)
})

Coaching.displayName = "Coaching"
export default Coaching