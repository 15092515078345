import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import MailSupport from './mail-support';
import { useSelector } from 'react-redux';

export default function HowItWorks({ show, onHide, other }) {

    const steps = other?.platform_help?.steps || [];
    const [current, setCurrent] = useState(1);
    const [mailSupport, setMailSupport] = useState(false);

    return (
        <>
            <Modal show={show} onHide={onHide} centered size='md'>
                <Modal.Header className='r'>

                    <div className="w-100 text-center" style={{paddingTop:"32px"}}>
                        <ul className="d-flex justify-content-center iq-product-tracker mb-0" style={{ paddingLeft: "0" }} id="progressbar">
                            {steps.map((step, index) => (
                                <li
                                    key={index}
                                    className={`iq-tracker-position-0 ${current === index + 1 ? ' active ' : ''} ${current > index + 1 ? ' done ' : ''}`}
                                    data-step={index + 1}
                                >.</li>
                            ))}
                        </ul>
                    </div>
                    <div type="button" style={{paddingBottom:"60px"}} className="close" onClick={onHide}>
                        <span aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </span>
                    </div>

                </Modal.Header>

                <Modal.Body>
                    {steps.map((step, index) => (
                        <div key={index} className={`iq-product-tracker-card b-0 ${current === index + 1 ? ' show ' : ''}`}>
                            <h5 className='px-5 text-center mb-5'>{step.text}</h5>
                            <div className='d-flex justify-content-center img-content-mod'>
                                <img src={step.image} className="img-fluid" alt="img" loading='lazy' />
                            </div>
                            <div className='d-flex justify-content-center gap-4'>
                                {index > 0 && (
                                    <Button id="place-order" onClick={() => setCurrent(index)} variant="outline-primary d-block mt-3 prev">Back</Button>
                                )}
                                {index < steps.length - 1 ? (
                                    <Button id="place-order" onClick={() => setCurrent(index + 2)} variant="primary d-block mt-3 next">Next</Button>
                                ) : (
                                    <Button id="place-order" onClick={() => { onHide(); setMailSupport(true) }} variant="primary d-block mt-3 next">Finish</Button>
                                )}
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
            {/* <MailSupport show={mailSupport} onHide={() => setMailSupport(false)} /> */}
        </>
    );
}
