import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export const SummeryGenrate = ({ show, handleClose,handleSave }) => {
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Refresh Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body>This will replace your existing summary including any changes you’ve made</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
