import React, { StrictMode, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import axios_inst from './utilities/axios';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Router
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// Store
import { store } from './store/index';
import { Provider } from 'react-redux';

import Index from './views/index';
import AppRoutes from './router/index'; // Ensure this is the correct import

const AppWrapper = () => {
    const [sideBarData, setSideBarData] = useState([]); // Declare useState here
    const [isLoading, setIsLoading] = useState(true); // Loader state
    const [loaderColor, setLoaderColor] = useState('text-primary'); // Default color
    const [newPages, setNewPages] = useState([]); // Store API data
    const [activeKey, setActiveKey] = useState(null); // Add activeKey state

    
    const router = createBrowserRouter(
        [
            {
                path: '/',
                element: <Index />,
            },
            ...AppRoutes(sideBarData, setSideBarData, newPages, setNewPages, activeKey, setActiveKey), // Pass state to routes
        ],
        {
            basename: window?.iqonic_base_path !== undefined ? window.iqonic_base_path : process.env.PUBLIC_URL
        }
    );

    const queryClient = new QueryClient();

    useEffect(() => {
        const typography = JSON.parse(localStorage?.getItem('typography'));
        if (typography && typography?.typography?.bg_color_1) {
            setLoaderColor(typography?.typography?.bg_color_1);
        }

        // Simulate loading time
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000); // 3 seconds

        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex min-vh-100 align-items-center justify-content-center">
                <div className="loader">
                    <div className="d-flex align-items-center flex-column">
                        <div className="spinner-border" style={{ color: loaderColor }} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <App>
                    <RouterProvider router={router} />
                </App>
            </Provider>
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
};

ReactDOM.createRoot(document.getElementById('root')).render(
    <>
        <AppWrapper />
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals.console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
