import React, { memo, useState, Fragment, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { AddNewToolkit } from '../store/dashboardConfig/dashboardConfigSlice';
import EventSourceObj from '../utilities/EventSource';
import Vimeo from '@u-wave/react-vimeo';
import axios_inst from '../utilities/axios';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import { GetDocument } from '../apis/Dashboard';

const ButtonWidget = memo((props) => {
  const [modalContent, setModalContent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [surveyData, setSurveyData] = useState(null);
  const [formId, setFormId] = useState(null);
  const [answerId, setAnswerId] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal && formId && !surveyData) {
      fetchFormData();
    }
  }, [showModal, formId, surveyData]);

  const fetchFormData = async () => {
    setLoading(true);
    try {
      const response = await axios_inst.get(`api/v3/forms?id=${formId}&answers=start`);
      const answerId = response?.data?.form?.answers[0]?.id;
      setAnswerId(answerId);
      if (answerId) {
        const formResponse = await axios_inst.get(`api/v3/forms?id=${formId}&answers=${answerId}`);
        setSurveyData(formResponse?.data?.form?.data || null);
      }
    } catch (error) {
      console.error('Error fetching form data:', error);
    }
    setLoading(false);
  };

  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalContent(null);
    setSurveyData(null);
    setFormId(null);
    setAnswerId(null);
    setDocumentData(null);
  };

  const handleChatButtonClick = () => {
    if (props.buttonType === 'chat') {
      props.onChatButtonClick(props.persona, props.message);
    }
  };

  const handleChatPanelButtonClick = async () => {
    try {
      props.updateChatPanel(props.persona, props.message);
      return;
      const toolkitId = props.source?.toolkit_id || '';
      const eventSourceUrl = `api/v3/chat?persona=${props.persona}&group_id=${props?.category_id}&toolkit_id=${toolkitId}&message=${encodeURIComponent(props.message)}`;
      let event = EventSourceObj(eventSourceUrl);

      event.addEventListener('message', (eventData) => {
        let eventJsonData = JSON.parse(eventData.data);
        let { stream, finished, newDocument } = eventJsonData;

        if (newDocument) {
          const startTime = Math.floor(Date.now() / 1000);
          const finishTime = startTime + 100;
          dispatch(AddNewToolkit({
            documents: [newDocument],
            finish_time: finishTime,
            start_time: startTime
          }));
        }
        if (finished) {
          event.close();
        }

        if (stream) {
          props.updateChatPanel(eventJsonData);
        }
      });

      event.addEventListener('error', (eventData) => {
        event.close();
      }, { once: true });

    } catch (error) {
      console.error('Error updating chat panel:', error);
    }
  };

  const renderVideo = () => {
    if (props.videoUrl.includes('vimeo')) {
      const videoId = props.videoUrl.split('/').pop();
      return (
        <div className="iq-partner-modal">
          <Vimeo
            video={videoId}
            autoplay={props.autoplay}
            width="100%"
            height="360"
          />
        </div>
      );
    } else if (props.videoUrl.includes('youtube')) {
      const videoId = props.videoUrl.split('v=')[1];
      const autoplayParam = props.autoplay ? '&autoplay=1' : '';
      return (
        <iframe
          width="100%"
          height="360"
          src={`https://www.youtube.com/embed/${videoId}?rel=0${autoplayParam}`}
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      );
    } else {
      return (
        <video controls width="100%" autoPlay={props.autoplay}>
          <source src={props.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
  };

  const handlePopup = (url) => {
    window.open(url, 'popup', 'width=600,height=600,scrollbars=no,resizable=no');
  };

  const handleSurveyComplete = (survey) => {
    axios_inst.post(`api/v3/forms?id=${formId}&answers=${answerId}`, survey.data)
      .then(response => {
        const updatedResponse = { ...response.data, data: survey.data };
        console.log('Survey results saved:', updatedResponse);
      })
      .catch(error => console.error('Error saving survey results:', error));
  };

  const handleDocumentView = async () => {
    setLoading(true);
    try {
      const response = await GetDocument(props.document_id);
      setDocumentData(response);
      handleShowModal("document");
    } catch (error) {
      console.error('Error fetching document:', error);
    }
    setLoading(false);
  };

  const renderButton = () => {
    switch (props.buttonType) {
      case 'link':
        return (
          <Link to={props.url} target={props.target} className="text-primary text-decoration-underline me-2">
            {props.buttonText}
          </Link>
        );
      case 'modal':
        return (
          <Button variant="primary" onClick={() => handleShowModal(props.modalContent)} className="me-2">
            {props.buttonText}
          </Button>
        );
      case 'videoModal':
        return (
          <Button
            variant="primary"
            onClick={() => handleShowModal("video")}
            data-video={props.videoUrl}
            className="me-2"
          >
            {props.buttonText}
          </Button>
        );
      case 'chat':
        if (props.target === 'panel') {
          return (
            <Button variant="primary" onClick={handleChatPanelButtonClick} className="me-2">
              {props.buttonText}
            </Button>
          );
        } else {
          return (
            <Button variant="primary" onClick={handleChatButtonClick} className="me-2">
              {props.buttonText}
            </Button>
          );
        }
      case 'url':
        if (props.target === 'tab') {
          return (
            <a href={props.url} target="_blank" rel="noopener noreferrer" className="btn btn-primary me-2">
              {props.buttonText}
            </a>
          );
        } else if (props.target === 'popup') {
          return (
            <Button variant="primary" onClick={() => handlePopup(props.url)} className="me-2">
              {props.buttonText}
            </Button>
          );
        }
        break;
      case 'video':
        return (
          <Button
            variant="primary"
            onClick={() => handleShowModal("video")}
            data-video={props.videoUrl}
            className="me-2"
          >
            {props.buttonText}
          </Button>
        );
      case 'form':
        if (props.target === 'popup') {
          return (
            <Button variant="primary" onClick={() => { setFormId(props.id); handleShowModal("form"); }} className="me-2">
              {props.buttonText}
            </Button>
          );
        }
        break;
      case 'document_view':
        return (
          <Button variant="primary" onClick={handleDocumentView} className="me-2">
            {props.buttonText}
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {renderButton()}
      {props.target !== "panel" && (
        <Modal show={showModal} onHide={handleCloseModal} animation={false} centered>
          <Modal.Body>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Spinner animation="border" variant="primary"/>
              </div>
            ) : (
              modalContent === "form" && surveyData && <Survey model={new Model(surveyData)} onComplete={handleSurveyComplete} />
            )}
            {modalContent === "video" && renderVideo()}
            {modalContent === "document" && documentData && (
              <div className="document-content">
                {/* Render document content here */}
                <pre>{JSON.stringify(documentData, null, 2)}</pre>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  );
});

export default ButtonWidget;