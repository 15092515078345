import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    config: {
        name: false,
        logo: false,
        url: false,
        description: false,
        colours: {
            primary: "#005826",
            secondary: "#38B349"
        }
    },
    pages: {
        "Learn": {
            "categories": false,
            "tabs": false
        },
        "Impact": [],
        "Community": []
    },
    isSideBarSet: false,
    GroupID: -1,
    rowData: null,
    groupStatusUpdate: null,
    inputOption: [],
    isLoading: false,
    plan_id: "",
    welcomepage: false,
    landingPage: "",
    perasonaData: {},
    category_id: {
        category_id: "",
        persona_id: ""
    },
    chatTitel: "",
    toolkitId: '',
    toolkitDetails: {},
    className: "",
    selecetedToolkit: "",
    nav_id: '',
    selectdids:"",
    subitemsIds:'',
    connectmessage:'',
    connectmessagestatus:false,
    mobileChatModel: false,
    GroupIds: null,
    isOther:null,
    ActivePlan: false,
    toolktStatus: false,
    addNewToolkit: {},
    theme : 'light',
    chatFullScreen: true
}
export const dashboardConfigSlice = createSlice({
    name: 'dashboardConfig',
    initialState,
    reducers: {
        setDashboardConfig: (state, action) => {
            state.config = action.payload
        },
        setDashboardSidebar: (state, action) => {
            state.pages = action.payload
        },
        setStatuDashboardSidebarLoaded: (state, action) => {
            state.isSideBarSet = action.payload
        },
        Other: (state, action) => {
            state.isOther = action.payload
        },
        setGroupID: (state, action) => {
            state.GroupID = action.payload
        },
        setRowData: (state, action) => {
            state.rowData = action.payload;
        },
        GroupStatusUpdate: (state, action) => {
            state.groupStatusUpdate = action.payload;
        },
        InputOption: (state, action) => {
            state.inputOption = action.payload;
        },
        IsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        Plan_id: (state, action) => {
            state.plan_id = action.payload;
        },
        WelcomePage: (state, action) => {
            state.welcomepage = action.payload;
        },
        LandingPage: (state, action) => {
            state.landingPage = action.payload;
        },
        setPersonaData: (state, action) => {
            state.perasonaData = action.payload;
        },
        setCategoryId: (state, action) => {
            state.category_id = action.payload;
        },
        setPersonaTitle: (state, action) => {
            state.chatTitel = action.payload;
        },
        setToolkitId: (state, action) => {
            state.toolkitId = action.payload;
        },
        setToolkitDetails: (state, action) => {
            state.toolkitDetails = action.payload;
        },
        ClassName: (state, action) => {
            state.className = action.payload;
        },
        setSelecetedToolkit: (state, action) => {
            state.selecetedToolkit = action.payload;
        },
        Nav_Id: (state, action) => {
            state.nav_id = action.payload;
        },
        SelecetedId: (state, action) => {
            state.selectdids = action.payload;
        },
        SubitemsIds: (state, action) => {
            state.subitemsIds = action.payload;
        },
        ConnectMessage: (state, action) => {
            state.connectmessage = action.payload;
        },
        ConnectMessageStatus: (state, action) => {
            state.connectmessagestatus = action.payload;
        },
        MobileChatModel: (state, action) => {
            state.mobileChatModel = action.payload;
        },
        setGroupIDs: (state, action) => {
            state.GroupIds = action.payload;
        },
        setUserName: (state, action) => {
            state.username = action.payload;
        },
        SetActivePlan: (state, action) => {
            state.ActivePlan = action.payload;
        },
        ToolktStatus: (state, action) => {
            state.toolktStatus = action.payload;
        },
        AddNewToolkit: (state, action) => {
            state.addNewToolkit = action.payload;
        },
        ThemeColour : (state , action) => {
            state.theme = action.payload;
        },
        ChatFullScreen : (state , action) => {
            state.chatFullScreen = action.payload;
        }
    }
})


export const { setDashboardConfig, setDashboardSidebar, setStatuDashboardSidebarLoaded, setGroupID, setRowData, GroupStatusUpdate, InputOption, IsLoading, Plan_id, WelcomePage, LandingPage, setPersonaData, setCategoryId, setPersonaTitle, setToolkitId, setToolkitDetails, ClassName, setSelecetedToolkit, Nav_Id, SelecetedId,SubitemsIds,ConnectMessage,ConnectMessageStatus,MobileChatModel,setGroupIDs,Other,setUserName ,SetActivePlan,ToolktStatus,AddNewToolkit, activeGroups, ThemeColour, ChatFullScreen} = dashboardConfigSlice.actions

export default dashboardConfigSlice.reducer