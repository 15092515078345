import { memo, Fragment, useEffect, useState } from 'react'

// react-bootstrap
import { Row,Col,Tab,Nav } from 'react-bootstrap'

// react-router
import { Link, useParams } from 'react-router-dom'

// component
import  Card  from '../../components/bootstrap/card';
// import ChoicesJs from '../choices'
import EventSourceObj from '../../utilities/EventSource';
import Markdown from '../partials/components/markdowan';

import Vimeo from '@u-wave/react-vimeo';
import ButtonWidget from '../button-widget';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectMessage, ConnectMessageStatus, ChatFullScreen } from '../../store/dashboardConfig/dashboardConfigSlice';


const NewsWidget = memo(() => {

  const { group } = useParams();
    const [summary, setSummary] = useState('');
    const [media, setMedia] = useState(null);
    const [loading, setLoading] = useState(true);
    const [prevGroupId, setPrevGroupId] = useState(null);
    const [visibleNews, setVisibleNews] = useState([]);
    const [expandedDocs, setExpandedDocs] = useState({});
    const [selectedType, setSelectedType] = useState('Fillter');
    const [buttons, setButtons] = useState([]);
    const [videoUrl, setVideoUrl] = useState(null);
    const dispatch = useDispatch();
    const category = useSelector(state => state.category_id);

    const handleChatButtonClick = (persona, message) => {
            dispatch(ChatFullScreen(true));
            dispatch(ConnectMessage(message));
            dispatch(ConnectMessageStatus(false));
            
        };
        
        const updateChatPanel = (persona, message) => {
            dispatch(ChatFullScreen(false));
            dispatch(ConnectMessage(message));
            dispatch(ConnectMessageStatus(false));
        };

    useEffect(() => {
      let newsdata;
      if (prevGroupId !== group) {
          // Reset states when group changes
          setVisibleNews([]);
          setSummary('');
          setMedia(null);
          setLoading(true);

          newsdata = EventSourceObj(`api/v3/get_content?type=news&group_id=${group}`);
          setPrevGroupId(group);
      }
      if (!newsdata) return;

      const messageHandler = (newsData) => {
          const newsJsonData = JSON.parse(newsData.data);
          
          if (newsJsonData.finished) {
              setLoading(false);
              newsdata.close();
              return;
          }

          if(newsJsonData?.type === 'summary'){
                if(newsJsonData?.content?.length){
                    newsJsonData?.content.forEach(item => {
                        if (item.type === 'video') {
                            setVideoUrl(item.url);
                        } else {
                            setButtons((prevbuttons) => [...prevbuttons, item]);
                        }
                    });
                }else{
                    setSummary((prevSummary) => prevSummary + newsJsonData?.stream);
                }
            } else if (newsJsonData.media) {
            setMedia(newsJsonData.media);
          } else {
            // Check for duplicates using a unique identifier
            setVisibleNews(prevEvents => {
                const eventId = newsJsonData.start_time?.timestamp || newsJsonData.id;
                const isDuplicate = prevEvents.some(event => 
                    (event.start_time?.timestamp || event.id) === eventId
                );
                
                if (isDuplicate) {
                    return prevEvents;
                }
                return [...prevEvents, newsJsonData];
            });
            setLoading(false);
          }
      };
      newsdata.addEventListener('message', messageHandler);
      newsdata.addEventListener('error', (error) => {
          console.error('newsdata error:', error);
          newsdata.close();
      });

      return () => {
          newsdata?.close();
      };
    }, [group]);
    
    // Add a helper function to format the date
const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle missing dates gracefully
    const date = new Date(dateString); // Parse the date string
    if (isNaN(date)) return ''; // Handle invalid dates
    // Format the date as MM/DD/YYYY
    return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
};

  // Handle filter change
  const handleFilterChange = (e) => {
    setSelectedType(e.target.value);
};

// Prepare options for ChoicesJs component
const options = [
    { value: 'Fillter', label: 'Fillter' },
    ...Array.from(
        new Map(
            visibleNews.map((news) => [news.platform, { value: news.platform, label: news.platform }])
        ).values()
    ),
];

// Filter the eventResponce based on selectedType
const filteredData = visibleNews.filter((value) => 
selectedType === 'Fillter' || selectedType === value.platform
);

const fallbackImage = "https://wallpapers.com/images/featured/blank-white-7sn5o1woonmklx1h.jpg";

const handleImageError = (event) => {
    event.target.src = fallbackImage;
  };

const skeletonItems = new Array(4).fill(null);

  return (
    <Fragment>
        {summary ? (
                <div className="d-flex gap-3 mb-5 flex-wrap flex-md-nowrap">
                    <div>
                        <img src={media?.image} alt="Avatar" className="img-fluid avatar avatar-130 avatar-sm-60" />
                    </div>
                    <div className='iq-accordian w-100 broder-0'>
                        <Card className="mb-0 h-100 p-2">
                            <Markdown content={summary} />
                            {buttons?.length 
                                ? ( 
                                    <div>
                                    {
                                        buttons.map((item, index) => (
                                            <ButtonWidget
                                                key={`event-widget-button-${index}`}
                                                buttonType={item?.action}
                                                buttonText={item?.text}
                                                url={item?.url}
                                                target={item?.target}
                                                videoUrl={item?.url}
                                                persona={category?.persona_id}
                                                category_id={category?.category_id}
                                                message={item?.message}
                                                source={item?.source}
                                                autoplay={item?.autoplay}
                                                id={`event-widget-button-${index}`} // Ensure the id prop is passed here
                                                onChatButtonClick={handleChatButtonClick}
                                                updateChatPanel={updateChatPanel}
                                            />
                                        ))
                                    }
                                    </div>
                                )
                                : ''
                            }
                            {videoUrl && (
                                <Vimeo
                                    video={videoUrl}
                                    autoplay={false}
                                    width="100%"
                                />
                            )}
                        </Card>
                    </div>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '2rem' }}>
                    <div className="iq-img-skeleton iq-skeleton" style={{ width: '130px', height: '130px', borderRadius: '50%' }}></div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="iq-skeleton" style={{ height: '20px', width: '60%', borderRadius: '4px' }}></div>
                        <div className="iq-skeleton" style={{ height: '20px', width: '90%', borderRadius: '4px' }}></div>
                        <div className="iq-skeleton" style={{ height: '20px', width: '75%', borderRadius: '4px' }}></div>
                    </div>
                </div>
            )}

            <Tab.Container defaultActiveKey="grid">
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap gap-3 gap-md-0'>
                    <div className="filter-select">
                        {/* <ChoicesJs select="single" value={selectedType} options={options} className="iq-select" onChange={handleFilterChange} /> */}
                    </div>
                    <Nav className='nav-iconly gap-2 flex-nowrap overflow-auto nav nav-tabs' data-toggle="slider-tab" role="tablist">
                        <Nav.Item className='d-none d-md-block'   role="presentation">
                            <Nav.Link className="iq-nav-tab nav-link px-3" eventKey="grid" data-bs-toggle="tab" data-bs-target="#grid" type="button" role="tab" aria-controls="grid" aria-selected="true">
                                <div className='d-flex gap-2 align-items-center'>
                                    Grid View
                                    <span className="btn-inner">
                                            <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z" fill="currentColor"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item  role="presentation" className='d-none d-md-block'>
                            <Nav.Link eventKey="list" className="iq-nav-tab nav-link px-3" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">
                                <div className='d-flex gap-2 align-items-center'>
                                    List View
                                    <span className="btn-inner">
                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z" fill="currentColor"></path>
                                            <path opacity="0.4" d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z" fill="currentColor"></path>
                                            <path d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z" fill="currentColor"></path>
                                            <path d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <p className="blog-line-around my-5"><span className="blog-line-day px-4 py-2">News</span></p>
                <Tab.Content className="mt-2">
                  <Tab.Pane  eventKey="grid" variant=" fade show active" id="grid" role="tabpanel" aria-labelledby="pills-grid-tab1">
                    <div className='iq-product-list-view iq-product-event-view'>
                      <Row>
                        {
                          loading ? (
                            skeletonItems.map((_, index) => (
                                <Col md={6} className="mb-4">
                                    <Card className="inner-card h-100">
                                        <Card.Body>
                                            <div className="d-flex align-items-start">
                                            <div className="iq-img-skeleton iq-skeleton rounded me-3" style={{ width: '80px', height: '80px' }}></div>
                                            <div className="flex-grow-1">
                                                <div className='d-flex align-items-center gap-3 mb-2'>
                                                <div className="iq-img-skeleton iq-skeleton rounded-pill" style={{ width: '40px', height: '40px' }}></div>
                                                <div className="iq-skeleton" style={{ height: '16px', width: '60%' }}></div></div>
                                                <div className="iq-skeleton" style={{ height: '20px', width: '80%' }}></div>
                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                    <div className="iq-skeleton" style={{ height: '16px', width: '30%' }}></div>
                                                    <div className="iq-skeleton" style={{ height: '30px', width: '100px' }}></div>
                                                </div>
                                            </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                          ) : filteredData.length > 0 ? (
                            filteredData.map((value, gridIndex) => {
                              return(
                                <Col md={6} key={gridIndex} className="mb-4">
                                  <Card className="inner-card h-100">
                                    <Card.Body>
                                      <div className="d-flex align-items-start flex-wrap flex-lg-nowrap">
                                        <img src={value.logo} className="img-fluid iq-news-image rounded me-3 h-100 iq-video-image  mb-3 mb-md-0" alt='app-image' loading='lazy' onError={handleImageError}/>
                                        <div className="flex-grow-1">
                                            <div className='d-flex align-items-center gap-3 mb-2'>
                                                {
                                                    value.source?.image && 
                                                    <img src={value.source?.image} alt="file-manager" className="img-fluid h-100 iq-images iq-video-list-image rounded-pill" onError={handleImageError}/>
                                                }
                                                {
                                                    value.source?.name && 
                                                    <p className="mb-0">{value.source?.name}</p>
                                                }
                                            </div>
                                            <h5 className='text-ellipsis short-2'>{value?.title}</h5> 
                                            <div className='d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap gap-3'>
                                                {value.date?.text && <p className="mb-0">{formatDate(value.date.text)}</p>}
                                                <Link to={value.links?.['Read more']} className='btn btn-primary' target="_blank">Read More</Link>
                                            </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              )
                            })
                          ) : (
                            <div>No News Found</div>
                          )
                      }
                      </Row>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="list" className="fade" id="list" role="tabpanel" aria-labelledby="pills-list-tab1">
                    <div className='iq-product-list-view'>
                      {
                        loading ? (
                            skeletonItems.map((_, index) => (
                            <Card className="inner-card" key={index}>
                                <Card.Body>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex justify-content-between align-items-center gap-3'>
                                            <div className="iq-img-skeleton iq-skeleton rounded me-3" style={{ width: '80px', height: '80px' }}></div>
                                            <div className="flex-grow-1">
                                                <div className='d-flex align-items-center gap-3 mb-2'>
                                                    <div className="iq-img-skeleton iq-skeleton rounded-pill" style={{ width: '40px', height: '40px' }}></div>
                                                    <div className="iq-skeleton" style={{ height: '16px', width: '60%' }}></div>
                                                </div>
                                                <div className="iq-skeleton" style={{ height: '20px', width: '80%' }}></div>
                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                    <div className="iq-skeleton" style={{ height: '16px', width: '30%' }}></div>
                                                    <div className="iq-skeleton" style={{ height: '30px', width: '100px' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            ))
                        ) : filteredData.length > 0 ? (
                            filteredData.map((value, IndexList) => {
                                return(
                                    <Card className="inner-card" key={IndexList}>
                                        <Card.Body>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='d-flex justify-content-between align-items-center gap-3'>
                                                <img src={value.logo} className="img-fluid iq-product-img rounded me-3" alt='app-image' loading='lazy' onError={handleImageError}/>
                                                    <div className="flex-grow-1">
                                                        <div className='d-flex align-items-center gap-3 mb-2'>
                                                            {
                                                                value.source?.image &&
                                                                <img src={value.source?.image} alt="file-manager" className="img-fluid h-100 iq-images iq-video-list-image rounded-pill" onError={handleImageError}/>
                                                            }
                                                            {
                                                                value.source?.name &&
                                                                <p className="mb-0">{value.source?.name}</p>
                                                            }
                                                        </div>
                                                        <h5>{value?.title}</h5>
                                                        {
                                                            value.date?.text && <p className="mb-0">{formatDate(value.date.text)}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <Link to={value.links?.['Read more']} className='btn btn-primary' target="_blank">Read More</Link>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            })
                        ) : (
                            <h1>No News Found</h1>
                        )
                      }
                    </div>
                  </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
    </Fragment>
  )
})

NewsWidget.displayName = 'NewsWidget'
export default NewsWidget