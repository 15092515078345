import React,{memo,Fragment,useState} from 'react'

// react-bootstrap
import { Modal,Button,Form,FloatingLabel } from 'react-bootstrap'

const ImpactModal = memo(() => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Fragment>
        <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <h5>Change Prompt</h5>
        <FloatingLabel controlId="floatingTextarea2" label="Enter details">
        <Form.Control
          as="textarea"
          placeholder="Enter details"
          style={{ height: '200px' }}
        />
      </FloatingLabel>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

    </Fragment>
  )
})

ImpactModal.displayName = "ImpactModal"
export default ImpactModal