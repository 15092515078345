import { memo, Fragment, useState, useEffect, useRef, useCallback, useContext, useMemo } from 'react'

// react-bootstrap
import { Row,Col,Button,Tab,Nav, ButtonGroup } from 'react-bootstrap'

//react router dom
import { useParams } from 'react-router-dom';

// plugin
import { GoogleMap,Marker,useLoadScript,InfoWindow } from "@react-google-maps/api";
import Vimeo from '@u-wave/react-vimeo';

// components  
import { LocationSupport } from '../modals';
import Card from '../../components/bootstrap/card';
import { Sweetalert } from '../../components/error-alert';
import EventSourceObj from '../../utilities/EventSource';
import Noservice from '../../assets/images/no_service.png'
import ChoicesJs from '../choices'
import Markdown from '../partials/components/markdowan';
import ButtonWidget from '../button-widget';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectMessage, ConnectMessageStatus, ChatFullScreen } from '../../store/dashboardConfig/dashboardConfigSlice';
const ServiceWidget = memo(() => {
    const [locationSupport, setLocationSupport] = useState(false)
    const [summary, setSummary] = useState('');
    const [media, setMedia] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedType, setSelectedType] = useState('Fillter');
    const mapRef = useRef(null);
    const [mapCenter, setMapCenter] = useState({ lat: 54.0, lng: -2.0 });
    const [mapZoom, setMapZoom] = useState(6);
    const [prevGroupId, setPrevGroupId] = useState(null);
    const [visibleServices, setVisibleServices] = useState([]);
    const [expandedDocs, setExpandedDocs] = useState({});
    const [buttons, setButtons] = useState([]);
    const [videoUrl, setVideoUrl] = useState(null);
    const dispatch = useDispatch();
    const category = useSelector(state => state.category_id);

    const { group } = useParams();

    const handleChatButtonClick = (persona, message) => {
        dispatch(ChatFullScreen(true));
        dispatch(ConnectMessage(message));
        dispatch(ConnectMessageStatus(false));
        
    };
    
    const updateChatPanel = (persona, message) => {
        dispatch(ChatFullScreen(false));
        dispatch(ConnectMessage(message));
        dispatch(ConnectMessageStatus(false));
    };


    useEffect(() => {
    let eventdata;
    if (prevGroupId !== group) {
        // Reset states when group changes
        setVisibleServices([]);
        setSummary('');
        setMedia(null);
        setLoading(true);
        setButtons([]); // Reset buttons state
        dispatch(ConnectMessage('')); // Reset chat message state
        dispatch(ConnectMessageStatus(false)); // Reset chat message status

        eventdata = EventSourceObj(`api/v3/get_content?type=services&group_id=${group}`);
        setPrevGroupId(group);
    }
    if (!eventdata) return;

    const messageHandler = (eventData) => {
        const eventJsonData = JSON.parse(eventData.data);
        
        if (eventJsonData.finished) {
            setLoading(false);
            eventdata.close();
            return;
        }

        if(eventJsonData?.type === 'summary'){
            if(eventJsonData?.content?.length){
                eventJsonData?.content.forEach(item => {
                    if (item.type === 'video') {
                        setVideoUrl(item.url);
                    } else {
                        setButtons((prevbuttons) => [...prevbuttons, item]);
                    }
                });
            }else{
                setSummary((prevSummary) => prevSummary + eventJsonData?.stream);
            }
          } else if (eventJsonData.media) {
            setMedia(eventJsonData.media);
          } else {
            // Check for duplicates using a unique identifier
            setVisibleServices(prevServices => {
                const serviceId = eventJsonData.id;
                const isDuplicate = prevServices.some(service => 
                    service.id === serviceId
                );
                
                if (isDuplicate) {
                    return prevServices;
                }
                return [...prevServices, eventJsonData];
            });
            setLoading(false);
        }
    };

    eventdata.addEventListener('message', messageHandler);
    eventdata.addEventListener('error', (error) => {
        console.error('eventdata error:', error);
        eventdata.close();
    });

    return () => {
        eventdata?.close();
    };
}, [group]);

   

    // Handle filter change
    const handleFilterChange = (e) => {
        setSelectedType(e.target.value);
    };

    // Prepare options for ChoicesJs component
    const options = useMemo(() => [
        { value: 'Fillter', label: 'Fillter' },
        ...Array.from(
            new Map(
                visibleServices.map((event) => [event.type, { value: event.type, label: event.type }])
            ).values()
        ),
    ], [visibleServices]);

  // Filter the eventResponce based on selectedType
  const filteredData = visibleServices.filter((value) => 
    selectedType === 'Fillter' || selectedType === value.type
);

const [activeMarker, setActiveMarker] = useState(null);
 // Use useRef to track if we're currently updating
 const isUpdating = useRef(false);

 // Memoize the map load handler
 const onMapLoad = useCallback((map) => {
     mapRef.current = map;
 }, []);

 // Memoize the map change handler with debouncing
 const onMapChange = useCallback(() => {
     if (mapRef.current && !isUpdating.current) {
         isUpdating.current = true;
         
         // Use setTimeout to debounce the updates
         setTimeout(() => {
             const center = mapRef.current.getCenter();
             setMapCenter({ 
                 lat: center.lat(), 
                 lng: center.lng() 
             });
             setMapZoom(mapRef.current.getZoom());
             isUpdating.current = false;
         }, 100);
     }
 }, []);

 // Memoize the marker click handler
 const handleActiveMarker = useCallback((id) => {
     setActiveMarker(prev => prev === id ? null : id);
 }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBrPJHKx7Iz5el50c92W9NNg3mK4fnMyXk" // Add your API key
  });

  const skeletonItems = new Array(4).fill(null);

  // Toggle description expansion
  const handleToggleDescription = (id) => {
        setExpandedDocs(prev => ({
            ...prev,
            [id]: !prev[id], // Toggle state for the specific id
        }));
    };

    const isDescriptionLong = (description) => {
        return description && description.length > 100;
    };


    return (
        <Fragment>
            {summary ? (
                <div className="d-flex gap-3 mb-5 flex-wrap flex-md-nowrap">
                    <div>
                        <img src={media?.image} alt="Avatar" className="img-fluid avatar avatar-130 avatar-sm-60" />
                    </div>
                    <div className='iq-accordian w-100 broder-0'>
                        <Card className="mb-0 h-100 p-2">
                            <Markdown content={summary} />
                            {buttons?.length 
                                ? ( 
                                    <div>
                                    {
                                        buttons.map((item, index) => (
                                            <ButtonWidget
                                                key={`service-widget-button-${index}`}
                                                buttonType={item?.action}
                                                buttonText={item?.text}
                                                url={item?.url}
                                                target={item?.target}
                                                videoUrl={item?.url}
                                                persona={category?.persona_id}
                                                category_id={category?.category_id}
                                                message={item?.message}
                                                source={item?.source}
                                                autoplay={item?.autoplay}
                                                id={`service-widget-button-${index}`} // Ensure the id prop is passed here
                                                onChatButtonClick={handleChatButtonClick}
                                                updateChatPanel={updateChatPanel}
                                            />
                                        ))
                                    }
                                    </div>
                                )
                                : ''
                            }
                            {videoUrl && (
                                <Vimeo
                                    video={videoUrl}
                                    autoplay={false}
                                    width="100%"
                                />
                            )}
                        </Card>
                    </div>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '2rem' }}>
                    <div className="iq-img-skeleton iq-skeleton" style={{ width: '130px', height: '130px', borderRadius: '50%' }}></div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="iq-skeleton" style={{ height: '20px', width: '60%', borderRadius: '4px' }}></div>
                        <div className="iq-skeleton" style={{ height: '20px', width: '90%', borderRadius: '4px' }}></div>
                        <div className="iq-skeleton" style={{ height: '20px', width: '75%', borderRadius: '4px' }}></div>
                    </div>
                </div>
            )}
                 
                    <Tab.Container defaultActiveKey="grid"> 
                        <div className='d-flex justify-content-between flex-wrap flex-md-nowrap gap-3 gap-md-0'>
                            <div className="filter-select">
                                <ChoicesJs select="single" value={selectedType} options={options} className="iq-select" onChange={handleFilterChange} />
                            </div>
                            <Nav className='nav-iconly gap-2 flex-nowrap overflow-auto nav nav-tabs' data-toggle="slider-tab" role="tablist">
                                <Nav.Item   role="presentation">
                                    <Nav.Link className="iq-nav-tab nav-link px-3" eventKey="grid" data-bs-toggle="tab" data-bs-target="#grid" type="button" role="tab" aria-controls="grid" aria-selected="true">
                                        <div className='d-flex gap-2 align-items-center'>
                                            Grid View
                                            <span className="btn-inner">
                                                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z" fill="currentColor"></path>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item  role="presentation">
                                    <Nav.Link eventKey="list" className="iq-nav-tab nav-link px-3" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">
                                        <div className='d-flex gap-2 align-items-center'>
                                            List View
                                            <span className="btn-inner">
                                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z" fill="currentColor"></path>
                                                    <path opacity="0.4" d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z" fill="currentColor"></path>
                                                    <path d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z" fill="currentColor"></path>
                                                    <path d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item  role="presentation" >
                                    <Nav.Link eventKey="map" className="iq-nav-tab nav-link px-3" id="map-tab" data-bs-toggle="tab" data-bs-target="#map" type="button" role="tab" aria-controls="map" aria-selected="false">
                                        <div className='d-flex gap-2 align-items-center'>
                                            Map View
                                            <span className="btn-inner">
                                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-20" width="32" height="32" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M3.5 10.3178C3.5 5.71789 7.34388 2 11.9934 2C16.6561 2 20.5 5.71789 20.5 10.3178C20.5 12.6357 19.657 14.7876 18.2695 16.6116C16.7388 18.6235 14.8522 20.3765 12.7285 21.7524C12.2425 22.0704 11.8039 22.0944 11.2704 21.7524C9.13474 20.3765 7.24809 18.6235 5.7305 16.6116C4.34198 14.7876 3.5 12.6357 3.5 10.3178ZM9.19423 10.5768C9.19423 12.1177 10.4517 13.3297 11.9934 13.3297C13.5362 13.3297 14.8058 12.1177 14.8058 10.5768C14.8058 9.0478 13.5362 7.77683 11.9934 7.77683C10.4517 7.77683 9.19423 9.0478 9.19423 10.5768Z" fill="currentColor"></path></svg>
                                            </span>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Tab.Content className="mt-2">
                            <Tab.Pane  eventKey="grid" variant=" fade show active" id="grid" role="tabpanel"
                                aria-labelledby="pills-grid-tab1">
                                <div className="iq-product-grid-view iq-product-event-view">
                                    <Row>
                                        {
                                            loading ? (
                                                skeletonItems.map((_, index) => (
                                                    <Col md={6} className='mb-4'>
                                                        <Card className="inner-card h-100">
                                                            <Card.Body className="p-3">
                                                                <div className='d-flex gap-3 flex-wrap flex-lg-nowrap justify-content-lg-between justify-content-center'>
                                                                    <div className='d-flex gap-3 flex-wrap flex-md-nowrap justify-content-lg-between justify-content-center'>
                                                                        <div className="iq-img-skeleton rounded" style={{  minWidth: '100px', height: '100px', alignSelf: 'flex-start'}}></div>
                                                                        <div className="iq-list-view-left text-center text-sm-start">
                                                                            <div className="iq-skeleton mb-2" style={{ height: '16px', width: '100px' }}></div>
                                                                            <div className="iq-skeleton mb-3" style={{ height: '24px', width: '80%' }}></div>
                                                                            <div className="d-flex flex-column gap-2">
                                                                                <div className="iq-skeleton" style={{ height: '16px', width: '200px' }}></div>
                                                                                <div className="iq-skeleton" style={{ height: '16px', width: '90%' }}></div>
                                                                                <div className="iq-skeleton" style={{ height: '16px', width: '95%' }}></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='text-lg-end mb-2 d-block d-lg-none'>
                                                                            <div className="iq-skeleton rounded-circle" style={{ width: '18px', height: '18px' }}></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex d-lg-block gap-3 align-items-center text-end'>
                                                                        <div className='text-lg-end mb-2 d-none d-lg-block'>
                                                                            <div className="iq-skeleton rounded-circle" style={{ width: '18px', height: '18px' }}></div>
                                                                        </div>
                                                                        <div className="mb-0 mb-lg-2">
                                                                            <div className="iq-skeleton" style={{ height: '38px', width: '120px' }}></div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="iq-skeleton" style={{ height: '38px', width: '120px' }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                            <Card.Footer className="p-2 d-flex gap-1 align-items-center">
                                                                <div className="iq-skeleton rounded-circle" style={{ width: '22px', height: '22px' }}></div>
                                                                <div className="iq-skeleton" style={{ height: '16px', width: '200px' }}></div>
                                                            </Card.Footer>
                                                        </Card>
                                                    </Col>
                                                  ))
                                            ) : filteredData.length > 0 ? (
                                                filteredData.map((value, gridIndex) => {
                                                        const isDescriptionValid = typeof value.description === 'string' && value.description.length > 0;
                                                    return (
                                                        <Col md={6} key={gridIndex} className='mb-4'>
                                                            <Card className="inner-card h-100">
                                                                <Card.Body className="p-3">
                                                                    <div className='d-flex gap-3 flex-wrap flex-lg-nowrap justify-content-lg-between justify-content-center'>
                                                                        <div className='d-flex gap-3 flex-wrap flex-md-nowrap justify-content-lg-between justify-content-center'>
                                                                            {
                                                                                value.logo && 
                                                                                <img src={value.logo} alt="product-details" className="img-fluid  iq-product-img rounded" loading="lazy"/>
                                                                            }
                                                                            <div className="iq-list-view-left text-center text-sm-start">
                                                                                {
                                                                                    value.type &&
                                                                                    <p className='text-primary text-ellipsis short-1 mb-2'>{value.type}</p>
                                                                                }
                                                                                <div>
                                                                                    <h2 className="text-ellipsis short-1">{value.title}</h2>
                                                                                        {isDescriptionValid && (
                                                                                            <>
                                                                                                <p className={`text-ellipsis short-2 mb-0 ${expandedDocs[value.id] ? "expanded" : ""}`}>
                                                                                                    {expandedDocs[value.id] ? value.description : `${value.description.substring(0, 100)}...`}
                                                                                                </p>
                                                                                                {isDescriptionLong(value.description) && (
                                                                                                    <button 
                                                                                                        onClick={() => handleToggleDescription(value.id)} 
                                                                                                        className="btn btn-link p-0"
                                                                                                    >
                                                                                                        {expandedDocs[value.id] ? 'Read Less' : 'Read More'}
                                                                                                    </button>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='text-lg-end mb-2 d-block d-lg-none'>
                                                                                <svg  width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={Sweetalert}>
                                                                                    <g clipPath="url(#clip0_582_6699)">
                                                                                        <path d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9747 18 9C18 4.02578 13.9747 0 9 0ZM9 16.7442C4.72985 16.7442 1.25582 13.2702 1.25582 9C1.25582 4.72982 4.72985 1.25582 9 1.25582C13.2701 1.25582 16.7442 4.72982 16.7442 9C16.7442 13.2702 13.2701 16.7442 9 16.7442Z" fill="#38393B"/>
                                                                                        <path d="M9.34659 5L9.26136 11.2727H8.27273L8.1875 5H9.34659ZM8.76705 13.7955C8.55682 13.7955 8.37642 13.7202 8.22585 13.5696C8.07528 13.419 8 13.2386 8 13.0284C8 12.8182 8.07528 12.6378 8.22585 12.4872C8.37642 12.3366 8.55682 12.2614 8.76705 12.2614C8.97727 12.2614 9.15767 12.3366 9.30824 12.4872C9.45881 12.6378 9.53409 12.8182 9.53409 13.0284C9.53409 13.1676 9.49858 13.2955 9.42756 13.4119C9.35938 13.5284 9.26705 13.6222 9.15057 13.6932C9.03693 13.7614 8.90909 13.7955 8.76705 13.7955Z" fill="#38393B"/>
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_582_6699">
                                                                                            <rect width="18" height="18" fill="currentColor" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-lg-block gap-3 align-items-center text-end d-md-flex'>
                                                                            <div className='text-lg-end mb-2 d-none d-lg-block'>
                                                                                <svg  width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={Sweetalert}>
                                                                                    <g clipPath="url(#clip0_582_6699)">
                                                                                        <path d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9747 18 9C18 4.02578 13.9747 0 9 0ZM9 16.7442C4.72985 16.7442 1.25582 13.2702 1.25582 9C1.25582 4.72982 4.72985 1.25582 9 1.25582C13.2701 1.25582 16.7442 4.72982 16.7442 9C16.7442 13.2702 13.2701 16.7442 9 16.7442Z" fill="#38393B"/>
                                                                                        <path d="M9.34659 5L9.26136 11.2727H8.27273L8.1875 5H9.34659ZM8.76705 13.7955C8.55682 13.7955 8.37642 13.7202 8.22585 13.5696C8.07528 13.419 8 13.2386 8 13.0284C8 12.8182 8.07528 12.6378 8.22585 12.4872C8.37642 12.3366 8.55682 12.2614 8.76705 12.2614C8.97727 12.2614 9.15767 12.3366 9.30824 12.4872C9.45881 12.6378 9.53409 12.8182 9.53409 13.0284C9.53409 13.1676 9.49858 13.2955 9.42756 13.4119C9.35938 13.5284 9.26705 13.6222 9.15057 13.6932C9.03693 13.7614 8.90909 13.7955 8.76705 13.7955Z" fill="#38393B"/>
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_582_6699">
                                                                                            <rect width="18" height="18" fill="currentColor" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    value?.links?.['Visit site'] && 
                                                                                <Button href={value?.links?.['Visit site']} target="_blank" rel="noopener noreferrer" className='mb-2 mb-md-0 mb-lg-2'>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clipPath="url(#clip0_582_6672)">
                                                                                            <path d="M9 16.5C13.1423 16.5 16.5 13.1423 16.5 9C16.5 4.85775 13.1423 1.5 9 1.5C4.85775 1.5 1.5 4.85775 1.5 9C1.5 13.1423 4.85775 16.5 9 16.5Z" fill="currentColor" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M1.5 9H16.5" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M9 16.5C10.6568 16.5 12 13.1422 12 9C12 4.85775 10.6568 1.5 9 1.5C7.34325 1.5 6 4.85775 6 9C6 13.1422 7.34325 16.5 9 16.5Z" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M3.69629 3.80273C4.39194 4.50022 5.21858 5.05336 6.12869 5.43035C7.0388 5.80734 8.01444 6.00076 8.99954 5.99948V5.99948C9.98464 6.00076 10.9603 5.80734 11.8704 5.43035C12.7805 5.05336 13.6071 4.50022 14.3028 3.80273" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M14.3028 14.1968C13.6071 13.4993 12.7805 12.9461 11.8704 12.5691C10.9603 12.1921 9.98464 11.9987 8.99954 12C8.01444 11.9987 7.0388 12.1921 6.12869 12.5691C5.21858 12.9461 4.39194 13.4993 3.69629 14.1968" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_582_6672">
                                                                                                <rect width="18" height="18" fill="currentColor" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                    <span>
                                                                                    Visit Site
                                                                                    </span>
                                                                                </Button>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                value?.links?.Directions && 

                                                                                <Button href={value?.links?.Directions} target="_blank" rel="noopener noreferrer">
                                                                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clipPath="url(#clip0_4310_6921)">
                                                                                        <path d="M0 9.5C0 4.5374 4.0374 0.5 9 0.5C11.0043 0.5 12.9013 1.14483 14.4861 2.3648L12.3946 5.0816C11.4147 4.32731 10.2409 3.92857 9 3.92857C5.92791 3.92857 3.42857 6.42791 3.42857 9.5C3.42857 12.5721 5.92791 15.0714 9 15.0714C11.4743 15.0714 13.577 13.4503 14.3016 11.2143H9V7.78571H18V9.5C18 14.4626 13.9626 18.5 9 18.5C4.0374 18.5 0 14.4626 0 9.5Z" fill="currentColor"/>
                                                                                        </g>
                                                                                        <defs>
                                                                                        <clipPath id="clip0_4310_6921">
                                                                                        <rect width="18" height="18" fill="currentColor" transform="translate(0 0.5)"/>
                                                                                        </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                    <span className='ms-2'>
                                                                                    Get Direction
                                                                                    </span>
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                                {
                                                                    value.location.address && 
                                                                    <Card.Footer className="p-2 d-flex gap-1 text-primary align-items-center">
                                                                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-22" width="32" height="32" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M3.5 10.3178C3.5 5.71789 7.34388 2 11.9934 2C16.6561 2 20.5 5.71789 20.5 10.3178C20.5 12.6357 19.657 14.7876 18.2695 16.6116C16.7388 18.6235 14.8522 20.3765 12.7285 21.7524C12.2425 22.0704 11.8039 22.0944 11.2704 21.7524C9.13474 20.3765 7.24809 18.6235 5.7305 16.6116C4.34198 14.7876 3.5 12.6357 3.5 10.3178ZM9.19423 10.5768C9.19423 12.1177 10.4517 13.3297 11.9934 13.3297C13.5362 13.3297 14.8058 12.1177 14.8058 10.5768C14.8058 9.0478 13.5362 7.77683 11.9934 7.77683C10.4517 7.77683 9.19423 9.0478 9.19423 10.5768Z" fill="currentColor"></path></svg>
                                                                        <p className='mb-0'>{value.location.address}</p>
                                                                </Card.Footer>
                                                                }
                                                            </Card>
                                                        </Col>
                                                    )
                                                })
                                            ) : (
                                                <div className="iq-product-list-view">
                                                    <Card className="inner-card">
                                                        <div className="container no-service-container">
                                                            {/* temporary commented <img src={window.iqonic_rest_url + Noservice} alt="No Service" className="no-service-image" /> */}
                                                            <h1 className="no-service-title">No Service Available</h1>
                                                            <p className="no-service-text">We have not discovered any services available in this area at the moment.</p>
                                                        </div>
                                                    </Card>
                                                </div>
                                            )
                                        }
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="list" className="fade" id="list" role="tabpanel"
                                aria-labelledby="pills-list-tab1">
                                <div className="iq-product-list-view">
                                    {
                                        loading ? (
                                            <Card className="inner-card">
                                                <Card.Body className="d-flex flex-wrap flex-lg-nowrap flex-md-nowrap justify-content-between flex-sm-column flex-lg-row flex-md-row flex-column">
                                                    <div className="iq-product-list-left-side flex-lg-nowrap flex-md-nowrap d-flex gap-3 flex-wrap align-items-center justify-content-center justify-content-sm-center">
                                                        <img 
                                                            className="img-fluid iq-product-img iq-skeleton"
                                                            loading="lazy"
                                                        />
                                                        <div className="iq-list-view-left text-center text-sm-start" >
                                                            <h2 className="mb-2 iq-skeleton ">&nbsp;</h2>
                                                            <div>
                                                                <div className="d-flex align-items-center justify-content-lg-start justify-content-md-start justify-content-center ">
                                                                
                                                                    <span className='w-50 iq-skeleton'>&nbsp;</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p>&nbsp;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="iq-list-view-right d-flex flex-column justify-content-center align-items-end d-block align-items-center align-items-sm-end mx-sm-0 mx-auto">
                                                        <div>

                                                        </div>
                                                        <div className="iq-list-options d-flex flex-column align-items-end gap-2">
                                                            <Button
                                                                style={{ width: "10rem" }}
                                                                variant="soft-warning"
                                                                className="d-flex iq-skeleton align-items-center wishlist-btn gap-2  justify-content-center"
                                                            >

                                                                &nbsp;
                                                            </Button>
                                                            <Button
                                                                style={{ width: "10rem" }}
                                                                variant="soft-warning"
                                                                className="d-flex iq-skeleton align-items-center cart-btn gap-2 "
                                                            >
                                                                <span className="btn-inner d-flex">

                                                                </span>
                                                                &nbsp;
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <div className="d-flex justify-content-center align-items-center flex-wrap gap-3 justify-content-lg-between">
                                                        <div>
                                                            <div style={{ width: "15rem" }} className=" iq-skeleton">
                                                                <p>&nbsp;</p>
                                                            </div>
                                                            <div className=" iq-skeleton">

                                                                <p className="mb-0">&nbsp;</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex gap-2">
                                                            <Button
                                                                variant=" iq-btn-outline"
                                                                className="d-flex iq-skeleton align-items-center flex-wrap gap-3"
                                                            >
                                                                <span className="btn-inner d-flex">

                                                                </span>
                                                                &nbsp;
                                                            </Button>
                                                            <Button
                                                                variant=" iq-btn-outline"
                                                                className="iq-skeleton d-flex align-items-center flex-wrap gap-3">
                                                                <span className="btn-inner d-flex">

                                                                </span>
                                                                &nbsp;
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card.Footer>
                                            </Card>
                                        ) : filteredData.length > 0 ? (
                                            filteredData?.map((value, listIndex) => {
                                                const isDescriptionValid = typeof value.description === 'string' && value.description.length > 0;
                                                return (
                                                    <Card className="inner-card" key={listIndex}>
                                                        <Card.Body>
                                                            <div className="d-flex justify-content-md-between justify-content-center flex-wrap flex-md-nowrap">
                                                                <div className='d-flex gap-3'>
                                                                {value.logo && (
                                                                    <img
                                                                    src={value.logo}
                                                                    alt="product-details"
                                                                    className="img-fluid iq-product-img rounded"
                                                                    loading="lazy"
                                                                />
                                                                )}
                                                                
                                                                <div className="">
                                                                {
                                                                    value.type &&
                                                                    <p className='text-primary mb-2'>{value.type}</p>
                                                                }
                                                                    <h2 className='text-ellipsis short-1'>{value.title}</h2>
                                                                    {isDescriptionValid && (
                                                                        <>
                                                                            <p className={`text-ellipsis short-2 mb-0 ${expandedDocs[value.id] ? "expanded" : ""}`}>
                                                                                {expandedDocs[value.id] ? value.description : `${value.description.substring(0, 100)}...`}
                                                                            </p>
                                                                            {isDescriptionLong(value.description) && (
                                                                                <button 
                                                                                    onClick={() => handleToggleDescription(value.id)} 
                                                                                    className="btn btn-link p-0"
                                                                                >
                                                                                    {expandedDocs[value.id] ? 'Read Less' : 'Read More'}
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                                </div>
                                                                <div className='text-md-end text-center'>
                                                                <div className='mb-2'>
                                                                    <svg
                                                                        width="18"
                                                                        height="18"
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        onClick={Sweetalert}
                                                                    >
                                                                        <g clipPath="url(#clip0_582_6699)">
                                                                            <path
                                                                                d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9747 18 9C18 4.02578 13.9747 0 9 0ZM9 16.7442C4.72985 16.7442 1.25582 13.2702 1.25582 9C1.25582 4.72982 4.72985 1.25582 9 1.25582C13.2701 1.25582 16.7442 4.72982 16.7442 9C16.7442 13.2702 13.2701 16.7442 9 16.7442Z"
                                                                                fill="#38393B"
                                                                            />
                                                                            <path
                                                                                d="M9.34659 5L9.26136 11.2727H8.27273L8.1875 5H9.34659ZM8.76705 13.7955C8.55682 13.7955 8.37642 13.7202 8.22585 13.5696C8.07528 13.419 8 13.2386 8 13.0284C8 12.8182 8.07528 12.6378 8.22585 12.4872C8.37642 12.3366 8.55682 12.2614 8.76705 12.2614C8.97727 12.2614 9.15767 12.3366 9.30824 12.4872C9.45881 12.6378 9.53409 12.8182 9.53409 13.0284C9.53409 13.1676 9.49858 13.2955 9.42756 13.4119C9.35938 13.5284 9.26705 13.6222 9.15057 13.6932C9.03693 13.7614 8.90909 13.7955 8.76705 13.7955Z"
                                                                                fill="#38393B"
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_582_6699">
                                                                                <rect width="18" height="18" fill="currentColor" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </div>
                                                                    <div className='d-flex d-md-block gap-2'>
                                                                        <div className='mb-0 mb-md-2'>
                                                                            {
                                                                                value?.links?.['Visit site'] && 
                                                                                <Button href={value?.links?.['Visit site']} target="_blank" rel="noopener noreferrer" className='mb-0 mb-lg-2'>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clipPath="url(#clip0_582_6672)">
                                                                                            <path d="M9 16.5C13.1423 16.5 16.5 13.1423 16.5 9C16.5 4.85775 13.1423 1.5 9 1.5C4.85775 1.5 1.5 4.85775 1.5 9C1.5 13.1423 4.85775 16.5 9 16.5Z" fill="currentColor" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M1.5 9H16.5" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M9 16.5C10.6568 16.5 12 13.1422 12 9C12 4.85775 10.6568 1.5 9 1.5C7.34325 1.5 6 4.85775 6 9C6 13.1422 7.34325 16.5 9 16.5Z" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M3.69629 3.80273C4.39194 4.50022 5.21858 5.05336 6.12869 5.43035C7.0388 5.80734 8.01444 6.00076 8.99954 5.99948V5.99948C9.98464 6.00076 10.9603 5.80734 11.8704 5.43035C12.7805 5.05336 13.6071 4.50022 14.3028 3.80273" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M14.3028 14.1968C13.6071 13.4993 12.7805 12.9461 11.8704 12.5691C10.9603 12.1921 9.98464 11.9987 8.99954 12C8.01444 11.9987 7.0388 12.1921 6.12869 12.5691C5.21858 12.9461 4.39194 13.4993 3.69629 14.1968" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_582_6672">
                                                                                                <rect width="18" height="18" fill="currentColor" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                    <span>
                                                                                    Visit Site
                                                                                    </span>
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            value?.links?.Directions && 
                                                                            <Button href={value?.links?.Directions} target="_blank" rel="noopener noreferrer">
                                                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clipPath="url(#clip0_4310_6921)">
                                                                                    <path d="M0 9.5C0 4.5374 4.0374 0.5 9 0.5C11.0043 0.5 12.9013 1.14483 14.4861 2.3648L12.3946 5.0816C11.4147 4.32731 10.2409 3.92857 9 3.92857C5.92791 3.92857 3.42857 6.42791 3.42857 9.5C3.42857 12.5721 5.92791 15.0714 9 15.0714C11.4743 15.0714 13.577 13.4503 14.3016 11.2143H9V7.78571H18V9.5C18 14.4626 13.9626 18.5 9 18.5C4.0374 18.5 0 14.4626 0 9.5Z" fill="currentColor"/>
                                                                                    </g>
                                                                                    <defs>
                                                                                    <clipPath id="clip0_4310_6921">
                                                                                    <rect width="18" height="18" fill="currentColor" transform="translate(0 0.5)"/>
                                                                                    </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <span className='ms-2'>
                                                                                Get Direction
                                                                                </span>
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                        {
                                                            value.location.address && 
                                                            <Card.Footer className="p-2 d-flex gap-1 text-primary align-items-center">
                                                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-22" width="32" height="32" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M3.5 10.3178C3.5 5.71789 7.34388 2 11.9934 2C16.6561 2 20.5 5.71789 20.5 10.3178C20.5 12.6357 19.657 14.7876 18.2695 16.6116C16.7388 18.6235 14.8522 20.3765 12.7285 21.7524C12.2425 22.0704 11.8039 22.0944 11.2704 21.7524C9.13474 20.3765 7.24809 18.6235 5.7305 16.6116C4.34198 14.7876 3.5 12.6357 3.5 10.3178ZM9.19423 10.5768C9.19423 12.1177 10.4517 13.3297 11.9934 13.3297C13.5362 13.3297 14.8058 12.1177 14.8058 10.5768C14.8058 9.0478 13.5362 7.77683 11.9934 7.77683C10.4517 7.77683 9.19423 9.0478 9.19423 10.5768Z" fill="currentColor"></path></svg>
                                                                <p className='mb-0'>{value.location.address}</p>
                                                            </Card.Footer>
                                                        }
                                                </Card>
                                                )
                                            })
                                        ) : (
                                            <div className="iq-product-list-view">
                                                <Card className="inner-card">
                                                    <div className="container no-service-container">
                                                        {/*  temporary commented <img src={window.iqonic_rest_url + Noservice} alt="No Service" className="no-service-image" /> */}
                                                        <h1 className="no-service-title">No Service Available</h1>
                                                        <p className="no-service-text">We have not discovered any services available in this area at the moment.</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        )
                                    }
                                    </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="map"  className=" fade" id="map" role="tabpanel"
                                aria-labelledby="pills-map-tab1">
                                    <Card>
                                        {
                                            isLoaded ? (
                                                <GoogleMap
                                                center={mapCenter}
                                                zoom={mapZoom}
                                                mapContainerStyle={{ width: "100%", height: "500px" }}
                                                onClick={() => setActiveMarker(null)}
                                                onLoad={onMapLoad}
                                                onZoomChanged={onMapChange}
                                                onCenterChanged={onMapChange}
                                                    >
                                                    {filteredData.map((data, mapIndex) => {
                                                        const isDescriptionValid = typeof data.description === "string" && data.description.length > 0;

                                                        return (
                                                            data.location?.latlong && (
                                                                <Marker key={data.id} position={{ lat: data.location?.latlong?.latitude || 0, lng: data.location?.latlong?.longitude || 0,}}
                                                                    label={{ text: data.title.charAt(0).toUpperCase(), color: "white", fontSize: "14px", fontWeight: "bold",}}
                                                                    onClick={() => handleActiveMarker(data.id)} options={{ disableAutoPan: true }}>
                                                                    {activeMarker === data.id && (
                                                                    <InfoWindow onCloseClick={() => setActiveMarker(null)} options={{
                                                                        pixelOffset: new window.google.maps.Size(0, -30),
                                                                        disableAutoPan: true
                                                                    }}>
                                                                        <Card className="inner-card mb-0" style={{ width: "22rem" }}>
                                                                            <Card.Body className="p-3">
                                                                                <div className="d-flex gap-3 flex-wrap flex-lg-nowrap justify-content-between">
                                                                                    <div className="d-flex gap-3 flex-wrap flex-md-nowrap">
                                                                                        {data.logo && (
                                                                                        <img
                                                                                            src={data.logo}
                                                                                            alt="product-details"
                                                                                            className="img-fluid iq-product-img rounded"
                                                                                            loading="lazy"
                                                                                            style={{ objectFit: "contain" }}
                                                                                        />
                                                                                        )}
                                                                                        <div className="iq-list-view-left text-center text-sm-start">
                                                                                        {data.type && (
                                                                                            <p className="text-primary text-ellipsis short-1 mb-2">
                                                                                            Service - {data.type}
                                                                                            </p>
                                                                                        )}
                                                                                        <h4 className="text-ellipsis short-2">{data.title}</h4>
                                                                                        {isDescriptionValid && (
                                                                                            <>
                                                                                                <p className={`text-ellipsis short-2 mb-0 ${expandedDocs[data.id] ? "expanded" : ""}`}>
                                                                                                    {expandedDocs[data.id] ? data.description : `${data.description.substring(0, 100)}...`}
                                                                                                </p>
                                                                                                {isDescriptionLong(data.description) && (
                                                                                                    <button 
                                                                                                        onClick={() => handleToggleDescription(data.id)} 
                                                                                                        className="btn btn-link p-0"
                                                                                                    >
                                                                                                        {expandedDocs[data.id] ? 'Read Less' : 'Read More'}
                                                                                                    </button>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='mt-2'>
                                                                                        {
                                                                                            data?.links?.['Visit site'] && 
                                                                                            <Button href={data?.links?.['Visit site']} target="_blank" rel="noopener noreferrer" className='me-2'>
                                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <g clipPath="url(#clip0_582_6672)">
                                                                                                        <path d="M9 16.5C13.1423 16.5 16.5 13.1423 16.5 9C16.5 4.85775 13.1423 1.5 9 1.5C4.85775 1.5 1.5 4.85775 1.5 9C1.5 13.1423 4.85775 16.5 9 16.5Z" fill="currentColor" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                        <path d="M1.5 9H16.5" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                        <path d="M9 16.5C10.6568 16.5 12 13.1422 12 9C12 4.85775 10.6568 1.5 9 1.5C7.34325 1.5 6 4.85775 6 9C6 13.1422 7.34325 16.5 9 16.5Z" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                        <path d="M3.69629 3.80273C4.39194 4.50022 5.21858 5.05336 6.12869 5.43035C7.0388 5.80734 8.01444 6.00076 8.99954 5.99948V5.99948C9.98464 6.00076 10.9603 5.80734 11.8704 5.43035C12.7805 5.05336 13.6071 4.50022 14.3028 3.80273" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                        <path d="M14.3028 14.1968C13.6071 13.4993 12.7805 12.9461 11.8704 12.5691C10.9603 12.1921 9.98464 11.9987 8.99954 12C8.01444 11.9987 7.0388 12.1921 6.12869 12.5691C5.21858 12.9461 4.39194 13.4993 3.69629 14.1968" stroke="#005826" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                    </g>
                                                                                                    <defs>
                                                                                                        <clipPath id="clip0_582_6672">
                                                                                                            <rect width="18" height="18" fill="currentColor" />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                </svg>
                                                                                                <span>
                                                                                                Visit Site
                                                                                                </span>
                                                                                            </Button>
                                                                                        }
                                                                                    {
                                                                                        data?.links?.Directions && 
                                                                                        <Button href={data?.links?.Directions} target="_blank" rel="noopener noreferrer">
                                                                                            <svg width="18" height="19" viewBox="0 0 18 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clipPath="url(#clip0_4310_6921)">
                                                                                                <path d="M0 9.5C0 4.5374 4.0374 0.5 9 0.5C11.0043 0.5 12.9013 1.14483 14.4861 2.3648L12.3946 5.0816C11.4147 4.32731 10.2409 3.92857 9 3.92857C5.92791 3.92857 3.42857 6.42791 3.42857 9.5C3.42857 12.5721 5.92791 15.0714 9 15.0714C11.4743 15.0714 13.577 13.4503 14.3016 11.2143H9V7.78571H18V9.5C18 14.4626 13.9626 18.5 9 18.5C4.0374 18.5 0 14.4626 0 9.5Z" fill="currentColor"/>
                                                                                                </g>
                                                                                                <defs>
                                                                                                <clipPath id="clip0_4310_6921">
                                                                                                <rect width="18" height="18" fill="currentColor" transform="translate(0 0.5)"/>
                                                                                                </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                            <span className='ms-2'>
                                                                                            Get Direction
                                                                                            </span>
                                                                                        </Button>
                                                                                    }
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </InfoWindow>
                                                                    )}
                                                                </Marker>
                                                            )
                                                        );
                                                    })}
                                                </GoogleMap>
                                            ) : (
                                                <h1>loading...</h1>
                                            ) 
                                        }
      
                                    </Card>
                            </Tab.Pane>
                        </Tab.Content >
                    </Tab.Container>
           
            <LocationSupport show={locationSupport} onHide={() => setLocationSupport(false)}></LocationSupport>
        </Fragment>
    )
})

ServiceWidget.displayName = "ServiceWidget"
export default ServiceWidget