import { memo, Fragment, useState, useEffect, useCallback } from 'react';

// router
import { Link, useParams } from 'react-router-dom';

// react-bootstrap
import { Button,Tab,Nav } from 'react-bootstrap';

// component
import { LocationSupport } from '../modals';
import Event1 from '../../assets/images/event1.png';
import EventSourceObj from '../../utilities/EventSource';
import Card from '../../components/bootstrap/card';
import Markdown from '../partials/components/markdowan';

// plugins
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

import Vimeo from '@u-wave/react-vimeo';
import ButtonWidget from '../button-widget';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectMessage, ConnectMessageStatus, ChatFullScreen } from '../../store/dashboardConfig/dashboardConfigSlice';

const EventWidget = memo(() => {
    const [locationSupport, setLocationSupport] = useState(false);
    const [activeTab, setActiveTab] = useState("grid");

    const { group } = useParams();
    const [summary, setSummary] = useState('');
    const [media, setMedia] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(true);
    const [prevGroupId, setPrevGroupId] = useState(null);
    const [visibleEvents, setVisibleEvents] = useState([]);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});
    const [buttons, setButtons] = useState([]);
    const [videoUrl, setVideoUrl] = useState(null);
    const dispatch = useDispatch();
    const category = useSelector(state => state.category_id);

    const handleChatButtonClick = (persona, message) => {
            dispatch(ChatFullScreen(true));
            dispatch(ConnectMessage(message));
            dispatch(ConnectMessageStatus(false));
            
        };
        
        const updateChatPanel = (persona, message) => {
            dispatch(ChatFullScreen(false));
            dispatch(ConnectMessage(message));
            dispatch(ConnectMessageStatus(false));
        };

    useEffect(() => {
        let eventdata;
        if (prevGroupId !== group) {
            // Reset states when group changes
            setVisibleEvents([]);
            setSummary('');
            setMedia(null);
            setLoading(true);

            eventdata = EventSourceObj(`api/v3/get_content?type=events&group_id=${group}`);
            setPrevGroupId(group);
        }
        if (!eventdata) return;

        const messageHandler = (eventData) => {
            const eventJsonData = JSON.parse(eventData.data);
            
            if (eventJsonData.finished) {
                setLoading(false);
                eventdata.close();
                return;
            }

            if(eventJsonData?.type === 'summary'){
                if(eventJsonData?.content?.length){
                    eventJsonData?.content.forEach(item => {
                        if (item.type === 'video') {
                            setVideoUrl(item.url);
                        } else {
                            setButtons((prevbuttons) => [...prevbuttons, item]);
                        }
                    });
                }else{
                    setSummary((prevSummary) => prevSummary + eventJsonData?.stream);
                }
            } else if (eventJsonData.media) {
                setMedia(eventJsonData.media);
            } else {
                // Check for duplicates using a unique identifier
                setVisibleEvents(prevEvents => {
                    const eventId = eventJsonData.start_time?.timestamp || eventJsonData.id;
                    const isDuplicate = prevEvents.some(event => 
                        (event.start_time?.timestamp || event.id) === eventId
                    );
                    
                    if (isDuplicate) {
                        return prevEvents;
                    }
                    return [...prevEvents, eventJsonData];
                });
                setLoading(false);
              }
        };
        eventdata.addEventListener('message', messageHandler);
        eventdata.addEventListener('error', (error) => {
            console.error('eventdata error:', error);
            eventdata.close();
        });

        return () => {
            eventdata?.close();
        };
    }, [group]);

    const parseStartTime = useCallback((text) => {
        const [dayPart, timePart] = text.split(", ");
        const [day, date] = dayPart.split(" ");
        const [startTime, endTime] = timePart ? timePart.split("-") : [null, null];
        return { day, date, startTime, endTime };
    }, []);

    const handleSearchChange = useCallback((event) => {
        setSearchTerm(event.target.value);
    }, []);

    const filterEvents = useCallback(() => {
        const today = moment().startOf('day');
    
        return visibleEvents.filter((data) => {
            // Early return if data is invalid
            if (!data) return false;
            
            const lowerCaseSearchTerm = (searchTerm || '').toLowerCase().trim();
            const { start_time, title, description, term } = data || {};
            
            // Early return if no start_time
            if (!start_time?.timestamp) return false;
    
            const eventDate = moment(start_time.timestamp * 1000).startOf('day');
    
            // Only show events from today onwards
            const isFutureEvent = eventDate.isSameOrAfter(today);
    
            // Check if the event falls within the date range
            const isWithinRange = dateRange[0] && dateRange[1]
                ? eventDate.isBetween(moment(dateRange[0]), moment(dateRange[1]).endOf('day'), null, '[]')
                : true;
                
            // If there's no search term, only check date criteria
            if (!lowerCaseSearchTerm) {
                return isFutureEvent && isWithinRange;
            }
    
            // Safely check each field with null coalescing
            const titleMatch = (title || '').toLowerCase().includes(lowerCaseSearchTerm);
            const descriptionMatch = (description || '').toLowerCase().includes(lowerCaseSearchTerm);
            const termMatch = (term || '').toLowerCase().includes(lowerCaseSearchTerm);
                
            return isFutureEvent && isWithinRange && (titleMatch || descriptionMatch || termMatch);
        });
    }, [visibleEvents, searchTerm, dateRange]);

    const filteredEvents = filterEvents();

    const handleToggleDescription = (key) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [key]: !prevState[key], // Toggle the specific event's expanded state
        }));
    };

    return (
        <Fragment>
            {summary ? (
                <div className="d-flex gap-3 mb-5 flex-wrap flex-md-nowrap">
                    <div>
                        <img src={media?.image || Event1} alt="Avatar" className="img-fluid avatar avatar-130 avatar-sm-60" />
                    </div>
                    <div className='iq-accordian w-100 broder-0'>
                        <Card className="mb-0 h-100 p-2">
                            <Markdown content={summary} />
                            {buttons?.length 
                                ? ( 
                                    <div>
                                    {
                                        buttons.map((item, index) => (
                                            <ButtonWidget
                                                key={`event-widget-button-${index}`}
                                                buttonType={item?.action}
                                                buttonText={item?.text}
                                                url={item?.url}
                                                target={item?.target}
                                                videoUrl={item?.url}
                                                persona={category?.persona_id}
                                                category_id={category?.category_id}
                                                message={item?.message}
                                                source={item?.source}
                                                autoplay={item?.autoplay}
                                                id={`event-widget-button-${index}`} // Ensure the id prop is passed here
                                                onChatButtonClick={handleChatButtonClick}
                                                updateChatPanel={updateChatPanel}
                                            />
                                        ))
                                    }
                                    </div>
                                )
                                : ''
                            }
                            {videoUrl && (
                                <Vimeo
                                    video={videoUrl}
                                    autoplay={false}
                                    width="100%"
                                />
                            )}
                        </Card>
                    </div>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '2rem' }}>
                    <div className="iq-img-skeleton iq-skeleton" style={{ width: '130px', height: '130px', borderRadius: '50%' }}></div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="iq-skeleton" style={{ height: '20px', width: '60%', borderRadius: '4px' }}></div>
                        <div className="iq-skeleton" style={{ height: '20px', width: '90%', borderRadius: '4px' }}></div>
                        <div className="iq-skeleton" style={{ height: '20px', width: '75%', borderRadius: '4px' }}></div>
                    </div>
                </div>
            )}

            <Tab.Container defaultActiveKey="grid"> 
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap gap-3 gap-md-0'>
                    <div className="form-group input-group mb-3 mb-md-0 search-input iq-event-input">
                        <input type="search" placeholder="Search events..." value={searchTerm} onChange={handleSearchChange} className="form-control" />
                        <span className="input-group-text">
                            <svg width="20" className="icon-20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                    </div>
                    <div className="input-group iq-event-input mb-3 mb-md-0">
                        <span className="input-group-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12V3a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v1z"/>
                            </svg>
                        </span>
                        <Flatpickr className="form-control" options={{ mode: 'range', dateFormat: 'Y-m-d' }} value={dateRange} onChange={setDateRange} placeholder="Select date range"/>
                    </div>
                    <Nav className='nav-iconly gap-2 flex-nowrap overflow-auto nav nav-tabs' data-toggle="slider-tab" role="tablist">
                        <Nav.Item   role="presentation">
                            <Nav.Link className="iq-nav-tab nav-link px-3" eventKey="grid" data-bs-toggle="tab" data-bs-target="#grid" type="button" role="tab" aria-controls="grid" aria-selected="true">
                                <div className='d-flex gap-2 align-items-center'>
                                    Grid View
                                    <span className="btn-inner">
                                            <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z" fill="currentColor"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item  role="presentation">
                            <Nav.Link eventKey="list" className="iq-nav-tab nav-link px-3" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">
                                <div className='d-flex gap-2 align-items-center'>
                                    List View
                                    <span className="btn-inner">
                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z" fill="currentColor"></path>
                                            <path opacity="0.4" d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z" fill="currentColor"></path>
                                            <path d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z" fill="currentColor"></path>
                                            <path d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <p className="blog-line-around my-5"><span className="blog-line-day px-4 py-2">Upcoming Events</span></p>
                <Tab.Content className="mt-2">
                <Tab.Pane  eventKey="grid" variant=" fade show active" id="grid" role="tabpanel" aria-labelledby="pills-grid-tab1">
                <div className="iq-product-event-view">
                        <div className="row">
                            {loading ? (
                                Array.from({ length: 4 }).map((_, index) => (
                                    <div className="col-md-6 mb-4" key={index}>
                                        <Card className="inner-card">
                                            <Card.Body className="p-2">
                                                <div className="d-flex gap-3 mb-3">
                                                    <div className="iq-img-skeleton iq-skeleton" style={{ width: '100px', height: '100px', borderRadius: '8px' }}></div>
                                                    <div style={{ flex: 1 }}>
                                                        <div className="iq-skeleton mb-2" style={{ width: '70%', height: '25px' }}></div>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <div className="iq-skeleton" style={{ width: '20px', height: '20px', borderRadius: '50%' }}></div>
                                                            <div className="iq-skeleton" style={{ width: '50%', height: '20px' }}></div>
                                                        </div>
                                                        <div className="iq-skeleton mt-3" style={{ width: '100%', height: '15px' }}></div>
                                                        <div className="iq-skeleton mt-1" style={{ width: '90%', height: '15px' }}></div>
                                                        <div className="iq-skeleton mt-2" style={{ width: '30%', height: '15px' }}></div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))
                            ) : filteredEvents.length > 0 ? (
                                filteredEvents
                                .sort((a, b) => new Date(a.start_time.timestamp) - new Date(b.start_time.timestamp))
                                .map((data) => {
                                    const isDescriptionValid = typeof data.description === 'string' && data.description.length > 0;
                                    const isDescriptionLong = data.description.length > 100;
                                    const { day, date, startTime, endTime } = parseStartTime(data.start_time.text);
                                    const eventKey = `${data.title}-${data.start_time.text}`;
                                    return (
                                        <div className="col-md-6 mb-4" key={eventKey}>
                                            <Card className="inner-card">
                                                <Card.Body className="p-3">
                                                    <div className="d-flex gap-3 mb-3 flex-wrap flex-lg-nowrap justify-content-between">
                                                        <img src={data.logo} alt="event-logo" className="iq-event-image-grid rounded" />
                                                        <div className="bg-soft-gray px-3 py-2 h-25 rounded d-block d-lg-none">
                                                            <h4>{date}</h4>
                                                            <span>{day}</span>
                                                        </div>
                                                        <div>
                                                            <h5 className="card-title text-ellipsis short-2">{data.title}</h5>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-20 mini-icons-color" width="20" height="20" viewBox="0 0 24 24">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.25 12.0005C21.25 17.1095 17.109 21.2505 12 21.2505C6.891 21.2505 2.75 17.1095 2.75 12.0005C2.75 6.89149 6.891 2.75049 12 2.75049C17.109 2.75049 21.25 6.89149 21.25 12.0005Z" stroke="mini-icons-color" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M15.4316 14.9429L11.6616 12.6939V7.84692" stroke="mini-icons-color" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                <p className='ms-2 mb-0'>{startTime}{endTime ? ` - ${endTime}` : ''}</p>
                                                            </div>
                                                            {isDescriptionValid && (
                                                                <>
                                                                    <p className={`text-ellipsis short-2 mb-0 ${expandedDescriptions[eventKey] ? "expanded" : ""}`}>
                                                                        {expandedDescriptions[eventKey] ? data.description : `${data.description.substring(0, 100)}...`}
                                                                    </p>
                                                                    {isDescriptionLong && (
                                                                        <button 
                                                                            onClick={() => handleToggleDescription(eventKey)} 
                                                                            className="btn btn-link p-0"
                                                                        >
                                                                            {expandedDescriptions[eventKey] ? 'Read Less' : 'Read More'}
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}
                                                            <div>

                                                            <Link target="_blank" to={data.links.Details}>View Details</Link>
                                                            </div>
                                                        </div>
                                                        <div className="bg-soft-gray px-3 py-2 h-25 rounded d-none d-lg-block">
                                                            <h4>{date}</h4>
                                                            <span>{day}</span>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    );
                                })
                            ) : (
                                <p>No events found.</p>
                            )}
                        </div>
                    </div> 
                    </Tab.Pane>
                    <Tab.Pane eventKey="list" className="fade" id="list" role="tabpanel" aria-labelledby="pills-list-tab1">
                    {
                        loading ? (
                            <div className="event-item">
                                <div className="d-flex gap-5 mb-md-5 w-100">
                                    <div style={{ minWidth: '60px' }}>
                                        <div className="iq-skeleton iq-event-loader" style={{ height: '30px' }}></div>
                                        <div className="iq-skeleton mt-1 iq-event-loader" style={{ height: '20px' }}></div>
                                    </div>
                
                                    <div className="d-flex flex-wrap flex-md-nowrap gap-5 flex-grow-1">
                                        <div className="iq-img-skeleton iq-skeleton" style={{ width: '80px', height: '80px', borderRadius: '8px' }}></div>
                
                                        <div className="flex-grow-1 ms-3">
                                            <div className="iq-skeleton mb-2 iq-event-loader" style={{ width: '70%', height: '25px' }}></div>
                
                                            <div className="d-flex align-items-center gap-2">
                                                <div className="iq-skeleton iq-event-loader" style={{ width: '20px', height: '20px', borderRadius: '50%' }}></div>
                                                <div className="iq-skeleton iq-event-loader" style={{ width: '50%', height: '20px' }}></div>
                                            </div>
                
                                            <div className="iq-skeleton mt-3 iq-event-loader" style={{ width: '100%', height: '15px' }}></div>
                                            <div className="iq-skeleton mt-1 iq-event-loader" style={{ width: '90%', height: '15px' }}></div>
                
                                            <div className="iq-skeleton mt-2 iq-event-loader" style={{ width: '30%', height: '15px' }}></div>
                
                                            <div className="iq-skeleton mt-2 iq-event-loader" style={{ width: '25%', height: '15px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : filteredEvents.length > 0 ? (
                            filteredEvents
                            .sort((a, b) => new Date(a.start_time.timestamp) - new Date(b.start_time.timestamp))
                            .map((data) => {
                                const { day, date, startTime, endTime } = parseStartTime(data.start_time.text);
                                const isDescriptionValid = typeof data.description === 'string' && data.description.length > 0;
                                const isDescriptionLong = data.description.length > 100;
                                const eventKey = `${data.title}-${data.start_time.text}`;
                                return (
                                    <div key={eventKey} className="event-item">
                                        <div className="d-flex gap-5 mb-md-5 flex-wrap flex-md-nowrap">
                                            <div className='bg-soft-gray px-3 py-2 h-25 rounded d-none d-md-block'>
                                                <h4>{date}</h4>
                                                <span>{day}</span>
                                            </div>
                                            <div className="d-flex flex-wrap flex-md-nowrap gap-5 justify-content-between">
                                                <img src={data.logo} alt="event-logo" className="iq-event-image img-fluid rounded ms-2 ms-md-0" loading="lazy" />
                                                <div className='bg-soft-gray px-3 py-2 rounded d-block d-md-none me-2' style={{ height: "16%" }}>
                                                    <h4>{date}</h4>
                                                    <span>{day}</span>
                                                </div>
                                                <div className="flex-grow-1 ms-2 ms-md-3">
                                                    <h4>{data.title}</h4>
                                                    <div className="d-flex align-items-center mb-2">
                                                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-20 mini-icons-color" width="20" height="20" viewBox="0 0 24 24">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M21.25 12.0005C21.25 17.1095 17.109 21.2505 12 21.2505C6.891 21.2505 2.75 17.1095 2.75 12.0005C2.75 6.89149 6.891 2.75049 12 2.75049C17.109 2.75049 21.25 6.89149 21.25 12.0005Z" stroke="mini-icons-color" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M15.4316 14.9429L11.6616 12.6939V7.84692" stroke="mini-icons-color" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                    <p className='ms-2 mb-0'>{startTime}{endTime ? ` - ${endTime}` : ''}</p>
                                                    </div>
                                                    {isDescriptionValid && (
                                                        <>
                                                            <p className={`text-ellipsis short-2 mb-0 ${expandedDescriptions[eventKey] ? "expanded" : ""}`}>
                                                                {expandedDescriptions[eventKey] ? data.description : `${data.description.substring(0, 100)}...`}
                                                            </p>
                                                            {isDescriptionLong && (
                                                                <button 
                                                                    onClick={() => handleToggleDescription(eventKey)} 
                                                                    className="btn btn-link p-0"
                                                                >
                                                                    {expandedDescriptions[eventKey] ? 'Read Less' : 'Read More'}
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    <p>Search term: <span>{data.term}</span></p>
                                                    <Link target='_blank' to={data.links.Details}>View details</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom my-5"></div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No events found.</p>
                        )
                       }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>

            <LocationSupport show={locationSupport} onHide={() => setLocationSupport(false)} />
        </Fragment>
    );
});

EventWidget.displayName = "EventWidget";
export default EventWidget;
