import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Dropdown, Spinner, } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { GetPlans, GettingStartMutation } from "../apis/GettingStart";
import { useDispatch, useSelector } from "react-redux";
import { IsLoading } from '../store/dashboardConfig/dashboardConfigSlice';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';



const GetStarted = () => {
    const dispatch = useDispatch()

    const isLoading = useSelector(state => state.isLoading);
    const mutation = GettingStartMutation();
    const [percent, setPercent] = useState(0);
    const [step, setStep] = useState(1);
    const inputOption = useSelector((state) => state.inputOption);
    const [inputLabel, setInputLabel] = useState();
    const [industryData, setIndustryData] = useState();
    const [countryName, setCountryName] = useState();
    const [size, setSize] = useState();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [titleInformation, setTitleInformation] = useState({
        association: '',
        theme: '',
        content: ''
    })
    const [challengeIds, setChallengeIds] = useState([])
    const [groupIds, setGroupIds] = useState([])
    const [toolkitId, setToolkitId] = useState([])
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [selectedItems, setSelectedItems] = useState({
    });
    const [formData, setFormData] = useState({
    });

    const [validationErrors, setValidationErrors] = useState({});
    const [theme, seTheme] = useState()
    const [content, setContent] = useState()
    const formDetails = {
        categories: selectedOptions,
        groups: groupIds,
        challenges: challengeIds,
        toolkits: toolkitId,
        association: {},
        theme: selectedTheme?.name
    };

    if (Array.isArray(inputLabel)) {
        inputLabel.forEach((item) => {
            const value = formData[item.id] || selectedItems[item.id] || "";
            formDetails.association[item.id] = value;
        });
    } 
    const handleOptionToggle = (categoryIds, groupIds, challengeIds, toolkit_ids) => {
        if (selectedOptions.includes(categoryIds[0])) {
            setSelectedOptions(selectedOptions.filter((option) => option !== categoryIds[0]));
            setChallengeIds(challengeIds.filter((option) => option !== challengeIds[0]));
            setChallengeIds(groupIds.filter((option) => option !== groupIds[0]));
            setToolkitId(toolkit_ids.filter((option) => option !== groupIds[0]));
        } else {
            setGroupIds(prevGroupIds => [...prevGroupIds, groupIds[0]])
            setChallengeIds(prevChallengeIds => [...prevChallengeIds, challengeIds[0]])
            setToolkitId(prevToolkitId => [...prevToolkitId, toolkit_ids[0]]);
            setSelectedOptions([...selectedOptions, categoryIds[0]]);
        }
    };
    const handleItemSelect = (dropdownId, item) => {
        setSelectedItems(prevState => ({
            ...prevState,
            [dropdownId]: item 
        }));
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [dropdownId]: '',
        }));
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };
    const handleThemeSelect = (theme) => {
        setSelectedTheme(theme);
    };
    localStorage.setItem('groupIds', groupIds);
    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = {};
        inputLabel.forEach((field) => {
            if (field.active) {
                const value = formData[field.id];
                const selected = selectedItems[field.id];
    
                // Check if the field is required and if it is not checked
            if (field.required && !value && !selected && field.required !== false) {
                errors[field.id] = `${field.name} is required`;
            }
            }
        });
    
        const steps = inputOption?.data?.steps;
        const currentStep = steps ? steps[step - 1] : null;
        const isLastStep = step === steps?.length;
        if (!currentStep) return null;
    
        setValidationErrors(errors);
        if (Object.keys(errors).length === 0) {
            let shouldAdvanceStep = false;
            switch (currentStep.type) {
                case "association":
                    shouldAdvanceStep = true;
                    break;
                case "content":
                    const isOptionSelected = content.some(option => option.buttons.some(button => selectedOptions.includes(button.category_ids[0])));
                    if (isOptionSelected) {
                        shouldAdvanceStep = true;
                    } else {
                        errors.options = 'Please select at least one option';
                        setValidationErrors({ ...errors });
                    }
                    break;
                case "theme":
                    if (selectedTheme !== null) {
                        shouldAdvanceStep = true;
                    } else {
                        errors.theme = 'Please select a Team';
                        setValidationErrors({ ...errors });
                    }
                    break;
                default:
                    break;
            }
    
            if (shouldAdvanceStep) {
                (!isLastStep) &&setStep(step + 1);
                if (percent < 100) {
                    setPercent(prevPercent => prevPercent + 35);
                }
                if (isLastStep) {
                    mutation.mutate(formDetails);
                    dispatch(IsLoading(true));
                }
            }
        }
    };
    
    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
            if (percent > 0) {
                setPercent(prevPercent => prevPercent - 35);
            }
        }

    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'website' && value.trim() !== '' && !value.startsWith('http')) {
            const updatedWebsite = `https://${value}`;
            setFormData(prevState => ({ ...prevState, website: updatedWebsite }));
        }
    };
    useEffect(() => {
        (inputOption?.data?.steps || []).filter(step => step.type === "association").forEach(step => {
            setTitleInformation(prevState => ({ ...prevState, association: step.title }));
            setInputLabel(step.options);
        });
        (inputOption?.data?.steps || []).filter(step => step.type === "theme").forEach(step => {
            setTitleInformation(prevState => ({ ...prevState, theme: step.title }));
            seTheme(step.options);
        });
        (inputOption?.data?.steps || []).filter(step => step.type === "content").forEach(step => {
            const contentTitles = step.sections.map(item => item.title).join(', ');
            setTitleInformation(prevState => ({ ...prevState, content: contentTitles }));
            setContent(step.sections);
        });
    }, [inputOption]);



    useEffect(() => {
        if (inputLabel) {
            const filteredItems = inputLabel.filter(item => item.name === 'Industry');
            filteredItems.forEach(item => {
                setIndustryData(item.data);
            });
            const country = inputLabel.filter(item => item.name === 'Country');
            country.forEach(item => {
                setCountryName(item.data);
            });
            const Size = inputLabel.filter(item => item.name === 'Organisation size');
            Size.forEach(item => {
                setSize(item.data);
            });
        }
    }, [inputLabel]);


  

    const renderStepTitle = () => {
        const steps = inputOption?.data?.steps;

        if (steps && steps.length > 0 && step > 0 && step <= steps.length) {
            const currentStep = steps[step - 1]; // Step index is 1-based

            return (
                <div className='d-flex justify-content-center w-100 text-center'>
                    <p className='get-support-details'>
                        {currentStep.title}
                    </p>
                </div>
            );
        }

        return null; // Return null if steps are undefined, empty, or step is out of bounds
    };
    const renderStepContent = () => {
        const steps = inputOption?.data?.steps;
        const currentStep = steps ? steps[step - 1] : null;
        const isLastStep = step === steps?.length;
        if (!currentStep) return null;

        const renderButton = () => {
            return (
                <button className='btn btn-primary' type='submit' onClick={handleSubmit}>
                    <span className='me-2'>{isLastStep ? (mutation?.isLoading?'Jumping In':'Jump In') : 'Next'}</span>
                    {
                        mutation?.isLoading && (
                            <>
                          &nbsp;
                          <Spinner as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true">
                          </Spinner>
                        </>
                      )
                    }
                    <span>
                        <svg className='align-baseline' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.75 6H11.25M11.25 6L6 0.75M11.25 6L6 11.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </button>
            );
        };

        switch (currentStep.type) {
            case "association":
                return (
                    <div>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                {currentStep.options.map((field, index) => (
                                    <Col lg="6" md="6" style={{ margin: "2% 0" }} key={index}>
                                        {field.active && (
                                            <Form.Group controlId={`validationCustom${index + 1}`} className="z-form">
                                                <Form.Label> {field.name} {field.required && <span className="text-danger">*</span>}</Form.Label>
                                                {field.type === "select" ? (
                                                    <Dropdown>
                                                        <Dropdown.Toggle className='dropdown-custom' style={{ background: "#F9F9F9", color: "#38393B", border: "unset", fontStyle: "italic" }} id={`dropdown-basic-${index}`}>
                                                            {selectedItems[field.id] ? selectedItems[field.id] : `Select`}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {field.data.options.map((item, optionIndex) => (
                                                                <Dropdown.Item key={optionIndex} onClick={() => handleItemSelect(field.id, item)}>
                                                                    {item}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                ) : field.type === "textarea" ? (
                                                    <Form.Control
                                                        as="textarea"
                                                        name={field.id}
                                                        placeholder=""
                                                        onBlur={handleBlur}
                                                        value={formData[field.id]}
                                                        onChange={handleInputChange}
                                                        style={{ height: "4rem" }}
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        type={field.type}
                                                        name={field.id}
                                                        placeholder=""
                                                        onBlur={handleBlur}
                                                        value={formData[field.id]}
                                                        onChange={handleInputChange}
                                                    />
                                                )}
                                                {validationErrors[field.id] && <div className="error-message">{validationErrors[field.id]}</div>}
                                            </Form.Group>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                            <div className='mt-3 d-flex justify-content-center'>
                                {renderButton()}
                            </div>
                        </Form>
                    </div>
                );

            case "content":
                return (
                    <div>
                        <Form onSubmit={handleSubmit}>
                            <div className='d-flex justify-content-center'>
                                <div className="custom-dropdown">
                                    {currentStep.sections.map((section) => (
                                        <div className="row gy-3" key={section.title}>
                                            {section.buttons.map((button) => (
                                                <div
                                                    key={button.id}
                                                    className={`option col-sm-6 ${selectedOptions.includes(button.category_ids[0]) ? 'selected' : ''}`}
                                                    onClick={() => handleOptionToggle(button.category_ids.flat(), button.group_ids.flat(), button.challenge_ids.flat(), button.toolkit_ids.flat())}
                                                >
                                                    <span className='custom-dropdown-btn'>{button.title}</span>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {validationErrors.options && <div className="error-message" style={{ width: "58%" }}>{validationErrors.options}</div>}
                            <div className='mt-3 d-flex justify-content-center gap-3'>
                                <div className='mt-5'>
                                    <button className='btn btn-primary' onClick={handleBack}>Back</button>
                                </div>
                                <div className='mt-5'>
                                    {renderButton()}
                                </div>
                            </div>
                        </Form>
                    </div>
                );

            case "theme":
                return (
                    <div>
                        <Form onSubmit={handleSubmit}>
                            <Row className="gy-5">
                                {currentStep.options.map((item, index) => (
                                    <Col lg="6" md="6" key={index}>
                                        <div className={`team-box ${selectedTheme === item ? 'select-theme' : ''}`} onClick={() => handleThemeSelect(item)}>
                                            <div className='team-box-image'>
                                                <img src={item?.data?.image} className='img-fluid' alt={item?.name} />
                                            </div>
                                            <div className='team-box-content'>
                                                <div className='p-3 bg-primary text-white text-center rounded'>
                                                    <span className='d-block'>{item?.name}</span>
                                                </div>
                                                <div className='mt-3 pb-3 mb-3 border-bottom border-light'>
                                                    <p className='m-0 font-size-10'>{item?.data?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            {validationErrors.theme && <div className="error-message">{validationErrors.theme}</div>}
                        </Form>
                        <div className='mt-3 d-flex justify-content-center gap-3'>
                            <div className='mt-5'>
                                <button className='btn btn-primary' onClick={handleBack}>Back</button>
                            </div>
                            <div className='mt-5'>
                                {renderButton()}
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };



    return (
        <>
            {renderStepTitle()}
            <div className='d-flex align-items-center progress-list'>
                <span className={`progress-count ${percent >= 0 ? 'active' : 'active'}`}>1</span>
                <span className={`progress-bar-line ${percent >= 35 ? 'active-line' : ''}`}></span>
                <span className={`progress-count ${percent >= 35 ? 'active' : ''}`}>2</span>
                <span className={`progress-bar-line ${percent >= 70 ? 'active-line' : ''}`}></span>
                <span className={`progress-count ${percent >= 70 ? 'active' : ''}`}>3</span>
            </div>
            <Card.Body>
                {renderStepContent()}
            </Card.Body>

        </>
    );
};

export default GetStarted;