import React, { memo, Fragment, useState, useEffect } from "react";

import ReactMarkdown from "react-markdown";

// react-bootstrap
import {
    Nav,
    Container,
    Navbar,
    Button,
    Row,
    Col,
    Badge,
    Modal,
    Spinner,
} from "react-bootstrap";
import { useMutation } from '@tanstack/react-query';
// router-dom
import { Link, useNavigate, useLocation } from "react-router-dom";

// components
import Card from "../components/bootstrap/card";
import axios_inst from '../utilities/axios';

// image
// import img1 from "../assets/images/avatars/1.png";
// import img2 from "../assets/images/logo.png";
// import img3 from "../assets/images/footer-image.png";
import logoplaceholder from '../assets/images/logo-placeholder.png'

import Background from "../assets/images/background.png";
import BackgroundDesign from "../assets/images/background-design.png";

import Vimeo from '@u-wave/react-vimeo';


// Import selectors & action from setting store
import { GetPlans, GettingStart, GettingStartMutation } from "../apis/GettingStart";
import { useForm } from 'react-hook-form';
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import { InputOption } from "../store/dashboardConfig/dashboardConfigSlice";
import GetStarted from "./GetStated";
import { GetConfigData } from "../apis/Dashboard";

const Index = memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [trademark, setTrademark] = useState("")
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingApp, setIsLoadingApp] = useState(true)


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // date
    const [date, setDate] = useState();
    const getYear = () => setDate(new Date().getFullYear());
    useEffect(() => {
        getYear();
    }, []);

    const {
        register,
        handleSubmit,
    } = useForm();
    // const { data: platform } = GetConfigData()
     // Get platform data first
     const { data: platformData, isLoading: isPlatformLoading, refetch: refetchPlatform  } = GetConfigData(location.pathname === '/');
     
    // const plansdata = GetPlans()

    const [logo,setLogo]=useState( logoplaceholder );

    function isValidJSON(storedData) {
      try {
          JSON.parse(storedData);
          return true;
      } catch (error) {
          return false;
      }
  }

    const GettingStart = useMutation({
        mutationFn: async (data) => {
            const response = await axios_inst.get('api/v3/getting_started', { params: data });
            return response?.data;
        }
    });

    useEffect(() => {
        const handleDataFlow = async () => {
            setIsLoadingApp(isPlatformLoading);
            try {
                if (platformData?.plan_id) {
                    navigate('/dashboard');
                } else if (platformData) {
                    const response = await GettingStart.mutateAsync();
                    setData(response);
                    setIsLoading(GettingStart.isLoading);
                    dispatch(InputOption(response));
                    if(response){
                      console.log('hello')
                      setIsLoadingApp(false); // Loader will disappear when API hits successfully
                    }
                }
            } catch (error) {
                console.error('Error in handleDataFlow:', error);
            }
        };

        handleDataFlow();
    }, [platformData, isPlatformLoading]);

    useEffect(() => {
      const updateLogo = () => {
          const storedData = localStorage.getItem('typography');
          if (storedData && isValidJSON(storedData)) {
              const { logo_url } = JSON.parse(storedData);
              setLogo(logo_url); // Update the logo state based on `typography`
          }
      };
  
      window.addEventListener('storage', updateLogo); // Listen to storage changes
  
      // Initial load
      updateLogo();
  
      return () => {
          window.removeEventListener('storage', updateLogo);
      };
  }, []);

  if (isLoadingApp) {
    return (<>
        <div className="d-flex min-vh-100 align-items-center justify-content-center">
            <div className="loader">
                <div className="d-flex align-items-center flex-column">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

    if (isLoading) {
        return (<>
            <Loader />
        </>);
    }

    

    return (
        <Fragment>
            <Nav className="rounded  navbar navbar-expand-lg navbar-light">
                <Container>
                    <Navbar.Brand href="#" className="mx-2 d-flex align-items-center">
                        <img
                            className="img-fuild"
                            id="mainsitelogo"
                            src={logo}
                            loading="lazy"
                            style={{ width: "7rem" }}
                            alt="img"
                        />


                        <Modal show={show} onHide={handleClose} centered size="lg">
                            <Modal.Body>
                                <iframe src={"https://player.vimeo.com/video/932537831"} frameBorder="0" width={"100%"}
                                    height={"480px"} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="getting_start"></iframe>
                            </Modal.Body>
                        </Modal>
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        onClick={() => setOpen(!open)}
                    />
                    {/* <Navbar.Collapse id="basic-navbar-nav" in={open}>
            <ul className="mb-2 navbar-nav ms-auto mb-lg-0 d-flex align-items-start">
              <Nav.Item as="li" className="me-3 mb-2 mb-sm-0">
                <Button
                  variant="primary d-flex align-items-center gap-2"
                  aria-current="page"
                  href="#"
                  target="_blank"
                >
                  For Organisations
                </Button>
              </Nav.Item>
              <Nav.Item as="li">
                <Button
                  variant="outline-success d-flex align-items-center gap-2"
                  aria-current="page"
                  href="#"
                  target="_blank"
                >
                  Login
                </Button>
              </Nav.Item>
            </ul>
          </Navbar.Collapse> */}
                </Container>
            </Nav>

            <main className="main-content">
                <div className="position-relative iq-banner default">
                    <div className="iq-navbar-header" style={{ height: "200px" }}>
                        <Container className=" iq-container">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6">
                                    <h2 className="text-white">{data?.data?.text}</h2>
                                    {/* <p className="mb-0 lh-30 text-white">
                  Complete the steps to get a plan <br />
                    More are available after clicking "Get support".
                  </p> */}
                                </div>
                            </div>
                        </Container>
                        <div
                            className="iq-header-img"
                            style={{ backgroundColor: "#070D0A" }}
                        >
                            <img src={window.iqonic_build + Background} alt="img" />
                            <img src={BackgroundDesign} className="bg-design" alt="img" />
                        </div>
                    </div>
                </div>
                <Container className="content-inner pb-0">
                    <Row>
                        <Col lg="3" md="4" sm="12">
                            <Card className="iq-file-manager">
                                <Card.Body className="card-thumbnail">
                                    <div className="position-relative iq-video-hover">
                                        {/* <img src={window.iqonic_build + img1} className="img-fluid" alt="img" loading='lazy' /> */}
                                        <Vimeo
                                            video={data?.data?.video}
                                            className='w-100 vimeo-iframe'
                                        />
                                        {/* <div className="iq-btn-video btn btn-white text-primary rounded-pill btn-icon position-absolute"
                      onClick={() => handleShow()}
                    >
                      <span >
                        <svg
                          viewBox="0 0 44 44"
                          fill="none"
                          width="24"
                          height="35"
                          xmlns="http://www.w3.orgsvg"
                        >
                          <path
                            d="M15.8817 9.20912C14.5492 8.3891 12.8335 9.3478 12.8335 10.9124V33.0875C12.8335 34.6522 14.5492 35.6109 15.8817 34.7908L33.8989 23.7033C35.168 22.9223 35.168 21.0776 33.8989 20.2967L15.8817 9.20912Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </div> */}
                                    </div>
                                    <div className="mt-3">
                                        <ReactMarkdown>
                                          
                                            {data?.data?.description}
                                        </ReactMarkdown>
                                    </div>
                                    {/* <Link to="#" className="text-decoration-underline">
                    Get Support
                  </Link> */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="9" md="8" sm="12">
                            <Card>
                                <Card.Body>
                                    <GetStarted />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <footer style={{ marginTop: '150px' }}>
                        {/* <div className="mb-5">
              <Row>
                <Col lg="4" md="3" sm="6">

                  <img src={window.iqonic_build + img2} style={{ height: "6rem" }} loading="lazy" alt="img" />
                  <p>
                    Eco Heroes is your partner on the path to a greener,
                    healthier world. We are here to support SMEs, Corporates,
                    and NHS organizations throughout their journey to Net Zero.
                  </p>
                </Col>
                <Col lg="8" md="9" sm="6">
                  <Row>
                    <Col lg="3" md="3" sm="6">
                      <h6 className="text-primary">Key Information</h6>
                      <div className="mt-4">
                        <p>Accessibility Statement</p>
                        <p className="my-4">Privacy Policy</p>
                        <p className="mb-4">Terms of Service</p>
                        <p>Contact us</p>
                      </div>
                    </Col>
                    <Col lg="3" md="3" sm="6">
                      <h6 className="text-primary">Get In Touch</h6>
                      <div className="mt-4">
                        <p>+44 345 548 1654</p>
                        <p className="my-4">
                          Ergo, Bridgehead Business Park, Hessle, HU13 0GD
                        </p>
                      </div>
                    </Col>
                    <Col lg="3" md="3" sm="6">
                      <h6 className="text-primary">Sponsors</h6>
                      <div className="mt-4">
                        <p className="my-4">How we help</p>
                      </div>
                    </Col>
                    <Col lg="3" md="3" sm="6">
                      <h6 className="text-primary">SME Support</h6>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="d-flex justify-content-between align-items-center">
                <Link to="#">Get Support</Link>
                <img src={window.iqonic_build + img3} className="img-fluid" alt="img" />
              </div>
            </div> */}
                        <div className="d-flex">
                            <div>
                                {/* Copyright © {date}{" "}
                <Link to="#" className="mx-2">
                  {" "}
                  Eco Hero{" "}
                </Link>{" "}
                is a trademark of Upstream Outcomes Limited */}
                                {trademark}
                            </div>
                        </div>
                    </footer>
                </Container>
            </main>
        </Fragment>
    );
});

Index.displayName = "Index";
export default Index;
