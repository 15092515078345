import React, { Fragment, memo } from 'react'

// react-bootstrap
import { Table, ListGroupItem } from 'react-bootstrap'

// compoent
import Card from '../../components/bootstrap/card'

const Todo = memo(() => {
  const tabledata = [
    {
      ptag: "true",
      title: 'Assessing carbon offsetting options for residual emissions',
      pending: 'true',
      breadcrumb: 'Getting started',
      pagename: 'Net Zero'
    },
    {
      del: 'true',
      title: 'Evaluating the environmental credentials of energy suppliers',
      completed: 'true',
      breadcrumb: 'Getting started',
      pagename: 'Net Zero'
    },
    {
      ptag: "true",
      title: 'Exploring circular economy business models and product lifecycle approaches',
      progress: 'true',
      breadcrumb: 'Resources & Waste',
      pagename: 'Circular Economy'
    },
    {
      del: 'true',
      title: 'Evaluating the environmental credentials of energy suppliers',
      completed: 'true',
      breadcrumb: 'Targets & Monitoring',
      pagename: 'Data Analysis'
    },
    {
      ptag: "true",
      title: 'Engaging with carbon reporting platforms and frameworks',
      pending: 'true',
      breadcrumb: 'Finances & Reporting',
      pagename: 'Carbon Reporting'
    },
  ]
  return (
    <Fragment>
      <div>
        <Card className="overflow-hidden">
          <Card.Body className="p-0">
            <Table striped responsive>
              <thead>
                <tr className="bg-primary text-white">
                  <th>To-do List</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  tabledata.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex gap-1">
                            <small>To Do List</small>
                            <small>/</small>
                            <small>{item.breadcrumb}</small>
                            <small>/</small>
                            <small className="text-secondary">{item.pagename}</small>
                          </div>
                          <div>
                            {
                              item.ptag === "true" &&

                              <p>{item.title}</p>
                            }
                            {
                              item.del === "true" &&
                              <del>{item.title}</del>
                            }
                          </div>
                        </td>
                        <td>
                          <div>
                            {
                              item.pending === "true" &&
                              <ListGroupItem as="label">
                                <input className="form-check-input me-2" type="checkbox" defaultValue="" />
                                Pending
                              </ListGroupItem>
                            }
                            {
                              item.progress === "true" &&
                              // <div className='d-flex align-items-center gap-2'>
                              <ListGroupItem as="label">
                                <svg width="30" height="30" className="me-2" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M20.425 2.49973H9.5875C5.35 2.49973 2.5 5.47473 2.5 9.89973V20.1122C2.5 24.5247 5.35 27.4997 9.5875 27.4997H20.425C24.6625 27.4997 27.5 24.5247 27.5 20.1122V9.89973C27.5 5.47473 24.6625 2.49973 20.425 2.49973Z" fill="#FFEFEA" />
                                  <g clipPath="url(#clip0_778_4647)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.7279 8.1154C14.1409 8.03955 14.5661 8 15 8C18.866 8 22 11.134 22 15C22 18.866 18.866 22 15 22C14.5661 22 14.1409 21.9605 13.7279 21.8846C13.4111 21.8264 13.2014 21.5224 13.2596 21.2055C13.3178 20.8886 13.6219 20.6789 13.9387 20.7371C14.2824 20.8003 14.6371 20.8333 15 20.8333C18.2216 20.8333 20.8333 18.2216 20.8333 15C20.8333 11.7783 18.2216 9.16667 15 9.16667C14.6371 9.16667 14.2824 9.19974 13.9387 9.26287C13.6219 9.32107 13.3178 9.11139 13.2596 8.79452C13.2014 8.47766 13.4111 8.1736 13.7279 8.1154ZM12.2966 9.14968C12.4579 9.42855 12.3626 9.78539 12.0838 9.94671C11.288 10.407 10.6107 11.0502 10.1097 11.8186C9.93373 12.0885 9.57231 12.1646 9.30244 11.9886C9.03257 11.8127 8.95644 11.4513 9.1324 11.1814C9.73313 10.2601 10.5449 9.48907 11.4996 8.93683C11.7784 8.77551 12.1353 8.87081 12.2966 9.14968ZM18.3174 12.8261C18.5515 13.0474 18.5619 13.4166 18.3406 13.6507L14.588 17.621C14.2154 18.0152 13.5971 18.0152 13.2245 17.621L11.6594 15.9651C11.4381 15.731 11.4485 15.3618 11.6826 15.1405C11.9168 14.9192 12.286 14.9296 12.5073 15.1637L13.9062 16.6439L17.4927 12.8493C17.714 12.6152 18.0832 12.6048 18.3174 12.8261ZM8.88182 12.9735C9.19563 13.0464 9.3909 13.3599 9.31797 13.6737C9.21908 14.0992 9.16667 14.5431 9.16667 15C9.16667 15.4569 9.21908 15.9008 9.31797 16.3263C9.3909 16.6401 9.19563 16.9536 8.88182 17.0265C8.56802 17.0995 8.25452 16.9042 8.18159 16.5904C8.06272 16.0789 8 15.5464 8 15C8 14.4536 8.06272 13.9211 8.18159 13.4096C8.25452 13.0958 8.56802 12.9005 8.88182 12.9735ZM9.30244 18.0113C9.57231 17.8354 9.93373 17.9115 10.1097 18.1814C10.6107 18.9498 11.288 19.5929 12.0838 20.0533C12.3626 20.2146 12.4579 20.5715 12.2966 20.8503C12.1353 21.1292 11.7784 21.2245 11.4996 21.0632C10.5449 20.5109 9.73313 19.7399 9.1324 18.8186C8.95644 18.5487 9.03257 18.1873 9.30244 18.0113Z" fill="#F34508" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_778_4647">
                                      <rect width="14" height="14" fill="white" transform="translate(8 8)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Progress
                                {/* <p className='mb-0'>Progress</p> */}
                              </ListGroupItem>
                              // </div>
                            }
                            {
                              item.completed === "true" &&
                              // <div className='d-flex align-items-center gap-2'>
                              <ListGroupItem as="label">
                                <svg className="icon-24 me-2" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.2" d="M20.425 2.49973H9.5875C5.35 2.49973 2.5 5.47473 2.5 9.89973V20.1122C2.5 24.5247 5.35 27.4997 9.5875 27.4997H20.425C24.6625 27.4997 27.5 24.5247 27.5 20.1122V9.89973C27.5 5.47473 24.6625 2.49973 20.425 2.49973Z" fill="#005826" />
                                  <path d="M11 15.9104L13.3617 18.2721C13.5538 18.4642 13.6498 18.5602 13.7606 18.5962C13.858 18.6279 13.9629 18.6279 14.0604 18.5962C14.1711 18.5602 14.2672 18.4642 14.4593 18.2721L19.7314 13" stroke="#005826" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Completed
                                {/* <p className='mb-0'></p> */}
                              </ListGroupItem>
                              // </div>
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            <p>Showing 01 to 05 of 20 entries</p>
          </Card.Footer>
        </Card>
      </div>
    </Fragment>
  )
})

Todo.displayName = "Todo"
export default Todo