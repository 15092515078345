import React, { memo, Fragment, useEffect } from 'react'

//Router
import { Link, useLocation, useParams } from 'react-router-dom'

//React-bootstrap
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useSelector } from 'react-redux';

const SidebarMenu = memo((props) => {
    //location
    const selectdids = useSelector(state => state.selectdids);
    let location = useLocation();
    let { group, toolkit_id } = useParams();

    const firstString = location.pathname.split('/')[1];

    return (
        <Fragment>
            {firstString == "dashboard" && (
                <div>
                    {props.isTag === 'true' &&
                        <li className={`${location.pathname === `${props.pathname}` ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === `${props.pathname}` ? 'active' : ''} nav-link `} aria-current="page" to={props.pathname}>
                                <OverlayTrigger placement="right" overlay={<Tooltip>{props.title}</Tooltip>}>
                                    {props.children}
                                </OverlayTrigger>
                                <span className="item-name">{props.title} </span>
                            </Link>
                        </li>
                    }
                    {
                        props.isTag === 'false' &&
                        <Nav.Item as="li">
                            <Link className={`${`${group}/` === `${props.pathname}` ? 'active' : ''} nav-link`} to={"/dashboard/" + props.pathname + "advice"} onClick={props.modalopen} target={props.target}>
                                {props.staticIcon === 'true' &&
                                    <i className="icon">
                                        <svg className="icon-10" xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                            <g>
                                                <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                            </g>
                                        </svg>
                                    </i>
                                }
                                {props.children}
                                <OverlayTrigger placement="right" overlay={<Tooltip>{props.title}</Tooltip>}>
                                    <i className="sidenav-mini-icon" > {props.minititle} </i>
                                </OverlayTrigger>
                                <span className="item-name"> {props.title}</span>
                            </Link>
                        </Nav.Item>
                    }
                </div>
            )}
            {firstString === "toolkit" && (
                <div>
                    {props.isTag === 'true' && (
                        <li className={"nav-item " + (toolkit_id == props.pathname ? "active" : '')}>
                            <Link className={"nav-link " + (toolkit_id == props.pathname ? "active" : '')} aria-current="page" to={props.pathname}>
                                <OverlayTrigger placement="right" overlay={<Tooltip>{props.title}</Tooltip>}>
                                    {props.children}
                                </OverlayTrigger>
                                <span className="item-name">{props.title}</span>
                            </Link>
                        </li>
                    )}
                    {props.isTag === 'false' && (
                        <Nav.Item as="li">
                            <Link className={"nav-link " + (toolkit_id?.toString() === props?.pathname.replace(/\/$/, '') ? "active" : '')} to={props.pathname} onClick={props.modalopen} target={props.target}>
                                {props.staticIcon === 'true' && (
                                    <i className="icon">
                                        <svg className="icon-10" xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                            <g>
                                                <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                            </g>
                                        </svg>
                                    </i>
                                )}
                                {props.children}
                                <OverlayTrigger placement="right" overlay={<Tooltip>{props.title}</Tooltip>}>
                                    <i className="sidenav-mini-icon">{props.minititle}</i>
                                </OverlayTrigger>
                                <span className="item-name">{props.title}</span>
                            </Link>
                        </Nav.Item>
                    )}
                </div>
            )}
        </Fragment>
    )
})

SidebarMenu.displayName = "SidebarMenu"
export default SidebarMenu       