import { Modal, Button } from 'react-bootstrap'

export default function MailSupport({ show, onHide }) {
    return (
        <Modal show={show} onHide={onHide} size="sm-3" centered>
            <div className='text-end'>
                <Button variant='none' onClick={onHide}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.9945 7.00445L7.00497 16.994M16.9978 17L6.99988 7" stroke="#38393B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </Button>
            </div>
            <Modal.Body>
                <div className='px-3'>
                    <h2 className='text-center'>Email support</h2>
                    <p className='text-center px-5'>Enter an email address to receive your link, and ongoing support in areas you've identified</p>
                    <div className="input-group mb-3 mt-4 btn-group">
                        <input type="text" className="form-control" placeholder="Enter Email Address" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <button className="input-group-append btn btn-primary" style={{ flex: 0 }} onClick={onHide} type="button">
                            Submit
                        </button>
                    </div>
                    <div className='mx-4 mt-2 mb-5'>
                        <p className="text-center "><small>By providing your details, you are consenting to your personal data being used in line with our<u> Privacy Policy</u></small></p>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}