import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ImageVeiw } from './partials/components/ImageVeiw';

export const ImageUpload = ({ file, handelClose }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <div className='d-flex justify-content-between p-2 rounded' style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
            <div className='w-75'>
                <p>{file.name}</p>
            </div>

            <ImageVeiw file={file} handleClose={handleClose} show={show}/>
            
            <div style={{ width: "45px", position: 'relative' }}>
                <img className='img-fluid' onClick={handleShow} src={URL.createObjectURL(file)} alt=' ' />
                <span
                    onClick={handelClose}
                    style={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-5px',
                        cursor: 'pointer',
                        padding: '2px'
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                </span>
            </div>
            
        </div>
    )
}
