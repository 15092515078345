
import { Modal, Button } from 'react-bootstrap'

export default function LocationSupport({ show, onHide }) {
  return (
    <Modal show={show} onHide={onHide} size="sm-3" centered>
      <div className='text-end'>
        <Button variant='none' onClick={onHide}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.9945 7.00445L7.00497 16.994M16.9978 17L6.99988 7" stroke="#38393B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Button>
      </div>
      <Modal.Body>
        <div className='px-3'>
          <h2 className='text-center px-5'>Looking for support and events near you?</h2>
          <div className='px-5'>
            <p className='text-center px-5 mb-4 mt-3'>Enter your postcode and we'll see what's available locally</p>
          </div>
          <input type="text" className="form-control mt-4 mb-4" aria-label="Small" placeholder="Enter Details" aria-describedby="inputGroup-sizing-sm" />
        </div>
        <div className='d-flex justify-content-center gap-4 mt-4 mb-5'>
          <Button variant="primary" onClick={onHide}>
            Look Up
          </Button>
          <Button variant="outline-primary" onClick={onHide}>
            Cancel
          </Button></div>
      </Modal.Body>
    </Modal>
  )
}