import React, { memo, Fragment, useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chat from "../../components/chat";
import Sidebar from "../../components/partials/dashboard/sidebarstyle/toolkit-sidebar";
import Headerpro from "../../components/partials/pro/headerstyle/header-pro";
import Footer from "../../components/partials/dashboard/footerstyle/footer";
import Loader from "../../components/Loader";
import { MobileChatModel, SetActivePlan, setCategoryId, setStatuDashboardSidebarLoaded, setPersonaTitle, setToolkitDetails, setToolkitId } from '../../store/dashboardConfig/dashboardConfigSlice';
import { GetToolkit } from "../../apis/Dashboard";
import { useQuery } from "@tanstack/react-query";
import axios_inst from "../../utilities/axios";

const ToolkitLayout = memo(({setNewPages}) => {
    const dispatch = useDispatch();
    const { toolkit_id } = useParams();
    const mobileChatModel = useSelector(state => state.mobileChatModel);
    const [visibleChat, setVisibleChat] = useState(true);
    const [mobileView, setMobileView] = useState(false);
    const [showChatModal, setShowChatModal] = useState(false);
    const chatFetchedRef = useRef(false); // 🔹 Prevent double API calls

    const handleClose = () => {
        setShowChatModal(false);
        dispatch(MobileChatModel(false));
    };

    const navigate = useNavigate();

    const { data: plan_data, isFetched: plan_fetched } = useQuery({
        refetchOnWindowFocus: false,
        queryFn: () =>
            axios_inst
                .get('api/v4/get_plan')
                .then(res => res.data)
                .catch(err => {
                    console.log(err);
                }),
    });
    
    // Ensure SetActivePlan gets called only once when plan_fetched is true
    useEffect(() => {
        if (plan_fetched && plan_data) {
            dispatch(SetActivePlan(plan_data?.data));
            dispatch(setStatuDashboardSidebarLoaded(true));
            setNewPages(plan_data?.data?.new_pages);
        }
    }, [plan_fetched, plan_data, dispatch]);

    // Fetch Toolkit Data (Single Call Optimization)
    const { data, isFetched, isLoading } = GetToolkit();

    useEffect(() => {

        if (!isLoading && (!toolkit_id)) {
            let { id, persona, categoryKey, ...toolkit } = getFirstSelectedToolkitKey(data)
            if (id && (!toolkit_id)) {
                localStorage.setItem('personaTitle', JSON.stringify(persona));
                localStorage.setItem('toolkitId', id);
                dispatch(setCategoryId({ category_id: id, persona_id: persona.id }))
                dispatch(setToolkitId(id))
                dispatch(setToolkitDetails(toolkit.toolkit))
                dispatch(setPersonaTitle({ personaTitle: persona }));
                navigate(`/toolkit/${id}`);
            }
        }

        function getFirstSelectedToolkitKey(data) {

            const categories = data.data.categories;
            for (const categoryKey in categories) {
                const category = categories[categoryKey];
                if (category.selected) {
                    for (const toolkitKey in category.toolkits) {
                        const toolkit = category.toolkits[toolkitKey];
                        if (toolkit.selected) {
                            return { id: toolkitKey, toolkit, persona: category.persona, categoryKey };
                        }
                    }
                }
            }
            return { id: '', toolkit: {}, persona: {}, categoryKey: {} };  // If no matching toolkit is found
        }
        function getToolkitByID(data, id) {
            if (id == undefined) {
                return { id: '', toolkit: {}, persona: {}, categoryKey: {} };
            }
            const categories = data.data.categories;
            for (const categoryKey in categories) {
                const category = categories[categoryKey];
                if (category.toolkits[id]) {
                    return { id, toolkit: category.toolkits[id], persona: category.persona, categoryKey };
                }
            }
            return { id: '', toolkit: {}, persona: {}, categoryKey: {} };
        }

        if (!isLoading && toolkit_id && !data?.error) {
            let { id, persona, categoryKey, ...toolkit } = getToolkitByID(data, toolkit_id)

            localStorage.setItem('personaTitle', JSON.stringify(persona));
            localStorage.setItem('toolkitId', id);
            // localStorage.setItem('CategoryID', categoryKey);
            localStorage.setItem('PersonaID', persona.id);

            dispatch(setCategoryId({ category_id: id, persona_id: persona.id }))
            dispatch(setToolkitId(id))
            dispatch(setToolkitDetails(toolkit.toolkit))
            dispatch(setPersonaTitle({ personaTitle: persona }));
        }
    }, [isLoading, toolkit_id, data, dispatch, navigate])

    const partnerButton = plan_data?.data


    useEffect(() => {
        const handleResize = () => {
            const desktopView = window.innerWidth >= 920;
            const tabletView = window.innerWidth <= 820 && window.innerWidth >= 420;
            const mobileWidth = window.innerWidth <= 420;

            setVisibleChat(desktopView);
            setMobileView(tabletView || mobileWidth);
            setShowChatModal(mobileWidth ? mobileChatModel : false);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [mobileChatModel]);


    return (
        <Fragment>
            <Loader />
            <Sidebar />
            <main className="main-content">
                <div className="iq-banner default position-relative">
                    <Headerpro Button={partnerButton}/>
                </div>
                <div className="container-fluid content-inner pb-0 pt-5">
                    <Row>
                        <Col md={visibleChat ? 9 : 12} sm="12">
                            {!toolkit_id ? (
                                <div
                                    className="nav"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "70vh",
                                    }}
                                >
                                    <div>Please select Gurus</div>
                                </div>
                            ) : (
                                <Outlet />
                            )}
                        </Col>
                        {visibleChat ? (
                            <Col lg="3" md="12" sm="12" className="resize">
                                <Chat onToggle={() => setVisibleChat(false)} visible={visibleChat} />
                            </Col>
                        ) : (
                            <button
                                onClick={() =>
                                    mobileView ? setShowChatModal(true) : setVisibleChat(true)
                                }
                                style={{ top: "25%" }}
                                className="btn btn-fixed-end btn-primary btn-icon btn-setting"
                            >
                                <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon-20"
                                    height="20"
                                >
                                    <path
                                        opacity="0.4"
                                        d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11.9807 13.2901C11.2707 13.2801 10.7007 12.7101 10.7007 12.0001C10.7007 11.3001 11.2807 10.7201 11.9807 10.7301C12.6907 10.7301 13.2607 11.3001 13.2607 12.0101C13.2607 12.7101 12.6907 13.2901 11.9807 13.2901ZM7.37033 13.2901C6.67033 13.2901 6.09033 12.7101 6.09033 12.0101C6.09033 11.3001 6.66033 10.7301 7.37033 10.7301C8.08033 10.7301 8.65033 11.3001 8.65033 12.0101C8.65033 12.7101 8.08033 13.2801 7.37033 13.2901ZM15.3105 12.0101C15.3105 12.7101 15.8805 13.2901 16.5905 13.2901C17.3005 13.2901 17.8705 12.7101 17.8705 12.0101C17.8705 11.3001 17.3005 10.7301 16.5905 10.7301C15.8805 10.7301 15.3105 11.3001 15.3105 12.0101Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </button>
                        )}
                    </Row>
                    {showChatModal && (
                        <Modal show={showChatModal} onHide={handleClose} centered>
                            <Modal.Body>
                                <Chat onToggle={handleClose} visible={showChatModal} />
                            </Modal.Body>
                        </Modal>
                    )}
                </div>
                <Footer />
            </main>
        </Fragment>
    );
});

ToolkitLayout.displayName = "ToolkitLayout";
export default ToolkitLayout;
