import { Fragment, useEffect, useState, memo, createRef } from 'react';
import _ from 'lodash';
import Choices from 'choices.js';
import '../assets/custom/css/choices.css';

const ChoicesJs = memo((props) => {
    const single = createRef();
    const isMultiple = props.select === 'multi';
    const [choicesInstance, setChoicesInstance] = useState(null);

    useEffect(() => {
        // Destroy any existing Choices instance before re-initializing
        if (choicesInstance) {
            choicesInstance.destroy();
        }

        // Initialize Choices with the updated options
        const instance = new Choices(single.current, {
            removeItemButton: isMultiple,
            allowHTML: true,
            shouldSort: false,
        });

        setChoicesInstance(instance);

        // Populate the select with updated options
        instance.clearChoices();
        instance.setChoices(
            props.options.map((item) => ({ value: item.value, label: item.label })),
            'value',
            'label',
            false
        );

        // Set the initial selected value if `props.value` is provided
        if (props.value) {
            instance.setChoiceByValue(props.value);
        }

        // Cleanup to destroy instance on unmount
        return () => {
            instance.destroy();
        };
    }, [isMultiple, props.options, props.value]);  // Re-run effect when options or value change

    // Handle the change event to prevent duplicates
    const handleSelectionChange = (e) => {
        const selectedValue = e.target.value;

        // Prevent update if the value has not changed
        if (!isMultiple && selectedValue === props.value) {
            return;
        }

        // Allow change if it's a valid and new selection
        if (_.isFunction(props.onChange)) {
            props.onChange(e);
        }
    };

    return (
        <Fragment>
            <select
                ref={single}
                id={`choices-${Math.random()}`}
                className={props.className}
                onChange={handleSelectionChange}  // Use custom handleSelectionChange
                value={props.value}  // Set the selected value from props
                multiple={isMultiple}
            >
                {props.options.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
        </Fragment>
    );
});

ChoicesJs.displayName = 'ChoicesJs';
export default ChoicesJs;
