// Import necessary dependencies from React and react-bootstrap, as well as the Vimeo component
import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import Vimeo from '@u-wave/react-vimeo'; // Import the Vimeo video player component

// Define a functional component named HowItWorksVideoModal which receives props: pageVideos and location
const HowItWorksVideoModal = ({ pageVideos, location }) => {
  // Initialize state variable 'showModal' to control the visibility of the modal; default is false (hidden)
  const [showModal, setShowModal] = useState(false);
  // Initialize state variable 'isLoading' to manage the loading state of the video; default is true (loading)
  const [isLoading, setIsLoading] = useState(true);

  // Define a function to generate a unique key based on the current base route of the URL (rather than the full pathname)
  const getModalKey = () => {
    // If the current URL pathname includes the substring 'dashboard', return a specific key for dashboard
    if (location.pathname.includes('dashboard')) {
      return 'video_shown_dashboard';
    }
    // If the pathname includes 'toolkit', return a key for toolkit
    if (location.pathname.includes('toolkit')) {
      return 'video_shown_toolkit';
    }
    // If the pathname includes 'business', return a key for business
    if (location.pathname.includes('business')) {
      return 'video_shown_business';
    }
    // If none of the above routes match, return null (or you could set a default key)
    return null;
  };

  // Call the getModalKey function and store its result in the variable 'pageKey'
  const pageKey = getModalKey();

  // Define a function to retrieve the video data relevant to the current page from the pageVideos object
  const getVideoDataForPage = () => {
    // If there are no pageVideos provided, return an object with null values for video_url and video_title
    if (!pageVideos) return { video_url: null, video_title: null };

    // Normalize the current pathname by removing any leading or trailing slashes
    const pathname = location.pathname.replace(/^\/|\/$/g, "");
    // Loop through each key in the pageVideos object
    for (const key in pageVideos) {
      // Check if the normalized pathname includes the key from pageVideos
      if (pathname.includes(key)) {
        // If it does, return an object with the video_url and video_title
        // Use optional chaining to safely access the properties and provide fallback defaults if necessary
        return {
          video_url: pageVideos[key]?.video_url || null,
          video_title: pageVideos[key]?.title || "How it Works",
        };
      }
    }
    // If no matching key is found in pageVideos, return an object with null values
    return { video_url: null, video_title: null };
  };

  // Destructure video_url and video_title from the object returned by getVideoDataForPage()
  const { video_url, video_title } = getVideoDataForPage();

  // useEffect hook to determine if the modal should be displayed
  useEffect(() => {
    // Check if a video URL exists, a valid pageKey exists, and if the modal has not already been shown for this key (sessionStorage check)
    if (video_url && pageKey && !sessionStorage.getItem(pageKey)) {
      // Set showModal to true so that the modal becomes visible
      setShowModal(true);
      // Record in sessionStorage that the video modal has been shown for this route by setting the pageKey
      sessionStorage.setItem(pageKey, "true");
    }
    // This effect will run whenever video_url or pageKey changes
  }, [video_url, pageKey]);

  // Render the Modal component from react-bootstrap with the required props and content
  return (
    <Modal
      // The modal is shown if showModal is true
      show={showModal}
      // onHide is called when the modal is requested to close; it sets showModal to false
      onHide={() => setShowModal(false)}
      // 'backdrop="static"' prevents the modal from closing when clicking outside of it
      backdrop="static"
      // Disables closing the modal with the keyboard (e.g. pressing the Escape key)
      keyboard={false}
    >
      {/* Modal Header contains the title and a close button */}
      <Modal.Header closeButton>
        {/* Display the title of the video */}
        <Modal.Title>{video_title}</Modal.Title>
      </Modal.Header>
      {/* Modal Body containing the video or a loading indicator */}
      <Modal.Body className="text-center">
        {/* If isLoading is true, display a loading spinner and message */}
        {isLoading && (
          <div
            className="text-center d-flex justify-content-center align-items-center"
            style={{ minHeight: '365px' }} // Ensure a minimum height for proper vertical centering
          >
            <div>
              {/* Display a Bootstrap Spinner component with border animation */}
              <Spinner animation="border" role="status" className="mb-3">
                {/* This span is visually hidden but useful for screen readers */}
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <p>Loading...</p>
            </div>
          </div>
        )}
        {/* When loading is complete and a video URL is available, display the Vimeo video player */}
        {!isLoading && video_url && (
          <Vimeo
            className="w-100 vimeo-iframe" // Apply full width styling and a custom class name
            video={video_url}            // Pass the video URL to the Vimeo component
            height="300px"               // Set a fixed height for the video player
          />
        )}
        {/* Render a hidden Vimeo component that triggers the onReady callback once the video is ready */}
        {video_url && (
          <Vimeo
            video={video_url}                 // Provide the same video URL
            onReady={() => setIsLoading(false)} // Once the video is ready, update isLoading to false
            style={{ display: 'none' }}         // Hide this instance from view (used solely for managing loading state)
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

// Export the component so it can be imported and used in other parts of the application
export default HowItWorksVideoModal;
