import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TaskMenu } from './TaskMenu';
import { TaskList } from './TaskList';
import { GetTaskList } from '../../../apis/Dashboard';


export const TaskManager = () => {
     const { data, refetch } = GetTaskList();
    const [isMaximized, setIsMaximized] = useState(false);

    const fullscreen = () => {
        setIsMaximized(!isMaximized);
    };

    return (
        <Fragment>
            <TaskMenu fullscreen={fullscreen} data={data} onTasksUpdate={refetch}  />
            <Modal show={isMaximized} onHide={() => setIsMaximized(false)} className='task-model'>
                <div className="btn btn-secondary btn-icon btn-sm rounded-pill task-modal-close icons-colors-filled" style={{ padding: '5px' }} onClick={() => setIsMaximized(false)}>
                    <span className="btn-inner">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M1.30955 6.82823L5.17188 6.82823M5.17188 6.82823L5.17188 10.6903M5.17188 6.82823L0.66583 11.334" stroke="white" strokeWidth="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.6903 5.17187L6.82794 5.17187M6.82794 5.17187L6.82794 1.3098M6.82794 5.17187L11.334 0.666125" stroke="white" strokeWidth="1.05" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                </div>
                <TaskList fullscreen={fullscreen} data={data} onTasksUpdate={refetch}/>
            </Modal>
        </Fragment>
    );
};
