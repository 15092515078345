import React, { useEffect, useState } from 'react'
import { Button, Card, Table, Form, Collapse } from 'react-bootstrap';
import { AddNewTasks, GetTaskList, UpdateTasks } from '../../../apis/Dashboard';
import ConfirmationModal from './ConfirmationModal';
import Markdown from './markdowan';

export const TaskList = ({ data = [], onTasksUpdate }) => {
    const { mutate: addTaskMutate } = AddNewTasks();
    const { mutate: updateTaskMutate } = UpdateTasks();
    const [tasks, setTasks] = useState(data);
    const [open, setOpen] = useState(true);
    const groupId = localStorage.getItem("Sub_id")
    const [newTaskText, setNewTaskText] = useState("");
    const [editTaskText, setEditTaskText] = useState("");
    const [editTaskId, setEditTaskId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);

    const moment = require('moment');
    
    const handleAddTask = () => {
        if (newTaskText.trim() === "") return; 
        const newTask = {
            id: tasks.length ? Math.max(tasks.map(task => task.id)) + 1 : 1,
            title: newTaskText,
            completed: false,
            created_at: new Date().toISOString()
        };
        // Add the new task to the local state
        setTasks([newTask, ...tasks]);

        // Call the mutate function to submit the task
        addTaskMutate({ title: newTaskText, group_id: groupId }, {
            onSuccess: () => {
                // Handle successful submission, e.g., clear the input
                setNewTaskText('');
                onTasksUpdate();
            },
            onError: (error) => {
                // Handle errors
            }
        });
    };

    const handleCheckboxChange = (id, completed) => {
        setUpdating(true);
        setTasks(tasks.map(task => task.id === id ? { ...task, completed, dateCompleted: completed ? new Date().toLocaleDateString() : "" } : task));
        const taskCompleted = { id: id, completed: completed };
        updateTaskMutate(taskCompleted, {
            onSuccess: () => {
                setEditTaskId(null);
                setEditTaskText("");
                onTasksUpdate().finally(() => {
                    setUpdating(false); // Set updating to false after refetch
                });
            },
            onError: (error) => {
                console.error("Error updating task:", error);
            }
        });
    };

    const handleDeleteTask = (id) => {
        setTaskToDelete(id);
        setShowModal(true);
    };
    const confirmDelete = () => {
        setUpdating(true);
        const id = taskToDelete;
        setTasks(tasks.filter(task => task.id !== id));
        const deleteTask = { id: id, deleted: true };
        updateTaskMutate(deleteTask, {
            onSuccess: () => {
                setEditTaskId(null);
                setEditTaskText("");
                onTasksUpdate().finally(() => {
                    setUpdating(false); // Set updating to false after refetch
                });
            },
            onError: (error) => {
                console.error("Error updating task:", error);
            }
        });
        setShowModal(false);
    };

    const cancelDelete = () => {
        setShowModal(false);
    };

    const editTask = (event, task_id) => {
        setEditTaskId(task_id);
        const taskToEdit = tasks.find(task => task.id === task_id);
        setEditTaskText(taskToEdit.title);
    };

    const handleEditChange = (event) => {
        setEditTaskText(event.target.value);
    };

    const handleSaveEdit = (taskId) => {
        const updatedTask = tasks.find(task => task.id === editTaskId);
        const updatedTaskData = { id: updatedTask.id, title: editTaskText };

        const updatedTasks = tasks.map(task =>
            task.id === taskId ? { ...task, title: editTaskText } : task
        );
        setTasks(updatedTasks);
        updateTaskMutate(updatedTaskData, {
            onSuccess: (response) => {
                setEditTaskId(null);
                setEditTaskText("");
                onTasksUpdate();
            },
            onError: (error) => {
                console.error("Error updating task:", error);
            }
        });
    };

    useEffect(() => {

        setLoading(true); // Set loading to true when fetching data
        onTasksUpdate().then(() => {
            setLoading(false); // Set loading to false after successful fetch
        }).catch(() => {
            setLoading(false); // Ensure loading is false even if there's an error
        });
    }, [onTasksUpdate]);




    return (
        <div>
            {
                loading || updating ? (
                    <div className="container mt-5">
                        <div className="input-group mb-3 w-75 mx-auto border rounded-3" style={{ backgroundColor: '#F9F9F9', position: "sticky", top: 30, zIndex: 1 }}>
                            <div className="iq-skeleton" style={{ height: '38px', width: '100%', borderRadius: '0.375rem' }}></div>
                        </div>
                        <Card className="mb-3" style={{ height: 'auto' }}>
                            <div className="table-responsive">
                                <Table className='border rounded-3'>
                                    <thead style={{ whiteSpace: "unset" }}>
                                        <tr style={{ borderRadius: "10px 10px 0 0" }} className='bg-soft-secondary'>
                                            <th style={{ fontWeight: '500' }}>Task List</th>
                                            <th>Status</th>
                                            <th>Added Date</th>
                                            <th>Chat</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: "unset", }} className='tasklist'>
                                        {Array.from({ length: 5 }).map((_, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="iq-skeleton" style={{ height: '20px', width: '100%', borderRadius: '4px' }}></div>
                                                </td>
                                                <td>
                                                    <div className="iq-skeleton" style={{ height: '20px', width: '50%', borderRadius: '4px' }}></div>
                                                </td>
                                                <td>
                                                    <div className="iq-skeleton" style={{ height: '20px', width: '50%', borderRadius: '4px' }}></div>
                                                </td>
                                                <td>
                                                    <div className="iq-skeleton" style={{ height: '20px', width: '50%', borderRadius: '4px' }}></div>
                                                </td>
                                                <td>
                                                    <div className="iq-skeleton" style={{ height: '20px', width: '50%', borderRadius: '4px' }}></div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                ):(
                    
           <div>
             <ConfirmationModal
                show={showModal}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
                message="Do you want to delete this task?"
            />

            <div className="container mt-5">
                <div className="input-group mb-3 w-75 mx-auto border rounded-3" style={{ backgroundColor: '#F9F9F9', position: "sticky", top: 30, zIndex: 1 }}>
                    <input
                        type="text"
                        className="form-control border-0 rounded-3"
                        placeholder='Create new task'
                        value={newTaskText}
                        onChange={(e) => setNewTaskText(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleAddTask();
                            }
                        }}
                    />
                    <span className="input-group-text border-0 rounded-3" onClick={handleAddTask}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-text" viewBox="0 0 16 16" >
                            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
                        </svg>
                    </span>
                    {/* <Button  variant="outline-primary" size="sm">Add Task</Button> */}
                </div>
                <Card className="mb-3" style={{ height: 'auto' }}>
                    {/* <Card.Header>Task List</Card.Header> */}
                    <div className="table-responsive">
                        <Table hover className='border rounded-3 tasklistTable'>
                            <thead style={{ whiteSpace: "unset" }}>
                                <tr style={{ borderRadius: "10px 10px 0 0" }} className='bg-soft-secondary'>
                                    <th style={{ fontWeight: '500' }}>Task List</th>
                                    <th>Status</th>
                                    <th>Added Date</th>
                                    <th>Chat</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ whiteSpace: "unset", }} className='tasklist'>
                                {tasks && tasks.filter(task => !task?.completed).map(task => {
                                    const dateForamte = moment(task?.created_at?.date).format('D MMM YYYY')
                                    const taskTitle = typeof task.title === 'string' ? task.title : '';
                                    return (
                                        <tr key={task.id} style={{ whiteSpace: "unset" }}>
                                            <td className='w-75' style={{ whiteSpace: "unset", minWidth: '350px' }}>
                                                {editTaskId === task.id ? (
                                                    <div className='w-100 border rounded-3 p-2 px-3 tasklist-title' style={{ backgroundColor: 'white' }}>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <textarea
                                                                className="form-control border-0 rounded-3 bg-transparent hide-scrollbar"
                                                                value={editTaskText}
                                                                onChange={handleEditChange}
                                                                autoFocus
                                                                style={{
                                                                    width: '97%', textWrap: 'wrap', wordWrap: 'break-word', overflowWrap: 'break-word', hyphens: 'auto',
                                                                    resize: 'none', verticalAlign: 'middle'
                                                                }}
                                                            >
                                                            </textarea>
                                                            <span className='float-end' onClick={() => handleSaveEdit(task.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy2" viewBox="0 0 16 16">
                                                                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5m9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='w-100 border rounded-3 p-2 px-3 tasklist-title' style={{ backgroundColor: '#F9F9F9' }}>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <p
                                                                // readOnly
                                                                className="border-0 rounded-3 bg-transparent hide-scrollbar d-flex"
                                                                // rows={task.title.split(' ').length > 20 ? 2 : 1}
                                                                style={{
                                                                    width: '97%', textWrap: 'wrap', wordWrap: 'break-word', overflowWrap: 'break-word', hyphens: 'auto',
                                                                    resize: 'none', verticalAlign: 'middle'
                                                                }}
                                                            >
                                                                {/* {task.title} */}
                                                                <Markdown content={task.title} />
                                                            </p>
                                                            <span className='float-end' onClick={(e) => editTask(e, task.id)}>
                                                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.4561 16.0355H16.4999" stroke="#38393B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.65001 2.16233C10.2964 1.38982 11.4583 1.27655 12.2469 1.90978C12.2905 1.94413 13.6912 3.03232 13.6912 3.03232C14.5575 3.55599 14.8266 4.66925 14.2912 5.51882C14.2627 5.56432 6.34329 15.4704 6.34329 15.4704C6.07981 15.7991 5.67986 15.9931 5.25242 15.9978L2.21961 16.0358L1.53628 13.1436C1.44055 12.7369 1.53628 12.3098 1.79975 11.9811L9.65001 2.16233Z" stroke="#38393B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M8.18359 4.00098L12.7271 7.49025" stroke="#38393B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={task.completed}
                                                    onChange={(e) => handleCheckboxChange(task.id, e.target.checked)}
                                                />
                                            </td>
                                            <td>{dateForamte}</td>
                                            {/* <td>{task?.created_at ? format(new Date(task.created_at), 'dd/MM/yyyy') : 'N/A'}</td> */}
                                            <td>
                                                <button className="chat-button bg-soft-secondary">
                                                    <span>Chat</span>
                                                    <div className="chat-icon">
                                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="gurus">
                                                            <path opacity="0.4" d="M8.02804 0C3.37306 0 0 3.7977 0 8.01202C0 9.35804 0.392589 10.7441 1.08162 12.01C1.20981 12.2183 1.22584 12.4827 1.13771 12.7311L0.600901 14.5258C0.480721 14.9584 0.849274 15.2789 1.25789 15.1507L2.87631 14.67C3.31697 14.5258 3.66149 14.7101 4.07091 14.9584C5.24066 15.6475 6.69805 16 8.01202 16C11.986 16 16.024 12.9314 16.024 7.98798C16.056 3.62178 12.4827 0 8.02804 0Z" fill="currentColor" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.30288 9.04522C3.74204 9.04522 3.27734 8.58052 3.27734 8.01968C3.27734 7.45083 3.73403 6.99414 4.30288 6.99414C4.87174 6.99414 5.32842 7.45083 5.32842 8.01968C5.32842 8.58052 4.87174 9.03721 4.30288 9.04522ZM7.99624 9.04532C7.42739 9.03731 6.9707 8.58062 6.9707 8.01177C6.9707 7.45093 7.4354 6.98623 7.99624 6.99424C8.56509 6.99424 9.02178 7.45093 9.02178 8.01978C9.02178 8.58062 8.56509 9.04532 7.99624 9.04532ZM10.6621 8.01968C10.6621 8.58052 11.1188 9.04522 11.6876 9.04522C12.2565 9.04522 12.7132 8.58052 12.7132 8.01968C12.7132 7.45083 12.2565 6.99414 11.6876 6.99414C11.1188 6.99414 10.6621 7.45083 10.6621 8.01968Z" fill="currentColor"></path>
                                                        </svg>
                                                    </div>
                                                </button>
                                            </td>
                                            <td>
                                                <Button onClick={() => handleDeleteTask(task.id)} className='bg-transparent border-0'>
                                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.4929 6.10156C12.4929 6.10156 12.0856 11.1528 11.8494 13.2806C11.7369 14.2968 11.1091 14.8923 10.0809 14.9111C8.12411 14.9463 6.16511 14.9486 4.20911 14.9073C3.21986 14.8871 2.60261 14.2841 2.49236 13.2858C2.25461 11.1393 1.84961 6.10156 1.84961 6.10156" stroke="#ED2B2B" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M13.531 3.68066H0.8125" stroke="#ED2B2B" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M11.0809 3.68023C10.4922 3.68023 9.98517 3.26398 9.86967 2.68723L9.68742 1.77523C9.57492 1.35448 9.19392 1.06348 8.75967 1.06348H5.58492C5.15067 1.06348 4.76967 1.35448 4.65717 1.77523L4.47492 2.68723C4.35942 3.26398 3.85242 3.68023 3.26367 3.68023" stroke="#ED2B2B" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </Card>

            </div>
            <div className="container mt-5 pt-5 border-top">
                <span
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    style={{ padding: "45px 0" }}
                >
                    <span className="d-inline-flex align-items-center gap-1" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
                            <path d="M11.25 0.75L6 5.25L0.75 0.75" stroke="currentColor" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Completed Task
                    </span>
                </span>
                <Collapse in={open}>
                    <div id="example-collapse-text" className="mt-5">
                        <Card>
                            {/* <Card.Header>Task List</Card.Header> */}
                            <div className="table-responsive">
                                <Table hover>
                                    <thead>
                                        <tr style={{borderRadius: "10px 10px 0 0", }}className='bg-soft-secondary'>
                                            <th>Task List</th>
                                            <th>Status</th>
                                            <th>Done Date</th>
                                            <th>Chat</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: "unset" }} className='tasklist'>
                                        {tasks && tasks.filter(task => task?.completed).map(task => {
                                            const dateUpdateForamte = moment(task?.updated_at?.date).format('D MMM YYYY')
                                            return (
                                                <tr key={task.id}>
                                                    <td className='w-75' style={{ textDecoration: 'line-through', minWidth: '350px' }}>
                                                        {editTaskId === task.id ? (
                                                           <div className='w-100 border rounded-3 p-2 px-3 tasklist-title' style={{ backgroundColor: 'white' }}>
                                                           <div className="d-flex align-items-center gap-2">
                                                               <textarea
                                                                   className="form-control border-0 rounded-3 bg-transparent hide-scrollbar"
                                                                   value={editTaskText}
                                                                   onChange={handleEditChange}
                                                                   autoFocus
                                                                   style={{
                                                                       width: '97%', textWrap: 'wrap', wordWrap: 'break-word', overflowWrap: 'break-word', hyphens: 'auto',
                                                                       resize: 'none', verticalAlign: 'middle'
                                                                   }}
                                                               >
                                                               </textarea>
                                                               <span className='float-end' onClick={() => handleSaveEdit(task.id)}>
                                                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy2" viewBox="0 0 16 16">
                                                                       <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5m9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                                                                   </svg>
                                                               </span>
                                                           </div>
                                                       </div>
                                                        ) : (
                                                            <div className='w-100 border rounded-3 p-2 px-3 tasklist-title' style={{ backgroundColor: '#F9F9F9' }}>
                                                                <div className="d-flex align-items-center gap-2">
                                                                    <p
                                                                        type="text"
                                                                        className="border-0 rounded-3 bg-transparent text-decoration-line-through hide-scrollbar d-flex"
                                                                        // rows={task?.titel.split(' ').length > 20 ? 2 : 1}
                                                                        style={{
                                                                            width: '97%', textWrap: 'wrap', wordWrap: 'break-word', overflowWrap: 'break-word', hyphens: 'aut0',
                                                                            resize: 'none', verticalAlign: ' middle'
                                                                        }}
                                                                    >
                                                                        {/* {task.title} */}
                                                                        <Markdown content={task.title} />
                                                                    </p>
                                                                    <span className='float-end' onClick={(e) => editTask(e, task.id)}>
                                                                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M10.4561 16.0355H16.4999" stroke="#38393B" strokeWidth="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.65001 2.16233C10.2964 1.38982 11.4583 1.27655 12.2469 1.90978C12.2905 1.94413 13.6912 3.03232 13.6912 3.03232C14.5575 3.55599 14.8266 4.66925 14.2912 5.51882C14.2627 5.56432 6.34329 15.4704 6.34329 15.4704C6.07981 15.7991 5.67986 15.9931 5.25242 15.9978L2.21961 16.0358L1.53628 13.1436C1.44055 12.7369 1.53628 12.3098 1.79975 11.9811L9.65001 2.16233Z" stroke="#38393B" strokeWidth="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M8.18359 4.00098L12.7271 7.49025" stroke="#38393B" strokeWidth="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={task.completed}
                                                            onChange={(e) => handleCheckboxChange(task.id, e.target.checked)}
                                                        />
                                                    </td>
                                                    <td>{dateUpdateForamte}</td>
                                                    <td><button className="chat-button bg-soft-secondary">
                                                        <span>Chat</span>
                                                        <div className="chat-icon">
                                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="gurus">
                                                                <path opacity="0.4" d="M8.02804 0C3.37306 0 0 3.7977 0 8.01202C0 9.35804 0.392589 10.7441 1.08162 12.01C1.20981 12.2183 1.22584 12.4827 1.13771 12.7311L0.600901 14.5258C0.480721 14.9584 0.849274 15.2789 1.25789 15.1507L2.87631 14.67C3.31697 14.5258 3.66149 14.7101 4.07091 14.9584C5.24066 15.6475 6.69805 16 8.01202 16C11.986 16 16.024 12.9314 16.024 7.98798C16.024 3.72559 12.5789 0 8.02804 0Z" fill="currentColor" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.30288 9.04522C3.74204 9.04522 3.27734 8.58052 3.27734 8.01968C3.27734 7.45083 3.73403 6.99414 4.30288 6.99414C4.87174 6.99414 5.32842 7.45083 5.32842 8.01968C5.32842 8.58052 4.87174 9.03721 4.30288 9.04522ZM7.99624 9.04532C7.42739 9.03731 6.9707 8.58062 6.9707 8.01177C6.9707 7.45093 7.4354 6.98623 7.99624 6.99424C8.56509 6.99424 9.02178 7.45093 9.02178 8.01978C9.02178 8.58062 8.56509 9.04532 7.99624 9.04532ZM10.6621 8.01968C10.6621 8.58052 11.1188 9.04522 11.6876 9.04522C12.2565 9.04522 12.7132 8.58052 12.7132 8.01968C12.7132 7.45083 12.2565 6.99414 11.6876 6.99414C11.1188 6.99414 10.6621 7.45083 10.6621 8.01968Z" fill="currentColor" />
                                                            </svg>
                                                        </div>
                                                    </button></td>
                                                    <td>
                                                        <Button onClick={() => handleDeleteTask(task.id)} className='bg-transparent border-0'>
                                                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.4929 6.10156C12.4929 6.10156 12.0856 11.1528 11.8494 13.2806C11.7369 14.2968 11.1091 14.8923 10.0809 14.9111C8.12411 14.9463 6.16511 14.9486 4.20911 14.9073C3.21986 14.8871 2.60261 14.2841 2.49236 13.2858C2.25461 11.1393 1.84961 6.10156 1.84961 6.10156" stroke="#ED2B2B" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M13.531 3.68066H0.8125" stroke="#ED2B2B" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M11.0809 3.68023C10.4922 3.68023 9.98517 3.26398 9.86967 2.68723L9.68742 1.77523C9.57492 1.35448 9.19392 1.06348 8.75967 1.06348H5.58492C5.15067 1.06348 4.76967 1.35448 4.65717 1.77523L4.47492 2.68723C4.35942 3.26398 3.85242 3.68023 3.26367 3.68023" stroke="#ED2B2B" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </Button>
                                                    </td>
                                                    {/* <td><Button variant="outline-success" size="sm">Chat</Button></td>
                                                <td><Button variant="outline-danger" size="sm" onClick={() => handleDeleteTask(task.id)}>Delete</Button></td> */}
                                                </tr>
                                            )
                                        }

                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Collapse>
            </div>
           </div>
                )
            }
        </div>
    )
}
