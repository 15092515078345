import { useMutation, useQuery } from "@tanstack/react-query"
import axios_inst from "../utilities/axios"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setDashboardConfig, setDashboardSidebar, setStatuDashboardSidebarLoaded, Plan_id, LandingPage, setPersonaData, Other, setUserName, setGroupID, IsLoading, SetActivePlan }  from "../store/dashboardConfig/dashboardConfigSlice"
import { findFirstSelectedGroupID, GetConfigData } from '../apis/Dashboard';

var groupId = ""
var planIDs = ""
const CategoryID = localStorage.getItem('CategoryID');

const GetPlans = (meta = false) => {
    const CategoryID = localStorage.getItem('CategoryID');
    const navigate = useNavigate();
    const configDispatch = useDispatch();
    const { data: platformData } = GetConfigData();
    
    const { data, isFetched, isStale } = useQuery({
        queryKey: ['get_plan'],
        enabled: !!platformData?.plan_id, // Only run if platform has plan_id
        refetchOnWindowFocus: false,
 queryFn: async () => {
            const response = await axios_inst.get('api/v4/get_plan');
            
            if (response?.data?.data?.pages?.Learn?.categories) {
                let groups = findFirstSelectedGroupID(response.data.data.pages.Learn);
                
                // Dispatch all the necessary actions
                configDispatch(SetActivePlan(response?.data?.data));
                configDispatch(setStatuDashboardSidebarLoaded(true));
                configDispatch(setUserName(response?.data?.data?.user));
                configDispatch(setDashboardConfig(response?.data?.data?.org));
                configDispatch(setDashboardSidebar(response?.data?.data?.pages));
                configDispatch(setPersonaData(response?.data?.data));
                configDispatch(LandingPage(response?.data?.data?.landing));
                configDispatch(Other(response?.data?.data?.other));
                configDispatch(Plan_id(response?.data?.data?.plan_id));
                configDispatch(IsLoading(false));

                if (typeof groups === "object" && groups?.groupId) {
                    configDispatch(setGroupID(groups?.groupId));
                    localStorage.setItem('Sub_id', groups.groupId);
                    navigate(`/dashboard/${groups.groupId}/advice`);
                } else {
                    navigate(`/dashboard`);
                }
            }
            return response.data;
        }
    });

     return { data, isFetched, isStale }; // Assuming this is a helper component, not rendering anything
};
const GettingStart = () => {
    const navigate = useNavigate();
    const { data: platformData } = GetConfigData();
    return useQuery({
        queryKey: ['getting_start'],
        enabled: platformData && !platformData.plan_id, // Only run if platform exists but has no plan_id
        refetchOnWindowFocus: false,
        queryFn: () =>
            axios_inst
                .get('api/v3/getting_started')
                .then((res) => {
                    
                    if (res.status == 403) {
                        navigate('/403');
                    }
                    planIDs = res.data
                    return res.data
                }).catch(err => {
                    console.log(err);
                }),
    })
}


const GettingStartMutation = () => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: (data) => {
            return axios_inst.post('api/v3/getting_started', data);
        },
        mutationKey: 'getting_start',
        onSuccess: (response) => {
            if (response?.data?.plan_id) {
                navigate(`/dashboard`);
            }
        },
    });
};

export { GettingStart, GettingStartMutation, GetPlans }