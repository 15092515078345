import React, { useState, useEffect } from 'react';
import { Accordion, Button, Card, Form, Modal, useAccordionButton } from 'react-bootstrap';
import { TaskCreate } from './TaskCreate';
import { UpdateTasks } from '../../../apis/Dashboard';
import Markdown from './markdowan';

function CustomToggle({ eventKey, onToggle, isCollapsed }) {
    const decoratedOnClick = useAccordionButton(eventKey, onToggle);

    return (
        <button
            className="btn btn-soft-secondary btn-sm rounded-3 icons-colors"
            style={{ padding: "2px 4px", border: "none" }}
            type="button"
            onClick={decoratedOnClick}
        >
            {isCollapsed ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" fill="currentColor" className="bi bi-arrow-up-short" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" stroke="currentColor" strokeWidth="1" />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" stroke="currentColor" strokeWidth="1" />
                </svg>
            )}
        </button>
    );
}

export const TaskMenu = ({ isMaximized, fullscreen, show, data, onTasksUpdate }) => {
    const { mutate: updateTaskMutate } = UpdateTasks();
    const [isShow, setIsShow] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [tasks, setTasks] = useState(data || []);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        setTasks(data);
    }, [data]);

    const handleCloseFullScreen = () => setIsShow(!isShow);
    const handleToggle = () => setIsCollapsed(!isCollapsed);

    const handleCheckboxChange = (id, completed) => {
        setUpdating(true);
        setTasks(tasks.map(task => task.id === id ? { ...task, completed, dateCompleted: completed ? new Date().toLocaleDateString() : "" } : task));
        const taskCompleted = { id: id, completed: completed };
        updateTaskMutate(taskCompleted, {
            onSuccess: () => {
                onTasksUpdate().finally(() => {
                    setUpdating(false); // Set updating to false after refetch
                });
            },
            onError: (error) => {
                console.error("Error updating task:", error);
            }
        });
    };

    return (
        <div className="w-100 rounded iq-sidebar-background" style={{ border: "1px solid #F0F0F0", background: "rgb(249 249 249 / 18%)" }}>
            <Modal show={isShow} onHide={handleCloseFullScreen} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <TaskCreate onHide={handleCloseFullScreen} />
            </Modal>
            <Accordion defaultActiveKey="0" style={{ display: isMaximized ? "none" : "" }}>
                <Card className="mb-0">
                    <Card.Header className="d-flex p-3 justify-content-between align-items-center gap-3">
                        <div className="d-flex justify-content-center align-items-center gap-1">
                            <h5 className="default-icon text-uppercase text-primary" style={{ verticalAlign: 'middle', margin: '2px 0 0 0', fontSize: "1rem" }}>MY TASKS</h5>
                        </div>
                        <div className="d-inline-flex gap-1">
                            <CustomToggle eventKey="0" onToggle={handleToggle} isCollapsed={isCollapsed} />
                            <button className="btn btn-soft-secondary btn-sm rounded-3 icons-colors" style={{ padding: "2px 4px", border: "none" }} onClick={() => setIsShow(!isShow)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" stroke="currentColor" strokeWidth="1" />
                                </svg>
                            </button>
                            <div className="btn btn-soft-secondary btn-icon btn-md rounded-3 icons-colors" style={{ height: "1.6rem", width: "1.6rem", padding: "2px 4px", border: "none" }} onClick={fullscreen}>
                                <span className="btn-inner">
                                    <svg className={`normal-screen ${show ? '' : 'd-none'} icon-24`} width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5528 5.99656L13.8595 10.8961" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"></path>
                                        <path d="M14.8016 5.97618L18.5524 5.99629L18.5176 9.96906" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"></path>
                                        <path d="M5.8574 18.896L10.5507 13.9964" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"></path>
                                        <path d="M9.60852 18.9164L5.85775 18.8963L5.89258 14.9235" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"></path>
                                    </svg>
                                    <svg className={`full-normal-screen ${!show ? '' : 'd-none'} icon-24`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.7542 10.1932L18.1867 5.79319" stroke="white" strokeWidth="1.5" strokeLinejoin="round"></path>
                                        <path d="M17.2976 10.212L13.7547 10.1934L13.7871 6.62518" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"></path>
                                        <path d="M10.4224 13.5726L5.82149 18.1398" stroke="white" strokeWidth="1.5" strokeLinejoin="round"></path>
                                        <path d="M6.74391 13.5535L10.4209 13.5723L10.3867 17.2755" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0" style={{ background: 'rgb(249, 249, 249)' }}>
                        <Card.Body className="p-3 my-task-list custom-scrollbar">
                            <Form className=''>
                                <div className="text-start ">
                                    {!tasks || updating ? (
                                        <div className='d-flex pt-3 flex-column justify-content-start h-100'>
                                            <p className='iq-skeleton' style={{ width: "80%" }}>&nbsp;</p>
                                            <p className='iq-skeleton' style={{ width: "90%" }}>&nbsp;</p>
                                            <p className='iq-skeleton' style={{ width: "83%" }}>&nbsp;</p>
                                        </div>

                                    ) : (
                                        <>

                                            {tasks && tasks.map((task, index) => {
                                                return (
                                                    <Form.Check
                                                        style={{ whiteSpace: "break-spaces" }}
                                                        key={task.id || index}
                                                        className={`text-start ${task.completed ? 'check-with-linethrough' : ''}`}
                                                        reverse
                                                        name="taskGroup"
                                                        type="checkbox"
                                                        id={`task-${task.id}`}
                                                    >
                                                        <Form.Check.Input type="checkbox"  checked={task.completed}
                                                    onChange={(e) => handleCheckboxChange(task.id, e.target.checked)}/>
                                                        <Form.Check.Label>
                                                            <Markdown content={task.title} />
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
};
