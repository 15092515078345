import { memo, useEffect, useState } from 'react'


// image
import LogoFull from '../../../assets/images/logo.png'
import LogoMini from '../../../assets/images/logo-mini.jpg'
import logoplaceholder from '../../../assets/images/logo-placeholder.png'
import { useSelector } from 'react-redux'
import LogoSkeleton from './skeleton/LogoSkeleton'
// import Sample from './../../../assets/images/avatars/01.png'



const Logo = memo(({isLogoMini}) => {

    function isValidJSON(storedData) {
        try {
            JSON.parse(storedData);
            return true;  // Valid JSON
        } catch (error) {
            return false;  // Invalid JSON
        }
    }
    const selector = useSelector(state => state.config);
    const [logo,setLogo]=useState( logoplaceholder );
    useEffect(() => {
        const storedData = localStorage.getItem('typography');
        if (storedData && isValidJSON( storedData ) ) {
          const {logo_url } = JSON.parse(storedData);
          setLogo(logo_url)
        }
      }, []);

    // const selector = useSelector(state => state.dashboardConfig.config);

    return (
        <>
            <div className="logo-main">
                <img id="mainsitelogo" style={{width:'auto'}} src={logo} alt='logo' className="logo-normal img-fluid" loading='lazy' />
                {isLogoMini === true ? (
                    <img src={window.iqonic_build + LogoMini} alt='logo' className="logo-mini img-fluid" loading='lazy' />
                ) : ('') }
            </div>
        </>
    )
})

Logo.displayName = "Logo"
export default Logo
