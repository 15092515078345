import React from "react";
import Default from '../layouts/dashboard/default';
import NavTabLayout from '../layouts/nav-tab-layout';
import ImpaceLayout from '../layouts/dashboard/impact-layout';

// Pages
import Todo from '../views/dashboard/todo';
import ServiceWidget from '../components/widget/service-widget';
import EventWidget from '../components/widget/event-widget';
import AdviceWidget from '../components/widget/advice-widget';
import BusinessProfile from '../views/dashboard/business-profile';
import CarbonFootprint from '../views/dashboard/carbon-footprint';
import ImpactStatement from '../views/dashboard/impact-statement';
import Coaching from '../views/dashboard/coaching';
import Toolkit from '../views/dashboard/toolkit';
import ToolkitLayout from '../layouts/dashboard/toolkit-layout';
import VideoWidget from '../components/widget/video-widget';
import NewsWidget from '../components/widget/news-widget';
import AppsWidget from '../components/widget/apps-widget';

// Partner Portal
import PartnerPortal from "../views/dashboard/partner-portal";

const AppRoutes = (sideBarData, setSideBarData, newPages, setNewPages, activeKey, setActiveKey) => {
    return [
        {
            path: '',
            element: <Default sideBarData={sideBarData} setSideBarData={setSideBarData} newPages={newPages} setNewPages={setNewPages}/>,
            children: [
                {
                    path: 'todo',
                    element: <Todo />,
                },
                {
                    path: 'dashboard',
                    element: <NavTabLayout />,
                    children: [
                        {
                            path: ':group/advice',
                            element: <AdviceWidget />,
                        },
                        {
                            path: ':group/event-widget',
                            element: <EventWidget />,
                        },
                        {
                            path: ':group/service-widget',
                            element: <ServiceWidget />,
                        },
                        {
                            path: ':group/video-widget',
                            element: <VideoWidget />,
                        },
                        {
                            path: ':group/news-widget',
                            element: <NewsWidget />,
                        },
                        {
                            path: ':group/apps-widget',
                            element: <AppsWidget />,
                        },
                    ],
                },
                {
                    path: '403',
                    element: <Todo />,
                },
            ],
        },
        {
            path: 'business',
            element: <ImpaceLayout newPages={newPages} setNewPages={setNewPages}/>,
            children: [
                {
                    path: '',
                    element: <BusinessProfile />,
                },
                {
                    path: 'carbon-footprint',
                    element: <CarbonFootprint />,
                },
                {
                    path: 'impact-statement',
                    element: <ImpactStatement />,
                },
            ],
        },
        {
            path: 'coaching',
            element: <ImpaceLayout newPages={newPages} setNewPages={setNewPages}/>,
            children: [
                {
                    path: '',
                    element: <Coaching />,
                },
            ],
        },
        {
            path: '/toolkit/',
            element: <ToolkitLayout newPages={newPages} setNewPages={setNewPages}/>,
            children: [
                {
                    path: ':toolkit_id',
                    element: <Toolkit sideBarData={sideBarData} setSideBarData={setSideBarData}/>
                },
            ],
        },
        ...(newPages ? newPages.map(page => ({
            path: `/${page.url}`,
            element: <ImpaceLayout newPages={newPages} setNewPages={setNewPages}/>,
            children: [
                {
                    path: ':tabUrl',
                    element: <PartnerPortal activeKey={activeKey} setActiveKey={setActiveKey} pageUrl={page.url}/>,
                },
            ],
        })) : []),
        // Dynamic Fallback Route for "portal/admin/My Links"
        {
            path: '/:admin/:tabUrl',
            element: <ImpaceLayout sideBarData={sideBarData} setSideBarData={setSideBarData} newPages={newPages} setNewPages={setNewPages}/>,
            children: [
                {
                    path: '',
                    element: <PartnerPortal activeKey={activeKey} setActiveKey={setActiveKey} />,
                },
            ],
        },
    ]
};

export default AppRoutes;
