import { useEffect, useState } from 'react'
import Card from '../components/bootstrap/card'
import Chat from '../components/chat'
// react-bootstrap
import { Row, Col } from 'react-bootstrap'
import NavLayout from '../components/nav-layout/NavLayout'

import { Outlet } from 'react-router-dom'
import { Instruction } from './dashboard/Instruction'
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";


const NavTabLayout = () => {
    const location = useLocation()
    const welcomepage = useSelector( state => state.welcomepage );
    const landingPage = useSelector( state => state.landingPage );
    const loader      = useSelector( state => state.isLoading );

    const [visibleChat, setVisibleChat] = useState(true)
    const [showChatModal, setShowChatModal] = useState(false);
    const [mobileView, setMobileView] = useState(false);
        
    const [showArrow, setShowArrow] = useState(true); // State to control arrow visibility
    const [lastScrollY, setLastScrollY] = useState(0); // State to track the last scroll position

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // Hide arrow when scrolling down, show it when scrolling up
            if (currentScrollY > lastScrollY) {
                setShowArrow(false);
            } else {
                setShowArrow(true);
            }

            // Update the last scroll position
            setLastScrollY(currentScrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

   

    const handleClose = () => setShowChatModal(false);
    const handleShow = () => setShowChatModal(true);


    useEffect(() => {
        const handleResize = () => {
            const maxwidth = window.innerWidth <= 820;
            const minwidth = window.innerWidth >= 420;
            const mobileWidth = window.innerWidth <= 420;

            if (maxwidth && minwidth) {
                setVisibleChat(false);
                setMobileView(true)
            }
            if (mobileWidth) {
                setMobileView(true)
                setVisibleChat(false)
            }

        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const hasWelcomeCompleted = sessionStorage.getItem("welcomeCompleted");

    return (
        <> { ( location.pathname === "/dashboard" || location.pathname === "/dashboard/" ) && loader && (
            <div style={{ position: "absolute", zIndex: "999", top: "0", left: "0", width: '100%', height: "100vh", display: "flex", justifyContent: "center", alignItems: "center",background:"#8a8a8a73" }}> <div className="spinner"></div></div>
        )}

            <Row>
                <Col md={visibleChat ? 12 : 12} lg={visibleChat ? 9 : 12} sm="12" style={{ position: "relative" }}>
                    <Card>
                        {landingPage?.type === "welcome" && ! welcomepage && !hasWelcomeCompleted ? (
                            <Card.Body>
                                {!landingPage?.type === "welcome" ? (
                                    <div>
                                        <p className='iq-skeleton' style={{ width: "100%", height: "100vh" }}>&nbsp;</p>
                                    </div>
                                ) : (
                                    <>
                                    <Instruction />
                                    </>
                                )}
                            </Card.Body>
                        ) : (
                            <Card.Body>
                                {(location.pathname.endsWith("/advice") ||
                                    location.pathname.endsWith("/service-widget") ||
                                    location.pathname.endsWith("/event-widget") ||
                                    location.pathname.endsWith("/video-widget") ||
                                    location.pathname.endsWith("/apps-widget") ||
                                    location.pathname.endsWith("/news-widget")) && (
                                        <div className='mb-3'>
                                            <div className='nav scrollable-x-nav'>
                                                <NavLayout />
                                            </div>
                                        </div>
                                )}
                                {(!location.pathname.endsWith("/advice") &&
                                    !location.pathname.endsWith("/service-widget") &&
                                    !location.pathname.endsWith("/event-widget") &&
                                    !location.pathname.endsWith("/video-widget") &&
                                    !location.pathname.endsWith("/apps-widget") &&
                                    !location.pathname.endsWith("/news-widget")) && (
                                        <div className='nav' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                                            <div style={{
                                                // backgroundImage: `url(${landingTitle.media.image_background})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center'
                                            }}>
                                                Please select SUPPORT AREAS
                                            </div>
                                        </div>
                                    )}

                                {/* <div className='tab-content'>
                                    <Outlet></Outlet>
                                </div> */}


                                <div className="tab-content" style={{ position: "relative", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                                    <Outlet />
                                </div>

                                {/* Fixed arrow indicator */}
                                {showArrow && (
                                    <div className="arrow-down-wrapper">
                                        <div className='flex justify-content-center gap-1'>
                                            <span className="no-mob" style={{ fontSize: "12px", color: "#888" }}>Scroll down</span>
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25"
                                                    height="18"
                                                    viewBox="0 0 25 25"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-arrow-down"
                                                    style={{ color: "#666" }}
                                                >
                                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                                    <polyline points="19 12 12 19 5 12"></polyline>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                )}

                            </Card.Body>
                        )}
                    </Card>
                </Col>

                {visibleChat ?
                    <Col lg="3" md="12" sm="12" className='resize'>
                        <Chat onToggle={() => setVisibleChat(false)} visible={visibleChat}></Chat>
                    </Col> :
                    <button onClick={() => mobileView ? setShowChatModal(true) : setVisibleChat(true)} style={{ top: "25%" }} className='btn btn-fixed-end btn-primary btn-icon btn-setting 6'>
                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-20" height="20">
                            <path opacity="0.4" d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M11.9807 13.2901C11.2707 13.2801 10.7007 12.7101 10.7007 12.0001C10.7007 11.3001 11.2807 10.7201 11.9807 10.7301C12.6907 10.7301 13.2607 11.3001 13.2607 12.0101C13.2607 12.7101 12.6907 13.2901 11.9807 13.2901ZM7.37033 13.2901C6.67033 13.2901 6.09033 12.7101 6.09033 12.0101C6.09033 11.3001 6.66033 10.7301 7.37033 10.7301C8.08033 10.7301 8.65033 11.3001 8.65033 12.0101C8.65033 12.7101 8.08033 13.2801 7.37033 13.2901ZM15.3105 12.0101C15.3105 12.7101 15.8805 13.2901 16.5905 13.2901C17.3005 13.2901 17.8705 12.7101 17.8705 12.0101C17.8705 11.3001 17.3005 10.7301 16.5905 10.7301C15.8805 10.7301 15.3105 11.3001 15.3105 12.0101Z" fill="currentColor"></path>
                        </svg>
                    </button>
                }
            </Row>
            {showChatModal && (
                <Modal show={showChatModal} onHide={handleClose} centered>
                    <Modal.Body>
                        <Chat onToggle={handleClose} visible={showChatModal} />
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}

export default NavTabLayout