import React, { useEffect, useState } from 'react'
import ImpactSubHeader from '../../components/partials/dashboard/headerstyle/impact-sub-header'
import img1 from "../../assets/images/business.png";
import girl from "../../assets/images/girl.png";
import boy from "../../assets/images/boy.png";
import guru1 from "../../assets/images/guru1.png";
import guru2 from "../../assets/images/guru2.png";
import guru3 from "../../assets/images/guru2.png";
import guru4 from "../../assets/images/guru4.png";
import guru5 from "../../assets/images/guru5.png";
import guru6 from "../../assets/images/guru6.png";
import chart1 from "../../assets/images/chart1.png";
import chart2 from "../../assets/images/chart2.png";
import chart3 from "../../assets/images/chart3.png";
import chart4 from "../../assets/images/chart4.png";
import chart5 from "../../assets/images/chart5.png";
import chart6 from "../../assets/images/chart6.png";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { WelcomePage } from '../../store/dashboardConfig/dashboardConfigSlice'
import { Modal, Button } from 'react-bootstrap';
import { PersonaAvatar } from '../../components/partials/components/PersonaAvatar';
import { GetToolkit } from '../../apis/Dashboard';
import EventSourceObj from '../../utilities/EventSource';

// import Tour from './tour';

export const Instruction = () => {
    const { data } = GetToolkit()
    const personaDetails = useSelector(state => state.pages);
    const landingPage = useSelector(state => state.landingPage);
    const SideBar = useSelector(state => state.landing);
    const dispatch = useDispatch()
    const [openModel, setOpenModel] = useState(false)
    const plan_id = useSelector(state => state.plan_id);
    const [avatarDetail, setAvatarDetail] = useState()
    const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    const [banner, setBanner] = useState()
    const [status,setStatus]=useState()
    // const Banner = SideBar.Business?.banner

    const landingTitle = landingPage?.data[0].pages?.advice
    useEffect(() => {
        if (SideBar) {
            setBanner(SideBar)
        }
    }, [SideBar])


    if (!plan_id) {
        // development
        // window.location.href = `${baseUrl}/zeroheros/index.php/membership-account/membership-levels/`;
        //   live
        //   window.location.href = `${baseUrl}/index.php/membership-account/membership-levels/`;
    }
    const handleClick = (e) => {
        e.preventDefault(); // Prevent any default behavior
        sessionStorage.setItem('welcomeCompleted', true);
        dispatch(WelcomePage(true));
    }
    const handleOpenPopup = (e, disc, avatar) => {
        const avatarDetails = {
            skills: disc.skills,
            text: disc.text,
            title: disc.title,
            avatar: avatar
        };
        setAvatarDetail(avatarDetails)
        setOpenModel(true)
        setStatus(0)
    }
    const handlePopupToolkit = (e, disc) => {
        const avatarDetails = {
            skills: disc.skills,
            text: disc.description,
            title: disc.title,
            avatar: disc.media.thumbnail
        };
        setAvatarDetail(avatarDetails)
        setOpenModel(true)
        setStatus(1)
    }
    const handleClose = () => {
        setOpenModel(false)
    }


    useEffect(() => {
        // const businessSummaryKey = "summary"; // local
        const businessSummaryKey = "association?summary"; // live
        let event = EventSourceObj(`api/v3/${businessSummaryKey}`);
        let eventResponses = {};
      
        event.addEventListener('message', (eventData) => {
          let eventJsonData = JSON.parse(eventData.data);
          eventResponses = { ...eventResponses, ...eventJsonData };
      
          // Convert eventJsonData to string before storing it in businessSummary
          let { stream, finished, type } = eventJsonData;
      
          if (type === "summary" && stream) {
            // Update businessSummary by appending new data
            localStorage.setItem("businessSummarylocal",stream); // Store updated string in localStorage
          }
        });
      
        event.addEventListener('error', (eventData) => {
          event.close();
        }, { once: true });
      
        return () => {
          event.close();
        };
      }, []);


    return (
        <div className="">
            <PersonaAvatar show={openModel} handleClose={handleClose} avatarDetail={avatarDetail} status={status} />
            {/* <ImpactSubHeader image={window.iqonic_build + img1}> */}
            

            <div style={{ backgroundImage: `url(${landingTitle.media.image_background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                <div className="site-banner banner-home">
                    <div className='close-icon-main'>
                        <button className='close-icon-child' onClick={handleClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                        </button>
                    </div>
                    <div className='d-flex flex-column align-items-center w-100'>
                        <h1>{landingTitle?.title}</h1>
                        <div className='row'>
                            <div className='col-lg-3 col-md-2 d-md-block d-none'></div>
                            <div className='col-lg-6 col-md-8'>
                                <p className='text-center mb-4'>{landingTitle?.description}
                                </p>
                            </div>
                            <div className='col-lg-3 col-md-2 d-md-block d-none'></div>
                        </div>
                    </div>
                    <div className='tools' style={{
                        position: "relative",
                        left: "40%"
                    }}></div>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-1 d-xxl-block d-none'></div>
                        <div className='col-xxl-10'>
                            <div className='text-center'>
                                <div className="row row-cols-1 row-cols-lg-2 g-4">
                                    <div className="col" >
                                        <div className="card" style={{ zIndex: "1" }}>
                                            <div className="card-body" style={{ background: " #F9F9F9", borderRadius: "20px" }}>
                                                <h4 className="card-title">Coaches</h4>
                                                {!personaDetails.Learn.categories ? (
                                                    <div>
                                                        <p className='iq-skeleton' style={{ width: "80%", height: "50px" }}>&nbsp;</p>
                                                        <p className='iq-skeleton' style={{ width: "90%", height: "35px" }}>&nbsp;</p>
                                                        <p className='iq-skeleton' style={{ width: "80%", height: "50px" }}>&nbsp;</p>
                                                        <p className='iq-skeleton' style={{ width: "90%", height: "35px" }}>&nbsp;</p>
                                                    </div>
                                                ) :
                                                    <div className="row gy-4" style={{ minHeight: "243px" }}>
                                                        {Object.values(personaDetails.Learn.categories)
                                                            .filter(item => item.selected)
                                                            .map((item) => {
                                                                return (
                                                                    <div className="col-md-4 col-sm-6">
                                                                        <div className="coaches-box" onClick={(e) => handleOpenPopup(e, item?.persona?.description, item?.persona?.media?.avatar)}>
                                                                            <div className="image">
                                                                                <img src={!item?.persona?.media?.avatar ? guru1 : item?.persona?.media?.avatar} alt='image' className='coaches-image' />
                                                                                <button onClick={(e) => handleOpenPopup(e, item?.persona?.description, item?.persona?.media?.avatar)} className="coaches-info" >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                                        <g clipPath="url(#clip0_2893_2971)">
                                                                                            <rect width="18" height="18" rx="9" fill="white" />
                                                                                            <path d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9748 18 9C18 4.02578 13.9748 0 9 0ZM9 16.7442C4.72985 16.7442 1.25582 13.2702 1.25582 9C1.25582 4.72982 4.72985 1.25582 9 1.25582C13.2701 1.25582 16.7442 4.72982 16.7442 9C16.7442 13.2702 13.2701 16.7442 9 16.7442Z" fill="#38B349" />
                                                                                            <path d="M9.34659 5L9.26136 11.2727H8.27273L8.1875 5H9.34659ZM8.76705 13.7955C8.55682 13.7955 8.37642 13.7202 8.22585 13.5696C8.07528 13.419 8 13.2386 8 13.0284C8 12.8182 8.07528 12.6378 8.22585 12.4872C8.37642 12.3366 8.55682 12.2614 8.76705 12.2614C8.97727 12.2614 9.15767 12.3366 9.30824 12.4872C9.45881 12.6378 9.53409 12.8182 9.53409 13.0284C9.53409 13.1676 9.49858 13.2955 9.42756 13.4119C9.35938 13.5284 9.26705 13.6222 9.15057 13.6932C9.03693 13.7614 8.90909 13.7955 8.76705 13.7955Z" fill="#38B349" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_2893_2971">
                                                                                                <rect width="18" height="18" rx="9" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                            <h6 className='mt-2 mb-0 coaches-title'>
                                                                                <a href="#">{item?.persona?.description?.title}</a>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card" style={{ zIndex: "1" }}>
                                            <div className="card-body " style={{ background: " #F9F9F9", borderRadius: "20px" }}>
                                                <h4 className="card-title">Tools</h4>
                                                {data?.data?.categories && (
                                                    <div className="row gy-4">
                                                        {Object.values(data.data.categories)
                                                            .filter(item => item && item.selected)
                                                            .map((item, index) => (
                                                                Object.values(item.toolkits)
                                                                    .filter(toolkit => toolkit.selected)
                                                                    .map((selecter, index) => {
                                                                        return (
                                                                            <div className="col-md-4 col-sm-6" key={index}>
                                                                                <div className="coaches-box" onClick={(e) => handlePopupToolkit(e, selecter)}>
                                                                                    <div className="image no-border">
                                                                                        <img
                                                                                            src={selecter.media.thumbnail}
                                                                                            alt="image"
                                                                                            className="coaches-image"
                                                                                        />
                                                                                        <a href="#" className="coaches-info">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="18"
                                                                                                height="18"
                                                                                                viewBox="0 0 18 18"
                                                                                                fill="none"
                                                                                            >
                                                                                                <g clipPath="url(#clip0_2893_2971)">
                                                                                                    <rect
                                                                                                        width="18"
                                                                                                        height="18"
                                                                                                        rx="9"
                                                                                                        fill="white"
                                                                                                    />
                                                                                                    <path
                                                                                                        d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9748 18 9C18 4.02578 13.9748 0 9 0ZM9 16.7442C4.72985 16.7442 1.25582 13.2702 1.25582 9C1.25582 4.72982 4.72985 1.25582 9 1.25582C13.2701 1.25582 16.7442 4.72982 16.7442 9C16.7442 13.2702 13.2701 16.7442 9 16.7442Z"
                                                                                                        fill="currentColor"
                                                                                                    />
                                                                                                    <path
                                                                                                        d="M9.34659 5L9.26136 11.2727H8.27273L8.1875 5H9.34659ZM8.76705 13.7955C8.55682 13.7955 8.37642 13.7202 8.22585 13.5696C8.07528 13.419 8 13.2386 8 13.0284C8 12.8182 8.07528 12.6378 8.22585 12.4872C8.37642 12.3366 8.55682 12.2614 8.76705 12.2614C8.97727 12.2614 9.15767 12.3366 9.30824 12.4872C9.45881 12.6378 9.53409 12.8182 9.53409 13.0284C9.53409 13.1676 9.49858 13.2955 9.42756 13.4119C9.35938 13.5284 9.26705 13.6222 9.15057 13.6932C9.03693 13.7614 8.90909 13.7955 8.76705 13.7955Z"
                                                                                                        fill="currentColor"
                                                                                                    />
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath id="clip0_2893_2971">
                                                                                                        <rect
                                                                                                            width="18"
                                                                                                            height="18"
                                                                                                            rx="9"
                                                                                                            fill="white"
                                                                                                        />
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </a>
                                                                                    </div>
                                                                                    <h6 className="mt-2 mb-0 coaches-title">
                                                                                        <a href="#">{selecter?.title}</a>
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })

                                                            ))}
                                                    </div>
                                                )}
                                                {/* <div className='row gy-4'>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="coaches-box">
                                                            <div className="image no-border">
                                                                <img src={chart1} alt='image' className='coaches-image' />
                                                                <a href="#" className="coaches-info" >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <g clipPath="url(#clip0_2893_2971)">
                                                                            <rect width="18" height="18" rx="9" fill="white" />
                                                                            <path d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9748 18 9C18 4.02578 13.9748 0 9 0ZM9 16.7442C4.72985 16.7442 1.25582 13.2702 1.25582 9C1.25582 4.72982 4.72985 1.25582 9 1.25582C13.2701 1.25582 16.7442 4.72982 16.7442 9C16.7442 13.2702 13.2701 16.7442 9 16.7442Z" fill="currentColor" />
                                                                            <path d="M9.34659 5L9.26136 11.2727H8.27273L8.1875 5H9.34659ZM8.76705 13.7955C8.55682 13.7955 8.37642 13.7202 8.22585 13.5696C8.07528 13.419 8 13.2386 8 13.0284C8 12.8182 8.07528 12.6378 8.22585 12.4872C8.37642 12.3366 8.55682 12.2614 8.76705 12.2614C8.97727 12.2614 9.15767 12.3366 9.30824 12.4872C9.45881 12.6378 9.53409 12.8182 9.53409 13.0284C9.53409 13.1676 9.49858 13.2955 9.42756 13.4119C9.35938 13.5284 9.26705 13.6222 9.15057 13.6932C9.03693 13.7614 8.90909 13.7955 8.76705 13.7955Z" fill="currentColor" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_2893_2971">
                                                                                <rect width="18" height="18" rx="9" fill="white" />
                                                                            </clipPath>
                                                                    </defs>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <h6 className='mt-2 mb-0 coaches-title'>
                                                                <a href="#">Marketing</a>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="coaches-box">
                                                            <div className="image no-border">
                                                                <img src={chart2} alt='image' className='coaches-image' />
                                                            </div>
                                                            <h6 className='mt-2 mb-0 coaches-title'>
                                                                <a href="#">Sales</a>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="coaches-box">
                                                            <div className="image no-border">
                                                                <img src={chart3} alt='image' className='coaches-image' />
                                                            </div>
                                                            <h6 className='mt-2 mb-0 coaches-title'>
                                                                <a href="#">Financial</a>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="coaches-box">
                                                            <div className="image no-border">
                                                                <img src={chart4} alt='image' className='coaches-image' />
                                                            </div>
                                                            <h6 className='mt-2 mb-0 coaches-title'>
                                                                <a href="#">Operations</a>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="coaches-box">
                                                            <div className="image no-border">
                                                                <img src={chart5} alt='image' className='coaches-image' />
                                                            </div>
                                                            <h6 className='mt-2 mb-0 coaches-title'>
                                                                <a href="#">Branding</a>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="coaches-box">
                                                            <div className="image no-border">
                                                                <img src={chart6} alt='image' className='coaches-image' />
                                                            </div>
                                                            <h6 className='mt-2 mb-0 coaches-title'>
                                                                <a href="#">Analytics</a>
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-1 d-xxl-block d-none'></div>
                    </div>
                    {/* <div className='d-flex my-5 px-50 align-items-center justify-content-center flex-column' style={{ textAlign: "center" }}>
                    </div> */}
                    <div className='text-center'>
                        <button type="button" className="btn btn-primary" onClick={handleClick}>Get Started</button>
                    </div>
                    <img src={landingTitle.media.left_image} className='site-banner-img-1' alt="img" />
                    <img src={landingTitle.media.right_image} className='site-banner-img-2' alt="img" />
                </div>
            </div>
            {/* </ImpactSubHeader> */}

        </div>

    )
}
