import React from 'react'
import { Modal } from 'react-bootstrap'
import guru1 from "../../../assets/images/guru1.png";
import Markdown from './markdowan';

export const PersonaAvatar = ({ show, handleClose, avatarDetail,status }) => {
    return (
        <Modal show={show} onHide={handleClose} className='modal-coaches-info'>
            <Modal.Header closeButton>
                <div className='d-inline-flex align-items-center gap-2 flex-wrap'>
                    <div className='text-primary'>
                        <i className="bi bi-info-circle gurus"></i>
                    </div>
                    <h4 className='mb-0 line-height-normal'>{avatarDetail?.title}</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-sm-row flex-column gap-3'>
                    <div className='flex-shrink-0'>
                        <img className='modal-coaches-image' src={!avatarDetail?.avatar ? guru1 : avatarDetail?.avatar} />
                    </div>
                    <div>
                        {/* <p className='m-0'>{avatarDetail?.text}</p> */}
                        <Markdown content={avatarDetail?.text} />
                        {status === 0 && (
                        <div className='mt-5'>
                            <div className='mb-2 d-flex align-items-center gap-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                                    <path opacity="0.4" d="M0.710526 5.34115L9.18853 5.34115C9.58074 5.34115 9.89905 5.65947 9.89905 6.05168C9.89905 6.44389 9.58074 6.76221 9.18853 6.76221L0.710526 6.76221C0.318316 6.76221 0 6.44389 0 6.05168C0 5.65947 0.318316 5.34115 0.710526 5.34115Z" fill="#005826" />
                                    <mask maskUnits="userSpaceOnUse" x="8" y="0" width="10" height="12">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.47852 11.5V0.603602H17.418V11.5H8.47852Z" fill="white" />
                                    </mask>
                                    <g>
                                        <path d="M9.18904 0.603318C9.32167 0.603318 9.45241 0.640265 9.56799 0.712265L17.0863 5.45005C17.2928 5.58079 17.4188 5.80721 17.4188 6.05163C17.4188 6.29605 17.2928 6.52248 17.0863 6.65321L9.56799 11.391C9.34915 11.5284 9.07252 11.5369 8.84609 11.4109C8.61873 11.2858 8.47852 11.0481 8.47852 10.7894V1.31384C8.47852 1.05521 8.61873 0.817423 8.84609 0.69237C8.95315 0.632687 9.07157 0.603318 9.18904 0.603318Z" fill="#005826" />
                                    </g>
                                </svg>
                                <h6 className='m-0'>Skills</h6>
                            </div>
                            <p className='m-0'>{avatarDetail?.skills}</p>
                        </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
